import { Select } from 'antd';
import usePredefinedData from 'context/predefined_data/hooks';
import useAuthentication from 'context/security_authentication/hook';
import React, { useEffect, useState } from 'react';

type Props = Readonly<{
  placeholder?: string;
}>;
const DivisionSelect = React.forwardRef((props: Props, ref) => {
  const { placeholder } = props;
  const { divisions, getDivisions } = usePredefinedData();
  const { currentAccount } = useAuthentication();
  const [selectedDivisions, setSelectedDivisions] = React.useState(() =>
    currentAccount?.userDivisionMappings?.map(d => d.divisionId),
  );
  const [options, setOptions] = useState<any[]>([]);
  const selectedDivisionRef = React.useRef(selectedDivisions);

  const handleChange = value => {
    selectedDivisionRef.current = value;
    setSelectedDivisions(value);
  };

  React.useImperativeHandle(ref, () => ({
    getSelectedDivisions: () => selectedDivisionRef.current,
    reset: () => {
      setSelectedDivisions(
        currentAccount?.userDivisionMappings?.map(d => d.divisionId),
      );
    },
  }));

  useEffect(() => {
    getDivisions();
  }, []);

  useEffect(() => {
    if (divisions) {
      const divisionOptions = divisions.map(division => {
        return { value: division.id, label: division.name };
      });

      setOptions(divisionOptions);
    }
  }, [divisions]);

  return (
    <div className="flex items-center">
      <span className="flex mr-1 items-center">Select Division(s)</span>
      <Select
        options={options}
        defaultValue={selectedDivisions}
        value={selectedDivisions}
        allowClear
        placeholder={placeholder}
        style={{ width: '280px' }}
        mode="multiple"
        maxTagCount="responsive"
        onChange={handleChange}
      />
    </div>
  );
});

export default DivisionSelect;
