import Spinner from 'components/Loaders/Spinner';
import React, { Suspense } from 'react';

/**
 *
 * Reusable component for lazy loading
 *
 * @export
 * @param {{
 *   loader?: React.ReactNode;
 *   component: React.ReactNode;
 * }} {
 *   loader = <Spinner />, // Custom loader
 *   component, // Component to be lazy loaded. Must be loaded with React.lazy()
 * }
 *
 * @returns JSX.Element
 *
 * @example
 * const Surcharges = React.lazy(() => import('./forms/surcharges'));
 * <Lazy component={<Surcharges />} />
 */

export default function Lazy({
  loader = <Spinner />,
  component,
}: Readonly<{
  loader?: React.ReactNode;
  component: React.ReactNode;
}>) {
  return <Suspense fallback={loader}>{component}</Suspense>;
}
