import {
  TBookingMilestoneTypes,
  TBookingPlaceTypes,
} from 'components/config/types/booking';
import { orderBy } from 'lodash';
import { create } from 'zustand';
import { getMilestoneDate } from '../BookingPlaceCard/BookingPlaceTab/Milestone/helper';
import { IActions, IState, TBookingPlace } from './types';

type GeneralNamespace = 'pickup' | 'activity' | 'delivery' | string;
type ExtraNamespace = 'pickup' | 'activity' | string;
let bookingPlaces = new Map<string, Map<string, TBookingPlace>>();

const initBookingPlaces = () => {
  bookingPlaces = new Map<string, Map<string, TBookingPlace>>();
  bookingPlaces.set(
    'pickup',
    new Map().set(
      'pickup',
      getInitialPlaceState(
        'pickup',
        TBookingPlaceTypes.Pickup,
        TBookingMilestoneTypes.REQUIRED,
      ),
    ),
  );

  bookingPlaces.set(
    'activity',
    new Map().set(
      'activity',
      getInitialPlaceState(
        'activity',
        TBookingPlaceTypes.Activity,
        TBookingMilestoneTypes.REQUIRED,
      ),
    ),
  );

  bookingPlaces.set(
    'delivery',
    new Map().set(
      'delivery',
      getInitialPlaceState(
        'delivery',
        TBookingPlaceTypes.Delivery,
        TBookingMilestoneTypes.REQUIRED,
      ),
    ),
  );
};

const useBookingPlacesStore = create<IState & IActions>(set => ({
  bookingPlaces,
  currentPlaces: [],
  initBookingPlaces: () => {
    initBookingPlaces();
    set({ bookingPlaces: new Map(bookingPlaces) });
  },
  updatePlace,
  deletePlace: (key: string) => {
    deletePlace(key);
    set({ bookingPlaces: new Map(bookingPlaces) });
  },
  addExtraPlace: (namespace: string, extraNumber: number) => {
    addExtraPlace(namespace, extraNumber);
    set({ bookingPlaces: new Map(bookingPlaces) });
  },

  populatePlacesWithData: (placeArray, placeMap) => {
    bookingPlaces = placeMap;
    set({ bookingPlaces, currentPlaces: placeArray });
  },
}));

export function bookingPlaceArrayToMap(bookingPlacesData: TBookingPlace[]) {
  const orderedPlaces = orderBy(bookingPlacesData, ['placeOrder'], ['asc']);
  const updatedPlaces = new Map();
  const activityBookingPlace = orderedPlaces.find(
    place => place.bookingPlaceType === TBookingPlaceTypes.Activity,
  );
  orderedPlaces.forEach(bookingPlace => {
    const placeDetails = { ...bookingPlace };
    delete placeDetails['__typename'];
    let namespace;
    switch (placeDetails.bookingPlaceType) {
      case TBookingPlaceTypes.Activity: {
        namespace = 'activity';
        break;
      }
      case TBookingPlaceTypes.Pickup: {
        namespace = 'pickup';
        break;
      }
      case TBookingPlaceTypes.Extra: {
        if (
          activityBookingPlace &&
          placeDetails.placeOrder <= activityBookingPlace.placeOrder
        ) {
          namespace = 'pickup';
        } else if (activityBookingPlace) {
          namespace = 'activity';
        } else {
          namespace = 'pickup';
        }
        break;
      }
      default: {
        namespace = 'delivery';
        break;
      }
    }
    const placeMap = updatedPlaces.get(namespace) || new Map();
    let subRoute;
    if (namespace === 'pickup' || namespace === 'activity') {
      const routeSize = placeMap?.size;
      subRoute =
        routeSize === 0 ? namespace : `${namespace}/extra/${routeSize}`;
    } else {
      subRoute = namespace;
    }
    placeMap.set(subRoute, {
      namespace: subRoute,
      ...placeDetails,
    });
    updatedPlaces.set(namespace, placeMap);
  });

  return updatedPlaces;
}

export default useBookingPlacesStore;

// helpers ---
function getInitialPlaceState(
  namespace: GeneralNamespace,
  bookingPlaceType: TBookingPlaceTypes,
  milestoneType?: TBookingMilestoneTypes,
): TBookingPlace {
  const bookingMilestones = [
    {
      milestoneType,
      milestone: getMilestoneDate(bookingPlaceType),
    },
  ];
  return {
    namespace,
    bookingPlaceType,
    organizationLocationId: null,
    bookingMilestones,
  };
}
function updatePlace(routeKey: string, data: any) {
  const route =
    routeKey.split('/extra/').length > 1
      ? bookingPlaces.get(routeKey.split('/extra/')[0])
      : bookingPlaces.get(routeKey);

  if (route) {
    route.set(routeKey, {
      ...route.get(routeKey),
      ...data,
    });
  }
}

async function updateRoute(namespace: string, index: number) {
  const route = bookingPlaces.get(namespace);
  const tempRoutes = [];
  for (const [key, value] of route.entries()) {
    const order = parseInt(key.split('/extra/')[1], 10);
    if (!isNaN(order) && order >= index) {
      const shiftRouteKey = `${namespace}/extra/${order + 1}`;
      tempRoutes.push({
        key: shiftRouteKey,
        value: {
          ...value,
          namespace: shiftRouteKey,
        },
      });
      route.delete(key);
    }
  }

  const subRoute = `${namespace}/extra/${index}`;
  route.set(subRoute, {
    ...getInitialPlaceState(
      subRoute,
      TBookingPlaceTypes.Extra,
      TBookingMilestoneTypes.REQUIRED,
    ),
  });
  for (const tempRoute of tempRoutes) {
    route.set(tempRoute.key, tempRoute.value);
  }
}

function addExtraPlace(namespace: ExtraNamespace, extraNumber: number) {
  const route = bookingPlaces.get(namespace);

  const hasExtraPlace = route?.size > 1;

  if (route) {
    let subRouteOrder = 1;

    // extra place already existed
    if (hasExtraPlace) {
      subRouteOrder = extraNumber ? extraNumber + 1 : 1;

      // insert extra place in the top or middle
      if (subRouteOrder < route?.size) {
        updateRoute(namespace, subRouteOrder);
        return;
      }
      // insert extra place in the bottome
      const subRoute = `${namespace}/extra/${subRouteOrder}`;
      route.set(subRoute, {
        ...getInitialPlaceState(
          subRoute,
          TBookingPlaceTypes.Extra,
          TBookingMilestoneTypes.REQUIRED,
        ),
      });
      return;
    }

    // no extra place existed
    const subRoute = `${namespace}/extra/1`;
    route.set(subRoute, {
      ...getInitialPlaceState(
        subRoute,
        TBookingPlaceTypes.Extra,
        TBookingMilestoneTypes.REQUIRED,
      ),
    });
    bookingPlaces.set(namespace, route);
  } else {
    bookingPlaces.set(
      'activity',
      new Map().set(
        'activity',
        getInitialPlaceState(
          'activity',
          TBookingPlaceTypes.Activity,
          TBookingMilestoneTypes.REQUIRED,
        ),
      ),
    );
    const newOrder = ['pickup', 'activity', 'delivery'];
    bookingPlaces = new Map(newOrder.map(key => [key, bookingPlaces.get(key)]));
  }
}

function deletePlace(key: string) {
  const mainNamespace = key.split('/extra/')[0]; // get mainNamespace from key i.e. pickup/extra/1 = 'pickup'
  const mainNamespacePlaces = bookingPlaces.get(mainNamespace); // get places from the mainNamespace

  // deletingPlaceOrder number from key i.e. pickup/extra/1 = 1
  const deletingPlaceOrder = parseInt(key.split('/extra/')[1], 10);
  if (!isNaN(deletingPlaceOrder)) {
    if (deletingPlaceOrder === mainNamespacePlaces?.size - 1) {
      // if deletingPlaceOrder number is the last extra place,
      mainNamespacePlaces.delete(key); // just delete it without re-ordering
    } else {
      // if deletingPlaceOrder number is not the last extra place, need to re-order the places
      const tempPlaces = deletePlaceAndReOrder(
        mainNamespacePlaces,
        deletingPlaceOrder,
      ); // temporary array to store the places that need to be re-ordered

      // set tempPlaces to mainNamespacePlaces
      for (const tempPlace of tempPlaces) {
        mainNamespacePlaces.set(tempPlace.key, tempPlace.value);
      }
    }
  } else {
    // if deletingPlaceOrder number is not a number, so it's not an extra place,
    // this is the Activity place when ContainerJobType is MOV
    bookingPlaces.delete(key); // just delete it without re-ordering
  }
}

function deletePlaceAndReOrder(mainNamespacePlaces, deletingPlaceOrder) {
  const tempPlaces = [];
  for (const [key, value] of mainNamespacePlaces.entries()) {
    const order = parseInt(key.split('/extra/')[1], 10);

    // only re-order the extra place that has order number greater than the deletingPlaceOrder
    if (!isNaN(order) && order > deletingPlaceOrder) {
      const newOrder = order - 1; // move up the order number by 1
      // generete new key with newOrder i.e. pickup/extra/2 => pickup/extra/1
      const newKey = `${key.split('/extra/')[0]}/extra/${newOrder}`;

      // push re-ordered places to temp array before delete it
      tempPlaces.push({
        key: newKey,
        value: {
          ...value,
          namespace: newKey,
        },
      });
      mainNamespacePlaces.delete(key);
    }
  }
  return tempPlaces;
}
