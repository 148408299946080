import React from 'react';

export default function WarningAbandon() {
  return (
    <div className="flex items-center text-red-500 mr-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
      >
        <g
          id="feWarning0"
          fill="none"
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
        >
          <g id="feWarning1" fill="currentColor" fillRule="nonzero">
            <path
              id="feWarning2"
              d="M12 20a8 8 0 1 0 0-16a8 8 0 0 0 0 16Zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10Zm-1-6h2v2h-2v-2Zm0-10h2v8h-2V6Z"
            />
          </g>
        </g>
      </svg>
      <span className="flex ml-1 font-medium">You have unsaved changes</span>
    </div>
  );
}
