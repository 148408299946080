import React from 'react';
import {
  ErrorBoundary,
  FallbackProps as FallbackPropsType,
} from 'react-error-boundary';

type FallbackProps = Readonly<FallbackPropsType>;
type Props = React.PropsWithChildren<{
  errorHandler?: () => void;
}>;

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ whiteSpace: 'normal' }}>{error.message}</pre>
      <button type="button" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
}

export default ({ children, errorHandler }: Props) => (
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onError={errorHandler}
    onReset={() => {
      // TODO: implement
      // reset the state of your app so the error doesn't happen again
    }}
  >
    {children}
  </ErrorBoundary>
);
