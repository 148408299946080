// IMPORTANT: Make sure AG Grid is loaded before calling any of these functions
import { GridApi } from 'ag-grid-community';

interface IGridTransaction {
  data: any;
  gridApiRef?: GridApi;
  addIndex?: number;
}

function add({ data, gridApiRef = null, addIndex = 0 }: IGridTransaction) {
  const api: GridApi = gridApiRef ?? window.$gridApi;

  api.applyTransaction({
    add: [data],
    addIndex,
  });
}

function remove({ data, gridApiRef = null }: IGridTransaction) {
  const api: GridApi = gridApiRef ?? window.$gridApi;

  api.applyTransaction({
    remove: [data],
  });
}

function update({ data, gridApiRef = null }: IGridTransaction) {
  const api: GridApi = gridApiRef ?? window.$gridApi;

  api.applyTransaction({
    update: [data],
  });
}

function undo(gridApiRef = null) {
  const api: GridApi = gridApiRef ?? window.$gridApi;
  api.undoCellEditing();
}

export default {
  add,
  remove,
  update,
  undo,
};
