import SVGIcon, { IconRef } from 'components/IconSheet';
import React from 'react';
import tw, { styled } from 'twin.macro';
import { IMenuPayload, SidebarMenuSelectCallback } from './types';

const StyledSideMenuItemGroup = styled.div.attrs({})`
  height: auto;
  display: block;
  overflow: hidden;

  &#switch-account-container {
    padding-bottom: 90px;
  }

  h3 {
    ${tw`px-4 mt-3 mb-2`}
    color: ${p => p.theme.textColor};
    font-size: 13px;
    position: relative;
    

    button {
      font-weight: bold;
      text-transform: uppercase;
      &:focus {
        ${tw`outline-none`}
      }
    }

    svg {
      position: absolute;
      right: 14px;
      top: -5px;
      cursor: pointer;
      
      &:hover {
        filter: invert(51%) sepia(7%) saturate(245%) hue-rotate(209deg)
          brightness(95%) contrast(91%);
      }
    }
  }

  /* > div {
    ${tw`px-6`}
  }

  &.collapse {
    height: 35px !important;
  } */
`;

type Props = React.PropsWithChildren<{
  handleMenuSelect?: SidebarMenuSelectCallback;
  routeParams?: any;
  collapse?: boolean;
  label?: string;
  id?: string;
  onCollapse?: (isCollapse) => void;
}>;

export default function Component(props: Props) {
  const [isMenuCollapsed, setIsMenuCollapsed] = React.useState<boolean>(
    props.collapse || false,
  );

  function handleMenuSelect(params: IMenuPayload) {
    props.handleMenuSelect?.(params);
  }

  return (
    <StyledSideMenuItemGroup
      className={isMenuCollapsed ? 'collapse' : ''}
      {...props}
    >
      {props.label && (
        <h3>
          <button
            type="button"
            onClick={() => handleMenuSelect(props.routeParams)}
          >
            {props.label}
          </button>
          <SVGIcon
            onClick={() => {
              setIsMenuCollapsed(!isMenuCollapsed);
              props.onCollapse?.(!isMenuCollapsed);
            }}
            icon={IconRef[isMenuCollapsed ? 'chevronDown' : 'chevronUp']}
          />
        </h3>
      )}
      {props.children}
    </StyledSideMenuItemGroup>
  );
}
