import { ICellRendererParams } from 'ag-grid-community';
import { Input } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

export default forwardRef((props: ICellRendererParams, ref) => {
  const refInput = useRef(null);
  const [value, setValue] = useState(props.value);

  const handleOnChange = e => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
    };
  });

  useEffect(() => {
    if (!props.node.rowPinned) {
      refInput.current.focus();
    }
  }, []);

  return (
    <div className="flex flex-row justify-end">
      {!props.node.rowPinned ? (
        <Input
          ref={refInput}
          bordered={false}
          value={value}
          onChange={handleOnChange}
        />
      ) : (
        <span>{props.value}</span>
      )}
    </div>
  );
});
