import { GridOptions } from 'ag-grid-community';
import SelectorFloatingFilter from 'components/ReactGrid/filters/SelectorFloatingFilter';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';
import { numberFilterParams, textFilterParams } from 'helpers/filterParams';

export const vehicleGridOptions = {
  headerHeight: 40,
  components: {
    CustomCell,
    SelectorFloatingFilter,
  },
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressSideButton: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  },
  defaultColDef: {
    initialHide: false,
    enableRowGroup: true,
    editable: false,
    cellRenderer: 'CustomCell',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: textFilterParams,
  },
  suppressScrollOnNewData: true,
  columnDefs: [
    {
      headerName: 'Division',
      field: 'division.name',
    },
    {
      headerName: 'Vehicle ID',
      field: 'id',
      filter: 'agNumberColumnFilter',
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Name',
      field: 'name',
      pinned: 'left',
    },
    {
      headerName: 'Fleet Number',
      field: 'code',
    },
    {
      headerName: 'Description',
      field: 'description',
    },
    {
      headerName: 'Registration',
      field: 'registration',
      width: 150,
    },
    {
      headerName: 'Make Model',
      field: 'makeModel',
    },
    {
      headerName: 'License',
      field: 'license',
    },
    {
      headerName: 'GVM',
      field: 'gvm',
      filter: 'agNumberColumnFilter',
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Tare',
      field: 'tare',
      filter: 'agNumberColumnFilter',
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Cubic',
      field: 'loadCapacityCube',
      filter: 'agNumberColumnFilter',
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Weight',
      field: 'loadCapacityWeight',
      filter: 'agNumberColumnFilter',
      filterParams: numberFilterParams,
    },
  ],
} as GridOptions;
