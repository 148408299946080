import { GridOptions } from 'ag-grid-community';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';

export const selectVehicleGridConfig = {
  gridOptions: {
    rowSelection: 'single',
    suppressRowClickSelection: false,
    enableRangeSelection: true,
    enableFillHandle: true,
    components: {
      CustomCell,
    },

    defaultColDef: {
      initialHide: false,
      enableRowGroup: true,
      editable: false,
      cellRenderer: 'CustomCell',
    },
    columnDefs: [
      {
        headerName: '',
        checkboxSelection: true,
        maxWidth: 50,
        pinned: true,
        suppressMovable: true,
        lockPinned: true,
        lockPosition: true,
        lockVisible: false,
        filter: false,
        suppressColumnsToolPanel: false,
        cellStyle: { paddingLeft: '17px !important' },
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        headerName: 'Fleet Number',
        field: 'code',
        width: 120,
        pinned: 'left',
      },
      {
        headerName: 'Name',
        field: 'name',
        width: 150,
      },
      {
        headerName: 'Registration',
        field: 'registration',
        width: 120,
      },
      {
        colId: 'division',
        headerName: 'Division',
        field: 'division.name',
        width: 150,
      },
      {
        headerName: 'Description',
        field: 'description',
        width: 160,
      },
    ],
  } as GridOptions,
};
