import { Button, InputNumber, message } from 'antd';
import Dialog from 'components/Dialog';
import { IDialogHeader } from 'components/Dialog/types';
import Field from 'components/Form/Field';
import WithFormHooksController from 'components/common/WithFormHooksController';
import WithThemeProvider from 'components/providers/WithThemeProvider';
import { FormHooksProvider, useFormHooks } from 'context/FormHooksContext';
import useBookings from 'context/bookings/hooks';
import { getErrorMessage } from 'helpers/form-errors';
import React, { useEffect, useRef, useState } from 'react';
import { duplicateResolver } from '../../DetailsScreen/Container/schema-resolver';

type Props = {
  selectedBooking: any;
  onClose?: () => void;
} & IDialogHeader;

const Form = ({ selectedBooking, onClose }: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useFormHooks();
  const { duplicateBookings, setNewItems } = useBookings();
  const [isSaving, setIsSaving] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (Object.keys(errors).length) {
      message.error(getErrorMessage(errors));
    }
  }, [errors]);

  // eslint-disable-next-line no-underscore-dangle
  async function handleSave() {
    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  }

  async function handleSubmitData(data) {
    setIsSaving(true);
    const result = await duplicateBookings(selectedBooking?.id, data?.quantity);
    if (result.data.duplicateBooking?.length) {
      message.success('Duplicate Booking Successfully');
      setNewItems(result.data.duplicateBooking);
      onClose();
    } else {
      message.error('Something went wrong... , cannot duplicate Booking!');
    }
    setIsSaving(false);
  }

  return (
    <form
      ref={formRef}
      className="flex flex-col w-full h-full"
      onSubmit={handleSubmit(handleSubmitData)}
    >
      <div className="flex flex-col w-full h-full">
        <Dialog.Header
          onClose={onClose}
          title={`Duplicate Booking (ID: ${selectedBooking?.id})`}
        />

        <div className="flex gap-3 grid-cols-12 h-auto px-3 mt-3 justify-center">
          <Field label="Number of Copies" span={6}>
            <WithFormHooksController
              control={control}
              name="quantity"
              component={
                <InputNumber min={1} className="px-2 rounded-md w-full" />
              }
            />
          </Field>
        </div>
      </div>
      <div className="w-full px-4 py-3 border-t border-solid border-gray-200">
        <WithThemeProvider token={{ colorPrimary: '#fff' }}>
          <div className="flex justify-end">
            <Button
              onClick={onClose}
              className="bg-gray-400 text-white hover:bg-red-600"
            >
              Cancel
            </Button>
            <Button
              loading={isSaving}
              onClick={handleSave}
              className="ml-2 bg-mainOrange text-white hover:text-white hover:bg-mainOrangeHover"
            >
              Duplicate
            </Button>
          </div>
        </WithThemeProvider>
      </div>
    </form>
  );
};

export default function MainForm(props: Props) {
  return (
    <FormHooksProvider
      opts={{ shouldFocusError: true, defaultValues: { quantity: 1 } }}
      resolver={duplicateResolver}
    >
      <Form {...props} />
    </FormHooksProvider>
  );
}
