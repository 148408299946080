import {
  faClock,
  faExclamationCircle,
  faMessage,
  faPhone,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Select, Space, Typography } from 'antd';
import { SelectorFloatingFilterOptions } from 'components/config/types/common';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { styled } from 'twin.macro';
import { ShiftCell, StatusCell } from '../renderers';

const DropdownFloatingFilter = styled.div`
  .ant-select {
    padding: 2px 0;
    height: 30px;
    .ant-select-selection-search {
      display: none;
    }
    .ant-select-selector {
      height: 28px;
      align-items: center;
      padding-block: initial;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
    }
    .ant-select-selection-search-input {
      height: 28px;
    }
    .ant-select-selection-item {
      margin-top: 0;
      height: 22px;
      vertical-align: middle;
      .ant-select-selection-item-content {
        align-self: center;
      }
      .anticon {
        vertical-align: text-top;
      }
    }
    .ant-select-selection-placeholder {
      line-height: 23px;
      font-weight: 400 !important;
    }
  }
`;

export default forwardRef((props: any, ref: any) => {
  // = =========initial===========//
  const selectRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState([]);
  const { Option } = Select;
  const colId = props?.column?.colId;

  // = ======== event handlers=============//

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          selectRef.current.value = '';
          setSelectedValue(null);
        } else {
          selectRef.current.value = `${parentModel.filter}`;
          const arrayFilter = parentModel.filter.split(',');
          setSelectedValue(arrayFilter);
        }
      },
    };
  });

  const onChangeHandler = (value: any) => {
    props.parentFilterInstance((instance: any) => {
      instance.onFloatingFilterChanged('equals', value);
    });
  };

  const handleClear = () => {
    props.parentFilterInstance((instance: any) => {
      instance.onFloatingFilterChanged(null);
    });
  };

  // = =========== render components ============== //

  const renderIcon = icon => {
    return (
      <span key={icon} className="mr-1">
        {
          {
            ATTENTIONT: (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ fontSize: 13, color: 'red', verticalAlign: 'middle' }}
              />
            ),
            EXPRESS: (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ fontSize: 13, color: 'blue', verticalAlign: 'middle' }}
                className="flag-hover"
              />
            ),
            CALL: (
              <FontAwesomeIcon
                icon={faPhone}
                style={{
                  fontSize: 13,
                  color: 'orange',
                  verticalAlign: 'middle',
                }}
              />
            ),
            URGENT: (
              <FontAwesomeIcon
                icon={faClock}
                style={{
                  fontSize: 13,
                  color: 'orange',
                  verticalAlign: 'middle',
                }}
              />
            ),
            SPECIAL: (
              <FontAwesomeIcon
                icon={faMessage}
                style={{ fontSize: 13, color: 'blue', verticalAlign: 'middle' }}
              />
            ),
          }[icon]
        }
      </span>
    );
  };

  // = ======= main render =============//

  const renderContent = (colId, el) => {
    switch (colId) {
      case 'shift':
        return <ShiftCell value={el?.value} />;
      case 'status':
        return <StatusCell label={el?.label} />;
      case 'invoicingStatus':
        return <StatusCell label={el?.label} />;
      case 'invoiceStatus':
        return <StatusCell label={el?.label} />;
      default:
        return (
          <div
            style={{
              backgroundColor: el?.bgColor,
              width: el?.width,
              textAlign: el?.textAlign || 'center',
            }}
          >
            {el?.icon ? renderIcon(el?.icon) : null}
            <span>{el?.label}</span>
          </div>
        );
    }
  };

  return (
    <DropdownFloatingFilter
      className="custom-dropdown-floating-filter"
      style={{
        width: '100%',
        borderRadius: '4px',
      }}
    >
      <Select
        dropdownRender={menu => (
          <>
            <Space align="center" style={{ padding: '0 8px 4px' }}>
              <Typography.Link
                onClick={handleClear}
                style={{ whiteSpace: 'nowrap' }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{ verticalAlign: 'unset' }}
                />{' '}
                Clear
              </Typography.Link>
            </Space>
            <Divider style={{ margin: '8px 0' }} />
            {menu}
          </>
        )}
        mode="multiple"
        value={selectedValue?.filter(i => i !== undefined)}
        ref={selectRef}
        onChange={onChangeHandler}
        placeholder="Select ..."
        maxTagCount="responsive"
        dropdownStyle={{ minWidth: 'fit-content' }}
        style={{ width: '100%', borderRadius: '4px' }}
      >
        {SelectorFloatingFilterOptions[colId]?.map(el => {
          return (
            <Option value={el?.value?.toString()} key={el?.value}>
              {renderContent(colId, el)}
            </Option>
          );
        })}
      </Select>
    </DropdownFloatingFilter>
  );
});
