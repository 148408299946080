import { Input, Popconfirm, Space, Tabs, message } from 'antd';
import useScreenViews from 'context/setting_screenViews/hooks';
import React, { useState } from 'react';
import { styled } from 'twin.macro';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
    button .anticon > svg {
      vertical-align: inherit;
    }
    .ant-tabs-nav-add {
      min-height: 40px;
    }
  }
`;

interface Props {
  onAddTab?: (tab: any) => void;
  hasView?: boolean;
}

const TabViewItem = (props: Readonly<any>) => {
  const { pane, onOpenpop, setOpenPopup, remove } = props;

  const [editingTab, setEditingTab] = useState(null);
  const {
    screenViewSettings,
    modifyScreenViewRegistry,
    updatingTab,
  } = useScreenViews();
  const [tabName, setTabName] = useState(null);

  const handleKeyPress = (e: React.KeyboardEvent): any => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleSaveTabName();
    }
  };
  const handleTabNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTabName(value);
  };

  const handleSaveTabName = () => {
    if (tabName?.length > 0) {
      const newScreenViews = screenViewSettings?.screenViews?.map(item => {
        if (item.viewId === screenViewSettings.activeView) {
          return {
            ...item,
            tabs: item.tabs.map(tab => {
              if (tab.tabId === editingTab.tabId) {
                return {
                  ...tab,
                  tabName,
                };
              }
              return tab;
            }),
          };
        }
        return item;
      });
      modifyScreenViewRegistry(
        {
          ...screenViewSettings,
          screenViews: newScreenViews,
        },
        updatingTab,
      );
      setEditingTab(null);
    }
  };

  const handleEditTab = pane => {
    setTabName(pane.tabName);
    setEditingTab(pane);
  };

  if (editingTab?.tabId === pane?.tabId) {
    return (
      <Input
        type="text"
        size="small"
        autoFocus
        onKeyDown={handleKeyPress}
        value={tabName}
        onBlur={handleSaveTabName}
        onChange={handleTabNameChange}
      />
    );
  }
  return (
    <div>
      <Space
        className="tab-label"
        tabIndex={0}
        onDoubleClick={() => handleEditTab(pane)}
      >
        {pane.tabName}
      </Space>
      <Popconfirm
        title="Are you sure to delete this tab?"
        onConfirm={() => remove(pane.tabId)}
        okText="Yes"
        cancelText="No"
        open={onOpenpop}
        onCancel={() => setOpenPopup(null)}
      ></Popconfirm>
    </div>
  );
};

function ViewTab(props: Readonly<Props>) {
  const { onAddTab, hasView } = props;
  const [openPopup, setOpenPopup] = useState(null);

  const {
    currentView,
    screenViewSettings,
    deleteViewTab,
    modifyScreenViewRegistry,
    updatingTab,
  } = useScreenViews();

  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      add();
    }
    if (action === 'remove') {
      setOpenPopup(targetKey);
    }
  };

  const add = () => {
    onAddTab('New Tab');
  };

  const remove = async targetKey => {
    const result = await deleteViewTab(targetKey);
    setOpenPopup(null);
    if (result?.data) {
      message.success(result.message);
    } else {
      message.error(result.message);
    }
  };

  const onChange = key => {
    if (openPopup != null) return;
    const newScreenViews = screenViewSettings?.screenViews?.map(item =>
      item.viewId === currentView?.viewId
        ? {
            ...currentView,
            activeTab: key,
          }
        : item,
    );

    modifyScreenViewRegistry(
      {
        ...screenViewSettings,
        screenViews: newScreenViews,
        activeTab: key,
      },
      updatingTab,
    );
  };

  if (hasView) {
    return (
      <div className="view-tabs" style={{ paddingTop: '4px' }}>
        <StyledTabs
          type="editable-card"
          defaultActiveKey="1"
          tabPosition="top"
          onChange={onChange}
          activeKey={currentView?.activeTab}
          onEdit={onEdit}
          tabBarGutter={8}
        >
          {currentView?.tabs?.map(pane => (
            <TabPane
              className="items-center tabs"
              style={{ borderRadius: '8px 8px' }}
              tab={
                <TabViewItem
                  pane={pane}
                  onOpenpop={openPopup === pane.tabId}
                  setOpenPopup={setOpenPopup}
                  remove={remove}
                />
              }
              key={pane.tabId}
            ></TabPane>
          ))}
        </StyledTabs>
      </div>
    );
  }
  return null;
}

export default ViewTab;
