import dayjs from 'dayjs';

namespace DateFormats {
  // ui date format (human-friendly)
  export const asDisplayDate = 'DD/MM/YYYY';
  export const asDisplayDateTime = `${asDisplayDate} HH:mm`;

  // graphql payload date format
  export const asPayloadDate = 'YYYY-MM-DD';
  export const asPayloadDateTime = `${asPayloadDate}THH:mm:ss.SSSZ`;

  // This is NOT dayjs format
  // https://www.ag-grid.com/react-data-grid/excel-export-data-types/
  // keep the letters lowercase
  export const asExportDate = `dd/mm/yyyy`;
  export const asExportDateTime = `${asExportDate} hh:mm`;
}

export const defaultBookingDateFilter = {
  from: dayjs(
    dayjs()
      .subtract(1, 'month')
      .subtract(1, 'day')
      .format(DateFormats.asPayloadDate),
    DateFormats.asPayloadDateTime,
  ),
  to: dayjs(
    dayjs().add(1, 'day').format(DateFormats.asPayloadDate),
    DateFormats.asPayloadDateTime,
  ),
};

export const defaultBookingDatePicker = {
  from: defaultBookingDateFilter.from,
  to: dayjs(
    dayjs().format(DateFormats.asPayloadDate),
    DateFormats.asPayloadDateTime,
  ),
};

export default DateFormats;
