import { IDialog } from 'components/Dialog/types';
import { ReactNode } from 'react';

export type ModalContent = {
  type: string;
  module: string;
  formConfig: any;
  visible: boolean;
  record: any;
  width: number;
  mode: ModalMode;
  footer: any;
};

export enum ModalMode {
  CREATE = 'Create',
  MODIFY = 'Modify',
}

export type ScreenConfig = {
  name: string;
  screenViews: boolean;
};

export interface AppState {
  screenConfig: ScreenConfig;
  dialog: { params: IDialog };
  modals: { [key: string]: ModalContent };
  selectedModalContent: ModalContent | ReactNode | undefined;
}

export type ContainerState = AppState;
