import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Popconfirm } from 'antd';
import React from 'react';

type Props = Readonly<{
  value: string;
  view: any;
  screenViewSettings: any;
  editingView: any;
  handleViewNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSaveViewName: (view: any) => void;
  handleKeyDown: (e: React.KeyboardEvent) => void;
  handleSetCurrentView: (view: any) => void;
  handleEditView: (view: any) => void;
  handleDeleteView: (viewId: any) => void;
}>;

function ViewItem(props: Props) {
  const {
    value,
    view,
    screenViewSettings,
    editingView,
    handleViewNameChange,
    handleSaveViewName,
    handleKeyDown,
    handleSetCurrentView,
    handleEditView,
    handleDeleteView,
  } = props;
  const isFocused = screenViewSettings?.activeView === view.viewId;
  const isEditing = view?.viewId === editingView?.viewId;

  return (
    <div
      className={`group px-1 flex flex-nowrap items-center text-action-iconDefault hover:text-gray-700 
      focus:outline-none rounded-none h-full `}
    >
      {isEditing ? (
        <Input
          value={value}
          type="text"
          style={{ width: 150 }}
          size="small"
          className="focus:outline-none font-medium text-gray-700"
          placeholder="Add new view"
          onChange={handleViewNameChange}
          onBlur={() => handleSaveViewName(view)}
          onKeyDown={handleKeyDown}
          autoFocus
        />
      ) : (
        <div
          role="button"
          className="group px-1 flex flex-nowrap items-center text-action-iconDefault hover:text-gray-700 focus:outline-none rounded-none h-full"
          tabIndex={0}
          onClick={() => handleSetCurrentView(view)}
          onDoubleClick={() => handleEditView(view)}
          onKeyDown={() => handleSetCurrentView(view)}
        >
          <span
            className={`text-ellipsis overflow-hidden whitespace-nowrap mr-1 block ${
              isFocused && 'text-mainOrange'
            }`}
          >
            {view.viewName}
          </span>
          <Popconfirm
            placement="top"
            title={`Confirm delete view?`}
            onConfirm={() => handleDeleteView(view.viewId)}
            okText="Yes"
            cancelText="No"
            className="hidden group-hover:block"
          >
            <FontAwesomeIcon
              icon={faCircleMinus}
              style={{
                color: 'red',
                fontSize: '12px',
              }}
            />
          </Popconfirm>
        </div>
      )}
    </div>
  );
}

export default ViewItem;
