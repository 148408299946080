import { ColDef } from 'ag-grid-community';
import { Tabs, message } from 'antd';
import { ModuleTypes } from 'components/config/types/modules';
import { selectScreenConfig } from 'context/app/slice';
import useAuthentication from 'context/security_authentication/hook';
import useScreenViews from 'context/setting_screenViews/hooks';
import { selectWorkspaces } from 'context/workspace/selectors';
import containerInfoCols from 'helpers/bookingAttributeColDefs';
import { generateId } from 'helpers/generator';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { styled } from 'twin.macro';
import AddView from './AddView';
import ViewItem from './ViewItem';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    background-color: red;
  }
`;

function ScreenView() {
  const { currentAccount } = useAuthentication();
  const {
    defaultViews,
    deleteView,
    currentView,
    screenViewSettings,
    getDefaultViews,
    modifyScreenViewRegistry,
    getScreenViewRegistry,
    refreshScreenViews,
    createScreenViewRegistry,
    modifyScreenViewRegistryWithoutUpdatingRedux,
    updatingTab,
  } = useScreenViews();
  const screenConfig = useSelector(selectScreenConfig);
  const workspace = useSelector(selectWorkspaces);
  const [editingView, setEditingView] = useState(null);
  const [viewName, setViewName] = useState(null);
  const Location = useLocation();
  const previousLocation = useRef(Location).current;
  const previousView = useRef(null);

  const handleSaveViews = async view => {
    if (view) {
      modifyScreenViewRegistry(
        {
          ...screenViewSettings,
          activeView: view?.viewId,
        },
        updatingTab,
      );
    } else if (previousView.current !== null) {
      modifyScreenViewRegistryWithoutUpdatingRedux(previousView.current);
    }
    previousView.current = null;
  };

  useEffect(() => {
    if (previousLocation !== Location) {
      handleSaveViews(null);
    }
  }, [Location]);

  useEffect(() => {
    let updatedView = null;
    if (updatingTab && currentView) {
      const updatedScreenViews = screenViewSettings?.screenViews.map(view => {
        let tabs = view.tabs;
        if (view.viewId === currentView.viewId) {
          tabs = view?.tabs.map(tab => {
            if (tab.tabId === updatingTab.tabId) {
              return {
                ...tab,
                columnDefs: updatingTab.columnDefs,
                filterModel: updatingTab.filterModel,
                sortModel: updatingTab.sortModel,
                columnGroupState: updatingTab.columnGroupState,
              };
            }
            return tab;
          });
        }
        return { ...view, tabs };
      });
      updatedView = {
        screenViews: updatedScreenViews,
        activeTab: updatingTab.tabId,
        activeView: currentView.viewId,
      };
      previousView.current = updatedView;
    }
  }, [updatingTab]);

  const handleDeleteView = async viewId => {
    const result = await deleteView(viewId);
    if (result?.data) {
      message.success('View deleted');
    } else {
      message.error(result?.message);
    }
  };

  // save views when closing tab/browser
  const handleUnload = useRef(event => {
    event.preventDefault();
    handleSaveViews(null);
  }).current;

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [handleUnload, screenViewSettings]);

  useEffect(() => {
    let screenModule = screenConfig?.name;
    if (screenModule === ModuleTypes.Invoice.value) {
      screenModule = ModuleTypes.Booking.value;
    }
    getDefaultViews({
      filters: {
        entityId: { eq: null },
        keyGroup: { eq: 'settings' },
        key: { eq: `screenViews.${screenModule}` },
      },
      cursor: null,
      order: null,
    });
    getScreenViewRegistry({
      filters: {
        entityId: { eq: currentAccount?.id },
        keyGroup: { eq: 'settings' },
        key: { eq: `screenViews.${screenConfig?.name}` },
      },
      cursor: null,
      order: null,
    });
    return () => {
      refreshScreenViews();
    };
  }, [currentAccount, screenConfig?.name]);

  const generateViewItem = tabName => {
    const tabId = generateId(false, 6);
    let columnDefs: ColDef[] = workspace?.gridOptions.columnDefs.map(col => {
      return {
        ...col,
        hide: false,
        colId: col['colId'] || col['field'],
        rowGroup: false,
        sort: null,
      };
    });

    if (screenConfig?.module?.value === ModuleTypes.Booking.value) {
      const attributeCols = containerInfoCols?.map(col => {
        return {
          headerName: col?.headerName,
          colId: col?.colId,
          field: col?.field,
          width: 100,
          rowGroup: false,
          sort: null,
        };
      });
      columnDefs = [...columnDefs, ...attributeCols];
    }

    return {
      tabId,
      tabName,
      columnDefs,
      filterModel: {},
      columnGroupState: [],
      sortModel: [],
    };
  };

  const handleCreateView = item => {
    let tabs = [];
    let activeTab = null;
    if (item && item?.tabs?.length > 0) {
      tabs = item?.tabs?.map(tab => {
        const tabId = generateId(false, 6);
        return {
          ...tab,
          tabId,
        };
      });
      activeTab = tabs[0]?.tabId;
    } else {
      const newTab = generateViewItem('New Tab');
      tabs = [newTab];
      activeTab = newTab?.tabId;
    }

    const viewValue = {
      viewId: generateId(false, 6),
      viewName: 'Untitled View',
      tabs,
      activeTab,
    };

    if (!screenViewSettings?.screenViews) {
      const newView = {
        entityId: currentAccount?.id,
        entityType: 2,
        key: `screenViews.${screenConfig?.name}`,
        keyGroup: 'settings',
        value: JSON.stringify({
          activeView: viewValue.viewId,
          screenViews: [{ ...viewValue }],
        }),
      };
      createScreenViewRegistry(newView);
    } else {
      modifyScreenViewRegistry(
        {
          ...screenViewSettings,
          activeView: viewValue.viewId,
          screenViews: [viewValue, ...(screenViewSettings.screenViews ?? [])],
        },
        updatingTab,
      );
    }
  };

  const handleSetCurrentView = view => {
    if (currentView?.viewId !== view.viewId) {
      handleSaveViews(view);
    }
  };

  const handleEditView = item => {
    setEditingView(item);
    setViewName(item.viewName);
  };

  const handleSaveViewName = editingView => {
    if (viewName?.length > 0) {
      const newScreenViews = screenViewSettings?.screenViews?.map(item =>
        item.viewId === editingView.viewId
          ? {
              ...editingView,
              viewName,
            }
          : item,
      );
      modifyScreenViewRegistry(
        {
          ...screenViewSettings,
          screenViews: newScreenViews,
        },
        updatingTab,
      );
      setEditingView(null);
      previousView.current = null;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent): any => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleSaveViewName(editingView);
    }
  };

  const handleViewNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setViewName(value);
  };

  return (
    <StyledTabs
      activeKey={currentView?.viewId}
      tabBarExtraContent={{
        left: <AddView createView={handleCreateView} defaults={defaultViews} />,
      }}
    >
      {screenViewSettings?.screenViews?.map(view => (
        <TabPane
          tab={
            <ViewItem
              value={viewName}
              key={view.viewId}
              view={view}
              screenViewSettings={screenViewSettings}
              editingView={editingView}
              handleViewNameChange={handleViewNameChange}
              handleSaveViewName={handleSaveViewName}
              handleKeyDown={handleKeyDown}
              handleSetCurrentView={handleSetCurrentView}
              handleEditView={handleEditView}
              handleDeleteView={handleDeleteView}
            />
          }
          key={view.viewId}
        />
      ))}
    </StyledTabs>
  );
}

export default ScreenView;
