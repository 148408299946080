import { message } from 'antd';
import { IDialogTrigger } from 'components/Dialog/types';
import { FormHooksProvider } from 'context/FormHooksContext';
import useBookingRate from 'context/financials_rate/hooks';
import { selectFafGroupNew } from 'context/financials_rate/slice';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useFafGroupNewStore from '../store';
import MainForm from './MainForm';
import schemaResolver from './schema-resolver';
import useAuthentication from 'context/security_authentication/hook';

interface IProps {
  afterValidation?: (vehicle?: any) => void;
  fafGroup?: any;
}

export default ({
  afterValidation,
  fafGroup,
  ...rest
}: IDialogTrigger & IProps) => {
  const formTitle = fafGroup ? `Edit ${fafGroup.name}` : 'Create New FAF Group';
  const [setIsSaving, isSaving] = useFafGroupNewStore(state => [
    state.setIsSaving,
    state.isSaving,
  ]);
  const { currentAccount } = useAuthentication();
  const { createFafGroupNew, modifyFafGroupNew } = useBookingRate();
  const getSelectFafGroupNew = useSelector(selectFafGroupNew);
  useEffect(() => {
    setIsSaving(false);
  }, []);

  async function _onSubmit(formData: any) {
    const _fafGroup = fafGroup;
    if (_fafGroup) {
      const { id } = getSelectFafGroupNew;
      const { name, description } = formData;
      const payload = {
        id,
        name,
        description,
        subscriberOrganizationId:
          currentAccount?.organizationOrganizationMapping?.childOrganizationId,
      };
      setIsSaving(true);
      const result = await modifyFafGroupNew(payload);
      if (result?.data) {
        message.success('FAF Group updated');
        afterValidation?.(result.data?.modifyFafGroupNew);
      } else {
        message.error('Cannot save FAF Group');
      }
      setIsSaving(false);
      return;
    }
    onCreate(formData);
  }

  async function onCreate(formData: any) {
    const payload = {
      name: formData.name,
      description: formData.description,
      subscriberOrganizationId:
        currentAccount?.organizationOrganizationMapping?.childOrganizationId,
    };

    const result = await createFafGroupNew(payload);
    const {
      data: { addFafGroupNew: fafGroup },
    } = result;

    if (fafGroup) {
      afterValidation?.(fafGroup);
      message.success(`FAF Group created`);
    }
  }

  function _getFormOptions() {
    let defaultValues = {};
    if (fafGroup) {
      defaultValues = {
        name: fafGroup.name,
        description: fafGroup.description,
      };
      return {
        opts: { defaultValues },
      };
    }

    return {};
  }
  return (
    <FormHooksProvider resolver={schemaResolver} {..._getFormOptions()}>
      <MainForm
        {...rest}
        title={formTitle}
        onSubmit={_onSubmit}
        isSaving={isSaving}
        fafGroup={fafGroup}
      />
    </FormHooksProvider>
  );
};
