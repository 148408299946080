import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popconfirm } from 'antd';
import React from 'react';
import tw, { styled } from 'twin.macro';

export interface Props {
  context?: any;
  node?: any;
}

const StyledWrapper = styled.div`
  ${tw`w-full relative text-center items-center justify-center`}
  .anticon {
    vertical-align: baseline;
  }
`;

export default (props: Props) => {
  const text = 'Are you sure you want to cancel this booking?';
  const confirm = () => {
    props.context.handlCancelRecord(props);
  };

  return (
    <StyledWrapper>
      <Popconfirm
        className="flex bg-transparent items-center"
        placement="leftBottom"
        title={text}
        onConfirm={confirm}
        okText="Yes"
        cancelText="No"
      >
        {!props.node.rowPinned ? (
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />
        ) : (
          <span />
        )}
      </Popconfirm>
    </StyledWrapper>
  );
};
