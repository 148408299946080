import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GenericCodeListState {
  codeLists: any;
}
export const initialState: GenericCodeListState = {
  codeLists: {},
};

const storeSlice = createSlice({
  name: 'genericCodeLists',
  initialState,
  reducers: {
    setGenericCodeLists: (state, action: PayloadAction<any>) => {
      const { codeLists } = action.payload;
      state.codeLists = codeLists;
    },
  },
});

export const selectCodeLists = state => state.genericCodeLists?.codeLists;
export const selectDocumentType = state =>
  state.genericCodeLists?.codeLists?.DocumentType;

export const { actions, reducer, name: sliceKey } = storeSlice;
