import { create } from 'zustand';

interface IState {
  isSaving: boolean;
}

interface IActions {
  setIsSaving: (status: boolean) => void;
}

const useFafGroupNewStore = create<IState & IActions>(set => ({
  isSaving: false,

  setIsSaving: (status: boolean) => set({ isSaving: status }),
}));

export default useFafGroupNewStore;
