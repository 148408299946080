import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RightSideMenuState {
  showRightSideMenu: boolean;
  activeSideMenu: string;
}

export const initialState: RightSideMenuState = {
  showRightSideMenu: false,
  activeSideMenu: '',
};

const storeSlice = createSlice({
  name: 'rightSideMenu',
  initialState,
  reducers: {
    setShowRightSideMenu: (state, action: PayloadAction<any>) => {
      state.showRightSideMenu = action.payload;
    },
    setActiveSideMenu: (state, action: PayloadAction<any>) => {
      state.activeSideMenu = action.payload;
    },
  },
});

export const { setShowRightSideMenu, setActiveSideMenu } = storeSlice.actions;
export const { reducer } = storeSlice;
export const sliceKey = storeSlice.name;
