import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function ShiftCell({
  value,
}: Readonly<{ value: string; clickable?: boolean }>) {
  return (
    <div
      className={`flex items-center bg-opacity-[.15] rounded-md px-1
        ${
          value.toLowerCase().includes('day')
            ? 'text-[#D49900] bg-[#D49900]'
            : 'text-[#173071] bg-[#173071]'
        }`}
    >
      {value.toLowerCase().includes('day') ? (
        <FontAwesomeIcon
          icon={faSun}
          style={{
            fontSize: '16px',
            color: 'inherit',
            marginRight: '8px',
          }}
        />
      ) : (
        <FontAwesomeIcon
          icon={faMoon}
          style={{
            fontSize: '16px',
            color: 'inherit',
            marginRight: '8px',
          }}
        />
      )}

      {value.toLowerCase().includes('day') ? 'Day Shift' : 'Night Shift'}
    </div>
  );
}
