import { Drawer } from 'antd';
import tw, { styled } from 'twin.macro';

export const StyledDrawer = styled(Drawer)`
  top: 42px;
  height: calc(100% - 42px);
  /* width: 0 !important; */

  .ant-drawer-mask {
    //width: 0;
  }

  .ant-drawer-body {
    padding: 0;
    overflow: hidden;
    background-color: ${p => p.theme.headerBgColor};
    color: ${p => p.theme.textColor};

    h2 {
      color: ${p => p.theme.textColor};
      display: block;
      padding: 10px;
    }
  }

  .ant-drawer-content {
    overflow: hidden;
  }
`;

export const SideMenuItem = styled.div`
  ${tw`block px-4 py-2 leading-5 cursor-pointer`}
  font-size: 13px;
  position: relative;
  
  &:hover{
    background-color: ${({ theme }) => theme.menuItemHoverBGColor};
    color: ${p => p.theme.menuItemHoverTextColor};
  }

  svg {
    ${tw`text-black-200`}
    position: absolute;
    float: right;
    right: 10px;
    top: 10px;
    filter: invert(51%) sepia(7%) saturate(245%) hue-rotate(209deg)
      brightness(95%) contrast(91%);

    &:hover {
      filter: none;
    }
  }

  &.image-block {
    cursor: default;
    text-align: center;

    img {
      height: 120px;
      width: 120px;
      position: relative;
      display: inline-block;
      border-radius: 60px;
    }

    .ant-avatar-string{
      font-size: 40px;
    }

    span {
      font-size: 10px;
      display: block;
      font-weight: 400;
      color: ${p => p.theme.textColor};
    }

    h4 {
      ${tw`mt-2 font-semibold`}
      color: ${p => p.theme.textColor};
    }
  }
  
  &.switch-user{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${p => p.theme.textColor};
    border-left: 5px solid;
    border-color: ${p => p.theme.headerBgColor};
    
    &.selected{
      border-color: #ff9d3f;
    }

    img{
      width: 32px;
      height: 32px;
      border-radius: 16px;
    }
    
    div{
      margin-left: 10px;
      width: 170px;
    }

    h4{
      color: ${p => p.theme.textColor};
      font-size: 12px;
    }

    p{
      font-size: 10px;
    }

    span {
      position: absolute;
      font-size: 10px;
    }

  }
  
  

  &.disabled {
    ${tw`opacity-25 cursor-not-allowed`}
  }

  &::before {
    height: '16px';

    width: '16px';
    
    display: inline-block;
    color: #fff;
    border-radius: 19px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    left: 6px;
    top: 10px;
    line-height: 16px;

    
  }
`;

export const SideMenuItemsWrapper = styled.div.attrs({
  id: 'account-drawer',
  className: 'sub-child',
  role: 'menu',
  ariaOrientation: 'vertical',
})`

  &#account-drawer {
    overflow-y: scroll;
  }

  &#account-drawer::-webkit-scrollbar {
    width: 4px;
    background-color: #d25c17;
  }

  &#account-drawer::-webkit-scrollbar-thumb {
    background-color: rgb(211 171 151 / 75%) !important;
  }

  &#account-drawer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #d25c17;
  }

  ${tw`text-sm`};
  background-color: ${p => p.theme.headerBgColor};
  color: ${p => p.theme.textColor};
  top: 0;
  left: 48px;
  height: 100vh;

  hr {
    ${tw`my-2 mx-4`}
    border-color: ${p => p.theme.menuDivider};
  }

  #logout-container {
    background-color: ${p => p.theme.headerBgColor};
  }
`;

export const StyledThemeSelector = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  div {
    text-align: center;
    cursor: pointer;
    min-width: 50px;
  }

  span {
    position: relative;
    ${tw`rounded-full focus:outline-none`}
    display: inline-block;
    width: 32px;
    height: 32px;

    &.default {
      background-color: #ee591a;
      border: 1px solid #eee;
    }

    &.light {
      background-color: #fff;
      border: 1px solid #eee;
    }

    &.dark {
      background-color: #26262d;
      border: 1px solid #eee;
    }

    &.system {
      background-color: #eaeaea;
      border: 1px solid #eee;
    }
  }
`;

export const StyledUserWrapper = styled.div``;

export const Title = styled.span.attrs({
  className: 'antialiased mr-2',
})`
  ${tw`px-4 text-center`}
  font-size: 13px;
  font-weight: 600;
  padding-right: 1rem;
  height: 38px;
  display: block;
  padding-top: 14px;

  svg {
    position: absolute;
    right: 18px;
    top: 16px;
    height: 14px;
    width: 14px;
  }
`;
