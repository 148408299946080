import { message } from 'antd';
import { IDialogTrigger } from 'components/Dialog/types';
import { FormHooksProvider } from 'context/FormHooksContext';
import useLocation from 'context/locations/hooks';
import useAuthentication from 'context/security_authentication/hook';
import { getLocationAddress } from 'helpers/getLocationAddress';
import React, { useEffect } from 'react';
import usePlacesStore from '../../store';
import MainForm from './MainForm';
import { serializePlaceAddressPayload } from './helpers/payload-serializer';
import schemaResolver from './schema-resolver';

interface IProps {
  place?: any;
  afterValidation?: (place?: any) => void;
}

export default ({
  place,
  afterValidation,
  ...rest
}: IDialogTrigger & IProps) => {
  const formTitle = `${place ? 'Edit' : 'New'} Place`;
  const [
    reset,
    selectedPlace,
    setSelectedPlace,
    setIsSaving,
  ] = usePlacesStore(state => [
    state.reset,
    state.selectedPlace,
    state.setSelectedPlace,
    state.setIsSaving,
    state.contact,
  ]);
  const { createOrgLocation, modifyOrgLocation } = useLocation();
  const { currentAccount } = useAuthentication();

  useEffect(() => reset(), []);

  useEffect(() => {
    if (place) {
      setSelectedPlace({
        isSaving: false,
        ...place.address,
        placeType: 'address',
        addressId: place.addressId,
        id: place.id,
        placeName: getLocationAddress(place.address),
        longitude: place.address.longitude,
        latitude: place.address.latitude,
      });
    }
  }, [place]);

  // eslint-disable-next-line no-underscore-dangle
  async function _onSubmit(formData: any) {
    const _place = place;

    if (_place) {
      const { addressId, id } = _place;

      const {
        placeName,
        subLocation1,
        subLocation2,
        siteId,
        siteNote,
        contactInputs,
        divisionId,
      } = formData;

      const modifyAddressInput = {
        id: addressId,
        address1: selectedPlace.address1,
        postCode: selectedPlace.postCode,
        district: selectedPlace.district,
        accuracy: selectedPlace.accuracy,
        neighborhood: selectedPlace.neighborhood,
        region: selectedPlace.region,
        regionCode: selectedPlace.regionCode,
        locality: selectedPlace.locality,
        country: selectedPlace.country,
        countryCode: selectedPlace.countryCode,
        mapId: selectedPlace.mapId,
        coordinates: selectedPlace.coordinates,
        longitude: selectedPlace.longitude,
        latitude: selectedPlace.latitude,
        place: selectedPlace.place,
        placeType: selectedPlace.placeType,
      };

      const payload = {
        id,
        modifyAddressInput,
        addressId,
        placeName,
        siteId,
        siteNote,
        subLocation1,
        subLocation2,
        divisionId,
        contactInputs: contactInputs.map(c => {
          delete c['__typename'];
          return c;
        }),
      };

      setIsSaving(true);
      const result = await modifyOrgLocation(payload);
      if (result?.data) {
        message.success('Place updated');
        afterValidation?.(result.data?.modifyOrganizationLocation);
      } else {
        message.error('Cannot save Place.');
      }

      setIsSaving(false);
      return;
    }

    onCreate(formData);
  }

  async function onCreate(formData: any) {
    const payload = {
      addAddressInput: serializePlaceAddressPayload(formData, selectedPlace),
      ...formData,
      organizationId:
        currentAccount?.organizationOrganizationMapping?.childOrganizationId,
    };

    const qry = await createOrgLocation(payload);
    const {
      data: { addOrganizationLocation },
    } = qry;

    if (addOrganizationLocation) {
      afterValidation?.(addOrganizationLocation);
      message.success(`Place ${formData.placeName} created`);
    } else {
      message.error('Something went wrong ...');
    }
  }

  function _getFormOptions() {
    let defaultValues = {};
    if (place) {
      defaultValues = {
        placeName: place.placeName,
        organizationId: getLocationAddress(place.address),
        siteId: place.siteId,
        siteNote: place.siteNote,
        subLocation1: place.subLocation1,
        subLocation2: place.subLocation2,
        contactInputs: place.organizationLocationContactMappings?.map(o => ({
          ...o.contact,
          id: o.contactId,
          isDefaultContact: o.isDefaultContact,
        })),
        divisionId: place.divisionId,
        division: place.division,
      };
      return {
        shouldShowErrors: false,
        opts: { defaultValues },
      };
    }

    return {
      shouldShowErrors: false,
    };
  }

  return (
    <FormHooksProvider resolver={schemaResolver} {..._getFormOptions()}>
      <MainForm
        place={selectedPlace}
        {...rest}
        onSubmit={_onSubmit}
        title={formTitle}
      />
    </FormHooksProvider>
  );
};
