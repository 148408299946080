import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'antd';
import { useGetPermissionsLazyQuery } from 'api/graphql/generated/serviceTypesAndHooks';
import CollapsibleContent from 'components/CollapsibleContent';
import Spinner from 'components/Loaders/Spinner';
import React, { useEffect, useState } from 'react';
import { TPermission, constructPermissionsTable } from './helper';

export default function Index() {
  const [permissions, setPermissions] = useState([]);

  const workspacePermissions = permissions.length
    ? constructPermissionsTable(permissions)
    : undefined;

  const [getPermissions, { loading }] = useGetPermissionsLazyQuery({
    onCompleted: res => {
      if (res?.permissions?.nodes) {
        const { nodes } = res.permissions;
        const perm = nodes.map(dataItem => {
          if (dataItem?.id) {
            return dataItem;
          }
          return [];
        });

        setPermissions(() => perm);
      }
    },
  });

  useEffect(() => {
    getPermissions({
      variables: {
        filters: null,
        cursor: null,
        // @ts-ignore
        order: { category: 'ASC' },
      },
    });
  }, []);

  return (
    <>
      <div className="flex flex-col px-3 mt-3">
        <Input
          className="px-2 rounded-md"
          placeholder={`${
            loading ? 'loading permissions ...' : 'type permission'
          }`}
          onPressEnter={e => {
            e.preventDefault();
          }}
          prefix={
            loading ? (
              <span>
                <Spinner />
              </span>
            ) : (
              <FontAwesomeIcon
                icon={faSearch}
                style={{
                  fontSize: '12px',
                  color: 'gray',
                  marginRight: '4px',
                  opacity: 0.3,
                }}
              />
            )
          }
        />
      </div>

      {workspacePermissions ? (
        <PermissionTable permissions={workspacePermissions} />
      ) : null}
    </>
  );
}

const PermissionTable = ({
  permissions,
}: {
  permissions: { [key: string]: Array<TPermission> };
}) => {
  return (
    <div className="flex flex-col h-[400px] overflow-y-scroll pb-4">
      {Object.keys(permissions).map(category => (
        <CollapsibleContent
          key={category}
          label={category[0].toUpperCase() + category.slice(1)}
          content={<PermissionCategory permissions={permissions[category]} />}
        />
      ))}
    </div>
  );
};

const PermissionCategory = ({ permissions }: { permissions: any }) => {
  return (
    <div className="flex flex-col min-h-min overflow-hidden pl-4">
      {permissions.map(permission => (
        <div className="py-2" key={permission}>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-semibold">{permission.name}</span>
              <span className="text-sm text-gray-500">
                {permission.description}
              </span>
            </div>
            <div className="flex items-center px-2">
              <select
                id="countries"
                className="bg-gray-50 shadow-sm border border-gray-300 text-gray-900 text-sm rounded-lg 
            focus:ring-gray-200 focus:border-gray-200 block w-full px-2 py-1
            dark:bg-gray-200 dark:border-gray-300 dark:placeholder-gray-400 dark:text-gray-700
            dark:focus:ring-gray-300 dark:focus:border-gray-300"
                style={{ borderRight: '8px solid transparent' }}
              >
                <option value="2" selected>
                  Allow
                </option>
                <option value="0">Not Set</option>
                <option value="1">Deny</option>
              </select>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
