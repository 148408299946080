import { ICellEditorParams } from 'ag-grid-community';
import { Select } from 'antd';
import {
  DangerousGoodFilterInput,
  useGetDangerousGoodLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import useAuthentication from 'context/security_authentication/hook';
import { debounce } from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

interface ISelectCellParams extends ICellEditorParams {
  value: string;
}

export default forwardRef((props: ISelectCellParams, ref) => {
  // =============================== init ======================================//
  const [value, setValue] = useState(props.value);
  const [options, setOptions] = useState(null);
  const { currentAccount } = useAuthentication();
  const [dgList, setDgList] = useState(null);

  // =============================== handler ======================================//

  const handleSearch = value => {
    if (!value) {
      setOptions([]);
      return false;
    }
    const variables = {
      filters: {
        unnumberAa: { contains: value },
        isDeleted: { neq: true },
      } as DangerousGoodFilterInput,
      cursor: null,
      order: { id: 'DESC' },
    };

    getDangerousGoods(variables);
    return true;
  };

  const handleOnChange = value => {
    setValue(value);
    const seletedItem = options?.find(item => item.value === value);
    if (seletedItem) {
      props.parseValue(seletedItem);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        const valueFormat = dgList?.find(item => item.id === value);

        if (valueFormat) {
          return valueFormat;
        }
        return props.value;
      },
      afterGuiAttached: () => {
        setValue(props.value);
      },
    };
  });

  // =============================== data process ======================================//

  const [getDangerousGoodLazyQuery] = useGetDangerousGoodLazyQuery({
    onCompleted: response => {
      if (response?.dangerousGoods?.nodes) {
        setDgList(response?.dangerousGoods?.nodes);
        const listOptions = response.dangerousGoods.nodes.map(dataItem => {
          if (dataItem?.id) {
            return {
              value: dataItem.id,
              label: dataItem.unnumberAa,
            };
          }
          return undefined;
        });
        setOptions(listOptions);
      }
    },
  });

  const getDangerousGoods = variables => {
    getDangerousGoodLazyQuery({
      variables,
    });
  };

  // =============================== useEffect ======================================//

  useEffect(() => {
    const variables = {
      filters: { isDeleted: { neq: true } } as DangerousGoodFilterInput,
      cursor: null,
      order: { id: 'DESC' },
    };
    getDangerousGoods(variables);
  }, [currentAccount]);

  // ================================ main render ======================================//

  return (
    <>
      {!props.node.rowPinned ? (
        <Select
          style={{ width: '100%' }}
          value={value}
          onSearch={debounce(handleSearch, 400)}
          onChange={handleOnChange}
          bordered={false}
          allowClear
          options={options}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.label
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        />
      ) : (
        <span>{props.value}</span>
      )}
    </>
  );
});
