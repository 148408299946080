import { Modal } from 'antd';
import tw, { styled } from 'twin.macro';

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    background-color: ${p => p.theme.modalHeaderBackgroundColor} !important;
    .ant-modal-title {
      color: #000;
      font-weight: bold;
    }
    .anticon svg {
      color: #000;
    }
  }
  .ant-modal-body {
    padding-top: 12px;
    overflow: hidden;
    .ant-form-item-label {
      padding: 0;

      label {
        font-size: 14px;
      }
    }

    .ant-form-item {
      margin-bottom: 0 !important;
    }

    /* .ant-form-item-required {
      &::before {
        display: none !important;
      }
    } */

    .form-events {
      position: absolute;
      bottom: 8px;
      text-align: right;
      width: 100%;
      left: 6px;

      button {
        margin-right: 8px;

        span {
          vertical-align: 0;
        }
      }
    }

    .user-details-label {
      display: flex;
      align-items: center;

      img {
        ${tw`rounded-full`}
        margin-right: 5px;
      }
    }
  }
  .ant-modal-close {
    display: none;
    color: #000;
  }
`;
