import { ICellEditorParams } from 'ag-grid-community';
import { Select } from 'antd';
import {
  RateFilterInput,
  RateGroupOrganizationMappingFilterInput,
  RateRateGroupMappingFilterInput,
  useGetRateCommoditiesFromRateRateGroupMappingsLazyQuery,
  useGetRateCommoditiesFromRatesLazyQuery,
  useGetRateGroupOrgMappingsLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { useBookingContext } from 'context/BookingContext';
import useAuthentication from 'context/security_authentication/hook';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

interface ISelectCellParams extends ICellEditorParams {
  value: string;
}

export default forwardRef((props: ISelectCellParams, ref) => {
  // ================================ init ======================================//
  const [value, setValue] = useState(props.value);
  const [options, setOptions] = useState(null);
  const { currentAccount } = useAuthentication();
  const [rateCommodityList, setRateCommodityList] = useState(null);
  const { bookingType, calculateRateInput } = useBookingContext();

  // ================================ handler ======================================//

  const handleOnChange = value => {
    setValue(value);
    const seletedItem = options?.find(item => item.value === value);
    if (seletedItem) {
      props.parseValue(seletedItem);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        const valueFormat = rateCommodityList?.find(item => item.id === value);
        if (valueFormat) {
          return valueFormat;
        }
        return props.value;
      },
      afterGuiAttached: () => {
        setValue(props.value);
      },
    };
  });

  // ================================ data process ======================================//

  const [
    getRateCommoditiesFromRateRateGroupMappingsLazyQuery,
  ] = useGetRateCommoditiesFromRateRateGroupMappingsLazyQuery({
    onCompleted: response => {
      return response;
    },
  });

  const getRateCommoditiesFromRateRateGroupMappings = variables => {
    return getRateCommoditiesFromRateRateGroupMappingsLazyQuery({
      variables,
    });
  };

  const [
    getRateCommodityFromRatesLazyQuery,
  ] = useGetRateCommoditiesFromRatesLazyQuery({
    onCompleted: response => {
      return response;
    },
  });

  const getRateCommoditiesFromRates = variables => {
    return getRateCommodityFromRatesLazyQuery({
      variables,
    });
  };

  const refineListOptions = (rateCommodities, groupRateCommodities) => {
    const filteredRates = (input, initial) => {
      if (!input) return null;
      return input.reduce((accumulator, currentValue) => {
        if (
          !accumulator?.find(
            acc => acc?.rateCommodityId === currentValue?.rateCommodityId,
          )
        ) {
          accumulator.push(currentValue);
        }
        return accumulator;
      }, initial);
    };

    // 1. direct rate
    const directRates = rateCommodities?.filter(
      rate =>
        rate?.organizationId !== rate?.rateCommodity?.subscriberOrganizationId,
    );
    // 2. group rate
    const groupRates = groupRateCommodities?.map(groupRate => groupRate?.rate);

    // 3. schedule rate
    const scheduleRates = rateCommodities?.filter(
      rate =>
        rate?.organizationId === rate?.rateCommodity?.subscriberOrganizationId,
    );

    // 4. reduce D->G->S
    const filteredDirectRates = filteredRates(directRates, []);
    const filteredGroupRates = filteredRates(groupRates, filteredDirectRates);
    const filteredScheduleRates = filteredRates(
      scheduleRates,
      filteredGroupRates,
    );

    // 5. refineListoptions for selector
    return filteredScheduleRates?.map(rate => {
      return {
        value: rate?.rateCommodity?.id,
        label: rate?.rateCommodity?.name,
      };
    });
  };

  const [getRateGroupOrgMappingQuery] = useGetRateGroupOrgMappingsLazyQuery({
    onCompleted: response => {
      return response;
    },
  });

  const getRateGroupOrgMapping = async ({ filters, cursor, order }) => {
    let resultGetRateCommodityFromGroup = null;
    const rateBookingTypeId = bookingType === 1 ? 34 : 36; // 36: container, 34: freight TODO: refactor this hard code
    const resultGetOrgGroup = await getRateGroupOrgMappingQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
    // Get Group Rate Commodities
    const rateGroupIds = resultGetOrgGroup?.data?.rateGroupOrganizationMappings?.nodes?.map(
      el => el?.rateGroupId,
    );
    if (rateGroupIds) {
      const queryGetRateCommodityFromGroup = {
        filters: {
          rateGroupId: { in: rateGroupIds },
          rate: {
            isDeleted: { neq: true },
            rateCommodity: {
              bookingTypeId: { eq: rateBookingTypeId },
            },
          },
        } as RateRateGroupMappingFilterInput,
        cursor: null,
        order: { id: 'DESC' },
      };
      resultGetRateCommodityFromGroup = await getRateCommoditiesFromRateRateGroupMappings(
        queryGetRateCommodityFromGroup,
      );
    }

    // Get Direct and Schedule Rate Commodities
    const queryGetRateCommodityFromRates = {
      filters: {
        organizationId: {
          in: [
            // Schedule rate commodity
            currentAccount?.organizationOrganizationMapping
              ?.parentOrganizationId,
            // freightPayerOrganization's rate commodity
            calculateRateInput?.customerInfoInput?.customerId,
          ],
        },
        isDeleted: { neq: true },
        rateCommodity: {
          bookingTypeId: { eq: rateBookingTypeId },
        },
        organizationIds: [
          // Schedule rate commodity
          currentAccount?.organizationOrganizationMapping?.parentOrganizationId,
          // freightPayerOrganization's rate commodity
          calculateRateInput?.customerInfoInput?.customerId,
        ],
      } as RateFilterInput,
      cursor: null,
      order: { id: 'DESC' },
      organizationIds: [
        // Schedule rate commodity
        currentAccount?.organizationOrganizationMapping?.parentOrganizationId,
        // freightPayerOrganization's rate commodity
        calculateRateInput?.customerInfoInput?.customerId,
      ],
    };

    const resultGetRateCommodityFromRates = await getRateCommoditiesFromRates(
      queryGetRateCommodityFromRates,
    );

    // refine list of options
    const refinedListOptions = refineListOptions(
      resultGetRateCommodityFromRates?.data?.ratesByOrganizationIds?.nodes,
      resultGetRateCommodityFromGroup?.data?.rateRateGroupMappings?.nodes,
    );
    // set options
    setOptions(refinedListOptions);
    const rateCommodities = resultGetRateCommodityFromRates?.data?.ratesByOrganizationIds?.nodes?.map(
      rate => rate?.rateCommodity,
    );
    const groupRateCommodities = resultGetRateCommodityFromGroup?.data?.rateRateGroupMappings?.nodes?.map(
      rate => rate?.rate?.rateCommodity,
    );

    setRateCommodityList([...rateCommodities, ...groupRateCommodities]);
  };

  useEffect(() => {
    if (calculateRateInput?.customerInfoInput?.customerId) {
      getRateGroupOrgMapping({
        filters: {
          organizationId: {
            eq: calculateRateInput?.customerInfoInput?.customerId,
          },
          isDeleted: { neq: true },
        } as RateGroupOrganizationMappingFilterInput,
        cursor: null,
        order: { id: 'DESC' },
      });
    }
  }, [calculateRateInput?.customerInfoInput?.customerId]);

  return (
    <>
      {!props.node.rowPinned ? (
        <Select
          style={{ width: '100%' }}
          value={value}
          onChange={handleOnChange}
          bordered={false}
          allowClear
          options={options}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.label
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        />
      ) : (
        <span>{props.value}</span>
      )}
    </>
  );
});
