import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'antd';
import Field from 'components/Form/Field';
import FormControlledDivisionSelect from 'components/common/FormControlledDivisionSelect';
import WithFormHooksController from 'components/common/WithFormHooksController';
import { useFormHooks } from 'context/FormHooksContext';
import React from 'react';
import usePlacesStore from '../../../store';
import MapView from '../MapView';
import FormControlledMapAddressAutoComplete from './FormControlledMapAddressAutoComplete';

export default function DetailsForm() {
  const { control } = useFormHooks();
  const selectedPlace = usePlacesStore(state => state.selectedPlace);
  let initialMapView = null;

  if (selectedPlace?.longitude && selectedPlace?.latitude) {
    initialMapView = {
      longitude: selectedPlace.longitude,
      latitude: selectedPlace.latitude,
    };
  }

  return (
    <>
      <div className="grid gap-3 grid-cols-12 h-auto px-3 mt-3">
        <Field label="Place Name" isRequired span={6}>
          <WithFormHooksController
            name="placeName"
            control={control}
            component={<Input className="px-2 rounded-md" />}
          />
        </Field>
        <Field
          label="Division"
          span={6}
          tooltip="Bookings to or from this place will be visible to this division (as well as the division which owns the booking) on the dispatch screen."
        >
          <FormControlledDivisionSelect name="divisionId" control={control} />
        </Field>
        <Field label="Location" isRequired span={12}>
          <FormControlledMapAddressAutoComplete
            name="organizationId"
            control={control}
          />
        </Field>
        <Field label="Site ID" span={6}>
          <WithFormHooksController
            control={control}
            name="siteId"
            component={<Input className="px-2 rounded-md" />}
          />
        </Field>
        <Field label="Site note" span={6}>
          <WithFormHooksController
            control={control}
            name="siteNote"
            component={<Input className="px-2 rounded-md" />}
          />
        </Field>
        <Field label="Sub Location 1" span={6}>
          <WithFormHooksController
            control={control}
            name="subLocation1"
            component={<Input className="px-2 rounded-md" />}
          />
        </Field>
        <Field label="Sub Location 2" span={6}>
          <WithFormHooksController
            control={control}
            name="subLocation2"
            component={<Input className="px-2 rounded-md" />}
          />
        </Field>
      </div>
      <div className="p-2 rounded-md overflow-hidden">
        <MapView className="h-[200px]" {...initialMapView} />
        <div className="flex items-center p-2">
          <span className="flex mr-1 ">
            <FontAwesomeIcon
              icon={faLocationDot}
              style={{
                fontSize: '16px',
                color: '#A8AEB8',
                opacity: 0.5,
              }}
            />
          </span>
          <span className="text-blue-500 text-[.7rem] truncate">
            {selectedPlace?.placeName ?? 'No location selected'}
          </span>
        </div>
      </div>
    </>
  );
}
