import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import AGGrid from 'components/ReactGrid';
import { generateId } from 'helpers/generator';
import React, { useEffect, useState } from 'react';

interface IAGGridProps {
  gridConfig: GridOptions;
  surcharges?: any;
  cartageCharges?: any;
  legs?: any;
  label?: string;
  style?: {};
}

export default function CostAndChargeGrid(props: Readonly<IAGGridProps>) {
  const { surcharges, cartageCharges, gridConfig, legs } = props;
  const [gridApi, setGridApi] = useState<GridApi>(null);
  const [gridApiColumn, setGridApiColumn] = useState<ColumnApi>(null);
  const [processTotal, setProcessTotal] = useState(false);
  const [dataItems, setDataItems] = useState([]);

  const columnsWithAggregation = ['cost', 'charge', 'quantity', 'amount'];

  const getRowId = params => params.data?.tempId;

  const handleSetItems = (surchargeList, cartageChargeList) => {
    let dataItems = [];

    if (cartageChargeList?.length) {
      const newList = [...cartageChargeList];
      const newMapList = newList?.map(item => {
        return {
          tempId: generateId(false, 6),
          surcharge: { name: 'Cartage Charge' },
          charge: item?.rateAmount,
          quantity: item?.quantity,
          amount: item?.rateAmount,
        };
      });
      dataItems = [...newMapList];
    }

    if (surchargeList?.length) {
      const newList = [...surchargeList];
      const newMapList = newList?.map(item => {
        let bookingLeg;
        const {
          entityType,
          entityId,
          bookingCharge,
          quantity,
          charge,
          ...rest
        } = item;
        if (entityType === 3) {
          let legDetails;
          const findLegEvent = legEvent => {
            legDetails = {
              id: legEvent.id,
              code: legEvent?.bookingLegEvent?.code,
            };
            return legEvent.id === entityId;
          };

          const findLegMatched = leg => {
            return leg?.bookingLegDetails?.nodes?.some(findLegEvent);
          };

          const legMatched = legs?.bookingLegs?.find(findLegMatched);

          bookingLeg = {
            legId: legMatched?.id,
            leg: { ...legMatched },
            legDetailId: entityId,
            legDetails,
          };
        } else if (item.entityType === 2) {
          const legInfo = legs.find(leg => leg.id === entityId);
          bookingLeg = {
            legId: entityId,
            leg: { ...legInfo },
          };
        }
        const tempId = generateId(false, 6);
        return {
          tempId,
          ...bookingLeg,
          chargeName: bookingCharge?.name,
          code: bookingCharge?.code,
          category: bookingCharge?.category,
          quantity,
          charge,
          amount: quantity * charge,
          ...rest,
        };
      });
      dataItems = [...dataItems, ...newMapList];
    }
    setDataItems(dataItems);
    setProcessTotal(!processTotal);
  };

  const onGridReady = params => {
    const { api, columnApi } = params;
    api.setHeaderHeight(36);
    setGridApiColumn(columnApi);
    setGridApi(api);
  };

  useEffect(() => {
    const activeSurcharges = surcharges?.filter(item => !item.isDeleted);
    const activeCartageCharges = cartageCharges?.filter(
      item => !item.isDeleted,
    );
    handleSetItems(activeSurcharges, activeCartageCharges);
  }, [surcharges, cartageCharges]);

  return (
    <div id="sc-grid" className="min-h-[150px] mb-[10px]">
      <h4>{props.label}</h4>
      <AGGrid
        getRowId={getRowId}
        {...gridConfig}
        gridApi={gridApi}
        columnApi={gridApiColumn}
        onGridReady={onGridReady}
        processTotal={processTotal}
        columnsWithAggregation={columnsWithAggregation}
        rowData={dataItems}
        hideOverlay={true}
      />
    </div>
  );
}
