import { ModuleTypes } from 'components/config/types/modules';
import useBookingRate from 'context/financials_rate/hooks';
import useCustomerGridConfig from './customersGridConfig';
import useRateGridConfig from './ratesGridConfig';

export default function useRaterConfig() {
  const {
    getFafGroupNewList,
    getRatesByFafGroupNewId,
    getOrgOrgMappingsByFafGroupNewId,
  } = useBookingRate();
  const { customersGridConfig } = useCustomerGridConfig();
  const { ratesGridConfig } = useRateGridConfig();

  const config = {
    title: 'FAF Group',
    id: 'rate-list',
    direction: 'horizontal',
    defaultActiveKey: ['rate', 'customer'],
    module: ModuleTypes.Faf,
    getFafGroupNewList: query => getFafGroupNewList(query),
    getRatesByFafGroupNewId: query => getRatesByFafGroupNewId(query),
    getCustomersByFafGroupNewId: query =>
      getOrgOrgMappingsByFafGroupNewId(query),
  };
  const detailConfig = {
    rate: {
      id: 'rate',
      header: 'Rates',
      gridConfg: ratesGridConfig,
      initGridHeight: `30vh`,
      newRecordValues: {
        id: undefined,
        apiKey: '',
        baseURL: '',
        region: 'NZ',
        status: 22,
        version: '',
      },
    },
    rateLine: {
      id: 'customer',
      header: 'Customers',
      initGridHeight: `45vh`,
      gridConfg: customersGridConfig,
      activeAddBtn: true,
    },
  };

  return {
    config,
    detailConfig,
  };
}
