export const ModuleTypes = {
  DashboardFinance: {
    label: 'Dashboard',
    value: 'dashboardfinance',
    url: '/dashboard/finance',
  },
  Booking: {
    label: 'Job Dispatch',
    customerLabel: 'Booking List',
    value: 'booking',
    url: '/dispatch/booking',
  },
  Search: {
    label: 'Search',
    value: 'search',
    url: '/dispatch/search',
  },
  Customer: {
    label: 'Customer',
    value: 'customer',
    url: '/organization/customer',
  },
  Subcontractor: {
    label: 'Subcontractor',
    value: 'subcontractor',
    url: '/organization/subcontractor',
  },
  Division: {
    label: 'Division',
    value: 'division',
    url: '/organization/division',
  },
  Vehicle: {
    label: 'Vehicle',
    value: 'vehicle',
    url: '/asset/vehicle',
  },
  Place: {
    label: 'Place',
    value: 'place',
    url: '/location/place',
  },
  Zone: {
    label: 'Zone',
    value: 'zone',
    url: '/location/zone',
  },
  Address: {
    label: 'Address(admin)',
    value: 'address',
    url: '/location/address',
  },
  Route: {
    label: 'Route',
    value: 'route',
    url: '/location/route',
  },
  Surcharge: {
    label: 'Surcharge',
    value: 'surcharge',
    url: '/financial/surcharge',
  },
  Rate: {
    label: 'Rate',
    value: 'rate',
    url: '/financial/rate',
  },
  Faf: {
    label: 'FAF Group',
    value: 'faf',
    url: '/financial/faf',
  },
  Invoice: {
    label: 'Invoiceable Bookings',
    value: 'invoice',
    url: '/financial/invoicing',
  },
  Invoices: {
    label: 'Invoices',
    value: 'invoices',
    url: '/financial/invoices',
  },
  Group: {
    label: 'Group',
    value: 'group',
    url: '/security/group',
  },
  User: {
    label: 'Users',
    value: 'user',
    url: '/security/user',
  },
  Permission: {
    label: 'Permission',
    value: 'permission',
    url: '/security/permission',
  },
  Invitation: {
    label: 'Invitation',
    value: 'invitation',
    url: '/security/invitation',
  },
  Report: {
    label: 'Report',
    value: 'report',
    url: '/security/report',
  },
  SubscriberSetting: {
    label: 'Subscriber Setting',
    value: 'subscribersetting',
    url: '/configurations/subscriberSetting',
  },
  CustomerSetting: {
    label: 'Customer Setting',
    value: 'customersetting',
    url: '/configurations/customerSetting',
  },
  BookingAttributeSetting: {
    label: 'Booking Attribute Setting',
    value: 'bookingattributesetting',
    url: '/configurations/bookingAttributeSetting',
  },
  DriverSetting: {
    label: 'Driver Setting',
    value: 'driversetting',
    url: '/configurations/driverSetting',
  },
  ServiceProvider: {
    label: 'Service Provider',
    value: 'serviceprovider',
    url: '/configurations/serviceProvider',
  },
};
