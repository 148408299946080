import { ITooltipParams } from '@ag-grid-community/core';
import useGenericCodeLists from 'context/genericCodeLists/hooks';
import React, { useMemo } from 'react';

export default (props: ITooltipParams & { color: string }) => {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex!)!.data,
    [],
  );
  const { genericCodeListData } = useGenericCodeLists();

  return (
    <div
      className="custom-tooltip"
      style={{
        backgroundColor: props.color || 'white',
        width: 'fit-content',
        height: 'fit-content',
        borderColor: 'black',
      }}
    >
      <p>
        <span> </span>
      </p>
      <p>{`${data?.rateCommodity?.name}(${data?.rateCommodityId})`}</p>
      <p>
        {`${
          genericCodeListData?.BookingType?.find(
            el => el.id === data?.rateCommodity?.bookingTypeId,
          )?.name
        }(${data?.rateCommodity?.bookingTypeId})`}
      </p>
    </div>
  );
};
