import { ICellRendererParams } from 'ag-grid-community';
import { DatePicker } from 'antd';
import DateFormats from 'components/config/date';
import dayjs from 'dayjs';
import React from 'react';
import { styled } from 'twin.macro';

const StyledDatePicker = styled(DatePicker)`
  .ant-picker-input > input {
    min-width: 94px;
  }
`;

export default function DatePickerCell(props: Readonly<ICellRendererParams>) {
  const { context, valueFormatted, value, setValue } = props;
  // eslint-disable-next-line react/prop-types
  const cellValue = valueFormatted || value;
  const onDateChange = date => {
    const newDate = dayjs(date);

    setValue(newDate);
  };

  return (
    <StyledDatePicker
      bordered={false}
      disabled={context?.disabled}
      size="small"
      format={DateFormats.asDisplayDate}
      value={cellValue ? dayjs(cellValue) : null}
      defaultValue={dayjs(cellValue).isValid() ? dayjs(cellValue) : null}
      style={{
        fontSize: '10px',
      }}
      allowClear={false}
      onChange={date => onDateChange(date)}
    />
  );
}
