import { Input } from 'antd';
import { User } from 'api/graphql/generated/serviceTypesAndHooks';
import Field from 'components/Form/Field';
import WithFormHooksController from 'components/common/WithFormHooksController';
import { useFormHooks } from 'context/FormHooksContext';
import React from 'react';
import FormControlledDivisionSelect from './FormControlledDivisionSelect';
import PhotoUploader from './PhotoUpload';

export default function Index({ user }: Readonly<{ user?: User }>) {
  const { control, setValue } = useFormHooks();

  return (
    <div className="px-4">
      <PhotoUploader />
      <div className="grid gap-3 grid-cols-12 h-auto">
        <Field label="Display Name" isRequired span={user?.id ? 6 : 12}>
          <WithFormHooksController
            name="userName"
            control={control}
            component={<Input disabled={!!user} className="px-2 rounded-md" />}
          />
        </Field>
        <Field label="Email" isRequired span={6}>
          <WithFormHooksController
            control={control}
            name="email"
            component={<Input type="email" className="px-2 rounded-md" />}
          />
        </Field>
        {user?.id ? null : (
          <Field label="Password" isRequired span={6}>
            <WithFormHooksController
              control={control}
              name="password"
              component={
                <Input.Password
                  visibilityToggle
                  placeholder="8 chars, 1 upper case, 1 number, 1 special case"
                  className="px-2 rounded-md"
                />
              }
            />
          </Field>
        )}
        <Field label="First Name" span={6}>
          <WithFormHooksController
            control={control}
            name="firstName"
            component={<Input className="px-2 rounded-md" />}
          />
        </Field>
        <Field label="Last Name" span={6}>
          <WithFormHooksController
            control={control}
            name="lastName"
            component={<Input className="px-2 rounded-md" />}
          />
        </Field>
        <Field label="Phone Number" span={6}>
          <WithFormHooksController
            control={control}
            name="phone"
            component={<Input type="tel" className="px-2 rounded-md" />}
          />
        </Field>
        <Field label="Mobile" span={6}>
          <WithFormHooksController
            control={control}
            name="mobile"
            component={<Input className="px-2 rounded-md" />}
          />
        </Field>
        <Field label="Position" span={6}>
          <WithFormHooksController
            control={control}
            name="position"
            component={<Input className="px-2 rounded-md" />}
          />
        </Field>
        <Field label="Division" isRequired>
          <FormControlledDivisionSelect
            control={control}
            name="divisionIds"
            onChange={value => setValue('divisionIds', value)}
            componentProps={{
              mode: 'multiple',
              allowClear: true,
              showSearch: true,
            }}
          />
        </Field>
      </div>
    </div>
  );
}
