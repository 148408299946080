import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Marker } from 'react-map-gl';

type Props = Readonly<{
  location?: any;
  onClick?: () => void;
}>;

export default function MarkerPin({ location, onClick }: Props) {
  const { latitude, longitude, color } = location || {};
  return (
    <Marker longitude={longitude} latitude={latitude} onClick={onClick}>
      <span style={{ cursor: 'pointer' }}>
        <FontAwesomeIcon
          icon={faLocationDot}
          style={{ fontSize: '30px', color }}
        />
      </span>
    </Marker>
  );
}
