import { GridOptions, SelectionChangedEvent } from 'ag-grid-community';
import {
  OrganizationFilterInput,
  OrganizationLocation,
} from 'api/graphql/generated/serviceTypesAndHooks';
import DefaultLayout from 'components/Layout/DefaultLayout';
import PlaceForm from 'containers/Locations/Places/DetailsScreen/components/Form';
import { placeScreenConfig } from 'containers/Locations/Places/MainScreen/config';
import useApp from 'context/app/hooks';
import useLocation from 'context/locations/hooks';
import { actions, reducer, sliceKey } from 'context/workspace/slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import { placeGridOptions } from './gridOptions';

export default () => {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });
  const { openDialog, closeDialog } = useApp();
  const dispatch = useDispatch();
  const {
    locationWorkspaceData,
    flagRows,
    selectedItems,
    getLocationList,
    refreshWorkspaceData,
    deleteOrgLocation,
    setSelectedItems,
  } = useLocation();

  function _createNewPlace() {
    openDialog({
      content: <PlaceForm afterValidation={closeDialog} />,
      dialogProps: {
        closable: false,
        height: '628px',
        width: '600px',
      },
    });
  }

  function _viewPlaceRecord(record: OrganizationLocation) {
    openDialog({
      content: <PlaceForm place={record} afterValidation={closeDialog} />,
      dialogProps: {
        closable: false,
        height: '628px',
        width: '600px',
      },
    });
  }

  const handleDeletePlace = async ids => {
    const result = await deleteOrgLocation(ids);
    let message = 'Places were deleted successfully';

    if (!result?.data?.deleteOrganizationLocations) {
      message = 'Failed to delete places';
    }

    return {
      message,
      data: result?.data?.deleteOrganizationLocations,
    };
  };

  const gridOptions = {
    ...placeGridOptions,
    onSelectionChanged: (event: SelectionChangedEvent) => {
      const { api } = event;
      const records = [];
      const selectedRows = api.getSelectedRows();
      api.forEachNodeAfterFilter((rowNode, _index) => {
        if (selectedRows.find(row => row.id === rowNode.data.id))
          records.push(rowNode.data);
      });
      setSelectedItems([...records]);
    },
  } as GridOptions;

  useEffect(() => {
    dispatch(
      actions.setWorkspace({
        workspace: { ...placeScreenConfig, gridOptions },
        query: {
          cursor: null,
          filters: {
            isDeleted: {
              neq: true,
            },
          } as OrganizationFilterInput,
          order: { id: 'DESC' },
        },
      }),
    );
  }, []);

  return (
    <DefaultLayout
      gridOptions={gridOptions}
      data={locationWorkspaceData}
      flagRows={flagRows}
      selectedItems={selectedItems}
      onGetDataList={getLocationList}
      onRefreshDataList={refreshWorkspaceData}
      onCreateNew={_createNewPlace}
      onViewRecord={_viewPlaceRecord}
      onDelete={handleDeletePlace}
    />
  );
};
