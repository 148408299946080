import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Division,
  OrganizationLocation,
  RateCommodity,
} from 'api/graphql/generated/serviceTypesAndHooks';

export interface PredefinedDataState {
  dangerousGoods: any[];
  rateCommodities: RateCommodity[];
  divisions: Division[];
  recentPlaces: OrganizationLocation[];
}
export const initialState: PredefinedDataState = {
  dangerousGoods: null,
  rateCommodities: null,
  divisions: null,
  recentPlaces: null,
};

const storeSlice = createSlice({
  name: 'predefinedData',
  initialState,
  reducers: {
    setDangerousGoodLists: (state, action: PayloadAction<any>) => {
      const { dangerousGoods } = action.payload;
      state.dangerousGoods = dangerousGoods;
    },
    setRateCommodities: (state, action: PayloadAction<any>) => {
      const { rateCommodities } = action.payload;
      state.rateCommodities = rateCommodities;
    },
    setDivisions: (state, action: PayloadAction<any>) => {
      const { divisions } = action.payload;
      state.divisions = divisions;
    },
    setRecentPlaces: (state, action: PayloadAction<any>) => {
      const { recentPlaces } = action.payload;
      state.recentPlaces = recentPlaces;
    },
  },
});

export const selectDivisions = state => state.predefinedData.divisions;
export const selectDangerousGoods = state =>
  state.predefinedData.dangerousGoods;
export const selectRateCommodities = state =>
  state.predefinedData.rateCommodities;
export const selectRecentPlaces = state => state.predefinedData.recentPlaces;

export const { actions, reducer, name: sliceKey } = storeSlice;
