import React, { ReactNode, createContext, useMemo } from 'react';
import Dialog from '..';
import useDialogStore, { IActions } from '../store';

interface DialogProviderProps {
  children: ReactNode;
}

const DialogContext = createContext<IActions>(null);

function DialogProvider({ children }: DialogProviderProps) {
  const [openDialog, closeDialog, dialog] = useDialogStore(state => [
    state.openDialog,
    state.closeDialog,
    state.dialog,
  ]);

  return useMemo(
    () => (
      <DialogContext.Provider value={{ openDialog, closeDialog }}>
        {children}
        {dialog ? <Dialog params={dialog} onClose={closeDialog} /> : null}
      </DialogContext.Provider>
    ),
    [openDialog, closeDialog, children, dialog],
  );
}

function useDialog() {
  return React.useContext(DialogContext);
}

export { DialogProvider, useDialog };
