import { IconRef } from 'components/IconSheet';
import { ModuleTypes } from 'components/config/types/modules';

export const divisionScreenConfig = {
  id: 'division-list',
  module: ModuleTypes.Division,
  gridIcon: IconRef.menuOrganization,
  initGridHeight: 225,
  toolbar: {
    actions: [
      {
        id: 'create',
        label: 'Add',
        tooltip: 'Add new division',
      },
      {
        id: 'delete',
        tooltip: 'Delete selected records',
      },
    ],
  },
  screenViews: {
    label: 'Your Views',
  },
};
