import { createGlobalStyle } from "styled-components";
import tw from "twin.macro";

export const GlobalStyle = createGlobalStyle` html,
  body {
    height: 100%;
    width: 100%;
    overflow: 'hidden';
  }

  body {
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size:  16px;
    overflow-y: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body.fontLoaded {
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }


  /* div {
    font-size: 13px;
  } */

  #root {
    min-height: 100%;
    min-width: 100%;
  }


  /* Grid Global Styles  */

  .k-grid {
    height: 100%;
  }

  .k-filtercell-operator {
    display: none !important;
  }

  .k-filtercell {
    height: 24px;
    margin-bottom: 4px;

    .k-filtercell-wrapper>.k-textbox {
      height: 24px
    }
  }

  .k-grid .k-hierarchy-cell>.k-icon {
    padding: 0;
  }


  .k-grid th, .k-grid td {
    padding: 4px 12px !important;
  }

  .k-checkbox {
    width: 14px;
    height: 14px;
  }

  .k-checkbox:checked {
    border-color: #ef6c00;
    color: #fff;
    background-color: #ef6c00;
  }

  .k-grid-content {
    color: #242424;
  }

  .k-master-row {
    ${tw`select-none`}
    height: auto !important;

    td {
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
     font-size: 12px;
    }
  }

  .k-grid td.k-state-selected,
  .k-grid tr.k-state-selected > td {
    font-weight: 500;
  }


  .k-filtercell .k-filtercell-operator  {
    display: flex
  }
  .k-filtercell .k-filtercell-operator>.k-button:not(.k-clear-button-visible) {
   display: none;
  }

  .k-filtercell .k-filtercell-operator>.k-button.k-clear-button-visible  {
    display: block;
  }

  .k-grid-content tr:last-child>td, .k-grid-content-locked tr:last-child>td {
  border-bottom-width: thin;
  }


  div[data-reach-dialog-content] {
    position: absolute;
    max-width: fit-content;
    width: 100%;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
`;
