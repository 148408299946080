import { GridReadyEvent } from 'ag-grid-community';
import { message } from 'antd';
import TopWidgets from 'components/Layout/TopWidgets';
import AGGrid from 'components/ReactGrid';
import { generateId } from 'helpers/generator';
import React, { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';

export interface Props {
  gridConfig: any;
  recordValue?: any;
  label?: string;
  style?: {};
  gridHeight?: any;
  onCreateNew?: () => void;
  onViewRecord?: (record: any) => void;
}

const StyledFormGroup = styled.div`
  h4 {
    display: block;
    width: 100%;
  }

  .ant-btn-text {
    background-color: ${p => p.theme.primaryButtonColor};
    color: white;
  }

  .grid-container {
    min-height: ${props => props?.style?.minHeight};
    ${tw`relative`}
    @media screen and (max-width: 1920px) {
      height: calc(100vh - 730px);
    }
    @media screen and (max-width: 1536px) {
      height: calc(100vh - 618px);
    }
    @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      height: calc(100vh - 770px);
    }

    div[class*='ant-spin'] {
      height: inherit;
    }
    .ag-row .ag-cell {
      display: flex;
      align-items: center;
    }
  }

  .small-grid-container {
    ${tw`relative`}
    @media screen and (max-width: 1920px) {
      height: calc(100vh - 860px);
    }
    @media screen and (max-width: 1536px) {
      height: calc(100vh - 850px);
    }
    @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      height: calc(100vh - 750px);
    }

    div[class*='ant-spin'] {
      height: inherit;
    }
    .ag-row .ag-cell {
      display: flex;
      align-items: center;
    }
  }
`;

export default function RateGrid(props: Readonly<Props>) {
  const { recordValue, gridConfig } = props;
  const [gridApi, setGridApi] = useState(null);
  const [dataItems, setDataItems] = useState([]);

  // =================== Handlers ===========================//

  const handleSetItems = list => {
    if (list?.length) {
      const newList = [...list];
      const newMapList = newList?.map(item => {
        const tempId = generateId(false, 6);
        return { tempId, ...item };
      });
      setDataItems(newMapList);
    } else {
      setDataItems([]);
    }
  };

  const onGridReady = ({ api }: GridReadyEvent) => {
    api.setHeaderHeight(36);
    setGridApi(api);
  };

  const handleAddNewItem = () => {
    if (props.onCreateNew) {
      props.onCreateNew();
    }
  };

  const handleOnRowValueChanged = async payload => {
    const { api, data } = payload;

    const result = await gridConfig?.hooks?.update(data);
    if (result?.data) {
      // remove the item from aggrid
      const nodes = api?.getRenderedNodes();
      if (nodes?.length) {
        nodes[0]?.setSelected(true); // selects the first row in the rendered view
      }
      message.success(result.message);
    } else {
      message.error(result.message);
    }
  };

  const handleDeleteRecord = async param => {
    const { api, data } = param;

    const result = await gridConfig?.hooks?.delete([data?.id]);
    if (result?.data) {
      api.applyTransaction({ remove: [data] });
      message.success(result.message);
    } else {
      message.error(result.message);
    }
  };

  const handleMultipleDelete = async () => {
    const selectedRowIds = gridApi?.getSelectedRows()?.map(el => el?.id);
    const result = await gridConfig?.hooks?.delete(selectedRowIds);
    gridApi.applyTransaction({ remove: gridApi?.getSelectedRows() });
    if (result?.data) {
      gridApi.applyTransaction({ remove: gridApi?.getSelectedRows() });
      message.success(result.message);
    } else {
      message.error(result.message);
    }
  };

  const handleRowDoubleClick = event => {
    if (props.onViewRecord) {
      const record = event.data;
      props.onViewRecord(record);
    }
  };

  const handleSelectionChanged = e => {
    const record = e?.api?.getSelectedNodes()[0];
    const selectedRow = { gridId: record?.gridId, ...record?.data };
    selectedRow.gridId = gridConfig?.gridId;
  };

  // =================== UseEffects ===========================//

  useEffect(() => {
    handleSetItems(recordValue);
  }, [recordValue]);

  // =================== Render components ===========================//
  return (
    <StyledFormGroup style={gridConfig?.style}>
      <TopWidgets
        toolbar={gridConfig?.toolbar}
        onCreateNew={handleAddNewItem}
        onMultipleDelete={handleMultipleDelete}
        className="grid-toolbar-details"
      />
      <h4>{props.label}</h4>
      <div
        className={gridConfig?.gridClassName}
        style={{
          height: `${props?.gridHeight}`,
        }}
      >
        <AGGrid
          {...gridConfig.gridOptions}
          context={{
            handleDeleteRecord,
          }}
          gridApi={gridApi}
          onGridReady={onGridReady}
          onRowValueChanged={handleOnRowValueChanged}
          rowData={dataItems}
          hideOverlay
          onCellClicked={handleSelectionChanged}
          onCellDoubleClicked={handleRowDoubleClick}
        />
      </div>
    </StyledFormGroup>
  );
}
