export function LatitudAndLongitudValidator(type, value) {
  const defaultLatitude = -37.00812673231812;
  const defaultLongitude = 174.88722111163142;
  switch (type) {
    case 'latitude':
      if (value <= 90 && value >= -90) return value;
      return defaultLatitude;
    case 'longitude':
      if (value <= 180 && value >= -180) return value;
      return defaultLongitude;
    default:
      return null;
  }
}
