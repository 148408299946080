import { GridOptions, SelectionChangedEvent } from 'ag-grid-community';
import DefaultLayout from 'components/Layout/DefaultLayout';
import DivisionForm from 'containers/Organizations/Organizations_division/DetailScreen';
import { divisionScreenConfig } from 'containers/Organizations/Organizations_division/MainScreen/config';
import useApp from 'context/app/hooks';
import useDivision from 'context/organizations_division/hooks';
import { actions, reducer, sliceKey } from 'context/workspace/slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import { divisionGridOptions } from './gridOptions';

export default () => {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();
  const { openDialog, closeDialog } = useApp();
  const {
    divisionWorkspaceData,
    flagRows,
    selectedItems,
    setSelectedItems,
    getDivisionList,
    refreshWorkspaceData,
    deleteDivision,
  } = useDivision();

  function _createNewDivision() {
    openDialog({
      content: <DivisionForm afterValidation={closeDialog} />,
      dialogProps: {
        closable: false,
        height: '323px',
        width: '520px',
      },
    });
  }

  function _viewDivisionRecord(record: any) {
    openDialog({
      content: <DivisionForm division={record} afterValidation={closeDialog} />,
      dialogProps: {
        closable: false,
        height: '323px',
        width: '520px',
      },
    });
  }

  const handleDeleteDivision = async ids => {
    const result = await deleteDivision(ids);
    let message = 'Cannot delete selected division(s)';

    if (result?.data?.deleteDivision) {
      message = 'Deleted division(s) successfully';
    }

    return {
      message,
      data: result?.data,
    };
  };

  const gridOptions = {
    ...divisionGridOptions,
    onSelectionChanged: (event: SelectionChangedEvent) => {
      const { api } = event;
      const records = [];
      const selectedRows = api.getSelectedRows();
      api.forEachNodeAfterFilter((rowNode, _index) => {
        if (selectedRows.find(row => row.id === rowNode.data.id))
          records.push(rowNode.data);
      });
      setSelectedItems([...records]);
    },
  } as GridOptions;

  useEffect(() => {
    dispatch(
      actions.setWorkspace({
        workspace: { ...divisionScreenConfig, gridOptions },
        query: {
          cursor: null,
          filters: { isDeleted: { neq: true } },
          order: { id: 'DESC' },
        },
      }),
    );
  }, []);

  return (
    <DefaultLayout
      gridOptions={gridOptions}
      data={divisionWorkspaceData}
      flagRows={flagRows}
      selectedItems={selectedItems}
      onGetDataList={getDivisionList}
      onRefreshDataList={refreshWorkspaceData}
      onCreateNew={_createNewDivision}
      onViewRecord={_viewDivisionRecord}
      onDelete={handleDeleteDivision}
    />
  );
};
