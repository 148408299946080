export const BreakpointRateType = [
  { label: 'Stepped', value: 2 },
  { label: 'PerUnit', value: 1 },
];

export const RateCommodityInputFields = {
  bookingTypeId: 'bookingTypeId',
  containerSize: 'containerSize',
};

export const RateMenuType = {
  CUSTOMER: 'customer',
  RATEGROUP: 'rateGroup',
};
