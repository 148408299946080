import { gql } from '@apollo/client';
import { fromPromise } from '@apollo/client/link/utils';

const REGENERATE_TOKEN = gql`
  mutation generateNewAccessToken($refreshToken: String!) {
    generateNewAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      expiresIn
      idToken
      tokenType
    }
  }
`;

const generateNewToken = async client => {
  const authToken = JSON.parse(localStorage.getItem('icosAuth'));
  const { refreshToken } = authToken;
  return await client.mutate({
    mutation: REGENERATE_TOKEN,
    variables: { refreshToken },
  });
};

export const fetchNewToken = (client, operation, forward) => {
  return fromPromise(
    generateNewToken(client).catch(() => {
      localStorage.removeItem('icosAuth');
      window.location.href = '/signIn';
      throw new Error('Cannot get new token.');
    }),
  )
    .filter(value => Boolean(value))
    .flatMap(res => {
      if (!res.data.generateNewAccessToken) {
        localStorage.removeItem('icosAuth');
        window.location.href = '/signIn';
        throw new Error('Cannot get new token.');
      }
      const authorization = res.data.generateNewAccessToken;
      localStorage.setItem('icosAuth', JSON.stringify(authorization));
      const { accessToken } = authorization;

      operation.setContext(({ headers }: { headers: any }) => {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${accessToken || ''}`,
          },
        };
      });
      return forward(operation);
    });
};
