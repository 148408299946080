import { Empty, Layout, Typography } from 'antd';
import {
  RateFilterInput,
  RateLineFilterInput,
  RateRateGroupMappingFilterInput,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { RateMenuType } from 'components/config/types/rates';
import RateGrid from 'containers/Financial/Rates/MainScreen/Components/RateGrid';
import {
  actions,
  selectBookingRates,
  selectRateLines,
  selectRates,
  selectSelectedRate,
} from 'context/financials_rate/slice';
import useAuthentication from 'context/security_authentication/hook';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomerList from './Components/CustomerList';

type Props = Readonly<{
  useConfig?: any;
  useDetailConfig: any;
  onCreateNewRate?: () => void;
  onCreateNewRateLine?: () => void;
  onModifyGroupSetting?: () => void;
  onViewRecord?: (record: any) => void;
}>;

const { Title } = Typography;

export default (props: Props) => {
  // = =========initial===========//
  const { useConfig, useDetailConfig } = props;
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [rateList, setRateList] = useState(null);
  const [rateLineList, setRateLineList] = useState([]);
  const selectedRateIdRef = useRef(null);
  const { currentAccount } = useAuthentication();

  const {
    queryOrgList,
    orgList,
    // queryRateGroupList,
    // rateGroupList,
  } = useSelector(selectBookingRates);
  const ratesFromRedux = useSelector(selectRates);
  const rateLinesFromRedux = useSelector(selectRateLines);
  const selectedRate = useSelector(selectSelectedRate);
  const dispatch = useDispatch();

  const { Content, Sider } = Layout;

  // ========= event handlers=============//

  const handleSelectionChanged = selectedRow => {
    // set selectedRate on redux store
    if (selectedRow?.gridId === 'rate') {
      dispatch(
        actions.setSelectedRate({
          selectedRow,
        }),
      );
    }

    dispatch(
      actions.setRefreshEditingAddress({
        editingAddressFrom: {},
        editingAddressTo: {},
      }),
    );
  };

  const menuClickHandler = async e => {
    const isCustomer = e?.type === RateMenuType.CUSTOMER;
    const id = Number(e?.key);
    if (isCustomer) {
      setSelectedMenuItem({
        type: RateMenuType.CUSTOMER,
        id,
      });
      dispatch(
        actions.setSelectedMenu({
          selectedMenu: {
            type: RateMenuType.CUSTOMER,
            id,
          },
        }),
      );
      dispatch(
        actions.setRefreshData({
          rates: {},
          rateLines: {},
          selectedRow: {},
          editingAddressFrom: {},
          editingAddressTo: {},
        }),
      );
    } else {
      setSelectedMenuItem({ type: RateMenuType.RATEGROUP, id });
      dispatch(
        actions.setSelectedMenu({
          selectedMenu: { type: RateMenuType.RATEGROUP, id },
        }),
      );
      dispatch(
        actions.setRefreshData({
          rates: {},
          rateLines: {},
          selectedRow: {},
          editingAddressFrom: {},
          editingAddressTo: {},
        }),
      );
    }
    selectedRateIdRef.current = null;
  };

  // ============ useEffects ============//

  useEffect(() => {
    if (queryOrgList) useConfig.getOrgList(queryOrgList);
  }, [queryOrgList]);

  // useEffect(() => {
  //   if (queryRateGroupList) useConfig.getRateGroupList(queryRateGroupList);
  // }, [queryRateGroupList]);

  useEffect(() => {
    if (selectedMenuItem?.type === RateMenuType.CUSTOMER) {
      useConfig?.getRates({
        cursor: null,
        filters: {
          organizationId: { eq: selectedMenuItem?.id },
          isDeleted: { neq: true },
        } as RateFilterInput,
        order: { id: 'DESC' },
        organizationIds: [selectedMenuItem?.id],
      });
    } else if (selectedMenuItem?.type === RateMenuType.RATEGROUP) {
      useConfig?.getRateRateGroupMapping({
        cursor: null,
        filters: {
          rateGroupId: { eq: selectedMenuItem?.id },
          rate: { isDeleted: { neq: true } },
        } as RateRateGroupMappingFilterInput,
        order: { id: 'DESC' },
      });
    }
  }, [selectedMenuItem]);

  useEffect(() => {
    setRateList(ratesFromRedux?.nodes);
  }, [ratesFromRedux]);

  useEffect(() => {
    setRateLineList(rateLinesFromRedux?.nodes);
  }, [rateLinesFromRedux]);

  useEffect(() => {
    // query rateLines
    if (selectedRateIdRef.current !== selectedRate?.id && selectedRate?.id) {
      useConfig.getRateLines({
        cursor: null,
        filters: {
          rateId: { eq: selectedRate?.id },
          isDeleted: { neq: true },
        } as RateLineFilterInput,
        order: { breakPoint1: 'ASC' },
      });
      selectedRateIdRef.current = selectedRate?.id;
    }
  }, [selectedRate]);

  // = =========== render components ============== //

  return (
    <Layout>
      <Sider width={320} theme="light">
        <CustomerList
          height="calc(31vh)"
          onChange={menuClickHandler}
          customerList={orgList}
          title="Customer List"
          currentAccount={currentAccount}
          selectedMenuItem={selectedMenuItem}
        />
        {/* <GroupList
          height="calc(31vh)"
          onChange={menuClickHandler}
          rateGroupList={rateGroupList}
          title="Group List"
          onHandleRateGroupSetting={props.onModifyGroupSetting}
          selectedMenuItem={selectedMenuItem}
        /> */}
      </Sider>
      <Content>
        {selectedMenuItem ? (
          <div>
            {Object.keys(useDetailConfig)?.map(fieldKey => {
              const field = useDetailConfig[fieldKey];
              return (
                <div className="px-2" key={fieldKey}>
                  <Title level={4}>{field?.header}</Title>
                  <RateGrid
                    gridConfig={field?.gridConfg}
                    recordValue={fieldKey === 'rate' ? rateList : rateLineList}
                    onHandleSelectionChanged={handleSelectionChanged}
                    onSelectedMenuItem={selectedMenuItem}
                    gridHeight={field?.initGridHeight}
                    onCreateNew={
                      fieldKey === 'rate'
                        ? props.onCreateNewRate
                        : props.onCreateNewRateLine
                    }
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <Empty />
        )}
      </Content>
    </Layout>
  );
};
