export type TPermission = {
  name: string;
  description: string;
};

export function constructPermissionsTable(
  permissions: Array<any>,
): { [key: string]: Array<TPermission> } {
  const permissionsTable = {};

  permissions.forEach(permission => {
    const { category, description, name } = permission;
    if (!permissionsTable[category]) {
      permissionsTable[category] = [];
    }

    permissionsTable[category].push({
      name,
      description,
    });
  });

  return permissionsTable;
}
