import {
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetAllCustomersLazyQuery,
  useGetOrgOrgMappingsLazyQuery,
  useUpdateOrganizationMutation,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { ERROR_MESSAGE } from 'helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  actions,
  reducer,
  selectFlagRows,
  selectSelectedItems,
  selectWorkspaceData,
  sliceKey,
} from './slice';

export default function useOrganization() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();

  const organizationWorkspaceData = useSelector(selectWorkspaceData);
  const flagRows = useSelector(selectFlagRows);
  const selectedItems = useSelector(selectSelectedItems);

  const [
    getOrganizationQuery,
    { loading: pendingGetOrganization },
  ] = useGetOrgOrgMappingsLazyQuery({
    onCompleted: response => {
      let data;
      if (response?.organizationOrganizationMappings?.nodes) {
        const nodes = response.organizationOrganizationMappings.nodes.map(
          dataItem => {
            if (dataItem?.childOrganization?.id) {
              return { ...dataItem, name: dataItem?.childOrganization?.name };
            }
            return undefined;
          },
        );

        let newList = nodes.filter(node => node?.childOrganization?.id);
        if (
          organizationWorkspaceData?.nodes?.length > 0 &&
          response.organizationOrganizationMappings.pageInfo.hasPreviousPage
        ) {
          newList = [...(organizationWorkspaceData?.nodes ?? []), ...nodes];
        }
        data = {
          ...response?.organizationOrganizationMappings,
          nodes: newList,
        };
      }
      dispatch(
        actions.setWorkspaceData({
          workspaceData: { ...data, loading: false, error: null },
        }),
      );
    },
    onError: () => {
      dispatch(
        actions.setWorkspaceData({
          workspaceData: {
            nodes: [],
            loading: false,
            error: ERROR_MESSAGE.LOADING_DATA_ERROR,
          },
        }),
      );
    },
  });

  const [
    getAllCustomersQuery,
    { loading: pendingAllCustomers },
  ] = useGetAllCustomersLazyQuery({
    onCompleted: response => {
      let data;
      if (response?.customers?.nodes) {
        const nodes = response.customers.nodes.map(dataItem => {
          if (dataItem?.id) {
            return { ...dataItem, name: dataItem?.name };
          }
          return undefined;
        });

        let newList = nodes.filter(node => node?.id);
        if (
          organizationWorkspaceData?.nodes?.length > 0 &&
          response.customers.pageInfo.hasPreviousPage
        ) {
          newList = [...(organizationWorkspaceData?.nodes ?? []), ...nodes];
        }
        data = {
          ...response?.customers,
          nodes: newList,
        };
      }
      dispatch(
        actions.setWorkspaceData({
          workspaceData: { ...data, loading: false },
        }),
      );
    },
  });

  const getCustomerList = ({ filters, cursor, order }) => {
    getAllCustomersQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const getOrganizationList = ({ filters, cursor, order }) => {
    getOrganizationQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const refreshWorkspaceData = () => {
    dispatch(
      actions.setWorkspaceData({
        workspaceData: null,
      }),
    );
  };

  const setSelectedItems = rows => {
    dispatch(
      actions.setSelectedItems({
        selectedItems: rows,
      }),
    );
  };

  const [
    createOrganizationMutation,
    { loading: pendingCreateOrganization },
  ] = useCreateOrganizationMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createOrganization = input => {
    return createOrganizationMutation({
      variables: { input },
    });
  };

  const [
    updateOrganizationMutation,
    { loading: pendingUpdateOrganization },
  ] = useUpdateOrganizationMutation();

  const updateOrganization = input => {
    return updateOrganizationMutation({
      variables: { input },
    });
  };

  const [
    deleteOrganizationMutation,
    { loading: pendingDeleteOrganization },
  ] = useDeleteOrganizationMutation();

  const deleteOrganizations = ids => {
    return deleteOrganizationMutation({
      variables: { ids },
    });
  };

  const setNewItem = newItem => {
    const newList = [newItem, ...(organizationWorkspaceData?.nodes ?? [])];
    const data = {
      ...organizationWorkspaceData,
      nodes: newList,
    };
    dispatch(
      actions.setNewOrganization({
        newItem,
        workspaceData: data,
        flagRows: [newItem],
      }),
    );
  };

  const setUpdatedItem = updatedItem => {
    const newList = organizationWorkspaceData?.nodes?.map(item => {
      if (item?.id === updatedItem?.id) {
        return { ...item, ...updatedItem };
      }
      return item;
    });
    const data = {
      ...organizationWorkspaceData,
      nodes: newList,
    };
    dispatch(
      actions.setUpdatedOrganization({
        updatedItem,
        workspaceData: data,
        flagRows: [updatedItem],
      }),
    );
  };

  return {
    refreshWorkspaceData,
    getOrganizationList,
    getCustomerList,
    createOrganization,
    updateOrganization,
    deleteOrganizations,
    organizationWorkspaceData,
    pendingCreateOrganization,
    pendingUpdateOrganization,
    pendingDeleteOrganization,
    pendingGetOrganization,
    pendingAllCustomers,
    setUpdatedItem,
    setNewItem,
    flagRows,
    selectedItems,
    setSelectedItems,
  };
}
