import { GridOptions } from 'ag-grid-community';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';
import { numberFilterParams } from 'helpers/filterParams';

export const customerGridOptions = {
  headerHeight: 40,
  components: {
    CustomCell,
  },
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressSideButton: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  },
  defaultColDef: {
    initialHide: false,
    enableRowGroup: true,
    editable: false,
    cellRenderer: 'CustomCell',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: { newRowsAction: 'keep', debounceMs: 1500 },
  },
  suppressScrollOnNewData: true,
  columnDefs: [
    {
      headerName: 'Customer ID',
      field: 'id',
      filter: 'agNumberColumnFilter',
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Name',
      field: 'name',
      pinned: 'left',
    },
    {
      headerName: 'Display Name',
      field: 'displayName',
      width: 300,
    },
    {
      headerName: 'Docket Prefix',
      field: 'autoDocket.docketPrefix',
    },
    {
      headerName: 'Range Start',
      field: 'autoDocket.rangeStart',
      filter: 'agNumberColumnFilter',
      filterParams: numberFilterParams,
    },
    {
      headerName: 'First Name',
      field: 'defaultContact.firstName',
      filter: false,
      sortable: false,
    },
    {
      headerName: 'Last Name',
      field: 'defaultContact.lastName',
      filter: false,
      sortable: false,
    },
    {
      headerName: 'Email',
      field: 'defaultContact.email',
      filter: false,
      sortable: false,
    },
    {
      headerName: 'Phone',
      field: 'defaultContact.phone',
      filter: false,
      sortable: false,
    },
    {
      headerName: 'Mobile',
      field: 'defaultContact.mobile',
      filter: false,
      sortable: false,
    },
    {
      headerName: 'Address',
      field: 'address.address1',
    },
    {
      headerName: 'Post Code',
      field: 'address.postCode',
    },
    {
      headerName: 'Region',
      field: 'address.addressCodeRegion.name',
    },
    {
      headerName: 'Region Code',
      field: 'address.addressCodeRegion.code',
    },
    {
      headerName: 'Country',
      field: 'address.addressCodeCountry.name',
    },
    {
      headerName: 'Country Code',
      field: 'address.addressCodeCountry.code',
    },
    {
      headerName: 'Division',
      field:
        'organizationOrganizationMappingChildOrganizations.some.division.name',
      valueGetter: params => {
        if (params.data?.division) {
          return params.data.division.name;
        }
        return null;
      },
    },
  ],
} as GridOptions;
