import React from 'react';

export default ({ title, subTitle }: { title: string; subTitle?: string }) => (
  <div className="relative border-solid border-r border-gray-200 pr-4">
    <h3 className="text-[18px] font-semibold text-primary">{title}</h3>
    {subTitle ? (
      <span className="flex text-[.8rem] -mt-[.4rem] text-mainOrange font-medium">
        {subTitle}
      </span>
    ) : null}
  </div>
);
