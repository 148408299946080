import { ICellEditorParams } from 'ag-grid-community';
import { Select } from 'antd';
import {
  SurchargeFilterInput,
  useGetSurchargeLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import useAuthentication from 'context/security_authentication/hook';
import { debounce } from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

interface ISelectCellParams extends ICellEditorParams {
  value: string;
}

export default forwardRef((props: ISelectCellParams, ref) => {
  // = =============================== init ======================================//
  const [value, setValue] = useState(props.value);
  const [options, setOptions] = useState([]);
  const { currentAccount } = useAuthentication();
  const [surchargeList, setSurchargeList] = useState([]);

  // = =============================== handler ======================================//

  const handleSearch = value => {
    if (!value) {
      setOptions([]);
      return false;
    }
    const variables = {
      filters: {
        or: [{ name: { contains: value } }, { code: { contains: value } }],
        isDeleted: { neq: true },
      } as SurchargeFilterInput,
      cursor: null,
      order: { id: 'DESC' },
    };
    getSurcharges(variables);
    return true;
  };

  const handleOnChange = value => {
    setValue(value);
    const seletedItem = options?.find(item => item.value === value);

    if (seletedItem) {
      props.parseValue(seletedItem);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        const valueFormat = surchargeList?.find(item => item.id === value);

        if (valueFormat) {
          return valueFormat;
        }

        return props.value;
      },
      afterGuiAttached: () => {
        setValue(props.value);
      },
    };
  });

  // = =============================== data process ======================================//

  const [getSurchargeLazyQuery] = useGetSurchargeLazyQuery({
    onCompleted: response => {
      const {
        surcharges: { nodes },
      } = response;

      setSurchargeList(nodes);

      if (nodes?.length) {
        const options = nodes?.map?.((dataItem: any) => {
          if (dataItem?.id) {
            return {
              value: dataItem.id,
              label: dataItem.name,
            };
          }
          return undefined;
        });
        setOptions(options);
      }
    },
  });

  const getSurcharges = variables => {
    getSurchargeLazyQuery({
      variables,
    });
  };

  // = =============================== useEffect ======================================//

  useEffect(() => {
    const variables = {
      filters: {
        isDeleted: { neq: true },
      } as SurchargeFilterInput,
      cursor: null,
      order: { id: 'DESC' },
    };
    getSurcharges(variables);
  }, [currentAccount]);

  // = =============================== main render ======================================//

  return (
    <>
      {!props.node.rowPinned ? (
        <Select
          style={{ width: '100%' }}
          value={value}
          onSearch={debounce(handleSearch, 400)}
          onChange={handleOnChange}
          bordered={false}
          allowClear
          options={options}
          showSearch
          optionFilterProp="children"
          filterOption={(_input, _option) => true}
        />
      ) : (
        <span>{props.value}</span>
      )}
    </>
  );
});
