import { FafGroupRate } from 'api/graphql/generated/serviceTypesAndHooks';
import CustomerForm from 'containers/Financial/FafGroup/DetailsScreen/CustomerForm';
import FafGroupForm from 'containers/Financial/FafGroup/DetailsScreen/FafGroupForm';
import RateForm from 'containers/Financial/FafGroup/DetailsScreen/RateForm';
import MainScreen from 'containers/Financial/FafGroup/MainScreen';
import useRateConfig from 'containers/Financial/FafGroup/MainScreen/config';
import useApp from 'context/app/hooks';
import { actions as rateActions } from 'context/financials_rate/slice';
import { actions, reducer, sliceKey } from 'context/workspace/slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';

export default () => {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();
  const { config, detailConfig } = useRateConfig();
  const { openDialog, closeDialog } = useApp();

  const onRateUpdate = (response: FafGroupRate) => {
    closeDialog();
    window.$gridApi?.getRowNode?.(response?.id)?.setData?.(response);
  };

  function _modifyFafGroup() {
    openDialog({
      content: <FafGroupForm afterValidation={onRateUpdate} />,
      dialogProps: {
        closable: false,
        height: '300px',
        width: '500px',
      },
    });
  }
  function _createNewRate() {
    openDialog({
      content: <RateForm afterValidation={onRateUpdate} />,
      dialogProps: {
        closable: false,
        width: '500px',
        height: '400px',
      },
    });
  }

  function _onViewRate(record) {
    openDialog({
      content: <RateForm rate={record} afterValidation={onRateUpdate} />,
      dialogProps: {
        closable: false,
        width: '500px',
        height: '400px',
      },
    });
  }

  function _createNewCustomer() {
    openDialog({
      content: <CustomerForm afterValidation={onRateUpdate} />,
      dialogProps: {
        closable: false,
        width: '800px',
        height: '550px',
      },
    });
  }

  dispatch(
    actions.setWorkspace({
      loading: true,
      workspace: config,
      workspaceId: config?.id,
    }),
  );

  useEffect(() => {
    dispatch(
      rateActions.setQueryFafGroupNewList({
        query: {
          cursor: null,
          filters: {
            isDeleted: { neq: true },
          },
          order: { id: 'DESC' },
        },
      }),
    );
  }, []);

  return (
    <MainScreen
      useConfig={config}
      useDetailConfig={detailConfig}
      onCreateNewRate={_createNewRate}
      onCreateNewCustomer={_createNewCustomer}
      onModifyFafGroup={_modifyFafGroup}
      onViewRate={_onViewRate}
    />
  );
};
