import { Button } from 'antd';
import { styled } from 'twin.macro';

const StyledSubmitButton = styled(Button)`
  background-color: ${p => p.theme.submitButtonColor} !important;
  color: ${p => p.theme.submitButtonTextColor} !important;
`;

const StyledCancelButton = styled(Button)`
  background-color: ${p => p.theme.cancelButtonColor} !important;
  color: ${p => p.theme.cancelButtonTextColor} !important;
`;

export { StyledSubmitButton, StyledCancelButton };
