import { Avatar, Space, Tooltip, Typography } from 'antd';
import { OrganizationType } from 'components/config/types/common';
import { selectShowRightSideMenu } from 'context/rightSideMenu/selectors';
import { setShowRightSideMenu } from 'context/rightSideMenu/slice';
import useAuthentication from 'context/security_authentication/hook';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'twin.macro';

const { Title } = Typography;

const StyledTitle = styled(Title)`
  margin-bottom: 0px !important;
  text-align: end !important;
`;

export default function RightHeader() {
  const dispatch = useDispatch();
  const { currentAccount } = useAuthentication();
  const showDrawer = useSelector(selectShowRightSideMenu);

  const { id, organizationOrganizationMapping, contact, userName } =
    currentAccount || {};

  const firstName = contact?.firstName?.[0] || userName?.[0] || 'N';
  const lastName = contact?.lastName?.[0] || userName?.[1] || 'A';
  const avatarName = `${firstName}${lastName}`;

  const displayNameOrg = organizationOrganizationMapping?.displayName;
  const accountName =
    contact?.firstName && contact?.lastName
      ? `${contact.firstName} ${contact.lastName}`
      : userName || 'NA';

  const organizationTypeId =
    organizationOrganizationMapping?.organizationTypeId;
  const tooltipTitle =
    organizationTypeId === OrganizationType.Subscriber
      ? `Subscriber ID: ${organizationOrganizationMapping?.parentOrganizationId}`
      : organizationTypeId === OrganizationType.Customer
      ? `Customer ID: ${organizationOrganizationMapping?.childOrganizationId}`
      : undefined;

  return (
    <div className="flex mx-2 items-center" style={{ height: '64px' }}>
      <Space direction="vertical" className="mr-2 items-end">
        <Tooltip placement="top" title={`User ID: ${id}`} color="gray">
          <StyledTitle
            style={{
              fontWeight: 'normal',
              fontSize: '14px',
              color: '#40464F',
            }}
          >
            {accountName}
          </StyledTitle>
        </Tooltip>
        <Tooltip placement="top" title={tooltipTitle} color="gray">
          <StyledTitle
            style={{
              fontWeight: 'normal',
              color: '#A6A9AC',
              fontSize: '11px',
            }}
          >
            {displayNameOrg}
          </StyledTitle>
        </Tooltip>
      </Space>
      <Avatar
        style={{
          backgroundColor: 'gray',
          verticalAlign: 'middle',
          cursor: 'pointer',
        }}
        onClick={() => dispatch(setShowRightSideMenu(!showDrawer))}
      >
        {avatarName?.toUpperCase()}
      </Avatar>
    </div>
  );
}
