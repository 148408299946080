import { message } from 'antd';
import { IDialogTrigger } from 'components/Dialog/types';
import { FormHooksProvider } from 'context/FormHooksContext';
import useDivisions from 'context/organizations_division/hooks';
import useAuthentication from 'context/security_authentication/hook';
import React, { useEffect } from 'react';
import MainForm from './components/MainForm';
import schemaResolver from './components/schema-resolver';
import useDivisionsStore from './store';

interface IProps {
  division?: any;
  afterValidation?: (division?: any) => void;
}

export default ({
  division,
  afterValidation,
  ...rest
}: IDialogTrigger & IProps) => {
  const formTitle = `${division ? 'Edit' : 'New'} Division`;
  const [
    reset,
    selectedDivision,
    setSelectedDivision,
    setIsSaving,
  ] = useDivisionsStore(state => [
    state.reset,
    state.selectedDivision,
    state.setSelectedDivision,
    state.setIsSaving,
  ]);
  const { createDivision, updateDivision } = useDivisions();
  const { currentAccount } = useAuthentication();

  useEffect(() => reset(), []);

  useEffect(() => {
    if (division) {
      setSelectedDivision({
        isSaving: false,
        ...division,
      });
    }
  }, [division]);

  async function _onSubmit(formData: any) {
    const _division = division;
    if (_division) {
      const payload = {
        id: _division.id,
        name: formData?.name,
      };
      const result = await updateDivision(payload);
      if (result?.data) {
        message.success('Update division Successfully');
        afterValidation?.({
          ...result?.data?.modifyDivision,
        });
      } else {
        message.error('Cannot update division');
      }
      setIsSaving(false);
      return;
    }

    onCreate(formData);
  }

  async function onCreate(formData: any) {
    const payload = {
      ...formData,
      organizationId:
        currentAccount?.organizationOrganizationMapping?.childOrganizationId,
    };

    const qry = await createDivision(payload);
    const {
      data: { addDivision },
    } = qry;

    if (addDivision) {
      afterValidation?.(addDivision);
      message.success(`Division ${formData.name} created`);
    } else {
      message.error('Something went wrong ...');
    }
  }

  let defaultValues = {};

  if (division) {
    defaultValues = {
      name: division.name,
      organizationId: division.organizationId,
    };
  }

  return (
    <FormHooksProvider
      resolver={schemaResolver}
      opts={{
        shouldFocusError: true,
        defaultValues,
      }}
    >
      <MainForm
        division={selectedDivision}
        {...rest}
        onSubmit={_onSubmit}
        title={formTitle}
      />
    </FormHooksProvider>
  );
};
