import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import tw, { styled } from 'twin.macro';

const StyledWrapper = styled.div`
  ${tw`w-full relative text-center items-center justify-center`}
  .anticon {
    vertical-align: baseline;
  }
`;

export default function DualCheckedCell(props: any) {
  const { value } = props;

  return (
    <StyledWrapper>
      {value?.webChecked ? (
        <FontAwesomeIcon
          style={{
            fontSize: 16,
            color: value?.mobileChecked ? '#52c41a' : 'blue',
          }}
          icon={faCheck}
        />
      ) : null}
    </StyledWrapper>
  );
}
