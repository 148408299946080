import {
  useGetReportByOrganizationByReportIdLazyQuery,
  useGetReportsByOrganizationIdLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  actions,
  reducer,
  selectLoading,
  selectLoadingReporstByGroup,
  selectReports,
  selectReportsByGroup,
  sliceKey,
} from './slice';

export default function usePowerBIReports() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });
  const dispatch = useDispatch();
  const reports = useSelector(selectReports);
  const reportsByGroup = useSelector(selectReportsByGroup);
  const loadingPowerBIReport = useSelector(selectLoading);
  const loadingReportsByGroup = useSelector(selectLoadingReporstByGroup);

  const [getReportsByOrganizationId] = useGetReportsByOrganizationIdLazyQuery({
    onCompleted: result => {
      const nodes = result?.reports?.edges
        ?.map(dataItem => dataItem?.node)
        ?.filter(node => node?.id && node?.pbiReportId && node?.pbiGroupId);

      if (nodes?.length) {
        dispatch(
          actions.setPowerBIReports({
            list: nodes,
            loadingPowerBIReport: false,
          }),
        );
      } else {
        dispatch(
          actions.setPowerBIReports({
            list: {},
            loadingPowerBIReport: false,
          }),
        );
      }
    },
  });

  const [
    getReportByOrganizationByReportId,
  ] = useGetReportByOrganizationByReportIdLazyQuery({
    onCompleted: result => {
      if (result && result?.reportByGroupIdByReportId) {
        const nodes = result.reportByGroupIdByReportId;
        nodes &&
          dispatch(
            actions.setReportsByGroup({
              reportsByGroup: nodes,
              loadingReportsByGroup: false,
            }),
          );
      } else {
        dispatch(
          actions.setReportsByGroup({
            reportsByGroup: {},
            loadingReportsByGroup: false,
          }),
        );
      }
    },
  });

  const generatePowerBIReport = (organizationId: number) => {
    dispatch(
      actions.setPowerBIReports({
        list: {},
        loadingPowerBIReport: true,
      }),
    );
    getReportsByOrganizationId({
      variables: {
        organizationId,
      },
    });
  };

  const generateReportByOrganization = (reportId: string, groupId: string) => {
    dispatch(
      actions.setReportsByGroup({
        reportsByGroup: {},
        loadingReportsByGroup: true,
      }),
    );
    getReportByOrganizationByReportId({
      variables: {
        reportId,
        groupId,
      },
    });
  };

  return {
    loadingPowerBIReport,
    loadingReportsByGroup,
    reports,
    reportsByGroup,
    generatePowerBIReport,
    generateReportByOrganization,
  };
}
