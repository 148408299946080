import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LocationState {
  workspaceData: any;
  flagRows: any[];
  newItem: any;
  updatedItem: any;
  selectedItems: any[];
}
export const initialState: LocationState = {
  workspaceData: null,
  flagRows: null,
  newItem: null,
  updatedItem: {},
  selectedItems: null,
};

const storeSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setPlaceList: (state, action: PayloadAction<any>) => {
      const { workspaceData } = action.payload;
      state.workspaceData = workspaceData;
    },
    setNewPlace: (state, action: PayloadAction<any>) => {
      const { workspaceData, newItem, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.newItem = newItem;
      state.flagRows = flagRows;
    },
    setUpdatedPlace: (state, action: PayloadAction<any>) => {
      const { workspaceData, updatedItem, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.updatedItem = updatedItem;
      state.flagRows = flagRows;
    },
    setSelectedItems: (state, action: PayloadAction<any>) => {
      const { selectedItems } = action.payload;
      state.selectedItems = selectedItems;
    },
  },
});

export const selectWorkspaceData = state => state.locations.workspaceData;
export const selectFlagRows = state => state.locations.flagRows;
export const selectNewItem = state => state.locations.newItem;
export const selectSelectedItems = state => state.locations.selectedItems;

export const { actions, reducer, name: sliceKey } = storeSlice;
