import { AddAddressInput } from 'api/graphql/generated/serviceTypesAndHooks';
import Map from 'components/Map';
import { getNamespaceColor } from 'containers/Dispatchs/Booking/DetailsScreen/components/Places/helpers/namespace';
import React, { HTMLAttributes } from 'react';
import usePlacesStore from '../../../store';
import { config } from './config';

type Props = {
  longitude?: number;
  latitude?: number;
} & HTMLAttributes<HTMLDivElement>;

export default function MapView(props: Props) {
  const selectedPlace: AddAddressInput =
    usePlacesStore(state => state.selectedPlace) ?? null;
  const pins = [];
  let initialViewState = null;

  if (selectedPlace) {
    const { longitude, latitude } = selectedPlace;

    if (longitude && latitude) {
      pins.push({ longitude, latitude, color: getNamespaceColor('activity') });

      initialViewState = {
        latitude,
        longitude,
      };
    }
  }

  if (props.longitude && props.latitude) {
    initialViewState = {
      latitude: props.latitude,
      longitude: props.longitude,
    };
  }

  return (
    <div {...props}>
      <Map config={config} pins={pins} initialViewState={initialViewState} />
    </div>
  );
}
