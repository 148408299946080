import { GridOptions } from 'ag-grid-community';
import SelectorFloatingFilter from 'components/ReactGrid/filters/SelectorFloatingFilter';
import { CheckedCell } from 'components/ReactGrid/renderers';
import { ICustomCellRendererParams } from 'components/ReactGrid/renderers/types';
import {
  booleanSelectorFilterParams,
  numberFilterParams,
} from 'helpers/filterParams';

export const surchargesGridOptions = {
  headerHeight: 40,
  components: {
    CheckedCell,
    SelectorFloatingFilter,
  },
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressSideButton: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  },
  defaultColDef: {
    initialHide: false,
    enableRowGroup: false,
    editable: false,
    cellRenderer: 'CustomCell',
    floatingFilter: true,
    menuTabs: ['filterMenuTab'],
    filter: 'agTextColumnFilter',
    filterParams: { newRowsAction: 'keep', debounceMs: 1500 },
  },
  suppressScrollOnNewData: true,
  columnDefs: [
    {
      headerName: 'Surcharge ID',
      field: 'id',
      pinned: true,
      width: 50,
      filter: 'agNumberColumnFilter',
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 180,
      pinned: 'left',
    },
    {
      headerName: 'Code',
      field: 'code',
      width: 180,
      pinned: 'left',
    },
    {
      headerName: 'Category',
      field: 'category',
    },
    {
      colId: 'description',
      headerName: 'Description',
      field: 'description',
      width: 200,
    },
    {
      headerName: 'Cost',
      field: 'cost',
      filter: 'agNumberColumnFilter',
      cellRendererParams: {
        type: 'currency',
      } as ICustomCellRendererParams,
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Charge',
      field: 'charge',
      filter: 'agNumberColumnFilter',
      cellRendererParams: {
        type: 'currency',
      } as ICustomCellRendererParams,
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Cost GL Code',
      field: 'costGlcode',
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Charge GL Code',
      field: 'chargeGlcode',
    },
    {
      headerName: 'UOM',
      field: 'unitOfMeasure.name',
    },
    {
      headerName: 'ApplyFAF',
      field: 'applyFAF',
      width: 110,
      cellRenderer: 'CheckedCell',
      floatingFilterComponent: 'SelectorFloatingFilter',
      filterParams: booleanSelectorFilterParams,
    },
  ],
} as GridOptions;
