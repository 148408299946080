import { AddressInput } from 'api/graphql/generated/serviceTypesAndHooks';

export function getLocationAddress(place: Partial<AddressInput>) {
  const {
    address1,
    addressCodePlace,
    addressCodeRegion,
    addressCodeLocality,
    addressCodeCountry,
    postCode,
  } = place;

  // construct location
  return [
    address1,
    addressCodeLocality?.name,
    addressCodePlace?.name,
    addressCodeRegion?.name,
    postCode ?? '',
    addressCodeCountry?.name,
  ]
    .filter(item => item?.length > 0)
    .join(', ');
}
