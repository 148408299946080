import {
  AddAddressInput,
  MapLocationModel,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { create } from 'zustand';

export type TContact = {
  id?: number | string;
  [key: string]: any;
};

export interface IContact {
  updated: Array<TContact>;
  new: Array<TContact>;
  deleted: Array<{ id: number | string }>;
}

interface IState {
  selectedPlace:
    | (Partial<AddAddressInput> & { isSaving: boolean } & Partial<
          MapLocationModel
        >)
    | null;
  contact: IContact;
}

interface IActions {
  setSelectedPlace: (address: Partial<AddAddressInput>) => void;
  setIsSaving: (status: boolean) => void;
  reset: () => void;
}

const usePlacesStore = create<IState & IActions>(set => ({
  selectedPlace: null,
  contact: { new: [], updated: [], deleted: [] },
  setSelectedPlace: (address: any) => set({ selectedPlace: address }),
  setIsSaving: (status: boolean) =>
    set(state => ({
      selectedPlace: {
        ...state.selectedPlace,
        isSaving: status,
      },
    })),
  reset: () =>
    set({
      selectedPlace: null,
      contact: { new: [], updated: [], deleted: [] },
    }),
}));

export default usePlacesStore;
