import { ModalProps } from 'antd';
import React from 'react';
import { Header } from './components/Header';
import './style.css';
import { StyledModal } from './styled';
import { IDialog } from './types';

type Props = Readonly<{
  params: IDialog;
  onClose?: () => void;
}>;

// defualt but overwriteable initial `dialogProps`
const defaultModalProps: Partial<ModalProps> = {
  footer: null,
  closable: false,
};

export default function Dialog({ params, onClose }: Props) {
  return (
    <StyledModal {...params.dialogProps} {...defaultModalProps} centered open>
      <div className="overflow-hidden rounded-md h-full">
        {React.cloneElement(params?.content, {
          ...params.content.props,
          onClose,
        })}
      </div>
    </StyledModal>
  );
}

Dialog.Header = Header;
