// To Depreciate
export type TModalContent = {
  width: number;
  mode?: ModalMode;
  formConfig?: any;
  record?: any;
  module?: string;
  title?: string;
  type?: string;
  visible?: boolean;
  footer?: any;
};

export enum ModalMode {
  CREATE = 'Create',
  MODIFY = 'Modify',
}
