import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DivisionState {
  workspaceData: any;
  itemDetails: any;
  flagRows: any[];
  newItem: any;
  updatedItem: any;
  selectedItems: any[];
}
export const initialState: DivisionState = {
  workspaceData: null,
  itemDetails: null,
  flagRows: null,
  newItem: {},
  updatedItem: {},
  selectedItems: null,
};

const storeSlice = createSlice({
  name: 'division',
  initialState,
  reducers: {
    setWorkspaceData: (state, action: PayloadAction<any>) => {
      const { workspaceData } = action.payload;
      state.workspaceData = workspaceData;
    },
    setItemDetails: (state, action: PayloadAction<any>) => {
      const { itemDetails } = action.payload;
      state.itemDetails = itemDetails;
    },
    setNewDivision: (state, action: PayloadAction<any>) => {
      const { workspaceData, newItem, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.newItem = newItem;
      state.flagRows = flagRows;
    },
    setUpdatedDivision: (state, action: PayloadAction<any>) => {
      const { workspaceData, updatedItem, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.updatedItem = updatedItem;
      state.flagRows = flagRows;
    },
    setSelectedItems: (state, action: PayloadAction<any>) => {
      const { selectedItems } = action.payload;
      state.selectedItems = selectedItems;
    },
  },
});

export const selectWorkspaceData = state => state.division.workspaceData;
export const selectFlagRows = state => state.division.flagRows;
export const selectItemDetails = state => state.division.itemDetails;
export const selectSelectedItems = state => state.division.selectedItems;

export const { actions, reducer, name: sliceKey } = storeSlice;
