import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuProps } from 'antd';
import { getItemProps } from 'components/common/types';

import logoExpanded from 'assets/icos-global-logo-v3.png';
import logoCollapsed from 'assets/icos-logo-icon-orange.png';
import useAuthentication from 'context/security_authentication/hook';
import { MENU_ITEMS } from 'helpers/constants';
import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BookingShortcut from './BookingShortcut';
import { StyledSider } from './styles';

interface IProps {
  collapsed?: boolean;
}

type MenuItem = Required<MenuProps>['items'][number];

function Sidebar(props: Readonly<IProps>) {
  // ==========initial===========//
  const { collapsed } = props;
  const location = useLocation();
  const path = location.pathname;
  const [openKeys, setOpenKeys] = useState<any[]>([]);
  const { modulePermissions, currentAccount } = useAuthentication();
  const isAdmin = currentAccount?.userTypeId === 19;

  const bookingShortcutRef = useRef<any>(null);

  const history = useNavigate();
  // ====================== Styles ===========================//
  const titleStyle: React.CSSProperties = {
    fontSize: '14px',
  };
  const selectedItemStyled: React.CSSProperties = {
    borderLeftColor: '#ee591a',
    borderLeftWidth: '2px',
    borderLeftStyle: 'solid',
    borderRadius: '0px',
  };
  const subTitleStyle: React.CSSProperties = {
    fontSize: '14px',
  };

  const selectedSubMenuItemStyle: React.CSSProperties = {
    backgroundColor: 'inherit',
    color: '#ee591a',
  };

  // ====================== Handlers ===========================//
  const handleChangeOpenKey = keys => {
    setOpenKeys([keys.pop()]);
  };

  const handleCreateBooking = type => {
    bookingShortcutRef?.current?.createBooking(type);
  };

  const getItem = (item: getItemProps): MenuItem => {
    return item as MenuItem;
  };

  const handleLinkToURL = subMenuItem => {
    history(subMenuItem.url);
  };

  const handleOpenNewTab = url => {
    window.open(url, '_blank');
  };

  // =================== Render components ===========================//

  const subMenuItems = (menu: any): MenuItem[] => {
    return menu?.subMenu?.map(sub => {
      if (
        (sub.show && modulePermissions?.includes(sub.value.toLowerCase())) ||
        sub.skipPermission ||
        isAdmin
      ) {
        return getItem({
          label: sub.label,
          key: sub.value,
          style: path === sub.url ? selectedSubMenuItemStyle : subTitleStyle,
          onClick: () => {
            sub.isBooking
              ? handleCreateBooking(sub.value)
              : handleLinkToURL(sub);
          },
        });
      }
      return null;
    });
  };

  // Only show the first menu item when the sidebar is collapsed
  const navigationMenus = collapsed ? MENU_ITEMS : [...MENU_ITEMS].slice(1);

  const menuItems: MenuProps['items'] = navigationMenus?.map(menu => {
    const hasSubMenu = menu?.subMenu?.length > 0;
    let hasModulePermission = modulePermissions?.includes(
      menu.value.toLowerCase(),
    );

    const selectedMenuItem = path.split('/')[1];
    if (hasSubMenu) {
      const menuModules = menu.subMenu.map(item => item.value.toLowerCase());

      hasModulePermission = modulePermissions?.filter(modulePermission => {
        return menuModules.includes(modulePermission);
      });

      if (
        (menu.show || isAdmin) &&
        (hasModulePermission?.length > 0 || menu.skipPermission || isAdmin)
      ) {
        return getItem({
          label: menu.label,
          key: menu.value,
          style:
            menu.value === selectedMenuItem ? selectedItemStyled : titleStyle,
          icon: (
            <FontAwesomeIcon
              // @ts-ignore
              icon={menu.icon}
              style={{
                fontSize: '20px',
                fontWeight: 900,
                color: 'inherit',
              }}
            />
          ),
          onClick: null,
          children: subMenuItems(menu),
        });
      }
      return null;
    }

    if (menu.show && (hasModulePermission || menu.skipPermission || isAdmin)) {
      return getItem({
        label: menu.label,
        key: menu.value,
        style: titleStyle,
        icon: (
          <FontAwesomeIcon
            // @ts-ignore
            icon={menu.icon}
            style={{
              fontSize: '20px',
              fontWeight: 900,
              color: 'inherit',
            }}
          />
        ),
        onClick: () => {
          handleOpenNewTab(menu.url);
        },
      });
    }
    return null;
  });

  const renderSidebar = () => {
    if (modulePermissions || isAdmin) {
      return (
        <Menu
          mode="inline"
          inlineIndent={14}
          defaultSelectedKeys={[path]}
          openKeys={openKeys}
          // @ts-ignore
          theme="dark"
          onOpenChange={keys => handleChangeOpenKey(keys)}
          style={{
            borderRight: 0,
            overflowY: 'auto',
            height: 'calc(100vh - 180px)',
            overflowX: 'hidden',
          }}
          items={menuItems}
        />
      );
    }
    return null;
  };

  return (
    <StyledSider
      collapsedWidth={50}
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={280}
    >
      <div className="p-2 flex items-center py-8">
        <img
          className="mr-auto ml-auto"
          height={48}
          src={collapsed ? logoCollapsed : logoExpanded}
          alt="Company logo"
        />
      </div>
      <BookingShortcut ref={bookingShortcutRef} isHidden={collapsed} />
      {renderSidebar()}
    </StyledSider>
  );
}

export default Sidebar;
