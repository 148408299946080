import {
  useCreateAddressMutation,
  useCreateOrgLocationMutation,
  useDeleteOrgLocationMutation,
  useGetOrgLocationsLazyQuery,
  useModifyOrgLocationMutation,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';

import { ERROR_MESSAGE } from 'helpers/constants';
import {
  actions,
  reducer,
  selectFlagRows,
  selectNewItem,
  selectSelectedItems,
  selectWorkspaceData,
  sliceKey,
} from './slice';

export default function useLocation() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();

  const locationWorkspaceData = useSelector(selectWorkspaceData);
  const flagRows = useSelector(selectFlagRows);
  const newLocation = useSelector(selectNewItem);
  const selectedItems = useSelector(selectSelectedItems);

  const [
    getLocationQuery,
    { loading: pendingGetLocation },
  ] = useGetOrgLocationsLazyQuery({
    onCompleted: response => {
      let data;
      if (response?.organizationLocations?.nodes) {
        const nodes = response.organizationLocations.nodes.map(dataItem => {
          if (dataItem?.id) {
            return dataItem;
          }
          return undefined;
        });

        let newList = nodes;
        if (
          locationWorkspaceData?.nodes?.length > 0 &&
          response.organizationLocations.pageInfo.hasPreviousPage
        ) {
          newList = [...(locationWorkspaceData?.nodes ?? []), ...nodes];
        }
        data = {
          ...response?.organizationLocations,
          nodes: newList,
        };
      }
      dispatch(
        actions.setPlaceList({
          workspaceData: { ...data, loading: false, error: null },
        }),
      );
    },
    onError: () => {
      dispatch(
        actions.setPlaceList({
          workspaceData: {
            nodes: [],
            loading: false,
            error: ERROR_MESSAGE.LOADING_DATA_ERROR,
          },
        }),
      );
    },
  });

  const getLocationList = ({ filters, cursor, order }) => {
    getLocationQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const refreshWorkspaceData = () => {
    dispatch(
      actions.setPlaceList({
        workspaceData: null,
        loading: false,
      }),
    );
  };

  const [
    createOrgLocationMutation,
    { loading: pendingCreateOrgLocation },
  ] = useCreateOrgLocationMutation({
    onCompleted: response => {
      const newPlace = response?.addOrganizationLocation;
      if (newPlace) {
        setNewItem(newPlace);
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createOrgLocation = input => {
    return createOrgLocationMutation({
      variables: { input },
    });
  };

  const [
    createAddressMutation,
    { loading: pendingCreateAddress },
  ] = useCreateAddressMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createAddress = input => {
    return createAddressMutation({
      variables: { input },
    });
  };
  const [
    ModifyOrgLocationMutation,
    { loading: pendingModifyOrgLocation },
  ] = useModifyOrgLocationMutation({
    onCompleted: response => {
      const updatedPlace = response?.modifyOrganizationLocation;
      if (updatedPlace) setUpdatedItem(updatedPlace);

      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifyOrgLocation = input => {
    return ModifyOrgLocationMutation({
      variables: {
        input,
      },
    });
  };
  const [
    DeleteOrgLocationMutation,
    { loading: pendingDeleteOrgLocation },
  ] = useDeleteOrgLocationMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteOrgLocation = input => {
    return DeleteOrgLocationMutation({
      variables: {
        input,
      },
    });
  };

  const setNewItem = newItem => {
    const currentList = locationWorkspaceData?.nodes || [];
    const newList = [newItem, ...currentList];
    const data = {
      ...locationWorkspaceData,
      nodes: newList,
    };
    dispatch(
      actions.setNewPlace({
        newItem,
        workspaceData: data,
        flagRows: [newItem],
      }),
    );
  };

  const setUpdatedItem = updatedItem => {
    const newList = locationWorkspaceData?.nodes?.map(item => {
      if (item?.id === updatedItem?.id) {
        return { ...item, ...updatedItem };
      }
      return item;
    });
    const data = {
      ...locationWorkspaceData,
      nodes: newList,
    };
    dispatch(
      actions.setUpdatedPlace({
        updatedItem,
        workspaceData: data,
        flagRows: [updatedItem],
      }),
    );
  };

  const setSelectedItems = rows => {
    dispatch(
      actions.setSelectedItems({
        selectedItems: rows,
      }),
    );
  };

  return {
    refreshWorkspaceData,
    getLocationList,
    createOrgLocation,
    createAddress,
    modifyOrgLocation,
    deleteOrgLocation,
    setNewItem,
    setUpdatedItem,
    setSelectedItems,
    flagRows,
    newLocation,
    locationWorkspaceData,
    pendingCreateOrgLocation,
    pendingCreateAddress,
    pendingModifyOrgLocation,
    pendingDeleteOrgLocation,
    pendingGetLocation,
    selectedItems,
  };
}
