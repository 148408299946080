import { GridOptions } from 'ag-grid-community';
import { InputCell, NumericCell } from 'components/ReactGrid/editors';
import { DeleteRow } from 'components/ReactGrid/renderers';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';
import useBookingRate from 'context/financials_rate/hooks';

export default function useCustomerGridConfig() {
  const { deleteFafGroupNewInOrgOrgMapping } = useBookingRate();
  const customersGridConfig = {
    toolbar: {
      actions: [
        {
          id: 'create',
          label: 'Add',
          tooltip: 'Add new customer',
          skipPermission: true,
        },
        {
          id: 'delete',
          tooltip: 'Delete selected customer',
          skipPermission: true,
        },
      ],
    },
    gridId: 'customer',
    modelInputField: {
      add: 'addFileStorageInput',
      modify: 'modifyFileStorageInput',
    },
    gridClassName: 'grid-container',
    gridOptions: {
      rowSelection: 'multiple',
      suppressContextMenu: true,
      enablePivot: true,
      components: {
        CustomCell,
        DeleteRow,
        InputCell,
        NumericCell,
      },
      stopEditingWhenCellsLoseFocus: true,
      maintainColumnOrder: true,
      editType: 'fullRow',
      defaultColDef: {
        flex: 1,
        resizable: true,
        sortable: true,
        cellRenderer: 'CustomCell',
        gridId: 'customer',
      },
      columnDefs: [
        {
          colId: 'id',
          headerName: 'ID',
          field: 'id',
          pinned: 'left',
          width: 80,
          editable: false,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          showDisabledCheckboxes: true,
        },
        {
          headerName: 'Customer Id',
          field: 'childOrganizationId',
          width: 30,
        },
        {
          headerName: 'Name',
          field: 'displayName',
          width: 100,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
        },
        {
          headerName: 'Action',
          colId: 'delAction',
          cellRenderer: 'DeleteRow',
          cellClass: 'cellCenter',
          editable: false,
          filter: false,
          width: 80,
          pinned: 'right',
        },
      ],
    } as GridOptions,
    hooks: {
      delete: async ids => {
        const resultRate = await deleteFafGroupNewInOrgOrgMapping(ids);
        if (resultRate?.data?.deleteFafGroupNewInOrgOrgMapping) {
          return {
            data: resultRate?.data?.deleteFafGroupNewInOrgOrgMapping,
            message: 'Delete Customer successfully',
          };
        }
        return {
          message: 'Cannot delete Customer',
        };
      },
    },
  };
  return {
    customersGridConfig,
  };
}
