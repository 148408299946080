import { BookingStatusTypes } from 'components/config/types/booking';

function getLegEventStatusNewValue(statusId, contJobType) {
  if (statusId === BookingStatusTypes.ALL) {
    return BookingStatusTypes.PLN;
  }
  if (statusId === BookingStatusTypes.PLN) {
    return BookingStatusTypes.ALL;
  }

  if (
    statusId === BookingStatusTypes.UPK ||
    statusId === BookingStatusTypes.PAK
  ) {
    return BookingStatusTypes.RFP;
  }
  if (statusId === BookingStatusTypes.RFP) {
    return contJobType === 'EXP'
      ? BookingStatusTypes.PAK
      : BookingStatusTypes.UPK;
  }

  if (statusId === BookingStatusTypes.NEW) {
    return BookingStatusTypes.RDY;
  }
  if (statusId === BookingStatusTypes.RDY) {
    return BookingStatusTypes.HOLD;
  }
  if (statusId === BookingStatusTypes.HOLD) {
    return BookingStatusTypes.NEW;
  }
  return null;
}

export function getNewValueAndForceUpdate(colId, data, value) {
  let newValue;
  let forceUpdate = false;
  let newColId = colId;

  switch (colId) {
    case 'status': {
      newColId = 'bookingLegEventStatusId';
      const statusId = BookingStatusTypes[data?.status];
      const contJobType = data?.containerJobType;
      newValue = getLegEventStatusNewValue(statusId, contJobType);
      if (newValue) forceUpdate = true;
      break;
    }

    case 'shift': {
      newColId = 'shifts';
      forceUpdate = true;
      if (value === '' || !value) {
        newValue = 'DAY';
      } else {
        newValue = value === 'DAY' ? 'NIGHT' : '';
      }
      break;
    }
    default:
      break;
  }

  return { newValue, forceUpdate, newColId };
}
