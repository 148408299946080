import React from 'react';
import { DialogProvider } from 'components/Dialog/context/DialogProvider';
import MainScreen from './MainScreen';

export default function Index() {
  return (
    <DialogProvider>
      <MainScreen />
    </DialogProvider>
  );
}
