import { Avatar } from 'antd';
import { OrganizationType } from 'components/config/types/common';
import {
  selectActiveSideMenu,
  selectShowRightSideMenu,
} from 'context/rightSideMenu/selectors';
import {
  reducer,
  setActiveSideMenu,
  setShowRightSideMenu,
  sliceKey,
} from 'context/rightSideMenu/slice';
import useAuthentication from 'context/security_authentication/hook';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { useInjectReducer } from 'utils/redux-injectors';
import SideMenuItemGroup from './SideMenuItemGroup';
import {
  SideMenuItem,
  SideMenuItemsWrapper,
  StyledDrawer,
  Title,
} from './styles';

export default () => {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });
  const dispatch = useDispatch();
  const history = useNavigate();
  const { logOut } = useAuthentication();

  const ref = useRef(null);
  useClickAway(
    ref,
    event => {
      if (event.target['id'] !== 'username') {
        dispatch(setShowRightSideMenu(false));
      }
    },
    ['mouseup', 'touchstart'],
  );

  const { currentAccount } = useAuthentication();

  const showDrawer = useSelector(selectShowRightSideMenu);
  const activeSideMenu = useSelector(selectActiveSideMenu);

  const handleLogOut = () => {
    logOut();
    dispatch(setShowRightSideMenu(false));
    history('/');
  };

  const renderTop = () => {
    const isClient =
      currentAccount?.organizationOrganizationMapping?.organizationTypeId ===
      OrganizationType.Customer;
    const firstName = currentAccount?.contact?.firstName
      ? currentAccount?.contact?.firstName[0]
      : currentAccount?.userName[0] || 'N';
    const lastName = currentAccount?.contact?.lastName
      ? currentAccount?.contact?.lastName[0]
      : currentAccount?.userName[1] || 'A';
    const avatarName = firstName + lastName;
    return (
      <>
        <Title>User Account</Title>
        <hr />
        <SideMenuItem className="image-block">
          <ul>
            <li
              style={{
                verticalAlign: 'middle',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
              }}
            >
              <Avatar
                style={{
                  backgroundColor: 'gray',
                  verticalAlign: 'middle',
                  display: 'flex',
                  justifyContent: 'end',
                  flexDirection: 'row',
                }}
                size={100}
                gap={4}
              >
                {avatarName?.toUpperCase()}
              </Avatar>
            </li>
            <li>
              <h4>{currentAccount?.userName || 'NA'}</h4>
            </li>
            <li>{currentAccount?.contact?.email}</li>
            {isClient ? (
              <>
                <li className="flex justify-center">
                  <span
                    style={{
                      color: '#EE5919',
                      fontWeight: 'bold',
                      fontSize: 12,
                    }}
                  >
                    {
                      currentAccount?.organizationOrganizationMapping
                        ?.displayName
                    }
                  </span>
                  <span style={{ fontSize: 12 }}> - Customer</span>
                </li>
                <li className="flex justify-center">
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: 12,
                    }}
                  >
                    {
                      currentAccount?.organizationOrganizationMapping
                        ?.parentOrganization?.displayName
                    }
                  </span>

                  <span style={{ fontSize: 12 }}> - Carrier</span>
                </li>
              </>
            ) : (
              <li
                style={{
                  fontWeight: 'bold',
                  fontSize: 12,
                }}
              >
                {currentAccount?.organizationOrganizationMapping?.displayName}
              </li>
            )}
          </ul>
        </SideMenuItem>
        <hr />
      </>
    );
  };

  const renderDrawer = () => {
    return (
      <>
        <StyledDrawer
          width={250}
          placement="right"
          closable={false}
          onClose={() => {
            dispatch(setShowRightSideMenu(false));
          }}
          open={showDrawer}
        >
          <SideMenuItemsWrapper ref={ref}>
            {renderTop()}
            <SideMenuItemGroup>
              <SideMenuItem>Profile</SideMenuItem>
              <SideMenuItem>Preferences</SideMenuItem>
              <SideMenuItem>Settings</SideMenuItem>
              <SideMenuItem className="disabled">Storage</SideMenuItem>
            </SideMenuItemGroup>
            <hr />

            <div
              id="logout-container"
              className="absolute w-full bottom-0 text-center"
            >
              <hr />
              <SideMenuItemGroup>
                <SideMenuItem onClick={() => handleLogOut()}>
                  Sign Out
                </SideMenuItem>
              </SideMenuItemGroup>
            </div>
          </SideMenuItemsWrapper>
        </StyledDrawer>
        <StyledDrawer
          width={450}
          placement="right"
          closable={false}
          onClose={() => {
            dispatch(setActiveSideMenu(''));
          }}
          open={activeSideMenu === 'messages'}
        >
          <h2>Messages</h2>
        </StyledDrawer>
        <StyledDrawer
          width={450}
          placement="right"
          closable={false}
          onClose={() => {
            dispatch(setActiveSideMenu(''));
          }}
          open={activeSideMenu === 'notifications'}
        >
          <h2>Notifications</h2>
        </StyledDrawer>
        <StyledDrawer
          width={450}
          placement="right"
          closable={false}
          onClose={() => {
            dispatch(setActiveSideMenu(''));
          }}
          open={activeSideMenu === 'chat'}
        >
          <h2>Chat</h2>
        </StyledDrawer>
        <StyledDrawer
          width={450}
          placement="right"
          closable={false}
          onClose={() => {
            dispatch(setActiveSideMenu(''));
          }}
          open={activeSideMenu === 'tasks'}
        >
          <h2>Tasks</h2>
        </StyledDrawer>
        <StyledDrawer
          width={450}
          placement="right"
          closable={false}
          onClose={() => {
            dispatch(setActiveSideMenu(''));
          }}
          open={activeSideMenu === 'search'}
        >
          <h2>Search</h2>
        </StyledDrawer>
      </>
    );
  };

  return <>{renderDrawer()}</>;
};
