import { User } from 'api/graphql/generated/serviceTypesAndHooks';

export function getUserDivisions<T = { id: number }>(
  user: Partial<User>,
): Array<T> {
  if (!user) {
    console.warn('No user provided to getUserDivisions');
    return [];
  }

  let divisionMappings = [];

  const divisions = user?.userDivisionMappings;

  if (divisions) {
    divisionMappings = divisions.map((o: any) => {
      return {
        id: o.division.id,
      };
    });
  }

  return divisionMappings;
}
