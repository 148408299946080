import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { themes } from './themes';
import { ThemeKeyType, ThemeState } from './types';
import { isSystemDark, saveTheme } from './utils';

export const initialState: ThemeState = {
  // selected: getThemeFromStorage() || 'light',
  selected:  'light',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeTheme(state, action: PayloadAction<ThemeKeyType>) {
      saveTheme(action.payload);
      state.selected = action.payload;
    },
  },
});

export const selectTheme = createSelector(
  [(state: RootState) => state.theme || initialState],
  theme => {
    if (theme.selected === 'system') {
      return isSystemDark ? themes.dark : themes.default;
    }

    return themes[theme.selected];
  },
);

export const selectThemeKey = createSelector(
  [(state: RootState) => state.theme || initialState],
  theme => theme.selected,
);

export const { changeTheme } = themeSlice.actions;
export const { reducer } = themeSlice;
export const themeSliceKey = themeSlice.name;
