import { GridApi, RowNode } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import { AgGridColumn, AgGridReactProps } from 'ag-grid-react';
import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/shared/changeDetectionService';
import logo from 'assets/loading.gif';
import { ERROR_MESSAGE } from 'helpers/constants';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { GridWrapper } from './styles';
import { IAgGridOptions } from './types';

export interface GridProps extends AgGridReactProps, IAgGridOptions {
  sizeColumnsToFit?: boolean;
  fields?: string[];
  flagRows?: any[];
  selectedItems?: any[];
  style?: CSSProperties;
  children?: ReactNode | ReactNode[];
  hideOverlay?: boolean;
  processTotal?: boolean;
  columnsWithAggregation?: string[];
  loading?: boolean;
  error?: string;
  apiRef?: string;
  gridApi?: any;
  gridColumnApi?: any;
}

export default (props: GridProps) => {
  const [gridApi, setGridApi] = useState(null as GridApi);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  process.env.REACT_APP_AG_LICENSE_KEY &&
    LicenseManager.setLicenseKey(process.env.REACT_APP_AG_LICENSE_KEY);

  const handleOnGridReady = params => {
    const { api, columnApi } = params;
    setGridApi(api);
    setGridColumnApi(columnApi);

    api.setFloatingFiltersHeight(32);
    api.refreshCells();
    props.onGridReady?.(params);
    // global reference to gridApi
    if (props.apiRef) {
      // sets unique reference to gridApi
      window[props.apiRef] = api;
    } else {
      window.$gridApi = api;
    }

    window.$gridColumnApi = columnApi;
  };

  useEffect(() => {
    if (props.rowData && gridColumnApi && props.columnsWithAggregation) {
      const pinnedBottomData = generatePinnedBottomData(gridApi, gridColumnApi);
      gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }
  }, [props.processTotal, props.rowData, gridColumnApi]);

  const generatePinnedBottomData = (api, gridColumnApi) => {
    const gridColumns = gridColumnApi?.getAllGridColumns();

    const result = {};
    gridColumns.forEach(item => {
      if (item.visible) {
        result[item.colId] = null;
      }
    });

    props.columnsWithAggregation.forEach(element => {
      api?.forEachNodeAfterFilter((rowNode: RowNode) => {
        if (rowNode.data[element]) {
          result[element] += Number(rowNode.data[element]);
        } else {
          result[element] = 0;
        }
      });
    });

    return result;
  };

  const getChildren = () => {
    if (props.fields && props.rowData) {
      return props.fields.map(field => (
        <AgGridColumn key={field} field={`${field}`} editable />
      ));
    }
    return props.children;
  };

  const getRowStyle = params => {
    if (params.node.rowPinned === 'bottom') {
      return { background: '#c5c5c5', color: '#000' };
    }
    return {};
  };

  const rowClassRules = {
    'flag-row-yellow': function setFlagColor(params) {
      let isFlagged = false;

      const flaggedIds = props.flagRows?.map(item => item?.id);
      if (flaggedIds?.includes(params.data?.id) && params.rowIndex != null) {
        gridApi.ensureIndexVisible(params.rowIndex, 'middle');
        isFlagged = true;
      }
      return isFlagged;
    },
    'selected-row-blue': function setSelectedColor(params) {
      let isSelected = false;
      const selectedIds = props.selectedItems?.map(item => item?.id);
      if (selectedIds?.includes(params.data?.id)) {
        isSelected = true;
      }
      return isSelected;
    },
  };

  const setOverlay = () => {
    if (props.loading) {
      gridApi?.showLoadingOverlay();
    } else {
      !props.rowData?.length && !props.hideOverlay
        ? gridApi?.showNoRowsOverlay()
        : gridApi?.hideOverlay();
    }
  };

  useEffect(() => {
    setOverlay();
  }, [props.loading, gridApi]);

  return (
    <GridWrapper
      className="ag-theme-alpine"
      {...props}
      suppressScrollOnNewData
      rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
      overlayLoadingTemplate={`<img src=${logo} alt="loading-logo" width=10% height=10%/>`}
      overlayNoRowsTemplate={`<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">${
        props?.error || ERROR_MESSAGE.NO_DATA_FOUND
      }  </span>`}
      rowClassRules={rowClassRules}
      getRowStyle={getRowStyle}
      onGridReady={handleOnGridReady}
    >
      {getChildren()}
    </GridWrapper>
  );
};
