import { User } from 'api/graphql/generated/serviceTypesAndHooks';
import { getUserDivisions } from './serializers';

export function getFormOptions(user: User = null) {
  let defaultValues = {};
  let options = {};

  if (user) {
    defaultValues = {
      userName: user.userName,
      email: user.contact.email,
      firstName: user.contact.firstName,
      lastName: user.contact.lastName,
      position: user.position,
      phone: user.contact.phone,
      mobile: user.contact.mobile,
      orgDisplayName: user.organizationOrganizationMapping.displayName,
      divisionIds: getUserDivisions(user).map(o => o.id),
    };

    options = {
      opts: {
        defaultValues,
      },
    };
  }

  return options;
}
