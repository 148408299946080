import { CellClassParams } from 'ag-grid-community';
import DateFormats from 'components/config/date';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { serverTimeToLocalTime, utcToLocalDate } from 'helpers/date';
import { toPrecision } from 'helpers/formatter';
import { TCellTypes, TJustify } from './types';

dayjs.extend(utc);

export const getCellTextStyle = (type: TCellTypes, value: any) => {
  const cellClass = {
    currency: value < 0 ? 'text-red-900' : 'text-green-800',
    move: 'text-darkblue-100',
    default: '',
  };

  return cellClass[type] ?? cellClass.default;
};

function hasWord(str: string, strLike: string) {
  return String(str).toLowerCase().includes(strLike);
}

// https://www.ag-grid.com/javascript-data-grid/cell-styles/#cell-class-rules
export const getStatusCellClassRules = () => {
  // We're removing the cell backgrounds :-)
  // Leaving this here for reference
  const status = (str: string) => params => {
    return String(params.value).toLowerCase() === str;
  };

  // To add Status cell style, add the style in tailwind.config.js
  // under status property
  const cells = [
    'acc',
    'all',
    'canc',
    'del',
    'hold',
    'new',
    'ofl',
    'pak',
    'pln',
    'pod',
    'pup',
    'rdy',
    'rfp',
    'upk',
    'upl',
    'web',
    'ofd',
    'spl',
    'edi',
    'qte',
    'default',
    'invc',
    'dispt',
  ];

  function cellStyleRule(cells) {
    return cells.reduce((rules, cell) => {
      rules[`text-status-${cell}`] = status(cell);
      return rules;
    }, {});
  }

  return cellStyleRule(cells);
};

export const getNowAtCellClassRules = () => {
  return {
    'font-bold': params => params?.data?.nowAt === 'On Truck',
  };
};

export const getGoingToCellClassRules = () => {
  return {
    'text-darkblue-100': params =>
      params?.data?.status === 'PLN' ||
      params?.data?.status === 'ALL' ||
      params?.data?.status === 'PUP',
  };
};

export const getDateCellClassRules = () => {
  return {
    'bg-red-100 text-white': params => {
      if (params.value === null) return false;
      return dayjs().diff(dayjs(params.value), 'd') < 0;
    },
    'bg-orange-100 text-white': params =>
      dayjs().diff(dayjs(params.value), 'd') > 0,
    'bg-dodgerblue text-white': params =>
      dayjs().diff(dayjs(params.value), 'd') === 0,
  };
};

export const getNextMilestoneColorRules = () => {
  return {
    'bg-red-100 text-white': params => {
      const nextMilestone = params?.data?.nextMilestone;

      if (nextMilestone) {
        const nextMilestoneWithoutDiff = nextMilestone?.split('T');
        // eslint-disable-next-line prefer-destructuring
        const convertedDate = nextMilestoneWithoutDiff[0];
        const zoneDate = new Date(convertedDate).setHours(0, 0, 0, 0);
        const diff = Math.round(
          (zoneDate - new Date().setHours(0, 0, 0, 0)) / (1000 * 3600 * 24),
        );
        return diff < 0;
      }
      return null;
    },

    // blue color
    'bg-status-pup text-white': params => {
      const nextMilestone = params?.data?.nextMilestoneValue;

      if (nextMilestone?.datetimeRequired) {
        const nextMilestoneWithoutDiff = nextMilestone?.datetimeRequired?.split(
          'T',
        );
        // eslint-disable-next-line prefer-destructuring
        const convertedDate = nextMilestoneWithoutDiff[0];
        const zoneDate = new Date(convertedDate).setHours(0, 0, 0, 0);
        const diff = Math.round(
          (zoneDate - new Date().setHours(0, 0, 0, 0)) / (1000 * 3600 * 24),
        );
        return diff === 0;
      }
      return null;
    },
    // brown color
    'bg-status-upk text-white': params => {
      const nextMilestone = params?.data?.nextMilestoneValue;

      if (nextMilestone?.datetimeRequired) {
        const nextMilestoneWithoutDiff = nextMilestone?.datetimeRequired?.split(
          'T',
        );
        // eslint-disable-next-line prefer-destructuring
        const convertedDate = nextMilestoneWithoutDiff[0];
        const zoneDate = new Date(convertedDate).setHours(0, 0, 0, 0);
        const diff = Math.round(
          (zoneDate - new Date().setHours(0, 0, 0, 0)) / (1000 * 3600 * 24),
        );
        return diff >= 1 && diff <= 5;
      }
      return null;
    },
    // green color
    'bg-status-all': params => {
      const nextMilestone = params?.data?.nextMilestoneValue;

      if (nextMilestone?.datetimeRequired) {
        const nextMilestoneWithoutDiff = nextMilestone?.datetimeRequired?.split(
          'T',
        );
        // eslint-disable-next-line prefer-destructuring
        const convertedDate = nextMilestoneWithoutDiff[0];
        const zoneDate = new Date(convertedDate).setHours(0, 0, 0, 0);
        const diff = Math.round(
          (zoneDate - new Date().setHours(0, 0, 0, 0)) / (1000 * 3600 * 24),
        );
        return diff >= 6;
      }
      return null;
    },
  };
};

export const getDateColorRules = (greenNumber, orangeNumber) => {
  return {
    'bg-green-900 text-black': params => {
      const detention = params?.value;
      return (
        Number(
          detention?.substr(
            detention?.indexOf('(') + 1,
            detention?.indexOf(')') - detention?.indexOf('(') - 1,
          ),
        ) >= greenNumber
      );
    },
    'bg-orange-900 text-black': params => {
      const detention = params?.value;
      if (detention?.length === 0) return null;
      const diffDays = Number(
        detention?.substr(
          detention?.indexOf('(') + 1,
          detention?.indexOf(')') - detention?.indexOf('(') - 1,
        ),
      );
      return diffDays < greenNumber && diffDays >= orangeNumber;
    },
    'bg-red-100 text-white': params => {
      const detention = params?.value;
      return (
        Number(
          detention?.substr(
            detention?.indexOf('(') + 1,
            detention?.indexOf(')') - detention?.indexOf('(') - 1,
          ),
        ) < orangeNumber
      );
    },
  };
};

export const getCellClassRules = () => {
  return {
    'bg-yellow-200': params => params.rowIndex % 2 === 0,
    'bg-yellow-300': params => params.rowIndex % 2 !== 0,
  };
};

export const getCellClass = (type: TCellTypes) => (params: CellClassParams) => {
  const cellClass = {
    goods: () => {
      const isGoodsWithBgColor =
        hasWord(params.value, 'mt') ||
        hasWord(params.value, 'f') ||
        hasWord(params.value, 'exp') ||
        hasWord(params.value, 'wbt') ||
        hasWord(params.value, 'nze') ||
        hasWord(params.value, 'depot');

      return isGoodsWithBgColor ? 'bg-goods' : '';
    },
    move: () => {
      const isMoveWithBgColor =
        hasWord(params.value, 'wbt') || hasWord(params.value, 'depot');

      return isMoveWithBgColor ? 'bg-move' : '';
    },

    date: () => dayjs(params.value).format(DateFormats.asDisplayDate),

    default: () => ``,
  };

  return cellClass[cellClass[type] ? type : 'default']();
};

/**
 * @description formats Cell value
 * @param {TCellTypes} type
 * @param {*} value
 * @param {number} [precision]
 * @returns
 */
export function formatValue(type: TCellTypes, value: any, precision?: number) {
  const formats = {
    currency: () => (value ? `${toPrecision(value, precision || 2)}` : `$0.00`),
    weight: () => (value ? `${toPrecision(value, precision || 2)}` : `0.00`),
    cubes: () => (value ? `${toPrecision(value, precision || 3)}` : `0.000`),
    date: () => {
      return value
        ? serverTimeToLocalTime(value, DateFormats.asDisplayDate)
        : ``;
    },
    time: () => {
      return value ? serverTimeToLocalTime(value, 'HH:MM:ss') : ``;
    },
    datetime: () => {
      return value
        ? dayjs(value).utc().format(DateFormats.asDisplayDateTime)
        : ``;
    },
    datetimeUTCtoLocal: () => {
      return value ? utcToLocalDate(value, DateFormats.asDisplayDateTime) : ``;
    },
    percentage: () =>
      value ? `${toPrecision(value * 100, precision || 2)}%` : `0.00%`,
    default: () => value,
  };

  return formats[formats[type] ? type : 'default']();
}

export const composeCellStyles = (
  type: TCellTypes,
  value: any,
  justify: TJustify = 'left',
) => {
  return `${getAlignmentStyle(type, justify)} ${getCellTextStyle(type, value)}`;
};

function getCellAlignmentStyle(type: TJustify) {
  const cellClass = {
    right: () => `text-right`,
    center: () => `text-center`,
    left: () => `text-left`,
    default: () => `text-left`,
  };

  return cellClass[cellClass[type] ? type : 'default']();
}

function getAlignmentStyle(type: TCellTypes, justify: TJustify) {
  let alignmentStyle = getCellAlignmentStyle(justify);

  const typeStyles = {
    cubes: () => {
      alignmentStyle = getCellAlignmentStyle('right');
    },
    currency: () => {
      alignmentStyle = getCellAlignmentStyle('right');
    },
    weight: () => {
      alignmentStyle = getCellAlignmentStyle('right');
    },
    number: () => {
      alignmentStyle = getCellAlignmentStyle('right');
    },

    date: () => {
      alignmentStyle = getCellAlignmentStyle('center');
    },
    enum: () => {
      alignmentStyle = getCellAlignmentStyle('center');
    },

    datetime: () => {
      alignmentStyle = getCellAlignmentStyle('center');
    },
    status: () => {
      alignmentStyle = getCellAlignmentStyle('center');
    },
    shift: () => {
      alignmentStyle = getCellAlignmentStyle('center');
    },
    memo: () => {
      alignmentStyle = getCellAlignmentStyle('center');
    },
    time: () => {
      alignmentStyle = getCellAlignmentStyle('center');
    },

    default: () => {
      alignmentStyle = getCellAlignmentStyle('left');
    },
  };

  typeStyles[typeStyles[type] ? type : 'default']();

  return alignmentStyle;
}
