import { DialogProvider } from 'components/Dialog/context/DialogProvider';
import Lazy from 'components/Lazy';
import DateFormats from 'components/config/date';
import { BookingProvider } from 'context/BookingContext';
import usePredefinedData from 'context/predefined_data/hooks';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { IBookingForm } from './types/booking';

const ContainerBooking = React.lazy(() => import('./Container'));
const FreightBooking = React.lazy(() => import('./Freight'));

export default (props: IBookingForm) => {
  const { getRecentPlaces } = usePredefinedData();

  useEffect(() => {
    const dateTime = dayjs(
      dayjs().subtract(1, 'month').subtract(1, 'day'),
    ).format(DateFormats.asPayloadDate);
    getRecentPlaces(dateTime, 10);
  }, []);

  return (
    <BookingProvider>
      <DialogProvider>
        {
          {
            Container: <Lazy component={<ContainerBooking {...props} />} />,
            Freight: <Lazy component={<FreightBooking {...props} />} />,
          }[props.bookingType]
        }
      </DialogProvider>
    </BookingProvider>
  );
};
