import { Tabs, message } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import Dialog from 'components/Dialog';
import { IDialogHeader } from 'components/Dialog/types';
import { useFormHooks } from 'context/FormHooksContext';
import { getErrorMessage } from 'helpers/form-errors';
import WithThemeProvider from 'components/providers/WithThemeProvider';
import 'helpers/schema-validation/yup';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { User } from 'api/graphql/generated/serviceTypesAndHooks';
import TabBarExtraHeader from 'components/TabBar/TabBarExtraHeader';
import { ValidationError } from 'yup';
import FormUserActions from 'components/Form/FormUserActions';
import useUsersStore from '../../store';
import UserForm from './User';
import GroupForm from './Groups';
import PermissionForm from './Permissions';
import { conditionalSchema } from './schema-resolver';

type Props = {
  user?: User;
  onClose?: () => void;
  onSubmit: (data: any) => void;
} & IDialogHeader;

type TUserTabs = 'user' | 'groups' | 'permission';

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    margin: 0.6rem 0 0.6rem 1.5rem !important;
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

const Form = ({ user, onClose, onSubmit, title, subTitle }: Props) => {
  const [isAbandoning, setIsAbandoning] = React.useState(false);
  const [tabSelected, setTabSelected] = React.useState<TUserTabs>('user');
  const isSaving = useUsersStore(state => state.isSaving);

  const {
    handleSubmit,
    formState: { errors, isDirty },
  } = useFormHooks();

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (Object.keys(errors).length) {
      message.error(getErrorMessage(errors), 4);
    }
  }, [errors]);

  // eslint-disable-next-line no-underscore-dangle
  async function _onSave() {
    setIsAbandoning(false);

    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  }

  // eslint-disable-next-line no-underscore-dangle
  function _onCancel() {
    if (isAbandoning || !isDirty) {
      onClose();
      return;
    }

    setIsAbandoning(true);
  }

  function onTabSelect(tab: TUserTabs) {
    setTabSelected(tab);
  }

  async function _validateExtraSchema(formData) {
    if (user) {
      onSubmit(formData);
      return;
    }

    try {
      await conditionalSchema.validate(formData, { abortEarly: false });
      onSubmit(formData);
    } catch (e) {
      const err = e as ValidationError;
      const [{ message: errorMessage, path }] = err.inner;
      const errorField = formRef.current.querySelector(`[name="${path}"]`);

      message.error(errorMessage, 4);

      if (errorField instanceof HTMLInputElement) {
        errorField.focus();
      }
    }
  }

  return (
    <form
      ref={formRef}
      className="flex flex-col w-full h-full"
      onSubmit={handleSubmit(data => {
        _validateExtraSchema(data);
      })}
    >
      <div className="flex flex-col w-full h-full">
        <Dialog.Header
          onClose={onClose}
          title={getHeaderTitle(title, subTitle, onTabSelect, user)}
        />
        {
          {
            user: <UserForm user={user} />,
            groups: (
              <GroupForm organization={user?.organizationOrganizationMapping} />
            ),
            permission: <PermissionForm />,
          }[tabSelected]
        }
      </div>
      <FormUserActions
        onCancel={_onCancel}
        onSave={_onSave}
        isAbandoning={isAbandoning}
        isLoading={isSaving}
      />
    </form>
  );
};
export default function Index({ user = null, ...rest }: Props) {
  return <Form user={user} {...rest} />;
}

const getHeaderTitle = (title, subTitle, onTabSelect, user) => (
  <WithThemeProvider
    token={{ colorPrimaryActive: '#EE5919', colorPrimary: '#ee5919' }}
  >
    <div className="flex h-full w-full">
      {getHeaderTitleTabs(title, subTitle, onTabSelect, user)}
    </div>
  </WithThemeProvider>
);

function getHeaderTitleTabs(title, subTitle, onTabSelect, user) {
  return user ? (
    <StyledTabs
      defaultActiveKey="1"
      className="w-full"
      onTabClick={onTabSelect}
      tabBarExtraContent={{
        left: <TabBarExtraHeader title={title} subTitle={subTitle} />,
      }}
    >
      <TabPane tab="User Details" key="user" />
      <TabPane tab="Groups" key="groups" />
      <TabPane tab="Permission" key="permission" />
    </StyledTabs>
  ) : (
    <div className="flex items-center">
      <div className="relative border-solid border-r border-gray-200 pr-4">
        <h3 className="text-[18px] font-semibold text-primary">{title}</h3>
      </div>

      <div className="ml-4">User Details</div>
    </div>
  );
}
