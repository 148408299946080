import { GridOptions } from 'ag-grid-community';
import { NumericCell } from 'components/ReactGrid/editors';
import BooleanIntFloatingFilter from 'components/ReactGrid/filters/BooleanIntFloatingFilter';
import SelectorFloatingFilter from 'components/ReactGrid/filters/SelectorFloatingFilter';
import { CheckedCell, CustomCell } from 'components/ReactGrid/renderers';
import { ICustomCellRendererParams } from 'components/ReactGrid/renderers/types';
import { sortByNumber } from 'helpers/comparators';
import {
  dateFilterParams,
  numberFilterParams,
  selectorFilterParams,
  textFilterParams,
} from 'helpers/filterParams';

export const invoiceGridOptions = {
  groupSelectsChildren: false,
  rowSelection: 'multiple',
  multiSortKey: 'ctrl',
  singleClickEdit: true,
  enableRangeSelection: true,
  masterDetail: true,
  detailRowAutoHeight: true,
  detailCellRendererParams: {
    detailGridOptions: {
      headerHeight: 35,
      rowHeight: 24,
      components: {
        CustomCell,
        CheckedCell,
      },
      defaultColDef: {
        cellRenderer: 'CustomCell',
        resizable: true,
      },
      columnDefs: [
        { headerName: 'Id', field: 'id', width: 80 },
        { headerName: 'Description', field: 'description', width: 300 },
        {
          headerName: 'Quantity',
          field: 'quantity',
          width: 120,
          cellRendererParams: {
            type: 'number',
          } as ICustomCellRendererParams,
        },
        {
          headerName: 'Price',
          field: 'price',
          width: 120,
          cellRendererParams: {
            type: 'currency',
          } as ICustomCellRendererParams,
        },
        {
          headerName: 'FAF Percentage',
          field: 'fafpercentage',
          width: 140,
          cellRendererParams: {
            type: 'percentage',
          } as ICustomCellRendererParams,
        },
        {
          headerName: 'FAF Amount',
          field: 'fafamount',
          width: 140,
          cellRendererParams: {
            type: 'currency',
          } as ICustomCellRendererParams,
        },
        {
          headerName: 'Total Before Tax',
          field: 'totalBeforeTax',
          width: 140,
          cellRendererParams: {
            type: 'currency',
          } as ICustomCellRendererParams,
        },

        {
          headerName: 'Tax Type',
          field: 'taxType',
          width: 140,
          valueGetter: params => {
            const taxType = params?.data?.taxType;

            if (taxType === 1) {
              return 'GST';
            }
            if (taxType === 2) {
              return 'VAT';
            }
            return null;
          },
        },
        {
          headerName: 'Tax Amount',
          field: 'taxAmount',
          width: 140,
          cellRendererParams: {
            type: 'currency',
          } as ICustomCellRendererParams,
        },
        {
          headerName: 'Line Total',
          field: 'lineTotal',
          width: 140,
          cellRendererParams: {
            type: 'currency',
          } as ICustomCellRendererParams,
        },
      ],
    },
    getDetailRowData: params => {
      params.successCallback(params.data.invoiceLines);
    },
  },
  components: {
    SelectorFloatingFilter,
    BooleanIntFloatingFilter,
    NumericCell,
  },
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressSideButton: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ],
  },

  defaultColDef: {
    initialHide: false,
    enableRowGroup: true,
    enablePivot: true,
    cellRenderer: 'CustomCell',
    floatingFilter: true,
    menuTabs: ['filterMenuTab'],
    filter: 'agTextColumnFilter',
    filterParams: textFilterParams,
  },
  suppressScrollOnNewData: true,

  columnDefs: [
    {
      headerName: 'ID',
      field: 'id',
      width: 75,
      filter: 'agNumberColumnFilter',
      filterParams: numberFilterParams,
      cellRenderer: 'agGroupCellRenderer',
      tooltipValueGetter: () => {
        return 'Double click to expand cell ';
      },
    },
    {
      headerName: 'Subscriber Name',
      field: 'subscriberName',
      colId: 'subscriberName',
      width: 250,
    },
    {
      headerName: 'Customer Name',
      field: 'customerName',
      colId: 'customerName',
      width: 250,
    },

    {
      headerName: 'Status',
      field: 'status',
      colId: 'invoiceStatus',
      width: 150,
      cellRendererParams: {
        type: 'status',
      } as ICustomCellRendererParams,
      floatingFilterComponent: 'SelectorFloatingFilter',
      filterParams: selectorFilterParams,
      valueGetter: params => {
        const status = params?.data?.status;

        if (status === 1) {
          return 'INVC';
        }
        return 'CANC';
      },
    },
    {
      headerName: 'Created Date',
      field: 'createdOn',
      filter: 'agDateColumnFilter',
      cellClass: 'dateType',
      cellRendererParams: {
        type: 'datetimeUTCtoLocal',
      } as ICustomCellRendererParams,
      width: 180,
      filterParams: dateFilterParams,
    },
    {
      headerName: 'Sub Total',
      field: 'subTotal',
      filter: 'agNumberColumnFilter',
      width: 180,
      comparator: sortByNumber,
      cellRendererParams: {
        type: 'currency',
      } as ICustomCellRendererParams,
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Total Tax',
      field: 'totalTax',
      filter: 'agNumberColumnFilter',
      width: 180,
      comparator: sortByNumber,
      cellRendererParams: {
        type: 'currency',
      } as ICustomCellRendererParams,
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Total Amount',
      field: 'totalAmount',
      filter: 'agNumberColumnFilter',
      width: 180,
      comparator: sortByNumber,
      cellRendererParams: {
        type: 'currency',
      } as ICustomCellRendererParams,
      filterParams: numberFilterParams,
    },
  ],
} as GridOptions;
