import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  workspaceData: any;
  itemDetails: any;
  flagRows: any[];
  newItem: any;
  updatedItem: any;
  selectedItems: any[];
}
export const initialState: UserState = {
  workspaceData: null,
  itemDetails: null,
  flagRows: null,
  newItem: {},
  updatedItem: {},
  selectedItems: null,
};

const storeSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setWorkspaceData: (state, action: PayloadAction<any>) => {
      const { workspaceData } = action.payload;
      state.workspaceData = workspaceData;
    },
    setUserDetails: (state, action: PayloadAction<any>) => {
      const { itemDetails } = action.payload;
      state.itemDetails = itemDetails;
    },
    setNewUser: (state, action: PayloadAction<any>) => {
      const { workspaceData, newItem, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.newItem = newItem;
      state.flagRows = flagRows;
    },
    setUpdatedUser: (state, action: PayloadAction<any>) => {
      const { workspaceData, updatedItem, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.updatedItem = updatedItem;
      state.flagRows = flagRows;
    },
    setSelectedItems: (state, action: PayloadAction<any>) => {
      const { selectedItems } = action.payload;
      state.selectedItems = selectedItems;
    },
  },
});

export const selectWorkspaceData = state => state.users.workspaceData;
export const selectFlagRows = state => state.users.flagRows;
export const selectItemDetails = state => state.users.itemDetails;
export const selectSelectedItems = state => state.users.selectedItems;

export const { actions, reducer, name: sliceKey } = storeSlice;
