import { GridReadyEvent } from 'ag-grid-community';
import { message } from 'antd';
import TopWidgets from 'components/Layout/TopWidgets';
import AGGrid from 'components/ReactGrid';
import {
  actions,
  selectAddressFrom,
  selectAddressTo,
  selectRateLines,
  selectSelectedRate,
} from 'context/financials_rate/slice';
import { generateId } from 'helpers/generator';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';

export interface Props {
  gridConfig: any;
  recordValue?: any;
  label?: string;
  style?: {};
  gridHeight?: any;
  onModelInputChange?: (
    modelInputField: string,
    list: any[],
    newField?: {},
    data?: {},
  ) => void;
  onHandleSelectionChanged?: any;
  onSelectedMenuItem: { type: string; id: number };
  onCreateNew?: () => void;
}

const StyledFormGroup = styled.div`
  h4 {
    display: block;
    width: 100%;
  }

  .ant-btn-text {
    background-color: ${p => p.theme.primaryButtonColor};
    color: white;
  }

  .grid-container {
    min-height: ${props => props?.style?.minHeight};
    ${tw`relative`}
    @media screen and (max-width: 1920px) {
      height: calc(100vh - 730px);
    }
    @media screen and (max-width: 1536px) {
      height: calc(100vh - 618px);
    }
    @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      height: calc(100vh - 770px);
    }

    div[class*='ant-spin'] {
      height: inherit;
    }
    .ag-row .ag-cell {
      display: flex;
      align-items: center;
    }
  }

  .small-grid-container {
    ${tw`relative`}
    @media screen and (max-width: 1920px) {
      height: calc(100vh - 860px);
    }
    @media screen and (max-width: 1536px) {
      height: calc(100vh - 850px);
    }
    @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      height: calc(100vh - 750px);
    }

    div[class*='ant-spin'] {
      height: inherit;
    }
    .ag-row .ag-cell {
      display: flex;
      align-items: center;
    }
  }
`;

export default function RateGrid(props: Readonly<Props>) {
  const {
    recordValue,
    gridConfig,
    onHandleSelectionChanged,
    onSelectedMenuItem,
  } = props;
  const [gridApi, setGridApi] = useState(null);
  const [dataItems, setDataItems] = useState([]);
  const selectedRate = useSelector(selectSelectedRate);
  const editedAdressFrom = useSelector(selectAddressFrom);
  const editedAddressTo = useSelector(selectAddressTo);
  const currentRateLines = useSelector(selectRateLines);

  const dispatch = useDispatch();

  // =================== Handlers ===========================//

  const handleSetItems = list => {
    if (list?.length) {
      const newList = [...list];
      const newMapList = newList?.map(item => {
        const tempId = generateId(false, 6);
        return { tempId, ...item };
      });
      setDataItems(newMapList);
    } else {
      setDataItems([]);
    }
  };

  const onGridReady = ({ api }: GridReadyEvent) => {
    api.setHeaderHeight(36);
    setGridApi(api);
  };

  const handleAddNewItem = () => {
    if (gridConfig?.gridId === 'rateLine' && selectedRate?.id === undefined) {
      return;
    }
    if (props.onCreateNew) {
      props.onCreateNew();
    }
  };

  const handleOnRowValueChanged = async payload => {
    const { api, data } = payload;

    const newData = {
      ...data,
      editedAdressFrom,
      editedAddressTo,
      currentRateLines,
      selectedRate,
      selectedMenu: onSelectedMenuItem,
    };

    const result = await gridConfig?.hooks?.update(newData);
    if (result?.data) {
      // remove the item from aggrid
      const nodes = api?.getRenderedNodes();
      if (nodes?.length) {
        nodes[0]?.setSelected(true); // selects the first row in the rendered view
      }
      message.success(result.message);
    } else {
      message.error(result.message);
    }
  };

  const handleDeleteRecord = async param => {
    const { api, data } = param;
    const newData = {
      deleteRateLines: [data?.id],
      currentRateLines,
      selectedRate,
      selectedMenu: onSelectedMenuItem,
    };
    let result;

    if (gridConfig?.gridId === 'rate') {
      result = await gridConfig?.hooks?.delete([data?.id]);
      if (result?.data) {
        api.applyTransaction({ remove: [data] });
        dispatch(
          actions.setSelectedRate({
            selectedRow: null,
          }),
        );

        dispatch(
          actions.setRateLines({
            nodes: null,
            loading: false,
          }),
        );
        message.success(result.message);
      } else {
        message.error(result.message);
      }
    } else if (gridConfig?.gridId === 'rateLine') {
      if (currentRateLines?.nodes?.length < 2) {
        message.error('Rate should have at least one rate line');
        return;
      }
      result = await gridConfig?.hooks?.delete(newData);
      if (result?.data) {
        message.success(result.message);
      } else {
        message.error(result.message);
      }
    }
  };

  const handleMultipleDelete = async () => {
    const isRateLine = gridConfig?.gridId === 'rateLine';
    const selectedRowIds = gridApi?.getSelectedRows()?.map(el => el?.id);
    const newData = {
      deleteRateLines: selectedRowIds,
      currentRateLines,
      selectedRate,
      selectedMenu: onSelectedMenuItem,
    };

    const shouldValidateRateLine =
      isRateLine &&
      currentRateLines?.nodes?.length - selectedRowIds?.length < 1;

    if (shouldValidateRateLine) {
      message.error('Rate should have at least one rate line');
      return;
    }

    const result = await gridConfig?.hooks?.delete(newData);
    if (result?.data) {
      message.success(result.message);
    } else {
      message.error(result.message);
    }
  };

  const handleSelectionChanged = e => {
    const record = e?.api?.getSelectedNodes()[0];
    const selectedRow = { gridId: record?.gridId, ...record?.data };
    selectedRow.gridId = gridConfig?.gridId;

    onHandleSelectionChanged(selectedRow);
  };

  // =================== UseEffects ===========================//

  useEffect(() => {
    handleSetItems(recordValue);
  }, [recordValue]);

  // =================== Render components ===========================//
  return (
    <StyledFormGroup style={gridConfig?.style}>
      <TopWidgets
        toolbar={gridConfig?.toolbar}
        onCreateNew={handleAddNewItem}
        onMultipleDelete={handleMultipleDelete}
        className="grid-toolbar-details"
      />
      <h4>{props.label}</h4>
      <div
        className={gridConfig?.gridClassName}
        style={{
          height: `${props?.gridHeight}`,
        }}
      >
        <AGGrid
          {...gridConfig.gridOptions}
          context={{
            handleDeleteRecord,
          }}
          gridApi={gridApi}
          onGridReady={onGridReady}
          onRowValueChanged={handleOnRowValueChanged}
          rowData={dataItems}
          hideOverlay
          onCellClicked={handleSelectionChanged}
        />
      </div>
    </StyledFormGroup>
  );
}
