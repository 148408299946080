import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetContainerVisitResponse } from 'api/graphql/generated/serviceTypesAndHooks';

type SubModalContent = {
  name: string;
  visible: boolean;
};

export interface BookingState {
  workspaceData: any;
  itemDetails: any;
  recordTemplate: any;
  selectedItems: any[];

  flagRows: any[];
  newItems: any[];
  updatedItems: any[];
  containerInfo: [GetContainerVisitResponse];

  subModals: {
    [key: string]: SubModalContent;
  };
  watchNewBookingPlace: {
    isWatching: boolean;
    newPlace: any;
    placeOrder: number;
  };
}
export const initialState: BookingState = {
  workspaceData: null,
  itemDetails: null,
  recordTemplate: {},
  selectedItems: null,
  flagRows: null,
  newItems: [],
  updatedItems: [],
  containerInfo: null as [GetContainerVisitResponse],
  subModals: {},
  watchNewBookingPlace: {
    isWatching: false,
    newPlace: null,
    placeOrder: null,
  },
};

const storeSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    setWorkspaceData: (state, action: PayloadAction<any>) => {
      const { workspaceData } = action.payload;
      state.workspaceData = workspaceData;
    },
    setBookingDetails: (state, action: PayloadAction<any>) => {
      const { itemDetails } = action.payload;
      state.itemDetails = itemDetails;
    },
    setContainerInfo: (state, action: PayloadAction<any>) => {
      const { containerInfo } = action.payload;
      state.containerInfo = containerInfo;
    },

    setSubModal: (state, action: PayloadAction<SubModalContent>) => {
      const { name } = action.payload;
      state.subModals[name] = action.payload;
    },
    setSelectedItems: (state, action: PayloadAction<any>) => {
      const { selectedItems } = action.payload;
      state.selectedItems = selectedItems;
    },
    setNewBookings: (state, action: PayloadAction<any>) => {
      const { workspaceData, newItems, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.newItems = newItems;
      state.flagRows = flagRows;
    },
    setFlagRows: (state, action: PayloadAction<any>) => {
      const { flagRows } = action.payload;
      state.flagRows = flagRows;
    },
    setUpdatedBooking: (state, action: PayloadAction<any>) => {
      const { workspaceData, updatedItems, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.updatedItems = updatedItems;
      state.flagRows = flagRows;
    },
    setOnlyUpdateBookingItems: (state, action: PayloadAction<any>) => {
      const { updatedItems } = action.payload;
      state.updatedItems = updatedItems;
    },
    setUpdatedBookingBySignalR: (state, action: PayloadAction<any>) => {
      const { workspaceData, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.flagRows = flagRows;
    },
    setNewBookingBySignalR: (state, action: PayloadAction<any>) => {
      const { workspaceData, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.flagRows = flagRows;
    },
    setWatchNewBookingPlace: (state, action: PayloadAction<any>) => {
      const { isWatching, newPlace, placeOrder } = action.payload;
      state.watchNewBookingPlace = {
        isWatching,
        newPlace,
        placeOrder,
      };
    },
  },
});

export const selectWorkspaceData = state => state.bookings.workspaceData;
export const selectBookingDetails = state => state.bookings.itemDetails;
export const selectSelectedItems = state => state.bookings.selectedItems;
export const selectFlagRows = state => state.bookings.flagRows;
export const selectContainerInfo = state => state.bookings.containerInfo;
export const selectSubModals = state => state.bookings.subModals;
export const selectWatchNewBookingPlace = state =>
  state.bookings.watchNewBookingPlace;
export const selectUpdatedItems = state => state.bookings.updatedItems;
export const selectNewItems = state => state.bookings.newItems;

export const { actions, reducer, name: sliceKey } = storeSlice;
