import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICellRendererParams } from 'ag-grid-community';
import { Button } from 'antd';
import React from 'react';
import tw, { styled } from 'twin.macro';

export interface Props {
  disabled?: boolean;
  context?: any;
}

const StyledWrapper = styled.div`
  ${tw`flex w-full relative text-center items-center justify-center`}
  .anticon {
    vertical-align: baseline;
  }
`;

export default (props: Props & ICellRendererParams) => {
  return (
    <StyledWrapper>
      {!props.node.rowPinned ? (
        <Button
          size="small"
          disabled={props.disabled}
          className={`flex justify-center ${
            props.disabled ? 'opacity-20' : 'opacity-100'
          }`}
          onClick={() => props.context.handleDownloadRecord(props)}
          icon={
            <FontAwesomeIcon icon={faDownload} style={{ cursor: 'pointer' }} />
          }
        />
      ) : (
        <span />
      )}
    </StyledWrapper>
  );
};
