import { Empty, Transfer, message } from 'antd';
import {
  OrganizationOrganizationMapping,
  useGetGroupsLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import Spinner from 'components/Loaders/Spinner';
import useAuthentication from 'context/security_authentication/hook';
import useGroups from 'context/security_groups/hooks';
import useUsers from 'context/security_users/hooks';
import { isAllow } from 'helpers/checkPermission';
import React, { useEffect, useState } from 'react';
import { styled } from 'twin.macro';

const StyledTransfer = styled(Transfer)`
  .ant-transfer-operation > button {
    display: grid !important;
    place-items: center !important;
  }
`;

type Props = Readonly<{
  organization?: OrganizationOrganizationMapping | null;
}>;

export default function Index({ organization }: Props) {
  const [groupList, setGroupList] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const { userPermissions, currentAccount } = useAuthentication();

  const {
    groupDetails,
    createUserGroupMapping,
    deleteUserGroupMapping,
  } = useGroups();

  const { userDetails } = useUsers();

  const [assignedGroups, setAssignedGroups] = useState(
    userDetails?.userGroupMappings?.nodes,
  );

  const [getGroupList, { loading }] = useGetGroupsLazyQuery({
    onCompleted: response => {
      if (response?.groups?.nodes) {
        const data = response?.groups?.nodes?.map(group => {
          const {
            userGroupMappings: { nodes: groupMappings },
          } = group;

          const groupUsers = groupMappings?.filter?.(
            groupMapping => groupMapping?.groupId === group?.id,
          );

          if (groupUsers?.length) {
            setAssignedUsers(() => [
              ...assignedUsers,
              ...groupUsers.map(item => item.userId),
            ]);
          }

          return {
            key: group?.id,
            title: group?.name,
            description: group?.description,
          };
        });

        setGroupList(() => data);
      }
      return undefined;
    },
  });

  useEffect(() => {
    getGroupList({
      variables: {
        filters: {
          ownerId: { eq: organization.parentOrganizationId ?? 0 },
          isDeleted: { neq: true },
        },
        cursor: null,
        order: null,
      },
    });
  }, [currentAccount]);

  const handleSearch = async (direction, value) => {
    if (direction === 'left') {
      getGroupList({
        variables: {
          filters: {
            ownerId: { eq: organization.parentOrganizationId ?? 0 },
            isDeleted: { neq: true },
            name: { contains: value },
          },
          cursor: null,
          order: null,
        },
      });
    }
  };

  const handleChange = (newTargetKeys, direction, moveKeys) => {
    if (direction === 'right') {
      createUserGroupMapping({
        userGroupId: [groupDetails?.id],
        userId: moveKeys,
      })
        .then(res => {
          if (res.data.addUserGroupMapping) {
            setAssignedGroups([
              ...assignedGroups,
              ...res.data.addUserGroupMapping,
            ]);
            setAssignedUsers(newTargetKeys);
            message.success('User(s) assigned to Group');
          } else {
            message.error('Cannot assign user(s) to Group');
          }
        })
        .catch(() => {
          message.error('Cannot assign user(s) to Group');
        });
    } else {
      const deleteIdList = moveKeys?.map(item => {
        return assignedGroups?.find(el => el.userId === item).id;
      });

      deleteUserGroupMapping(deleteIdList)
        .then(res => {
          if (res.data.deleteUserGroupMapping) {
            setAssignedUsers(newTargetKeys);
            message.success('User removed from the Group');
          } else {
            message.error('Cannot remove user(s) to Group');
          }
        })
        .catch(() => {
          message.error('Cannot remove user(s) to Group');
        });
    }
  };

  return (
    <div className="flex justify-center px-4 ">
      {(() => {
        if (loading && !groupList?.length)
          return (
            <span className="flex p-4">
              <Spinner />
            </span>
          );

        if (!isAllow(userPermissions, 'update', 'user'))
          return <Empty description={'No Permission'} />;

        return (
          <StyledTransfer
            className="h-[426px] my-2"
            dataSource={groupList}
            listStyle={{
              width: '340px',
              height: '100%',
            }}
            titles={['Groups', 'Assigned Group']}
            operations={['', '']}
            targetKeys={assignedUsers}
            onChange={handleChange}
            render={item => ({
              value: item.key,
              label: (
                <p className="text-gray-600">
                  {item.title}{' '}
                  <span className="text-gray-400">({item.description})</span>{' '}
                </p>
              ),
            })}
            onSearch={handleSearch}
            pagination
            showSearch
          />
        );
      })()}
    </div>
  );
}
