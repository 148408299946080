import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ReportsState {
  list: {}[];
  reportsByGroup: {};
  loading: boolean;
  loadingReportsByGroup: boolean;
}
export const initialState: ReportsState = {
  list: [],
  reportsByGroup: {},
  loading: true,
  loadingReportsByGroup: true,
};

const storeSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setPowerBIReports: (state, action: PayloadAction<any>) => {
      const { list, loadingPowerBIReport } = action.payload;
      state.list = list;
      state.loading = loadingPowerBIReport;
    },
    setReportsByGroup: (state, action: PayloadAction<any>) => {
      const { reportsByGroup, loadingReportsByGroup } = action.payload;
      state.reportsByGroup = reportsByGroup;
      state.loadingReportsByGroup = loadingReportsByGroup;
    },
  },
});
export const selectReports = state => state.reports.list;
export const selectReportsByGroup = state => state.reports.reportsByGroup;
export const selectLoading = state => state.reports.loading;
export const selectLoadingReporstByGroup = state =>
  state.reports.loadingReportsByGroup;
export const { actions, reducer, name: sliceKey } = storeSlice;
