import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker, Select } from 'antd';
import DateFormats, { defaultBookingDatePicker } from 'components/config/date';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import * as React from 'react';
import { styled } from 'twin.macro';

const { RangePicker } = DatePicker;

const StyledRangePicker = styled(RangePicker)`
  .ant-picker-input {
    max-width: 96px;
    font-weight: 400;
  }
`;

// https://github.com/ant-design/ant-design/issues/26190#issuecomment-703673400
dayjs.extend(weekday);
dayjs.extend(localeData);

type Props = {
  onChange?: (range: any) => void;
  config?: any;
};

const BookedDateRangePicker = React.forwardRef((props: Props, ref) => {
  const [defaultDate, setDefaultDate] = React.useState(() => getDefaultDate());
  const [dateFilter, setDateFilter] = React.useState(
    () => props.config[0]?.defaultValue,
  );

  const selectedDateRef = React.useRef({
    gte: getDefaultDate()[0].format(DateFormats.asPayloadDate),
    lte: getDefaultDate()[1].format(DateFormats.asPayloadDate),
  });

  const setFilter = React.useCallback(filter => {
    setDateFilter(filter);
  }, []);

  function getDefaultDate() {
    return [defaultBookingDatePicker.from, defaultBookingDatePicker.to];
  }

  React.useImperativeHandle(ref, () => ({
    getSelectedRange: () => selectedDateRef.current,
    getDateFilterType: () => {
      return dateFilter;
    },
    reset: () => {
      setDefaultDate(getDefaultDate());
      setFilter(props.config[0]?.defaultValue);
    },
  }));

  return (
    <div className="flex items-center">
      {props.config[0] && (
        <Select
          {...props.config[0]}
          value={dateFilter}
          onChange={(value: any) => setFilter(value)}
          className="text-xs text-primary"
          font
        />
      )}
      <StyledRangePicker
        format={DateFormats.asDisplayDate}
        className="text-xs text-primary border-gray-50 "
        onChange={(dates, _dateStrings) => {
          const dRange = {
            gte: dates[0].format(DateFormats.asPayloadDate),
            lte: dates[1].format(DateFormats.asPayloadDate),
          };
          selectedDateRef.current = dRange;
          props.onChange?.(dRange);
          // reading the default value from config doesn't seems to make
          // sense if we know the date range is always from 1 month ago
          setDefaultDate([
            dayjs(dates[0].format(DateFormats.asPayloadDate)),
            dayjs(dates[1].format(DateFormats.asPayloadDate)),
          ]);
        }}
        // @ts-ignore
        value={defaultDate}
        defaultValue={[
          defaultBookingDatePicker.from,
          defaultBookingDatePicker.to,
        ]}
        allowClear={false}
        suffixIcon={
          <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: '14px' }} />
        }
      />
    </div>
  );
});

export default BookedDateRangePicker;
