import { message } from 'antd';
import { IDialogTrigger } from 'components/Dialog/types';
import { FormHooksProvider } from 'context/FormHooksContext';
import useBookingRate from 'context/financials_rate/hooks';
import React from 'react';
import useFafGroupNewStore from '../store';
import MainForm from './MainForm';
import schemaResolver from './schema-resolver';

interface IProps {
  afterValidation?: (vehicle?: any) => void;
}

export default ({ afterValidation, ...rest }: IDialogTrigger & IProps) => {
  const formTitle = 'Add new customer(s)';
  const [setIsSaving, isSaving] = useFafGroupNewStore(state => [
    state.setIsSaving,
    state.isSaving,
  ]);

  const {
    selectedFafGroupNew,
    addFafGroupNewOrgOrgMappings,
  } = useBookingRate();

  async function _onSubmit(formData: any) {
    setIsSaving(true);
    onCreate(formData);
  }

  async function onCreate(formData: any) {
    const payload = {
      fafGroupNewId: selectedFafGroupNew?.id,
      orgOrgMappingIds: formData.fafGroupNewOrgOrgIds,
    };

    const result = await addFafGroupNewOrgOrgMappings(payload);

    const {
      data: { addFafGroupNewOrgOrgMappings: orgOrgMapping },
    } = result;

    if (orgOrgMapping?.organizationOrganizationMapping) {
      afterValidation?.(orgOrgMapping?.organizationOrganizationMapping);
      message.success(`Customer(s) added`);
    } else {
      message.error('Cannot add Customer(s)');
    }
  }

  return (
    <FormHooksProvider resolver={schemaResolver}>
      <MainForm
        {...rest}
        title={formTitle}
        onSubmit={_onSubmit}
        isSaving={isSaving}
      />
    </FormHooksProvider>
  );
};
