import Select, { DefaultOptionType } from 'antd/es/select';
import { useFormHooks } from 'context/FormHooksContext';
import React, { useEffect } from 'react';
import WithFormHooksController from './WithFormHooksController';

export type TFormControlledSelect = Readonly<{
  name: string;
  optionFilterProp?: string;
  setDefaultOption?: boolean;
  options?: DefaultOptionType[];
  defaultValue?: any;
  size?: 'small' | 'middle' | 'large';
  mode?: 'multiple' | 'tags';
  disabled?: boolean;
  onSelect?: (value: any) => void;
}>;

export default function FormControlledSelect({
  name,
  optionFilterProp,
  setDefaultOption = true,
  options,
  defaultValue,
  size = 'middle',
  mode,
  disabled,
  onSelect,
}: TFormControlledSelect) {
  const { control, setValue, getValues } = useFormHooks();

  useEffect(() => {
    if (setDefaultOption) {
      let defaultOption = getValues(name) ?? Object.values(options)[0]?.value;
      if (defaultValue) {
        defaultOption = defaultValue;
      }
      setValue(name, defaultOption);
    }
  }, []);

  return (
    <WithFormHooksController
      control={control}
      name={name}
      component={
        <Select
          optionFilterProp={optionFilterProp}
          mode={mode}
          size={size}
          defaultValue={defaultValue}
          options={options}
          onSelect={onSelect}
          disabled={disabled}
        />
      }
    />
  );
}
