const defaultTheme = {
  primary: '#ee591a',
  secondary: '#000',
  textColor: '#fff',
  linkColor: '#fff',
  headerBgColor: '#fff',
  headerModalBgColor: '#ee591a',
  sideMenuBgColor: '#fff',
  menuItemHoverBGColor: '#ff9d3f',
  menuItemHoverTextColor: 'black',
  menuDivider: '#ECEDF7',
  topBarActiveBorder: '#ECEDF7',
  primaryButtonColor: '#ee591a',
  primaryButtonTextColor: '#40464F',
  
  secondaryButtonColor: '#000',
  primaryButtonHoverColor: '#ff9d3f',
  secondaryButtonHoverColor: '#5a5a5a',
  activeBorderColor: '#ee591a',
  tooltipColor: '#ee591a',
  dispatchButtonColor: '#ee591a',
  toolbarBackgroundColor: '#000',

  submitButtonColor: '#ee591a',
  submitButtonTextColor: '#fff',
  collapsePanelBackgroundColor: '#fff',
  collapseBackgroundColor: '#f1f2f4',
  collapseHeaderBackgroundColor: '#ee591a',
  collapseHeaderBarBackgroundColor: '#fcc9b3',
  styledPanelBackgroundColor: '#fff',
  styledFormBackgroundColor: '#fff',
  iconFullscreenColor: '#7d7c83',
  webkitScrollbarBackgroundColor: '#f5f5f5',
  webkitScrollbarThumbBackgroundColor: '#f5f5f5',
  searchButtonBackgroundColor: '#ee591a',
  resetButtonBackgroundColor: '#000',
  modalHeaderBackgroundColor: '#ee591a',
  cancelButtonColor:'#000',
  cancelButtonTextColor: '#fff'

};

const darkTheme: Theme = {
  primary: '#000',
  secondary: '#ee591a',
  textColor: '#fff',
  linkColor: '#fff',
  headerBgColor: '#26262D',
  headerModalBgColor: '#ee591a',
  sideMenuBgColor: '#1C1B21',
  menuItemHoverBGColor: '#393942',
  menuItemHoverTextColor: '#fff',
  menuDivider: '#45454E',
  topBarActiveBorder: '#ECEDF7',
  primaryButtonColor: '#26262D',
  primaryButtonTextColor: '#40464F',
  secondaryButtonColor: '#ee591a',
  primaryButtonHoverColor: '#5a5a5a',
  secondaryButtonHoverColor: '#ff9d3f',
  
  activeBorderColor: '#26262D',
  tooltipColor: '#000',
  dispatchButtonColor: '#000',
  toolbarBackgroundColor: '#ee591a',

  submitButtonColor: '#000',
  submitButtonTextColor: '#fff',
  collapsePanelBackgroundColor: '#fff',
  collapseBackgroundColor: '#f1f2f4',
  collapseHeaderBackgroundColor: '#000',
  collapseHeaderBarBackgroundColor: '#c9c9c9',
  styledPanelBackgroundColor: '#fff',
  styledFormBackgroundColor: '#fff',
  iconFullscreenColor: '#7d7c83',
  webkitScrollbarBackgroundColor: '#f5f5f5',
  webkitScrollbarThumbBackgroundColor: '#f5f5f5',
  searchButtonBackgroundColor: '#000',
  resetButtonBackgroundColor: '#ee591a',
  modalHeaderBackgroundColor: '#000',
  cancelButtonColor:'#ee591a',
  cancelButtonTextColor: '#fff'
}

const lightTheme: Theme = {
  primary: '#40464F',
  secondary: '#1890ff',
  textColor: '#242424',
  linkColor: '#242424',
  headerBgColor: '#fff',
  headerModalBgColor: '#26262D',
  sideMenuBgColor: '#fff',
  menuItemHoverBGColor: '#F6F6F6',
  menuItemHoverTextColor: '#242424',
  menuDivider: '#ECEDF7',
  topBarActiveBorder: '#ECEDF7',
  primaryButtonColor: '#fff',
  primaryButtonTextColor: '#40464F',
  secondaryButtonColor: '#000',
  primaryButtonHoverColor: '#5a5a5a',
  secondaryButtonHoverColor: '#5a5a5a',
  activeBorderColor: '#fff',
  tooltipColor: '#1890ff',
  dispatchButtonColor: '#1890ff',
  toolbarBackgroundColor: '#fff',

  submitButtonColor: '#ee591a',
  submitButtonTextColor: '#fff',
  collapsePanelBackgroundColor: '#fff',
  collapseBackgroundColor: '#f1f2f4',
  collapseHeaderBackgroundColor: '#ee591a',
  collapseHeaderBarBackgroundColor: '#c2e1fc',
  styledPanelBackgroundColor: '#fff',
  styledFormBackgroundColor: '#fff',
  iconFullscreenColor: '#7d7c83',
  webkitScrollbarBackgroundColor: '#f5f5f5',
  webkitScrollbarThumbBackgroundColor: '#f5f5f5',
  searchButtonBackgroundColor: '#1890ff',
  resetButtonBackgroundColor: '#000',
  modalHeaderBackgroundColor: '#fff',
  cancelButtonColor:'#000',
  cancelButtonTextColor: '#fff'
};


export type Theme = typeof defaultTheme;

export const themes = {
  default: defaultTheme,
  dark: darkTheme,
  light: lightTheme,
};
