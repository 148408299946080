import { Button, message } from 'antd';
import Dialog from 'components/Dialog';
import { IDialogHeader } from 'components/Dialog/types';
import WarningAbandon from 'components/common/WarningAbandon';
import WithThemeProvider from 'components/providers/WithThemeProvider';
import { useFormHooks } from 'context/FormHooksContext';
import { getErrorMessage } from 'helpers/form-errors';
import React, { ReactNode, useRef } from 'react';
import DetailsForm from './DetailsForm';

type Props = {
  division?: any;
  onClose?: () => void;
  onSubmit: (data: any) => void;
} & IDialogHeader;

type FormProps = {
  detailsForm: ReactNode;
};

const getHeaderTitle = ({ title }) => (
  <WithThemeProvider
    token={{ colorPrimaryActive: '#EE5919', colorPrimary: '#ee5919' }}
  >
    <div className="flex h-full w-full">
      <div className="relative">
        <h3 className="text-[18px] font-semibold">{title}</h3>
      </div>
    </div>
  </WithThemeProvider>
);

const Form = ({
  onClose,
  onSubmit,
  title,
  division,
  detailsForm,
}: Props & FormProps) => {
  const [isAbandoning, setIsAbandoning] = React.useState(false);
  const {
    handleSubmit,
    formState: { errors, isDirty },
  } = useFormHooks();

  const formRef = useRef<HTMLFormElement>(null);

  function showErrors() {
    if (Object.keys(errors).length) {
      message.error(getErrorMessage(errors));
    }
  }

  // eslint-disable-next-line no-underscore-dangle
  async function _onSave() {
    setIsAbandoning(false);
    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
    showErrors();
  }

  // eslint-disable-next-line no-underscore-dangle
  function _onCancel() {
    if (isAbandoning || !isDirty) {
      onClose();
      return;
    }

    setIsAbandoning(true);
  }

  return (
    <form
      ref={formRef}
      className="flex flex-col w-full h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col w-full h-full">
        <Dialog.Header onClose={onClose} title={getHeaderTitle({ title })} />
        {detailsForm}
      </div>
      <div className="w-full px-4 py-3 border-t border-solid border-gray-200">
        <WithThemeProvider token={{ colorPrimary: '#fff' }}>
          <div className="flex justify-end">
            {isAbandoning ? <WarningAbandon /> : null}
            <Button
              onClick={_onCancel}
              className={
                isAbandoning
                  ? 'bg-gray-400 text-white hover:bg-red-600'
                  : 'bg-gray-100 border-0 text-gray-900 hover:bg-gray-400'
              }
            >
              {isAbandoning ? 'Discard' : 'Cancel'}
            </Button>
            <Button
              loading={division?.isSaving}
              onClick={_onSave}
              className="ml-2 bg-mainOrange text-white hover:text-white hover:bg-mainOrangeHover"
            >
              Save
            </Button>
          </div>
        </WithThemeProvider>
      </div>
    </form>
  );
};

export default function Index({ division = null, ...rest }: Props) {
  return (
    <Form
      {...rest}
      division={division}
      detailsForm={<DetailsForm key="details" />}
    />
  );
}
