import { IDialog } from 'components/Dialog/types';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import { actions, reducer, selectModals, sliceKey } from './slice';

export default function useApp() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const modals = useSelector(selectModals);

  const dispatch = useDispatch();

  const showModal = content => {
    dispatch(actions.showModal(content));
  };

  const closeModal = module => {
    const currentModals = { ...modals };
    delete currentModals[module];
    dispatch(actions.closeModal(currentModals));
  };

  const openDialog = (params: IDialog) => {
    dispatch(actions.openDialog(params));
  };

  const closeDialog = () => {
    dispatch(actions.closeDialog());
  };

  return {
    showModal,
    closeModal,
    openDialog,
    closeDialog,
  };
}
