export function serializePlaceAddressPayload(formData, selectedPlace) {
  const { placeName, subLocation1, subLocation2, siteId, siteNote } = formData;

  const {
    address1,
    country,
    countryCode,
    mapId,
    locality,
    longitude,
    latitude,
    shortCode,
    postCode,
    district,
    region,
    regionCode,
    place,
  } = selectedPlace;

  return {
    isDeleted: selectedPlace?.isDeleted,
    address1,
    placeName,
    subLocation1,
    subLocation2,
    siteId,
    siteNote,
    mapId,
    longitude,
    latitude,
    shortCode,
    postCode,
    district,
    locality,
    region,
    regionCode,
    country,
    countryCode,
    place,
  };
}
