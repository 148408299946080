import {
  useCreateAssetMutation,
  useDeleteAssetMutation,
  useGetAssetByIdLazyQuery,
  useGetAssetLazyQuery,
  useModifyAssetMutation,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { ERROR_MESSAGE } from 'helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  actions,
  reducer,
  selectFlagRows,
  selectItemDetails,
  selectSelectedItems,
  selectWorkspaceData,
  sliceKey,
} from './slice';

export default function useVehicle() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();

  const vehicleWorkpaceData = useSelector(selectWorkspaceData);
  const assetDetails = useSelector(selectItemDetails);
  const flagRows = useSelector(selectFlagRows);
  const selectedItems = useSelector(selectSelectedItems);

  const [
    getVehicleQuery,
    { loading: pendingVehicleList },
  ] = useGetAssetLazyQuery({
    onCompleted: response => {
      let data;
      if (response?.assets?.nodes) {
        const nodes = response.assets.nodes.map(dataItem => {
          if (dataItem?.id) {
            return dataItem;
          }
          return undefined;
        });

        let newList = nodes;
        if (
          vehicleWorkpaceData?.nodes?.length > 0 &&
          response.assets.pageInfo.hasPreviousPage
        ) {
          newList = [...(vehicleWorkpaceData?.nodes ?? []), ...nodes];
        }
        data = {
          ...response?.assets,
          nodes: newList,
        };
      }
      dispatch(
        actions.setVehicleList({
          workspaceData: { ...data, loading: false, error: null },
        }),
      );
    },
    onError: () => {
      dispatch(
        actions.setVehicleList({
          workspaceData: {
            nodes: [],
            loading: false,
            error: ERROR_MESSAGE.LOADING_DATA_ERROR,
          },
        }),
      );
    },
  });

  const getVehicleList = ({ filters, cursor, order }) => {
    getVehicleQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const [getAssetByIdQuery] = useGetAssetByIdLazyQuery({
    onCompleted: response => {
      if (response?.assetById) {
        dispatch(
          actions.setItemDetails({
            itemDetails: response?.assetById,
          }),
        );
      }
    },
  });

  const getAssetById = vehicleId => {
    getAssetByIdQuery({
      variables: { vehicleId },
    });
  };

  const resetItemDetails = () => {
    dispatch(
      actions.setItemDetails({
        itemDetails: null,
      }),
    );
  };

  const refreshWorkspaceData = () => {
    dispatch(
      actions.setVehicleList({
        workspaceData: null,
      }),
    );
  };

  const [
    createAssetMutation,
    { loading: pendingCreateAsset },
  ] = useCreateAssetMutation({
    onCompleted: response => {
      const result = response?.addAsset;
      if (result) {
        setNewItem(result);
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createAsset = input => {
    return createAssetMutation({
      variables: {
        input,
      },
    });
  };

  const [
    deleteAssetMutation,
    { loading: pendingDeleteeAsset },
  ] = useDeleteAssetMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteAsset = input => {
    return deleteAssetMutation({
      variables: {
        input,
      },
    });
  };

  const setNewItem = newItem => {
    const newList = [newItem, ...(vehicleWorkpaceData?.nodes ?? [])];
    const data = {
      ...vehicleWorkpaceData,
      nodes: newList,
    };
    dispatch(
      actions.setNewVehicle({
        newItem,
        workspaceData: data,
        flagRows: [newItem],
      }),
    );
  };

  const setUpdatedItem = updatedItem => {
    const newList = vehicleWorkpaceData?.nodes?.map(item => {
      if (item?.id === updatedItem?.id) {
        return { ...item, ...updatedItem };
      }
      return item;
    });
    const data = {
      ...vehicleWorkpaceData,
      nodes: newList,
    };
    dispatch(
      actions.setUpdatedVehicle({
        updatedItem,
        workspaceData: data,
        flagRows: [updatedItem],
      }),
    );
  };

  const [
    modifyAssetMutation,
    { loading: pendingModifyAsset },
  ] = useModifyAssetMutation({
    onCompleted: response => {
      const updatedAsset = response?.modifyAsset;
      if (updatedAsset) {
        setUpdatedItem(updatedAsset);
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifyAsset = input => {
    return modifyAssetMutation({
      variables: {
        input,
      },
    });
  };

  const setSelectedItems = rows => {
    dispatch(
      actions.setSelectedItems({
        selectedItems: rows,
      }),
    );
  };

  return {
    getVehicleList,
    getAssetById,
    resetItemDetails,
    refreshWorkspaceData,
    createAsset,
    setNewItem,
    setUpdatedItem,
    modifyAsset,
    deleteAsset,
    setSelectedItems,
    flagRows,
    vehicleWorkpaceData,
    assetDetails,
    pendingVehicleList,
    pendingCreateAsset,
    pendingModifyAsset,
    pendingDeleteeAsset,
    selectedItems,
  };
}
