import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type Props = Readonly<{
  type: string;
}>;

function LoadingContainer(props: Props) {
  return (
    <div className="flex flex-col w-full h-full justify-center items-center text-2xl font-bold bg-white">
      {
        {
          loading: (
            <FontAwesomeIcon
              icon={faSpinner}
              style={{ fontSize: 40, color: '#000000' }}
              spin
            />
          ),
          notFound: <p>404 NOT FOUND</p>,
          unavailable: <p>FEATURE UNAVAILABLE</p>,
        }[props.type]
      }
    </div>
  );
}

export default LoadingContainer;
