import {
  useCreateDivisionMutation,
  useDeleteDivisionMutation,
  useGetDivisionsLazyQuery,
  useUpdateDivisionMutation,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { ERROR_MESSAGE } from 'helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  actions,
  reducer,
  selectFlagRows,
  selectSelectedItems,
  selectWorkspaceData,
  sliceKey,
} from './slice';

export default function useDivision() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();

  const divisionWorkspaceData = useSelector(selectWorkspaceData);
  const flagRows = useSelector(selectFlagRows);
  const selectedItems = useSelector(selectSelectedItems);

  const [
    getDivisionsQuery,
    { loading: pendingGetDivision },
  ] = useGetDivisionsLazyQuery({
    onCompleted: response => {
      let data;
      let nodes = response.allDivisions.nodes;
      if (nodes) {
        if (
          divisionWorkspaceData?.nodes?.length > 0 &&
          response.allDivisions.pageInfo.hasPreviousPage
        ) {
          nodes = [...(divisionWorkspaceData?.nodes ?? []), ...nodes];
        }
        data = {
          ...response?.allDivisions,
          nodes,
        };
      }
      dispatch(
        actions.setWorkspaceData({
          workspaceData: { ...data, loading: false, error: null },
        }),
      );
    },
    onError: () => {
      dispatch(
        actions.setWorkspaceData({
          workspaceData: {
            nodes: [],
            loading: false,
            error: ERROR_MESSAGE.LOADING_DATA_ERROR,
          },
        }),
      );
    },
  });

  const getDivisionList = ({ filters, cursor, order }) => {
    getDivisionsQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const refreshWorkspaceData = () => {
    dispatch(
      actions.setWorkspaceData({
        workspaceData: null,
      }),
    );
  };

  const [
    createDivisionMutation,
    { loading: pendingCreateDivision },
  ] = useCreateDivisionMutation({
    onCompleted: response => {
      const newDivision = response?.addDivision;
      if (newDivision) {
        setNewItem(newDivision);
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createDivision = input => {
    return createDivisionMutation({
      variables: { input },
    });
  };

  const setNewItem = newItem => {
    const newList = [newItem, ...(divisionWorkspaceData?.nodes ?? [])];
    const data = {
      ...divisionWorkspaceData,
      nodes: newList,
    };
    dispatch(
      actions.setNewDivision({
        newItem,
        workspaceData: data,
        flagRows: [newItem],
      }),
    );
  };

  const setUpdatedItem = updatedItem => {
    const newList = divisionWorkspaceData?.nodes?.map(item => {
      if (item?.id === updatedItem?.id) {
        return { ...item, ...updatedItem };
      }
      return item;
    });
    const data = {
      ...divisionWorkspaceData,
      nodes: newList,
    };
    dispatch(
      actions.setUpdatedDivision({
        updatedItem,
        workspaceData: data,
        flagRows: [updatedItem],
      }),
    );
  };

  const setSelectedItems = rows => {
    dispatch(
      actions.setSelectedItems({
        selectedItems: rows,
      }),
    );
  };

  const [
    updateDivisionMutation,
    { loading: pendingUpdateDivision },
  ] = useUpdateDivisionMutation({
    onCompleted: response => {
      const updatedDivision = response?.modifyDivision;
      if (updatedDivision) {
        setUpdatedItem(updatedDivision);
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const updateDivision = input => {
    return updateDivisionMutation({
      variables: { input },
    });
  };

  const [
    deleteDivisionMutation,
    { loading: pendingDeleteDivision },
  ] = useDeleteDivisionMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteDivision = ids => {
    return deleteDivisionMutation({
      variables: { ids },
    });
  };

  return {
    refreshWorkspaceData,
    getDivisionList,
    createDivision,
    setSelectedItems,
    updateDivision,
    deleteDivision,
    divisionWorkspaceData,
    pendingCreateDivision,
    pendingGetDivision,
    setUpdatedItem,
    setNewItem,
    flagRows,
    selectedItems,
    pendingUpdateDivision,
    pendingDeleteDivision,
  };
}
