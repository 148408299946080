import { message } from 'antd';
import { IDialogTrigger } from 'components/Dialog/types';
import { FormHooksProvider } from 'context/FormHooksContext';
import useSurcharge from 'context/financials_surcharges/hooks';
import React, { useEffect } from 'react';
import MainForm from './Mainform';
import schemaResolver from './schema-resolver';
import useSurchargesStore from './store';

interface IProps {
  surcharge?: any;
  afterValidation?: (vehicle?: any) => void;
}

function DetailsScreen({
  surcharge,
  afterValidation,
  ...rest
}: IDialogTrigger & IProps) {
  const formTitle = `${surcharge ? 'Edit' : 'New'} Surcharge`;

  const { createSurcharge, modifySurcharge } = useSurcharge();

  const [
    reset,
    selectedSurcharge,
    setSelectedSurcharge,
    setIsSaving,
  ] = useSurchargesStore(state => [
    state.reset,
    state.selectedSurcharge,
    state.setSelectedSurcharge,
    state.setIsSaving,
  ]);

  async function _onSubmit(formData: any) {
    setIsSaving(true);
    const _surcharge = surcharge;
    if (_surcharge) {
      const { id } = selectedSurcharge;
      const {
        name,
        code,
        category,
        organizationId,
        cost,
        charge,
        costGlcode,
        chargeGlcode,
        description,
        unitOfMeasureId,
        applyFAF,
      } = formData;

      const payload = {
        id,
        name,
        code,
        category,
        organizationId,
        cost,
        charge,
        costGlcode,
        chargeGlcode,
        description,
        unitOfMeasureId,
        applyFAF,
      };

      const result = await modifySurcharge(payload);
      if (result?.data) {
        message.success('Surcharge updated');
        afterValidation?.(result.data?.modifySurcharge);
      } else {
        message.error('Cannot save Surcharge');
      }

      setIsSaving(false);
      return;
    }
    formData.chargeType = 'SURCHARGE';
    onCreate(formData);
  }

  async function onCreate(formData: any) {
    const payload = {
      ...formData,
    };
    const result = await createSurcharge(payload);
    if (result?.data) {
      const bookingCharge = result.data?.addSurcharge;
      message.success(`Surcharge ${bookingCharge.name} created`);
      afterValidation?.(result.data?.addSurcharge);
    } else {
      message.error('Something went wrong, cannot create Surcharge');
    }
    setIsSaving(false);
  }

  useEffect(() => reset(), []);

  useEffect(() => {
    if (surcharge) {
      setSelectedSurcharge({
        isSaving: false,
        ...surcharge,
      });
    }
  }, [surcharge]);

  return (
    <FormHooksProvider resolver={schemaResolver}>
      <MainForm
        surcharge={selectedSurcharge}
        {...rest}
        onSubmit={_onSubmit}
        title={formTitle}
      />
    </FormHooksProvider>
  );
}

export default DetailsScreen;
