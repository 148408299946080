import { message } from 'antd';
import { IDialogTrigger } from 'components/Dialog/types';
import { FormHooksProvider } from 'context/FormHooksContext';
import useAuthentication from 'context/security_authentication/hook';
import useAssets from 'context/vehicles/hooks';
import React, { useEffect } from 'react';
import MainForm from './MainForm';
import schemaResolver from './schema-resolver';
import useVehiclesStore from './store';

interface IProps {
  vehicle?: any;
  afterValidation?: (vehicle?: any) => void;
}

export default ({
  vehicle,
  afterValidation,
  ...rest
}: IDialogTrigger & IProps) => {
  const formTitle = `${vehicle ? 'Edit' : 'New'} Vehicle`;
  const [
    reset,
    selectedVehicle,
    setSelectedVehicle,
    setIsSaving,
  ] = useVehiclesStore(state => [
    state.reset,
    state.selectedVehicle,
    state.setSelectedVehicle,
    state.setIsSaving,
  ]);
  const { createAsset, modifyAsset } = useAssets();
  const { currentAccount } = useAuthentication();

  useEffect(() => reset(), []);

  useEffect(() => {
    if (vehicle) {
      setSelectedVehicle({
        isSaving: false,
        ...vehicle,
      });
    }
  }, [vehicle]);

  // eslint-disable-next-line no-underscore-dangle
  async function _onSubmit(formData: any) {
    const _vehicle = vehicle;
    if (_vehicle) {
      const { id } = selectedVehicle;

      const {
        name,
        code,
        description,
        registration,
        license,
        makeModel,
        gvm,
        tare,
        loadCapacityCube,
        loadCapacityWeight,
        divisionId,
      } = formData;

      const payload = {
        id,
        name,
        code,
        description,
        registration,
        license,
        makeModel,
        gvm,
        tare,
        loadCapacityCube,
        loadCapacityWeight,
        divisionId,
        organizationId:
          currentAccount?.organizationOrganizationMapping?.childOrganizationId,
      };

      setIsSaving(true);
      const result = await modifyAsset(payload);
      if (result?.data) {
        message.success('Vehicle updated');
        afterValidation?.(result.data?.modifyAsset);
      } else {
        message.error('Cannot save Vehicle');
      }

      setIsSaving(false);
      return;
    }
    onCreate(formData);
  }

  async function onCreate(formData: any) {
    const payload = {
      ...formData,
      organizationId:
        currentAccount?.organizationOrganizationMapping?.childOrganizationId,
    };

    const qry = await createAsset(payload);
    const {
      data: { addAsset: vehicle },
    } = qry;

    if (vehicle) {
      afterValidation?.(vehicle);
      message.success(`Vehicle ${formData.name} created`);
    } else {
      message.error('Something went wrong ...');
    }
  }

  function _getFormOptions() {
    let defaultValues = {};

    if (vehicle) {
      defaultValues = {
        name: vehicle.name,
        code: vehicle.code,
        description: vehicle.description,
        registration: vehicle.registration,
        license: vehicle.license,
        makeModel: vehicle.makeModel,
        gvm: vehicle.gvm,
        tare: vehicle.tare,
        loadCapacityCube: vehicle.loadCapacityCube,
        loadCapacityWeight: vehicle.loadCapacityWeight,
        divisionId: vehicle.divisionId,
        division: vehicle.division,
      };
      return {
        opts: { defaultValues },
      };
    }

    return {};
  }

  return (
    <FormHooksProvider resolver={schemaResolver} {..._getFormOptions()}>
      <MainForm
        {...rest}
        title={formTitle}
        vehicle={selectedVehicle}
        onSubmit={_onSubmit}
      />
    </FormHooksProvider>
  );
};
