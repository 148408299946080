import { GridOptions } from 'ag-grid-community';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';
import { numberFilterParams, textFilterParams } from 'helpers/filterParams';

export const divisionGridOptions = {
  groupSelectsChildren: false,
  rowSelection: 'multiple',
  components: {
    CustomCell,
  },
  getContextMenuItems: null,

  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressSideButton: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  },
  defaultColDef: {
    initialHide: false,
    enableRowGroup: false,
    editable: false,
    cellRenderer: 'CustomCell',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: textFilterParams,
  },
  suppressScrollOnNewData: true,
  columnDefs: [
    {
      headerName: 'Division ID',
      field: 'id',
      pinned: 'left',
      filter: 'agNumberColumnFilter',
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Name',
      field: 'name',
    },
  ],
} as GridOptions;
