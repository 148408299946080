import {
  AuthenticationUserFieldsFragment,
  useGenerateAccessTokenMutation,
  useGenerateNewAccessTokenMutation,
  useGetCurrentUserLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { ModuleTypes } from 'components/config/types/modules';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  actions,
  reducer,
  selectAzureUser,
  selectCurrentAccount,
  selectModulePermissions,
  selectSwitchAccounts,
  selectUserPermissions,
  sliceKey,
} from './slice';

export default function useAuthentication() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();
  const azureUser = useSelector(selectAzureUser);
  const currentAccount: AuthenticationUserFieldsFragment = useSelector(
    selectCurrentAccount,
  );
  const switchAccounts: [AuthenticationUserFieldsFragment] = useSelector(
    selectSwitchAccounts,
  );
  const modulePermissions = useSelector(selectModulePermissions);
  const userPermissions = useSelector(selectUserPermissions);

  const requestNewToken = async (refreshToken, userInfo) => {
    const res = await generateNewAccessToken({ refreshToken });

    if (res.data?.generateNewAccessToken) {
      const { accessToken } = res.data.generateNewAccessToken;

      if (accessToken) {
        const newAuthToken = res.data?.generateNewAccessToken;
        localStorage.setItem('icosAuth', JSON.stringify(newAuthToken));
        userInfo = jwtDecode(newAuthToken.accessToken);

        return userInfo;
      }
      return null;
    }

    localStorage.removeItem('icosAuth');
  };

  const userAuthenticate = async authToken => {
    const { accessToken, refreshToken } = authToken;

    try {
      let userInfo = jwtDecode(accessToken);
      const isSessionExpired = userInfo?.exp < Date.now() / 1000;

      if (isSessionExpired) {
        requestNewToken(refreshToken, userInfo);
      } else {
        return userInfo;
      }
    } catch (err) {
      localStorage.removeItem('icosAuth');
      return null;
    }
  };

  const [
    generateAccessTokenMutation,
    { loading: pendingGenerateToken },
  ] = useGenerateAccessTokenMutation();

  const generateAccessToken = input => {
    return generateAccessTokenMutation({
      variables: { ...input },
    });
  };

  const [
    generateNewAccessTokenMutation,
    { loading: pendingNewGenerateToken },
  ] = useGenerateNewAccessTokenMutation();

  const generateNewAccessToken = input => {
    return generateNewAccessTokenMutation({
      variables: { ...input },
    });
  };

  const setAzureUser = (metadata = null) => {
    let azureUser = metadata;
    if (metadata) {
      azureUser = {
        familyName: metadata.family_name,
        givenName: metadata.given_name,
        authorizationId: metadata.oid,
        email: metadata.emails?.[0] ?? null,
      };
    }

    dispatch(
      actions.setAzureUser({
        azureUser,
      }),
    );
  };

  const [
    getCurrentUserQuery,
    { loading: pendingGetCurrentUser },
  ] = useGetCurrentUserLazyQuery({
    onCompleted: response => {
      const currentAccount = response?.currentUser;
      if (currentAccount) {
        dispatch(
          actions.setCurrentAccount({
            switchAccounts: currentAccount,
            currentAccount,
          }),
        );
      } else {
        localStorage.removeItem('icosAuth');
        window.location.href = '/signIn';
        dispatch(
          actions.setAzureUser({
            azureUser: null,
          }),
        );
      }
    },
  });

  const getUserPermissions = (account: AuthenticationUserFieldsFragment) => {
    const userPermissions =
      account?.applicablePermissions?.map(item => ({
        name: item?.name.toLowerCase(),
        category: item?.category?.toLowerCase(),
        allowDeny: item?.allowDeny?.toLowerCase(),
      })) || [];

    const allowPermissions = userPermissions.filter(
      item => item.allowDeny === 'allow',
    );

    const distinctModules = [
      ...new Set(allowPermissions?.map(item => item.category)),
    ];

    if (distinctModules.includes('invoice')) {
      distinctModules.push('invoices');
    }

    dispatch(
      actions.setUserPermissions({
        userPermissions: allowPermissions,
        modulePermissions: distinctModules,
      }),
    );
  };

  const switchAccount = account => {
    dispatch(
      actions.setCurrentAccount({
        switchAccounts,
        currentAccount: account,
      }),
    );
  };

  const logOut = () => {
    localStorage.removeItem('icosAuth');
    dispatch(
      actions.setAzureUser({
        azureUser: null,
        currentAccount: null,
      }),
    );
  };

  const isAllow = (action, module, ignoreModule = true) => {
    const moduleToCheck =
      module === ModuleTypes.Invoice.value && ignoreModule
        ? ModuleTypes.Booking.value
        : module;

    const hasPermission = userPermissions?.find(
      item => item?.name === action && item.category === moduleToCheck,
    );
    return hasPermission !== undefined;
  };

  return {
    userAuthenticate,
    getCurrentUser: getCurrentUserQuery,
    getUserPermissions,
    logOut,
    setAzureUser,
    switchAccount,
    generateAccessToken,
    generateNewAccessToken,
    isAllow,
    azureUser,
    currentAccount,
    switchAccounts,
    userPermissions,
    modulePermissions,
    pendingGenerateToken,
    pendingNewGenerateToken,
    pendingGetCurrentUser,
  };
}
