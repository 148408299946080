import * as React from 'react';
import {
  Resolver,
  UseFormProps,
  UseFormReturn,
  useForm,
} from 'react-hook-form';

/**
 * React Context provider for utilizing react-hook-form library.
 *
 * @example
 *
 * <FormHooksProvider opts={{ mode: "onBlur" }}>
 * * Child components *
 * </FormHooksProvider>
 *
 * // In child components
 * const { register, handleSubmit, errors } = useFormHooks();
 *
 * @see https://www.react-hook-form.com/api/useform/ for api reference
 *
 */

interface IFormHooks {
  resolver: Resolver;
  children: React.ReactNode;

  // https://react-hook-form.com/docs/useform
  opts?: Partial<UseFormProps>;
}

export type TFormHooksProviderProps = { opts?: Partial<UseFormProps> } | {};

const FormHooksContext = React.createContext<UseFormReturn<any>>(null);
// eslint-disable-next-line react/prop-types
function FormHooksProvider({
  resolver,
  opts = {},
  children,
}: Readonly<IFormHooks>) {
  const rest = useForm({ resolver, ...opts });
  const value = React.useMemo(() => ({ ...rest }), [
    resolver,
    opts,
    rest,
    rest.formState.errors,
    rest.formState.isDirty,
  ]);

  return (
    <FormHooksContext.Provider value={value}>
      {children}
    </FormHooksContext.Provider>
  );
}

function useFormHooks() {
  return React.useContext(FormHooksContext);
}

export { FormHooksContext, FormHooksProvider, useFormHooks };
