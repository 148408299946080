import { message } from 'antd';
import { IDialogTrigger } from 'components/Dialog/types';
import { FormHooksProvider } from 'context/FormHooksContext';
import useBookingRate from 'context/financials_rate/hooks';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import useFafGroupNewStore from '../store';
import MainForm from './MainForm';
import schemaResolver from './schema-resolver';

interface IProps {
  rate?: any;
  afterValidation?: (vehicle?: any) => void;
}

export default ({
  rate,
  afterValidation,
  ...rest
}: IDialogTrigger & IProps) => {
  const formTitle = `${rate ? 'Edit' : 'New'} rate`;
  const [setIsSaving, isSaving] = useFafGroupNewStore(state => [
    state.setIsSaving,
    state.isSaving,
  ]);
  const {
    createFafGroupRate,
    modifyFafGroupRate,
    selectedFafGroupNew,
  } = useBookingRate();
  useEffect(() => {
    setIsSaving(false);
  }, []);

  async function _onSubmit(formData: any) {
    setIsSaving(true);

    if (rate) {
      const payload = {
        id: rate.id,
        fafGroupNewId: selectedFafGroupNew?.id,
        rate: Number((formData.rate / 100).toFixed(4)),
        startDate: formData.startDate,
        endDate: formData.endDate,
      };
      const modifyRateResult = await modifyFafGroupRate(payload);
      const {
        data: { modifyFafGroupRate: resultRate },
      } = modifyRateResult;
      if (resultRate) {
        afterValidation?.(resultRate);
        message.success(`Update Rate successfully`);
      } else if (modifyRateResult.errors?.length > 0) {
        message.error(modifyRateResult.errors[0].extensions.message);
      }
      setIsSaving(false);
      return;
    }
    onCreate(formData);
  }

  async function onCreate(formData: any) {
    const rateData = {
      fafGroupNewId: selectedFafGroupNew?.id,
      rate: Number((formData.rate / 100).toFixed(4)),
      startDate: formData.startDate,
      endDate: formData.endDate,
    };

    const createRateResult = await createFafGroupRate(rateData);
    const {
      data: { addFafGroupRate: resultRate },
    } = createRateResult;

    if (resultRate) {
      afterValidation?.(resultRate);
      message.success(`Rate created`);
    } else if (createRateResult.errors?.length > 0) {
      message.error(createRateResult.errors[0].extensions.message);
    }
    setIsSaving(false);
  }

  function _getFormOptions() {
    let defaultValues = {};

    if (rate) {
      defaultValues = {
        rate: `${(rate.rate * 100).toFixed(4)}`,
        startDate: dayjs(rate.startDate),
        endDate: dayjs(rate.endDate),
      };
      return {
        opts: { defaultValues },
      };
    }

    return {};
  }

  return (
    <FormHooksProvider resolver={schemaResolver} {..._getFormOptions()}>
      <MainForm
        {...rest}
        title={formTitle}
        onSubmit={_onSubmit}
        isSaving={isSaving}
      />
    </FormHooksProvider>
  );
};
