/* eslint-disable prefer-destructuring */
/* eslint-disable global-require */

import App from 'app';
import FontFaceObserver from 'fontfaceobserver';
import * as React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureAppStore } from 'store/configureStore';
import { ThemeProvider } from 'styles/theme/ThemeProvider';

document.addEventListener('DOMContentLoaded', () => {
  const openSansObserver = new FontFaceObserver('Inter', {});
  openSansObserver.load().then(() => {
    document.body?.classList?.add('fontLoaded');
  });
});

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

if (!MOUNT_NODE) {
  throw new Error("Could not find the element with ID 'root'.");
}
const root = createRoot(MOUNT_NODE);

export interface AppProps {
  Component: typeof App;
}

export const ConnectedApp = ({ Component }: AppProps) => (
  <Provider store={store}>
    <ThemeProvider>
      <Component />
    </ThemeProvider>
  </Provider>
);

const render = (Component: typeof App) => {
  root.render(<ConnectedApp Component={Component} />);
};

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

declare const module: {
  hot?: {
    accept(dependencies: string[], callback: (...args: any[]) => void): void;
  };
} & NodeModule;
if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./app'], () => {
    root.unmount(); // Use root.unmount instead of ReactDOM.unmountComponentAtNode
    const Application = require('./app').App;
    render(Application);
  });
}

render(App);
