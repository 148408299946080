/**
 * @alpha 0.1.0
 *
 * Error UI Component - display error and error details bsed on error code {TResourceErrorCode}
 *
 */

import { TResourceErrorCode } from 'context/errors';
import React from 'react';

interface IResourceError {
  code: TResourceErrorCode;
  onRetry?: () => void;
}

export default function ResourceError({
  code = null,
  onRetry,
}: Readonly<IResourceError>) {
  if (!code) {
    return <></>;
  }
  return (
    <div className="error mt-4">
      <div className="overflow-hidden rounded-lg shadow-md">
        <div className="flex items-center text-white bg-red-600 rounded-t-lg px-2 py-1">
          <div className="text-white mr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 32 32"
            >
              <path
                fill="currentColor"
                d="M16 21a1.5 1.5 0 1 0 1.5 1.5A1.5 1.5 0 0 0 16 21zM15 8h2v10h-2z"
              />
              <path
                fill="currentColor"
                d="M23 29H9a1 1 0 0 1-.864-.496l-7-12a1 1 0 0 1 0-1.008l7-12A1 1 0 0 1 9 3h14a1 1 0 0 1 .864.496l7 12a1 1 0 0 1 0 1.008l-7 12A1 1 0 0 1 23 29ZM9.574 27h12.852l6.416-11l-6.416-11H9.574L3.158 16Z"
              />
            </svg>
          </div>
          <span className="font-medium">Unable to connect</span>
        </div>
        <div className="p-4 text-gray-500">{code}</div>
        {onRetry ? (
          <div className="flex bg-gray-100 p-2 flex-row-reverse">
            <span className="flex">
              <button
                type="button"
                onClick={onRetry}
                className="hover:bg-mainOrange hover:text-white px-2 py-1 rounded-lg bg-white border  border-solid border-gray-200"
              >
                Retry
              </button>{' '}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
