import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SubModalContent = {
  name: string;
  visible: boolean;
};

export interface InvoiceState {
  workspaceData: any;
  itemDetails: any;
  selectedItems: any[];
  flagRows: any[];
  updatedItems: any[];
  subModals: {
    [key: string]: SubModalContent;
  };
}
export const initialState: InvoiceState = {
  workspaceData: null,
  itemDetails: null,
  selectedItems: null,
  flagRows: null,
  updatedItems: [],
  subModals: {},
};

const storeSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoiceList: (state, action: PayloadAction<any>) => {
      const { workspaceData } = action.payload;
      state.workspaceData = workspaceData;
    },
    setItemDetails: (state, action: PayloadAction<any>) => {
      const { itemDetails } = action.payload;
      state.itemDetails = itemDetails;
    },

    setSelectedItems: (state, action: PayloadAction<any>) => {
      const { selectedItems } = action.payload;
      state.selectedItems = selectedItems;
    },

    setFlagRows: (state, action: PayloadAction<any>) => {
      const { flagRows } = action.payload;
      state.flagRows = flagRows;
    },

    setSubModal: (state, action: PayloadAction<SubModalContent>) => {
      const { name } = action.payload;
      state.subModals[name] = action.payload;
    },

    setOnlyUpdateBookingItems: (state, action: PayloadAction<any>) => {
      const { updatedItems } = action.payload;
      state.updatedItems = updatedItems;
    },

    setUpdatedInvoice: (state, action: PayloadAction<any>) => {
      const { workspaceData, updatedItems, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.updatedItems = updatedItems;
      state.flagRows = flagRows;
    },
  },
});

export const selectWorkspaceData = state => state.invoices.workspaceData;
export const selectFlagRows = state => state.invoices.flagRows;
export const selectItemDetails = state => state.invoices.itemDetails;
export const selectSelectedItems = state => state.invoices.selectedItems;
export const selectSubModals = state => state.invoices.subModals;

export const { actions, reducer, name: sliceKey } = storeSlice;
