import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  rate: yup.number().required({ message: 'Rate is required' }),
  startDate: yup
    .date()
    .typeError({ message: 'Start Date is required' })
    .required({ message: 'Start Date is required' }),
  endDate: yup
    .date()
    .required({ message: 'End Date is required' })
    .min(yup.ref('startDate'), {
      message: 'End date must be later than start date',
    }),
});
export { schema };

export default yupResolver(schema);
