import { SelectionChangedEvent } from 'ag-grid-community';
import {
  Surcharge,
  SurchargeFilterInput,
} from 'api/graphql/generated/serviceTypesAndHooks';
import DefaultLayout from 'components/Layout/DefaultLayout';
import { surchargeScreenConfig } from 'containers/Financial/Surcharges/MainScreen/config';
import useApp from 'context/app/hooks';
import useSurcharge from 'context/financials_surcharges/hooks';
import useAuthentication from 'context/security_authentication/hook';
import { actions, reducer, sliceKey } from 'context/workspace/slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import SurchargeForm from '../DetailsScreen/index';
import { surchargesGridOptions } from './gridOptions';

export default () => {
  // = =============================== init ======================================//

  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();
  const { openDialog, closeDialog } = useApp();
  const { currentAccount } = useAuthentication();
  const {
    selectedItems,
    surchargeWorkpaceData,
    flagRows,
    getSurchargeList,
    refreshWorkspaceData,
    deleteSurcharge,
    setSelectedItems,
  } = useSurcharge();

  // = =============================== useEffect ======================================//

  const handleDeleteSurcharge = async id => {
    const result = await deleteSurcharge(id);
    let message = 'Delete surcharge(s) successfully';
    if (!result?.data?.deleteSurcharges) {
      message = 'Cannot delete surcharge(s)';
    }
    return {
      message,
      data: result?.data,
    };
  };

  function _createNewSurcharge() {
    openDialog({
      content: <SurchargeForm afterValidation={closeDialog} />,
      dialogProps: {
        closable: false,
        height: '400px',
        width: '720px',
      },
    });
  }

  function _viewSurchargeRecord(record: Surcharge) {
    openDialog({
      content: (
        <SurchargeForm surcharge={record} afterValidation={closeDialog} />
      ),
      dialogProps: {
        closable: false,
        height: '400px',
        width: '720px',
      },
    });
  }

  const filterRowNodes = (rowNode, selectedRows) => {
    return selectedRows.find(row => row.id === rowNode.data.id);
  };

  const gridOptions = {
    ...surchargesGridOptions,
    onSelectionChanged: (event: SelectionChangedEvent) => {
      const { api } = event;
      const records = [];
      const selectedRows = api.getSelectedRows();
      api.forEachNodeAfterFilter((rowNode, _index) => {
        if (filterRowNodes(rowNode, selectedRows)) {
          records.push(rowNode.data);
        }
      });
      setSelectedItems([...records]);
    },
  };

  useEffect(() => {
    dispatch(
      actions.setWorkspace({
        workspace: {
          ...surchargeScreenConfig,
          gridOptions,
        },
        query: {
          cursor: null,
          filters: {
            isDeleted: { neq: true },
          } as SurchargeFilterInput,
          order: { id: 'DESC' },
        },
      }),
    );
  }, [currentAccount]);

  // = =============================== main render ======================================//

  return (
    <DefaultLayout
      gridOptions={gridOptions}
      data={surchargeWorkpaceData}
      selectedItems={selectedItems}
      flagRows={flagRows}
      onGetDataList={getSurchargeList}
      onRefreshDataList={refreshWorkspaceData}
      onCreateNew={_createNewSurcharge}
      onViewRecord={_viewSurchargeRecord}
      onDelete={handleDeleteSurcharge}
    />
  );
};
