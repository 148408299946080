import { GridOptions } from 'ag-grid-community';
import {
  DateTimePickerCell,
  InputCell,
  NumericCell,
} from 'components/ReactGrid/editors';
import { DeleteRow } from 'components/ReactGrid/renderers';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';
import { ICustomCellRendererParams } from 'components/ReactGrid/renderers/types';
import useBookingRate from 'context/financials_rate/hooks';
import { dateFilterParams } from 'helpers/filterParams';

export default function useRateGridConfig() {
  const { modifyFafGroupRate, deleteFafGroupRates } = useBookingRate();

  const ratesGridConfig = {
    toolbar: {
      actions: [
        {
          id: 'create',
          label: 'Add',
          tooltip: 'Add new item',
          skipPermission: true,
        },
        {
          id: 'delete',
          tooltip: 'Delete selected records',
          skipPermission: true,
        },
      ],
    },
    gridId: 'rate',
    gridClassName: 'grid-container',
    modelInputField: {
      add: 'addPortConnectInput',
      modify: 'modifyPortConnectInput',
    },
    gridOptions: {
      rowSelection: 'multiple',
      suppressContextMenu: true,
      enablePivot: true,
      components: {
        CustomCell,
        DeleteRow,
        InputCell,
        NumericCell,
        DateTimePickerCell,
      },
      stopEditingWhenCellsLoseFocus: true,
      maintainColumnOrder: true,
      editType: 'fullRow',
      defaultColDef: {
        flex: 1,
        resizable: true,
        sortable: true,
        suppressMenu: true,
        cellRenderer: 'CustomCell',
        gridId: 'rate',
        editable: true,
      },
      columnDefs: [
        {
          colId: 'id',
          headerName: 'ID',
          field: 'id',
          pinned: 'left',
          width: 80,
          editable: false,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          showDisabledCheckboxes: true,
        },
        {
          headerName: 'Rate',
          field: 'rate',
          width: 10,
          editable: false,
          valueGetter: params => {
            return `${(params.data.rate * 100).toFixed(2)}%`;
          },
        },
        {
          headerName: 'Start date',
          field: 'startDate',
          filter: 'agDateColumnFilter',
          cellRendererParams: {
            type: 'datetimeUTCtoLocal',
          } as ICustomCellRendererParams,
          width: 180,
          filterParams: dateFilterParams,
        },
        {
          headerName: 'End date',
          field: 'endDate',
          filter: 'agDateColumnFilter',
          cellRendererParams: {
            type: 'datetimeUTCtoLocal',
          } as ICustomCellRendererParams,
          width: 180,
          filterParams: dateFilterParams,
        },
        {
          headerName: 'Action',
          colId: 'delAction',
          cellRenderer: 'DeleteRow',
          cellClass: 'cellCenter',
          editable: false,
          filter: false,
          width: 80,
          pinned: 'right',
        },
      ],
    } as GridOptions,
    hooks: {
      update: async input => {
        const updateRateData = {
          id: input?.id,
          fafGroupNewId: input?.fafGroupNewId,
          rate: input?.rate,
          startDate: input?.startDate,
          endDate: input?.endDate,
        };

        const resultModifyRate = await modifyFafGroupRate(updateRateData);
        if (resultModifyRate.data.modifyFafGroupRate) {
          return {
            data: resultModifyRate.data.modifyFafGroupRate,
            message: 'Update Rate successfully',
          };
        }

        return {
          message: 'Cannot update Rate',
        };
      },
      delete: async ids => {
        const resultRate = await deleteFafGroupRates(ids);
        if (resultRate?.data?.deleteFafGroupRates) {
          return {
            data: resultRate?.data?.deleteFafGroupRates,
            message: 'Delete Rate successfully',
          };
        }
        return {
          message: 'Cannot delete Rate',
        };
      },
    },
  };
  return {
    ratesGridConfig,
  };
}
