import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types/RootState';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.workspaces || initialState;

export const selectWorkspaces = createSelector(
  [selectDomain],
  state => state.workspace,
);

export const selectQuery = createSelector([selectDomain], state => state.query);
