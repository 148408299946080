import { ICellRendererParams } from 'ag-grid-community';
import { DatePicker } from 'antd';
import DateFormats from 'components/config/date';
import dayjs from 'dayjs';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

export default forwardRef((props: ICellRendererParams, ref) => {
  // eslint-disable-next-line react/prop-types

  const refInput = useRef(null);
  const [value, setValue] = useState(props.value);

  const handleOnChange = value => {
    setValue(value);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
    };
  });
  return (
    <DatePicker
      ref={refInput}
      showTime
      bordered={false}
      size="small"
      format={DateFormats.asDisplayDateTime}
      value={value ? dayjs(value) : null}
      defaultValue={dayjs(value).isValid() ? dayjs(value) : null}
      style={{
        fontSize: '10px',
      }}
      onChange={handleOnChange}
    />
  );
});
