import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout } from 'antd';
import { selectScreenConfig } from 'context/app/slice';
import { selectWorkspaces } from 'context/workspace/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

import { styled } from 'twin.macro';
import RightHeader from './RightHeader';
import ScreenViews from './ScreenViews';

const { Header, Content, Sider } = Layout;

const StyledHeader = styled(Header)`
  padding-inline: 0 !important;
  height: 55px;
`;

const StyledSider = styled(Sider)`
  flex: initial !important;
  min-width: fit-content !important;
  width: auto !important;
  max-width: auto !important;
`;

const StyledLayout = styled(Layout)`
  height: inherit;
  color: ${p => p.theme.textColor};
  background-color: ${p => p.theme.headerBgColor};
  z-index: 999;
`;

interface IProps {
  collapsed?: boolean;
  onToggle?: () => void;
}

function HeaderBar(props: Readonly<IProps>) {
  const { collapsed, onToggle } = props;
  const screenConfig = useSelector(selectScreenConfig);
  const workspace = useSelector(selectWorkspaces);

  return (
    <StyledHeader
      // @ts-ignore
      theme="light"
    >
      <StyledLayout>
        <StyledSider
          theme="light"
          // @ts-ignore
        >
          <div className="flex items-center min-w-fit bg-white">
            {collapsed ? (
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                onClick={onToggle}
                className="ml-2 cursor-pointer"
              />
            ) : (
              <FontAwesomeIcon
                icon={faAngleDoubleLeft}
                className="ml-2 cursor-pointer"
                onClick={onToggle}
              />
            )}
            <h2 className="text-[20px] min-w-[80px] border-r border-solid  border-gray-200 pr-4 pl-2">
              {workspace?.module?.label}
            </h2>
          </div>
        </StyledSider>
        <Content className="p-2" style={{ width: '64px !important' }}>
          {screenConfig?.screenViews ? <ScreenViews /> : null}
        </Content>
        <RightHeader />
      </StyledLayout>
    </StyledHeader>
  );
}

export default HeaderBar;
