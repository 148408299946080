import { GridOptions } from 'ag-grid-community';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';
import { ICustomCellRendererParams } from 'components/ReactGrid/renderers/types';
import { dateFilterParams, numberFilterParams } from 'helpers/filterParams';

export const userGridOptions = {
  headerHeight: 40,
  components: {
    CustomCell,
  },
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressSideButton: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  },
  defaultColDef: {
    initialHide: false,
    enableRowGroup: false,
    editable: false,
    cellRenderer: 'CustomCell',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: { newRowsAction: 'keep', debounceMs: 1500 },
  },
  suppressScrollOnNewData: true,
  columnDefs: [
    {
      headerName: 'User ID',
      field: 'id',
      filter: 'agNumberColumnFilter',
      filterParams: numberFilterParams,
    },

    {
      headerName: 'Organization',
      field: 'organizationOrganizationMapping.displayName',
      pinned: 'left',
    },
    {
      headerName: 'Display Name',
      field: 'userName',
      pinned: 'left',
    },
    {
      headerName: 'Email Address',
      field: 'contact.email',
    },
    {
      headerName: 'First Name',
      field: 'contact.firstName',
    },
    {
      headerName: 'Last Name',
      field: 'contact.lastName',
    },
    {
      headerName: 'Position',
      field: 'position',
    },
    {
      headerName: 'Phone Number',
      field: 'contact.phone',
    },
    {
      headerName: 'Mobile',
      field: 'contact.mobile',
    },
    {
      headerName: 'Created Date',
      field: 'createdOn',
      filter: 'agDateColumnFilter',
      cellClass: 'dateType',
      cellRendererParams: {
        type: 'datetimeUTCtoLocal',
      } as ICustomCellRendererParams,
      width: 180,
      filterParams: dateFilterParams,
    },
  ],
} as GridOptions;
