import {
  useCreateGroupMutation,
  useCreateUserGroupMappingMutation,
  useDeleteGroupMutation,
  useDeleteUserGroupMappingMutation,
  useGetGroupByIdLazyQuery,
  useGetGroupsLazyQuery,
  useModifyGroupMutation,
  useModifyUserGroupMappingMutation,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { ERROR_MESSAGE } from 'helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  actions,
  reducer,
  selectFlagRows,
  selectItemDetails,
  selectWorkspaceData,
  sliceKey,
} from './slice';

export default function useUser() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();
  const groupWorkspaceData = useSelector(selectWorkspaceData);
  const flagRows = useSelector(selectFlagRows);
  const groupDetails = useSelector(selectItemDetails);

  const [
    getGroupListQuery,
    { loading: pendingGetGroupList },
  ] = useGetGroupsLazyQuery({
    onCompleted: response => {
      let data;
      if (response?.groups?.nodes) {
        const nodes = response.groups.nodes.map(dataItem => {
          if (dataItem?.id) {
            return dataItem;
          }
          return undefined;
        });

        let newList = nodes;
        if (
          groupWorkspaceData?.nodes?.length > 0 &&
          response.groups.pageInfo.hasPreviousPage
        ) {
          newList = [...(groupWorkspaceData?.nodes ?? []), ...nodes];
        }
        data = {
          ...response?.groups,
          nodes: newList,
        };
      }
      dispatch(
        actions.setWorkspaceData({
          workspaceData: { ...data, loading: false, error: null },
        }),
      );
    },
    onError: () => {
      dispatch(
        actions.setWorkspaceData({
          workspaceData: {
            nodes: [],
            loading: false,
            error: ERROR_MESSAGE.LOADING_DATA_ERROR,
          },
        }),
      );
    },
  });

  const getGroupList = ({ filters, cursor, order }) => {
    getGroupListQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const [
    createGroupMutation,
    { loading: pendingCreateGroupMutation },
  ] = useCreateGroupMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createGroup = input => {
    return createGroupMutation({
      variables: { input },
    });
  };

  const [
    deleteGroupMutation,
    { loading: pendingDeleteGroupMutation },
  ] = useDeleteGroupMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteGroup = groupIds => {
    return deleteGroupMutation({
      variables: { groupIds },
    });
  };

  const [
    modifyGroupMutation,
    { loading: pendingModifyGroupMutation },
  ] = useModifyGroupMutation({
    onCompleted: response => {
      const updatedGroup = response?.modifyGroup;
      if (updatedGroup) {
        setUpdatedItem(updatedGroup);
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifyGroup = input => {
    return modifyGroupMutation({
      variables: { input },
    });
  };

  const [getGroupByIdQuery] = useGetGroupByIdLazyQuery({
    onCompleted: response => {
      if (response?.groupById) {
        dispatch(
          actions.setGroupDetails({
            itemDetails: response?.groupById,
          }),
        );
      }
    },
  });

  const getGroupById = groupId => {
    getGroupByIdQuery({
      variables: { groupId },
    });
  };

  const resetItemDetails = () => {
    dispatch(
      actions.setGroupDetails({
        itemDetails: null,
      }),
    );
  };

  const refreshWorkspaceData = () => {
    dispatch(
      actions.setWorkspaceData({
        workspaceData: null,
      }),
    );
  };

  const [
    modifyGroupMutationMapping,
    { loading: pendingModifyUserGroupMappingMutation },
  ] = useModifyUserGroupMappingMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifyUserGroupMapping = input => {
    return modifyGroupMutationMapping({
      variables: { input },
    });
  };

  const [
    createUserGroupMappingMutation,
    { loading: pendingCreateUserGroupMapping },
  ] = useCreateUserGroupMappingMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createUserGroupMapping = input => {
    return createUserGroupMappingMutation({
      variables: { input },
    });
  };

  const [
    deleteUserGroupMappingMutation,
    { loading: pendingDeleteUserGroupMappingMutation },
  ] = useDeleteUserGroupMappingMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteUserGroupMapping = input => {
    return deleteUserGroupMappingMutation({
      variables: { input },
    });
  };

  const setNewItem = newItem => {
    const newList = [newItem, ...(groupWorkspaceData?.nodes ?? [])];
    const data = {
      ...groupWorkspaceData,
      nodes: newList,
    };
    dispatch(
      actions.setNewGroup({
        workspaceData: data,
        flagRows: [newItem],
      }),
    );
  };

  const setUpdatedItem = updatedItem => {
    const newList = groupWorkspaceData?.nodes?.map(item => {
      if (item?.id === updatedItem?.id) {
        return { ...item, ...updatedItem };
      }
      return item;
    });

    const data = {
      ...groupWorkspaceData,
      nodes: newList,
    };
    dispatch(
      actions.setUpdatedGroup({
        updatedItem,
        workspaceData: data,
        flagRows: [updatedItem],
      }),
    );
  };

  return {
    refreshWorkspaceData,
    getGroupList,
    getGroupById,
    resetItemDetails,
    createGroup,
    modifyUserGroupMapping,
    deleteGroup,
    createUserGroupMapping,
    deleteUserGroupMapping,
    setNewItem,
    setUpdatedItem,
    modifyGroup,
    flagRows,
    groupDetails,
    groupWorkspaceData,
    pendingModifyUserGroupMappingMutation,
    pendingGetGroupList,
    pendingCreateUserGroupMapping,
    pendingCreateGroupMutation,
    pendingDeleteUserGroupMappingMutation,
    pendingDeleteGroupMutation,
    pendingModifyGroupMutation,
  };
}
