export function serializePlaceAddressPayload(selectedPlace) {
  const {
    address,
    country,
    countryCode,
    mapId,
    locality,
    longitude,
    latitude,
    shortCode,
    postCode,
    district,
    region,
    regionCode,
    place,
    placeName,
  } = selectedPlace;

  return {
    address1: address,
    placeName,
    mapId,
    longitude,
    latitude,
    shortCode,
    postCode,
    district,
    locality,
    region,
    regionCode,
    country,
    countryCode,
    place,
  };
}

export const defaultTGERateAddress = {
  placeName: 'New Zealand',
  mapId: 'country.8877',
  longitude: 171.779900195937,
  latitude: -41.8388752215127,
};
