import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import tw, { styled } from 'twin.macro';

const StyledWrapper = styled.div`
  ${tw`w-full relative text-center items-center justify-center`}
  .anticon {
    vertical-align: baseline;
  }
`;

export default function CheckedCell(props: any) {
  const { value } = props;

  return (
    <StyledWrapper>
      {value ? (
        <FontAwesomeIcon
          icon={faCheck}
          style={{ fontSize: 16, color: 'blue' }}
        />
      ) : null}
    </StyledWrapper>
  );
}
