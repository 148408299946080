import { ICellEditorParams } from 'ag-grid-community';
import { Select } from 'antd';
import {
  RateCommodityFilterInput,
  useGetRateCommoditiesLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import useGenericCodeLists from 'context/genericCodeLists/hooks';
import useAuthentication from 'context/security_authentication/hook';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

interface ISelectCellParams extends ICellEditorParams {
  value: string;
}

export default forwardRef((props: ISelectCellParams, ref) => {
  // = ============================= init ===========================//
  const [value, setValue] = useState(props.value);
  const [options, setOptions] = useState(null);

  const [rateCommodityList, setRateCommodityList] = useState(null);
  const typingTimeoutRef = useRef(null);

  const { currentAccount } = useAuthentication();
  const { genericCodeListData } = useGenericCodeLists();

  // = ============================= hanlder ===========================//

  const handleSearch = value => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      const variables = {
        filters: {
          subscriberOrganizationId: {
            eq:
              currentAccount?.organizationOrganizationMapping
                ?.childOrganizationId,
          },
          name: { contains: value },
          isDeleted: { neq: true },
        } as RateCommodityFilterInput,
        cursor: null,
        order: { id: 'DESC' },
      };

      getRateCommodities(variables);
    }, 300);
  };

  const handleOnChange = value => {
    setValue(value);
    const seletedItem = options?.find(item => item.value === value);
    if (seletedItem) {
      props.parseValue(seletedItem?.id);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        const valueFormat = rateCommodityList?.find(item => item.id === value);
        if (valueFormat) {
          return valueFormat.id;
        }

        return props.value;
      },
      afterGuiAttached: () => {
        setValue(props.value);
      },
    };
  });

  // = ============================= data processing ===========================//

  const [getRateCommodityLazyQuery] = useGetRateCommoditiesLazyQuery({
    onCompleted: response => {
      if (response?.rateCommodities?.nodes) {
        setRateCommodityList(response?.rateCommodities?.nodes);
        const listOptions = response.rateCommodities.nodes.map(dataItem => {
          const bookingTypeName = genericCodeListData?.bookingTypes?.find(
            item => item.id === dataItem?.bookingTypeId,
          )?.name;
          if (dataItem?.id) {
            return {
              value: dataItem?.id,
              label: (
                <p className="capitalize">
                  {dataItem?.name}
                  {bookingTypeName && <i>{bookingTypeName}</i>}
                </p>
              ),
            };
          }
          return undefined;
        });
        setOptions(listOptions);
      }
    },
  });

  const getRateCommodities = variables => {
    getRateCommodityLazyQuery({
      variables,
    });
  };

  // = ============================= render components ===========================//

  const renderSearch = () => {
    return (
      <Select
        style={{ width: '100%' }}
        value={value}
        onSearch={handleSearch}
        onChange={handleOnChange}
        bordered={false}
        allowClear
        options={options}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option: any) =>
          option?.label?.props?.children[0]
            ?.toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
      />
    );
  };

  // = ============================= main render ===========================//

  return <>{renderSearch()}</>;
});
