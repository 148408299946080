import { GridOptions, SelectionChangedEvent } from 'ag-grid-community';
import {
  Asset,
  AssetFilterInput,
} from 'api/graphql/generated/serviceTypesAndHooks';
import DefaultLayout from 'components/Layout/DefaultLayout';
import VehicleForm from 'containers/Assets/Vehicles/DetailScreen';
import { vehicleScreenConfig } from 'containers/Assets/Vehicles/MainScreen/config';
import useApp from 'context/app/hooks';
import useVehicles from 'context/vehicles/hooks';
import { actions, reducer, sliceKey } from 'context/workspace/slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import { vehicleGridOptions } from './gridOptions';

export default () => {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();
  const { openDialog, closeDialog } = useApp();
  const {
    vehicleWorkpaceData,
    flagRows,
    selectedItems,
    getVehicleList,
    refreshWorkspaceData,
    deleteAsset,
    setSelectedItems,
  } = useVehicles();

  function _createNewVehicle() {
    openDialog({
      content: <VehicleForm afterValidation={closeDialog} />,
      dialogProps: {
        closable: false,
        height: '523px',
        width: '720px',
      },
    });
  }

  function _viewVehicleRecord(record: Asset) {
    openDialog({
      content: <VehicleForm vehicle={record} afterValidation={closeDialog} />,
      dialogProps: {
        closable: false,
        height: '523px',
        width: '720px',
      },
    });
  }

  const handleDeleteAsset = async (ids: number[]) => {
    const result = await deleteAsset(ids);
    let message = 'Deleted vehicle(s) successfully';

    if (!result?.data?.deleteAssets) {
      message = 'Cannot delete selected vehicle(s)';
    }

    return {
      message,
      data: result?.data?.deleteAssets,
    };
  };

  const gridOptions = {
    ...vehicleGridOptions,
    onSelectionChanged: (event: SelectionChangedEvent) => {
      const { api } = event;
      const records = [];
      const selectedRows = api.getSelectedRows();
      api.forEachNodeAfterFilter((rowNode, _index) => {
        if (selectedRows.find(row => row.id === rowNode.data.id))
          records.push(rowNode.data);
      });
      setSelectedItems([...records]);
    },
  } as GridOptions;

  useEffect(() => {
    dispatch(
      actions.setWorkspace({
        workspace: {
          ...vehicleScreenConfig,
          gridOptions,
        },
        query: {
          cursor: null,
          filters: {
            isDeleted: { neq: true },
          } as AssetFilterInput,
          order: { id: 'DESC' },
        },
      }),
    );
  }, []);

  return (
    <DefaultLayout
      gridOptions={gridOptions}
      data={vehicleWorkpaceData}
      flagRows={flagRows}
      selectedItems={selectedItems}
      onGetDataList={getVehicleList}
      onRefreshDataList={refreshWorkspaceData}
      onCreateNew={_createNewVehicle}
      onViewRecord={_viewVehicleRecord}
      onDelete={handleDeleteAsset}
    />
  );
};
