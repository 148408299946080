import { create } from 'zustand';

interface IState {
  isSaving: boolean;
}

interface IActions {
  setIsSaving: (status: boolean) => void;
  reset: () => void;
}

const useGroupsStore = create<IState & IActions>(set => ({
  isSaving: false,

  setIsSaving: (status: boolean) =>
    set({
      isSaving: status,
    }),
  reset: () =>
    set({
      isSaving: false,
    }),
}));

export default useGroupsStore;
