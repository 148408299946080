import { PayloadAction } from '@reduxjs/toolkit';
import { IDialog } from 'components/Dialog/types';
import { createSlice } from '../../utils/@reduxjs/toolkit';
import { ContainerState, ModalContent, ScreenConfig } from './types';

export const initialState: ContainerState = {
  screenConfig: null as ScreenConfig,
  dialog: { params: null },
  modals: {},
  selectedModalContent: undefined,
};

const storeSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setScreenConfig: (state, action: PayloadAction<any>) => {
      const { screenConfig } = action.payload;
      state.screenConfig = screenConfig;
    },
    showModal: (state, action: PayloadAction<ModalContent>) => {
      const { module } = action.payload;
      state.modals[module] = action.payload;
    },
    closeModal: (state, action) => {
      state.modals = action.payload;
    },
    openDialog: (state, action: PayloadAction<IDialog>) => {
      // @ts-ignore
      state.dialog.params = action.payload;
    },

    closeDialog: state => {
      state.dialog.params = null;
    },
  },
});
export const selectScreenConfig = state => state.app.screenConfig;
export const selectModalContent = state => state.app.selectedModalContent;
export const selectModals = state => state.app.modals;
export const selectDialog = state => state.app.dialog;

export const { actions, reducer, name: sliceKey } = storeSlice;
