import { AddAddressInput } from 'api/graphql/generated/serviceTypesAndHooks';
import { create } from 'zustand';

interface IState {
  selectedFromPlace: Partial<AddAddressInput> | null;
  selectedToPlace: Partial<AddAddressInput> | null;
  isSaving: boolean;
}

interface IActions {
  setSelectedFromPlace: (address: Partial<AddAddressInput>) => void;
  setSelectedToPlace: (address: Partial<AddAddressInput>) => void;
  setIsSaving: (status: boolean) => void;
  resetFromPlace: () => void;
  resetToPlace: () => void;
}

const useRateStore = create<IState & IActions>((set, _get) => ({
  isSaving: false,
  selectedFromPlace: null,
  selectedToPlace: null,
  setSelectedFromPlace: (address: any) => set({ selectedFromPlace: address }),
  setSelectedToPlace: (address: any) => set({ selectedToPlace: address }),
  setIsSaving: (status: boolean) => set({ isSaving: status }),
  resetFromPlace: () =>
    set({
      selectedFromPlace: null,
    }),
  resetToPlace: () =>
    set({
      selectedToPlace: null,
    }),
}));

export default useRateStore;
