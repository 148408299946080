import { Empty, Layout, Typography } from 'antd';
import {
  FafGroupRateFilterInput,
  OrganizationOrganizationMappingFilterInput,
} from 'api/graphql/generated/serviceTypesAndHooks';
import RateGrid from 'containers/Financial/FafGroup/MainScreen/Components/RateGrid';
import {
  actions,
  selectBookingRates,
  selectFafGroupCustomers,
  selectFafGroupRates,
} from 'context/financials_rate/slice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GroupList from './Components/GroupList';

type Props = Readonly<{
  useConfig?: any;
  useDetailConfig: any;
  onCreateNewRate?: () => void;
  onViewRate?: (record: any) => void;
  onCreateNewCustomer?: () => void;
  onModifyFafGroup?: () => void;
}>;

const { Title } = Typography;

export default (props: Props) => {
  //= =========initial===========//
  const { useConfig, useDetailConfig } = props;
  const [selectedFafGroupNewItem, setSelectedFafGroupNewItem] = useState(null);
  const [rateList, setRateList] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const {
    queryFafGroupNewList,
    fafGroupNewList,
    selectedFafGroupNew,
  } = useSelector(selectBookingRates);
  const ratesFromRedux = useSelector(selectFafGroupRates);
  const customerFromRedux = useSelector(selectFafGroupCustomers);
  const dispatch = useDispatch();

  const { Content, Sider } = Layout;

  // ========= event handlers=============//

  const menuClickHandler = async e => {
    const id = Number(e?.key);
    const selectedFafGroupNewItem = fafGroupNewList.find(x => x.id === id);
    if (selectedFafGroupNewItem) {
      setSelectedFafGroupNewItem(selectedFafGroupNewItem);
      dispatch(
        actions.setSelectedFafGroupNew({
          selectedFafGroupNew: selectedFafGroupNewItem,
        }),
      );
    }
  };

  // ============ useEffects ============//

  useEffect(() => {
    if (queryFafGroupNewList)
      useConfig.getFafGroupNewList(queryFafGroupNewList);
  }, [queryFafGroupNewList]);

  useEffect(() => {
    if (selectedFafGroupNew) {
      setSelectedFafGroupNewItem(selectedFafGroupNew);

      useConfig.getRatesByFafGroupNewId({
        fafGroupNewId: selectedFafGroupNew.id,
        filters: {
          isDeleted: { neq: true },
        } as FafGroupRateFilterInput,
        order: { id: 'DESC' },
      });

      useConfig.getCustomersByFafGroupNewId({
        fafGroupNewId: selectedFafGroupNew.id,
        filters: {
          isDeleted: { neq: true },
        } as OrganizationOrganizationMappingFilterInput,
        order: { id: 'DESC' },
      });
    } else {
      setSelectedFafGroupNewItem(null);
      useConfig.getFafGroupNewList(queryFafGroupNewList);
    }
  }, [selectedFafGroupNew]);

  useEffect(() => {
    setRateList(ratesFromRedux);
  }, [ratesFromRedux]);

  useEffect(() => {
    setCustomerList(customerFromRedux);
  }, [customerFromRedux]);

  //= =========== render components ============== //

  return (
    <Layout>
      <Sider width={320} theme="light">
        <GroupList
          height="calc(50vh)"
          onChange={menuClickHandler}
          rateGroupList={fafGroupNewList}
          title="FAF Group"
          onHandleFafGroupSetting={props.onModifyFafGroup}
        />
      </Sider>
      <Content>
        {selectedFafGroupNewItem ? (
          <div className="overflow-y-auto max-h-[95vh]">
            {selectedFafGroupNewItem.description && (
              <Title className="p-2 my-0" level={4}>
                <span>Description: </span>
                <span className="font-normal">
                  {selectedFafGroupNewItem.description}
                </span>
              </Title>
            )}
            {Object.keys(useDetailConfig)?.map(fieldKey => {
              const field = useDetailConfig[fieldKey];
              return (
                <div className="p-2" key={fieldKey}>
                  <Title level={4}>{field?.header}</Title>
                  <RateGrid
                    gridConfig={field?.gridConfg}
                    recordValue={fieldKey === 'rate' ? rateList : customerList}
                    gridHeight={field?.initGridHeight}
                    onCreateNew={
                      fieldKey === 'rate'
                        ? props.onCreateNewRate
                        : props.onCreateNewCustomer
                    }
                    onViewRecord={fieldKey === 'rate' ? props.onViewRate : null}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <Empty />
        )}
      </Content>
    </Layout>
  );
};
