import { GridOptions } from 'ag-grid-community';
import { CustomCell, DeleteRow, ViewRow } from 'components/ReactGrid/renderers';

export const defaultGridOptions: GridOptions = {
  headerHeight: 40,
  groupSelectsChildren: false,
  getContextMenuItems: null,
  rowSelection: 'multiple',

  icons: {
    group: '<i class="fa fa-users"/>',
  },

  suppressMultiRangeSelection: true,
  rowHeight: 24,
  rowStyle: { borderWidth: 1, borderColor: '#f0f0f0' },

  // allows copy / paste using cell ranges
  enableRangeSelection: true,

  // enables the fill handle
  enableFillHandle: true,

  // enables undo / redo
  undoRedoCellEditing: true,
  undoRedoCellEditingLimit: 20,
  components: {
    DeleteRow,
    ViewRow,
    CustomCell,
  },
  // enables flashing to help see cell changes
  rowDragManaged: true,
  defaultColDef: {
    enablePivot: true,
    enableValue: true,
    toolPanelClass: params => {
      const colState = params.columnApi
        ?.getColumnState()
        ?.find(col => col.colId === params.column.getColId());
      return colState?.pinned ? 'pinned-col' : null;
    },
    initialHide: false,
    maxWidth: 500,
    resizable: true,
    sortable: true,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eText" class="ag-header-cell-text break-normal" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order pb-0"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>',
    },
  },
} as GridOptions;
