import { Select } from 'antd';
import { useGetDivisionsLazyQuery } from 'api/graphql/generated/serviceTypesAndHooks';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import WithFormHooksController from './WithFormHooksController';

type Props = Readonly<{
  control: any;
  name?: string;
  size?: 'small' | 'middle' | 'large';
  preselected?: any;
}>;

export default function FormControlledDivisionSelect(props: Props) {
  const { control, name, size = 'middle', preselected } = props;

  const [options, setOptions] = useState(null);
  const [selectedValue, setSelectedValue] = useState(
    control._defaultValues?.divisionId || preselected?.id,
  );

  const [getDivisions, { loading }] = useGetDivisionsLazyQuery({
    onCompleted: (response: any) => {
      const {
        allDivisions: { nodes },
      } = response;

      if (nodes?.length) {
        const options = nodes.map((data: any) => {
          return {
            label: `${data?.name}`,
            value: data.id,
          };
        });
        const defaultOption = control._defaultValues?.division;
        if (defaultOption) {
          addOptionFunction(options, defaultOption);
        }
        setOptions(options);
      }
    },
  });

  const addOptionFunction = (options: any, defaultOption: any) => {
    const existedOption = options.find(
      (option: any) => option.value === defaultOption.id,
    );
    if (!existedOption) {
      options.push({
        label: `${defaultOption?.name}`,
        value: defaultOption.id,
      });
    }
  };

  const _debouncedSearch = (value: string = '') => {
    const variables = {
      filters: { name: { contains: value }, isDeleted: { neq: true } },
    };

    getDivisions({
      variables,
    });
    return true;
  };

  useEffect(() => {
    const variables = {
      filters: { isDeleted: { neq: true } },
      cursor: null,
    };

    getDivisions({
      variables,
    });
  }, []);

  return (
    <WithFormHooksController
      name={name}
      control={control}
      component={
        <Select
          showSearch
          allowClear
          placeholder="Search division"
          value={selectedValue}
          size={size}
          onSelect={_value => {
            setSelectedValue(_value);
          }}
          loading={loading}
          popupClassName="certain-category-search-dropdown"
          options={options}
          filterOption={(input, option) =>
            option?.label
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onSearch={debounce(_debouncedSearch, 400)}
        />
      }
    />
  );
}
