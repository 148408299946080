// 1 upper case letter, 1 number and 1 special character
export const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\-\\/]).{8,}$/;
export const emailRegEx = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

export function isValidEmail(message) {
  return this.matches(emailRegEx, {
    name: 'email',
    message,
    excludeEmptyString: true,
  });
}

export function isStrongPassword(message) {
  return this.matches(passwordRegEx, {
    name: 'password',
    message,
    excludeEmptyString: true,
  });
}
