export const BookingStatusTypes = {
  BOOK: 1,
  NEW: 2,
  ALL: 3,
  ACC: 4,
  PUP: 5,
  UPL: 6,
  OFL: 7,
  DEL: 8,
  PLN: 9,
  TEST: 10,
  UPK: 11,
  POD: 12,
  RDY: 13,
  HOLD: 14,
  RFP: 15,
  PAK: 16,
  UNALL: 17,
  DEH: 18,
  EXP: 19,
  CANC: 20,
  REJ: 21,
  WEB: 22,
  EDI: 23,
  QTE: 24,
};

export const BookingPlaceTypes = {
  Pickup: 2,
  Activity: 3,
  Delivery: 4,
  Extra: 5,
};

export const BookingTypes = {
  GENERAL: 1,
  CONTAINER: 2,
};

export enum TBookingPlaceTypes {
  Pickup = 2,
  Activity = 3,
  Delivery = 4,
  Extra = 5,
}

export enum TBookingMilestoneTypes {
  REQUIRED = 'REQUIRED',
  ACTUAL = 'ACTUAL',
  AVAILABLE = 'AVAILABLE',
  OTHER = 'OTHER',
}

export const BookingInputFields = {
  items: 'bookingLines',
  places: 'bookingPlaces',
  dgs: 'bookingDangerousGoods',
  surcharges: 'bookingSurcharges',
  milestones: 'bookingMilestones',
};

export const containerSizes = [
  { label: '10', value: 'SIZE_10' },
  { label: '20', value: 'SIZE_20' },
  { label: '25', value: 'SIZE_25' },
  { label: '40', value: 'SIZE_40' },
  { label: '45', value: 'SIZE_45' },
];
