import Field from 'components/Form/Field';
import FormControlledCustomerSelect from 'components/common/FormControlledCustomerSelect';

import { useFormHooks } from 'context/FormHooksContext';
import useRate from 'context/financials_rate/hooks';
import React from 'react';

export default function DetailsForm() {
  const { selectedFafGroupNewCustomerList } = useRate();
  const { control, setValue } = useFormHooks();

  return (
    <div className="grid gap-3 grid-cols-12 h-auto px-3 mt-3">
      <Field label="Customers" isRequired span={24}>
        <FormControlledCustomerSelect
          control={control}
          name="fafGroupNewOrgOrgIds"
          onChange={value => setValue('fafGroupNewOrgOrgIds', value)}
          componentProps={{
            mode: 'multiple',
            allowClear: true,
            showSearch: true,
          }}
          selectedCustomerIds={selectedFafGroupNewCustomerList.map(
            org => org.id,
          )}
        />
      </Field>
    </div>
  );
}
