import { IDialogTrigger } from 'components/Dialog/types';
import React, { useEffect } from 'react';
import useRateStore from '../store';
import MainForm from './MainForm';

export default ({ ...rest }: IDialogTrigger) => {
  const formTitle = 'Update Group Setting';
  const [setIsSaving] = useRateStore(state => [state.setIsSaving]);

  useEffect(() => {
    setIsSaving(false);
  }, []);

  return <MainForm {...rest} title={formTitle} />;
};
