import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'antd';
import React from 'react';

type Props = Readonly<{
  defaults: any[];
  createView: (view) => void;
}>;

function AddView(props: Props) {
  const { defaults, createView } = props;
  if (defaults?.length) {
    const items = defaults.map((item, idx) => ({
      key: idx,
      label: `${item.viewName}`,
      onClick: () => createView(item),
    }));
    return (
      <Dropdown menu={{ items }}>
        <button
          type="button"
          className="flex px-3 h-10 items-center focus:outline-none ml-1 rounded-md hover:bg-gray-100 text-action-iconDefault hover:text-gray-700 bg-transparent"
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            style={{
              fontSize: '16px',
              color: '#EE5919',
              fontWeight: 900,
            }}
          />
          <span className="ml-2 min-w-max">New View </span>
        </button>
      </Dropdown>
    );
  }
  return (
    <button
      type="button"
      className={`
      flex px-3 h-10 items-center focus:outline-none ml-1 rounded-md bg-gray-50 hover:bg-gray-100 text-action-iconDefault hover:text-gray-700 bg-transparent}`}
      onClick={() => createView(null)}
    >
      <FontAwesomeIcon
        icon={faPlusCircle}
        style={{
          fontSize: '16px',
          color: '#EE5919',
          fontWeight: 900,
        }}
      />
      <span className="ml-2 min-w-max">New View </span>
    </button>
  );
}

export default AddView;
