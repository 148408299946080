import {
  faArrowUpRightFromSquare,
  faFilter,
  faFilterCircleXmark,
  faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import { message } from 'antd';
import DivisionSelect from 'components/Layout/TopWidgets/DivisionSelectFilter';
import { ModuleTypes } from 'components/config/types/modules';
import React, { useRef } from 'react';
import { GenericAction } from '../../ToolbarActions/ActionButtons';
import BookedDateRangePicker from './BookedDateRangePicker';

type Props = Readonly<{
  module?: string;
  fields?: any[];
  visible?: boolean;
  onApplyFilter?: (query: any) => void;
  onClearFilter?: () => void;
}>;

function Filters(props: Props) {
  // = ============================= init ===========================//
  const { module, fields, visible, onApplyFilter, onClearFilter } = props;
  const divisonRef = useRef<any>(null);
  const bookingPickerRef = useRef<any>(null);

  async function handleExportInvoice() {
    const dateRange = bookingPickerRef?.current?.getSelectedRange();
    const input = { startdate: dateRange?.gte, enddate: dateRange?.lte };
    const result = await fetch(
      'https://prod-07.australiaeast.logic.azure.com:443/workflows/19798845ac3c4584b0ba5ccccdcc48ef/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=RkOkFE_F2iF47nS_ctiF_MereECVdYSGvHexw-qEWF4',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(input),
      },
    );
    if (result.status === 202) {
      message.success('Exporting invoice successfully!');
    } else {
      message.error('Something went wrong, Exporting invoice failed');
    }
  }
  // ============================== main render ===========================//

  return (
    <>
      {fields ? (
        <>
          {visible && (
            <>
              <span className="flex mx-2 mr-3 h-[16px] w-[1px] bg-gray-200" />{' '}
              <DivisionSelect ref={divisonRef} />
            </>
          )}
          <span className="flex mx-2 mr-3 h-[16px] w-[1px] bg-gray-200" />
          <BookedDateRangePicker ref={bookingPickerRef} config={fields} />
          <div className="flex items-center ml-1">
            <GenericAction
              icon={faFilter}
              tooltip="Apply Date filter"
              onClick={() => {
                onApplyFilter?.({
                  dateRangePicker: {
                    ...bookingPickerRef?.current?.getSelectedRange(),
                  },
                  dateTypeSelector: bookingPickerRef?.current?.getDateFilterType(),
                  divisionIds: divisonRef?.current?.getSelectedDivisions(),
                });
              }}
            />
            <GenericAction
              icon={faFilterCircleXmark}
              tooltip="Clear filter"
              onClick={() => {
                bookingPickerRef?.current?.reset();
                divisonRef?.current?.reset();
                onClearFilter?.();
              }}
            />
            <GenericAction
              icon={faRotateRight}
              tooltip="Refresh"
              onClick={() =>
                onApplyFilter?.({
                  dateRangePicker: {
                    ...bookingPickerRef?.current?.getSelectedRange(),
                  },
                  dateTypeSelector: bookingPickerRef?.current?.getDateFilterType(),
                  divisionIds: divisonRef?.current?.getSelectedDivisions(),
                })
              }
            />

            {module === ModuleTypes.Invoice.value && (
              <GenericAction
                icon={faArrowUpRightFromSquare}
                tooltip="Export invoice"
                onClick={handleExportInvoice}
              />
            )}
          </div>
          <span className="flex mx-2 h-[16px] w-[1px]  bg-gray-200" />
        </>
      ) : null}
    </>
  );
}

export default Filters;
