import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup
  .object()
  .shape({
    fafGroupNewOrgOrgIds: yup.array().min(1, 'Please select customer(s).'),
  })
  .required();
export { schema };

export default yupResolver(schema);
