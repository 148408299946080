import { Collapse } from 'antd';
import { styled } from 'twin.macro';

export const StyledCollapse = styled(Collapse)`
  border: 0;
  border-radius: 0;

  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0;
  }
  .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
    background: white;
  }

  .ant-collapse-header-text {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-collapse-item {
    border: 0;
  }

  .ant-collapse-header {
    border-radius: 0 !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  .ant-collapse-item > .ant-collapse-content {
    border: 0;
  }

  .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
  }

  .anticon svg {
    width: 16px;
    height: 20px;
  }
`;
