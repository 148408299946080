import { ICellRendererParams } from 'ag-grid-community';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

const StyledWrapper = styled.div`
  ${tw`w-full relative text-center items-center justify-center`}
  .anticon {
    vertical-align: baseline;
  }
`;

export default function CheckBoxCell(props: Readonly<ICellRendererParams>) {
  // eslint-disable-next-line react/prop-types
  const [value, setValue] = useState(props.value);

  const onChange = (event: CheckboxChangeEvent) => {
    setValue(event.target.checked);
    props.setValue(event.target.checked);
  };

  return (
    <StyledWrapper>
      <Checkbox onChange={onChange} checked={value} />
    </StyledWrapper>
  );
}
