import {
  useCancelInvoiceMutation,
  useGetInvoiceLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { ERROR_MESSAGE } from 'helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  actions,
  reducer,
  selectFlagRows,
  selectItemDetails,
  selectSelectedItems,
  selectSubModals,
  selectWorkspaceData,
  sliceKey,
} from './slice';

export default function useProduct() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();

  const invoiceWorkpaceData = useSelector(selectWorkspaceData);
  const bookingDetails = useSelector(selectItemDetails);
  const selectedItems = useSelector(selectSelectedItems);
  const subModals = useSelector(selectSubModals);

  const flagRows = useSelector(selectFlagRows);

  const [getInvoiceListQuery] = useGetInvoiceLazyQuery({
    onCompleted: response => {
      let data;
      if (response?.invoices?.nodes) {
        const nodes = response?.invoices?.nodes;
        let newList = nodes;
        if (
          invoiceWorkpaceData?.nodes?.length > 0 &&
          response.invoices.pageInfo.hasPreviousPage
        ) {
          newList = [...(invoiceWorkpaceData?.nodes ?? []), ...nodes];
        }
        data = {
          ...response?.invoices,
          nodes: newList,
        };

        dispatch(
          actions.setInvoiceList({
            workspaceData: { ...data, loading: false, error: null },
          }),
        );
      }
    },
    onError: () => {
      dispatch(
        actions.setInvoiceList({
          workspaceData: {
            nodes: [],
            loading: false,
            error: ERROR_MESSAGE.LOADING_DATA_ERROR,
          },
        }),
      );
    },
  });

  const getInvoiceList = ({ filters, cursor, order }) => {
    return getInvoiceListQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const refreshWorkspaceData = () => {
    dispatch(
      actions.setInvoiceList({
        workspaceData: null,
      }),
    );
  };

  const resetItemDetails = () => {
    dispatch(
      actions.setItemDetails({
        itemDetails: null,
      }),
    );
  };

  const [
    cancelInvoiceMutation,
    { loading: pendingCancelInvoice },
  ] = useCancelInvoiceMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const cancelInvoices = invoiceIds => {
    return cancelInvoiceMutation({
      variables: {
        input: {
          invoiceIds,
        },
      },
    });
  };

  const setSelectedItems = rows => {
    dispatch(
      actions.setSelectedItems({
        selectedItems: rows,
      }),
    );
  };

  return {
    refreshWorkspaceData,
    resetItemDetails,
    getInvoiceList,
    cancelInvoices,
    setSelectedItems,
    pendingCancelInvoice,
    bookingDetails,
    selectedItems,
    subModals,
    flagRows,
    invoiceWorkpaceData,
  };
}
