import { yupResolver } from '@hookform/resolvers/yup';
import { isStrongPassword, isValidEmail } from 'helpers/schema-validation/yup';
import * as yup from 'yup';

yup.addMethod<yup.StringSchema>(
  yup.string,
  'isStrongPassword',
  isStrongPassword,
);

yup.addMethod<yup.StringSchema>(yup.string, 'isValidEmail', isValidEmail);

const conditionalSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password required')
    .min(8, 'Password must be at least 8 characters')
    // @ts-ignore
    .isStrongPassword(
      'Password must contain at least 1 uppercase, 1 lowercase, 1 number, and 1 special character',
    ),
});

const schema = yup
  .object({
    userName: yup.string().required({ message: 'Display Name required' }),
    email: yup
      .string()
      .required({ message: 'Email required' })
      // @ts-ignore
      .isValidEmail({ message: 'Invalid Email format' }),
  })
  .required();

export { conditionalSchema, schema };
export default yupResolver(schema);
