import { message } from 'antd';
import { IDialogTrigger } from 'components/Dialog/types';
import { OrganizationType } from 'components/config/types/common';
import { FormHooksProvider } from 'context/FormHooksContext';
import useOrganization from 'context/organizations/hooks';
import useAuthentication from 'context/security_authentication/hook';
import { getLocationAddress } from 'helpers/getLocationAddress';
import React, { useEffect } from 'react';
import MainForm from './MainForm';
import schemaResolver from './schema-resolver';

import usePlacesStore from '../../store';

interface IProps {
  place?: any;
  afterValidation?: (place?: any) => void;
}

export default ({
  place,
  afterValidation,
  ...rest
}: IDialogTrigger & IProps) => {
  const formTitle = `${place ? 'Edit' : 'New'} Customer`;
  const [
    reset,
    selectedPlace,
    setSelectedPlace,
    setIsSaving,
  ] = usePlacesStore(state => [
    state.reset,
    state.selectedPlace,
    state.setSelectedPlace,
    state.setIsSaving,
  ]);
  const { createOrganization, updateOrganization } = useOrganization();
  const { currentAccount } = useAuthentication();

  useEffect(() => reset(), []);

  useEffect(() => {
    if (place) {
      setSelectedPlace({
        isSaving: false,
        ...place.address,
        placeType: 'address',
        addressId: place.addressId,
        id: place.id,
        placeName: getLocationAddress(place.address),
        longitude: place.address.longitude,
        latitude: place.address.latitude,
      });
    }
  }, [place]);

  // eslint-disable-next-line no-underscore-dangle

  async function _onSubmit(formData: any) {
    setIsSaving(true);
    const _place = place;
    if (_place) {
      const modifyOrganizationOrganizationOrganizationMappingInput = {
        id: formData.id,
      };

      const modifyAddressInput = {
        id: formData.addressId,
        address1: formData.address1,
        postCode: formData.postCode,
        district: formData.district,
        accuracy: formData.accuracy,
        neighborhood: formData.neighborhood,
        region: formData.region,
        regionCode: formData.regionCode,
        locality: formData.locality,
        country: formData.country,
        countryCode: formData.countryCode,
        mapId: formData.mapId,
        coordinates: formData.coordinates,
        longitude: formData.longitude,
        latitude: formData.latitude,
        place: formData.place,
        placeType: formData.placeType,
      };

      const modifyAutoDocketInput = {
        id: formData.autoDocketId,
        docketPrefix: formData.docketPrefix,
        rangeStart: formData.rangeStart,
      };

      const contactInputs = formData.contactInputs.map(c => {
        delete c['__typename'];
        return c;
      });

      const modelInputFields = {
        modifyAddressInput,
        modifyAutoDocketInput,
        modifyOrganizationOrganizationOrganizationMappingInput,
        contactInputs,
      };

      const payload = {
        id: formData.id,
        name: formData.name,
        displayName: formData.displayName,
        divisionId: formData.divisionId,
        parentOrganizationId:
          currentAccount?.organizationOrganizationMapping?.parentOrganizationId,
        ...modelInputFields,
      };

      const result = await updateOrganization(payload);
      if (result?.data) {
        message.success('Update Organization Successfully');
        afterValidation?.({
          ...result?.data?.modifyOrganization,
          name: result?.data?.modifyOrganization?.name,
        });
      } else {
        message.error('Cannot update organization');
      }

      setIsSaving(false);
      return;
    }

    onCreate(formData);
  }

  async function onCreate(formData: any) {
    const addAddressInput = {
      ...selectedPlace,
    };

    const addAutoDocketInput = {
      docketPrefix: formData.docketPrefix,
      rangeStart: formData.rangeStart,
    };

    const payload = {
      name: formData.name,
      displayName: formData.displayName,
      organizationTypeId: OrganizationType.Customer,
      parentOrganizationId:
        currentAccount?.organizationOrganizationMapping?.parentOrganizationId,
      addAddressInput,
      addAutoDocketInput,
      contactInputs: formData.contactInputs,
      divisionId: formData.divisionId,
    };

    const result = await createOrganization(payload);
    if (result?.data) {
      const organization = result.data?.addOrganization;
      message.success(`Organization ${organization.name} created`);
      afterValidation?.({
        ...result?.data?.addOrganization,
        name: result?.data?.addOrganization.name,
      });
    } else {
      message.error('Something went wrong, cannot create organization');
    }
    setIsSaving(false);
  }

  function _getFormOptions() {
    let defaultValues = {};
    if (place) {
      defaultValues = {
        id: place.id,
        name: place.name,
        displayName: place.displayName,
        organizationId: getLocationAddress(place.address),
        placeName: getLocationAddress(place.address),
        addressId: place.addressId,
        address1: place.address.address1,
        locality: place.address.addressCodeLocality?.name,
        postCode: place.address.postCode,
        region: place.address.addressCodeRegion?.name,
        regionCode: place.address.addressCodeRegion?.code,
        country: place.address.addressCodeCountry?.name,
        countryCode: place.address.addressCodeCountry?.code,
        latitude: place.address.latitude,
        longitude: place.address.longitude,
        autoDocketId: place.autoDocket?.id,
        docketPrefix: place.autoDocket?.docketPrefix,
        rangeStart: place.autoDocket?.rangeStart,
        contactInputs: place.organizationContactMappings?.map(o => ({
          ...o.contact,
          id: o.contactId,
          isDefaultContact: o.isDefault,
        })),
        divisionId: place.division?.id,
      };
      return {
        opts: { defaultValues, shouldFocusError: false },
      };
    }

    return {
      shouldFocusError: false,
    };
  }
  return (
    <FormHooksProvider resolver={schemaResolver} {..._getFormOptions()}>
      <MainForm
        place={selectedPlace}
        {...rest}
        onSubmit={_onSubmit}
        title={formTitle}
      />
    </FormHooksProvider>
  );
};
