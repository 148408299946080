import { Button } from 'antd';
import Dialog from 'components/Dialog';
import { IDialogHeader } from 'components/Dialog/types';
import WarningAbandon from 'components/common/WarningAbandon';
import WithThemeProvider from 'components/providers/WithThemeProvider';
import RateGroup from 'containers/Financial/Rates/DetailsScreen/Components/RateGroup';
import React from 'react';

type Props = {
  onClose?: () => void;
} & IDialogHeader;

const getHeaderTitle = title => (
  <WithThemeProvider
    token={{ colorPrimaryActive: '#EE5919', colorPrimary: '#ee5919' }}
  >
    <div className="flex h-full w-full">
      <div className="relative">
        <h3 className="text-[18px] font-semibold">{title}</h3>
      </div>
    </div>
  </WithThemeProvider>
);

const Form = ({ onClose, title }: Props) => {
  const [isAbandoning, setIsAbandoning] = React.useState(false);

  // hmm, this form is missing form hooks?
  function _onCancel() {
    if (isAbandoning) {
      onClose();
      return;
    }

    setIsAbandoning(true);
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col w-full h-full">
        <Dialog.Header onClose={onClose} title={getHeaderTitle(title)} />
        <div className="pt-4">
          <RateGroup />
        </div>
      </div>
      <div className="w-full px-4 py-3 border-t border-solid border-gray-200">
        <WithThemeProvider token={{ colorPrimary: '#fff' }}>
          <div className="flex justify-end">
            {isAbandoning ? <WarningAbandon /> : null}
            <Button
              onClick={_onCancel}
              className={
                isAbandoning
                  ? 'bg-gray-400 text-white hover:bg-red-600'
                  : 'bg-gray-100 border-0 text-gray-900 hover:bg-gray-400'
              }
            >
              {isAbandoning ? 'Discard' : 'Cancel'}
            </Button>
          </div>
        </WithThemeProvider>
      </div>
    </div>
  );
};

export default function Index({ ...rest }: Props) {
  return <Form {...rest} />;
}
