import React, { useEffect, useRef, useState } from 'react';
import Map, { MapRef, Popup, ViewState } from 'react-map-gl';
import MarkerPin from './MarkerPin';
import { genericConfig } from './config';

import { IMarker } from './types';

export default ({
  config = genericConfig,
  initialViewState,
  pins = [],
  zoom = 8,
}: {
  config?: any;
  initialViewState?: Partial<ViewState>;
  pins?: Array<Omit<IMarker, 'zoom'> & { id?: string }>;
  zoom?: number;
}) => {
  const mapRef = useRef<MapRef>();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const latitude = initialViewState?.latitude ?? -37.00812673231812;
  const longitude = initialViewState?.longitude ?? 174.88722111163142;

  // https://visgl.github.io/react-map-gl/docs/api-reference/map#initialviewstate
  const mapViewState: Partial<ViewState> = {
    latitude,
    longitude,
    zoom,
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  useEffect(() => {
    const initialPickupLLocation = pins[0]; // pickup location
    const initialLongitude = initialPickupLLocation?.longitude ?? longitude;
    const initialLatitude = initialPickupLLocation?.latitude ?? latitude;
    if (mapLoaded && mapRef.current) {
      mapRef.current.setCenter([initialLongitude, initialLatitude]);
      mapRef.current.setZoom(mapViewState.zoom);
    }
  }, [mapLoaded, latitude, longitude, zoom]);

  return (
    <Map
      ref={mapRef}
      initialViewState={mapViewState}
      {...config}
      onLoad={handleMapLoad}
    >
      {mapLoaded && (
        <>
          {pins.map(
            (item, index) =>
              item?.latitude &&
              item?.longitude && (
                <MarkerPin
                  key={item.id ?? index}
                  location={item}
                  onClick={() => {
                    setSelectedPlace(item);
                  }}
                />
              ),
          )}
          {selectedPlace ? (
            <Popup
              anchor="bottom"
              latitude={selectedPlace?.latitude}
              longitude={selectedPlace?.longitude}
              closeButton={true}
              closeOnClick={false} // Prevent closing on map click
              onClose={() => setSelectedPlace(null)}
              style={{ zIndex: 9999 }}
            >
              <div>
                <h2
                  className="font-bold uppercase"
                  style={{ color: selectedPlace?.color }}
                >
                  {selectedPlace?.name}
                </h2>
                <p>{selectedPlace?.fullAddress}</p>
              </div>
            </Popup>
          ) : null}
        </>
      )}
    </Map>
  );
};
