import { Select } from 'antd';
import usePredefinedData from 'context/predefined_data/hooks';
import React, { useEffect, useState } from 'react';

type Props = Readonly<{
  placeholder?: string;
  selectedOptions: any[];
  onChange: (value: any) => void;
}>;

function DivisionSelect(props: Props) {
  const { onChange, selectedOptions, placeholder } = props;
  const { divisions } = usePredefinedData();
  const [options, setOptions] = useState<any[]>([]);
  const allDivisionIds = divisions.map(item => item.id);

  function handleChange(value) {
    let selectedDivisionIds = value;
    if (value.includes('all')) {
      const divisionIds = value.filter(element => typeof element === 'number');
      if (divisionIds.length !== allDivisionIds.length) {
        selectedDivisionIds = divisionIds;
      }
    }
    onChange(selectedDivisionIds);
  }

  function handleSelectChange(value) {
    let selectedDivisionIds = value;
    if (value === 'all') {
      selectedDivisionIds = ['all', ...allDivisionIds];
      onChange(selectedDivisionIds);
    }
  }

  function handleDeselectChange(value) {
    if (value === 'all') {
      onChange([]);
    }
  }

  useEffect(() => {
    let optionList = [{ value: 'all', label: 'All Divisions' }];
    if (divisions) {
      const divisionOptions = divisions.map(division => {
        return { value: division.id, label: division.name };
      });
      optionList = [...optionList, ...divisionOptions];
      setOptions(optionList);
    }
  }, [divisions]);

  return (
    <Select
      options={options}
      value={selectedOptions}
      allowClear
      placeholder={placeholder}
      style={{ flex: 1 }}
      mode="multiple"
      maxTagCount="responsive"
      defaultValue={selectedOptions}
      onChange={handleChange}
      onDeselect={handleDeselectChange}
      onSelect={handleSelectChange}
    />
  );
}

export default DivisionSelect;
