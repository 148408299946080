import {
  ApolloClient,
  DocumentNode,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { fetchNewToken } from './regenerateToken';

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const authorization = JSON.parse(localStorage.getItem('icosAuth'));
  // return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      'Access-Control-Allow-Origin': '*',
      authorization: `Bearer ${authorization?.accessToken || ''}`,
    },
  };
});
const retryLink = new RetryLink();
// Error link to handle token expiration and refresh

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.extensions?.code === 'AUTH_NOT_AUTHENTICATED') {
        // Token expired or invalid, trigger refresh
        return fetchNewToken(client, operation, forward);
      }
    }
  }
});

let httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GQL_SERVER}`,
});
let uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_GQL_SERVER}`,
});

httpLink = errorLink.concat(retryLink.concat(authLink.concat(httpLink)));
uploadLink = errorLink.concat(retryLink.concat(authLink.concat(uploadLink)));

const getMainDefinition = (query: DocumentNode) =>
  query.definitions.find(
    def =>
      def.kind === 'OperationDefinition' && def.name.value === 'addDocument',
  );

const uploadOrNonUploadLink = split(
  ({ query }) => Boolean(getMainDefinition(query)),
  uploadLink,
  httpLink,
);

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache(),
  link: uploadOrNonUploadLink,
  connectToDevTools: true,
  name: 'icos-web-client',
  version: '1.0.0',

  queryDeduplication: false,

  //  NOTE:
  //  Experimental fetchPolicies
  defaultOptions: {
    /*
    watchQuery
   https://www.apollographql.com/docs/react/api/core/ApolloClient/#apolloclient-functions

   This watches the cache store of the query according to the option
   s specified and returns an ObservableQuery. We can subscribe to this ObservableQuery and receive updated results through a GraphQL observer when the cache store changes.
 */

    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      awaitRefetchQueries: true,
      errorPolicy: 'all',
    },
  },
});
