import {
  useCreateSurchargeMutation,
  useDeleteSurchargeMutation,
  useGetSurchargeLazyQuery,
  useModifySurchargeMutation,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { ERROR_MESSAGE } from 'helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  actions,
  reducer,
  selectFlagRows,
  selectSelectedItems,
  selectWorkspaceData,
  sliceKey,
} from './slice';

export default function useSurcharge() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();
  const selectedItems = useSelector(selectSelectedItems);
  const surchargeWorkpaceData = useSelector(selectWorkspaceData);
  const flagRows = useSelector(selectFlagRows);
  const [
    getSurchargeQuery,
    { loading: pendingSurchargeList },
  ] = useGetSurchargeLazyQuery({
    onCompleted: response => {
      let data;
      if (response?.surcharges?.nodes) {
        const nodes = response.surcharges.nodes.map(dataItem => {
          if (dataItem?.id) {
            return dataItem;
          }
          return undefined;
        });

        let newList = nodes;
        if (
          surchargeWorkpaceData?.nodes?.length > 0 &&
          response.surcharges.pageInfo.hasPreviousPage
        ) {
          newList = [...(surchargeWorkpaceData?.nodes ?? []), ...nodes];
        }
        data = {
          ...response?.surcharges,
          nodes: newList,
        };
      }
      dispatch(
        actions.setSurchargeList({
          workspaceData: { ...data, loading: false, error: null },
        }),
      );
    },
    onError: () => {
      dispatch(
        actions.setSurchargeList({
          workspaceData: {
            nodes: [],
            loading: false,
            error: ERROR_MESSAGE.LOADING_DATA_ERROR,
          },
        }),
      );
    },
  });

  const getSurchargeList = ({ filters, cursor, order }) => {
    getSurchargeQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const refreshWorkspaceData = () => {
    dispatch(
      actions.setSurchargeList({
        workspaceData: null,
      }),
    );
  };

  const [
    createSurchargeMutation,
    { loading: pendingCreateSurcharge },
  ] = useCreateSurchargeMutation({
    onCompleted: response => {
      const result = response?.addSurcharge;
      if (result) {
        setNewItem(result);
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const createSurcharge = input => {
    return createSurchargeMutation({
      variables: {
        input,
      },
    });
  };

  const [
    modifySurchargeMutation,
    { loading: pendingModifySurcharge },
  ] = useModifySurchargeMutation({
    onCompleted: response => {
      const updatedSurcharge = response?.modifySurcharge;
      if (updatedSurcharge) {
        setUpdatedItem(updatedSurcharge);
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifySurcharge = input => {
    return modifySurchargeMutation({
      variables: {
        input,
      },
    });
  };

  const [
    deleteSurchargeMutation,
    { loading: pendingDeleteSurcharge },
  ] = useDeleteSurchargeMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const deleteSurcharge = input => {
    return deleteSurchargeMutation({
      variables: {
        input,
      },
    });
  };

  const setNewItem = newItem => {
    const newList = [newItem, ...(surchargeWorkpaceData?.nodes ?? [])];
    const data = {
      ...surchargeWorkpaceData,
      nodes: newList,
    };
    dispatch(
      actions.setNewSurcharge({
        newItem,
        workspaceData: data,
        flagRows: [newItem],
      }),
    );
  };

  const setUpdatedItem = updatedItem => {
    const newList = surchargeWorkpaceData?.nodes?.map(item => {
      if (item?.id === updatedItem?.id) {
        return { ...item, ...updatedItem };
      }
      return item;
    });
    const data = {
      ...surchargeWorkpaceData,
      nodes: newList,
    };
    dispatch(
      actions.setUpdatedSurcharge({
        updatedItem,
        workspaceData: data,
        flagRows: [updatedItem],
      }),
    );
  };
  const setSelectedItems = rows => {
    dispatch(
      actions.setSelectedItems({
        selectedItems: rows,
      }),
    );
  };
  return {
    refreshWorkspaceData,
    getSurchargeList,
    createSurcharge,
    modifySurcharge,
    setNewItem,
    setUpdatedItem,
    deleteSurcharge,
    setSelectedItems,
    flagRows,
    selectedItems,
    surchargeWorkpaceData,
    pendingCreateSurcharge,
    pendingSurchargeList,
    pendingModifySurcharge,
    pendingDeleteSurcharge,
  };
}
