import { GridOptions } from 'ag-grid-community';

export interface IToolbar {
  actions?: any;
  filters?: any;
  jobs?: any;
}

export interface IWorkspace {
  id: string;
  title: string;
  fields: string[];
  module: any;
  initGridHeight: number;
  filters?: {
    [key: string]: {};
  };
  defaultQuery?: {
    cursor: string;
    filters: any;
    order: any;
  };
  gridOptions?: GridOptions;
  tabs?: {};
  loading: boolean;
  dateQueryFormatter?: any;
  filterEngines?: any;
  getContextMenuItems?: any;
  contextMenuSettings?: any;
  toolbar?: any;
  screenViews?: any;
  pagination?: boolean;
  pageSize?: number;
}

export interface WorkspacesState {
  workspace: IWorkspace;
  query: WorkspaceQuery;
}

export interface WorkspaceQuery {
  filters: any;
  cursor: any;
  order: any;
}

export enum VIEWNAME {
  CONTAINER = 'Container Booking',
  FREIGHT = 'General Booking',
}
