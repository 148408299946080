import { ExcelStyle } from 'ag-grid-community';
import DateFormats from './date';

const ExcelStyles: ExcelStyle[] = [
  {
    id: 'dateType',
    dataType: 'DateTime',
    numberFormat: {
      format: DateFormats.asExportDateTime,
    },
  },
];

export default ExcelStyles;
