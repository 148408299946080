import { ICellRendererParams } from 'ag-grid-community';
import { InputNumber } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

export default forwardRef((props: ICellRendererParams, ref) => {
  const refInput = useRef(null);
  const [value, setValue] = useState(null);

  const handleOnChange = value => {
    setValue(value);
  };

  const valueFormatter = value => {
    let convertedValue;
    if (value === 'ALL ELSE') {
      convertedValue = 999999;
    } else {
      const valueList = value.split(' ');
      convertedValue = Number(valueList[valueList?.length - 1]);
    }
    return convertedValue;
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
    };
  });

  useEffect(() => {
    if (!props.node.rowPinned) {
      const eInput = refInput.current;
      eInput.focus();
    }
  }, []);

  useEffect(() => {
    setValue(valueFormatter(props.value));
  }, [props.value]);

  return (
    <div className="flex flex-row justify-end w-full">
      {!props.node.rowPinned ? (
        <InputNumber
          ref={refInput}
          min={1}
          bordered={false}
          defaultValue={valueFormatter(props?.value)}
          onChange={handleOnChange}
        />
      ) : (
        <span>{props.value}</span>
      )}
    </div>
  );
});
