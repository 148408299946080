import {
  faBan,
  faCopy,
  faFileCsv,
  faFileExcel,
  faFileExport,
  faFileImport,
  faPlusCircle,
  faStop,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, MenuProps, Popconfirm } from 'antd';
import BookingShortcut from 'components/Navigation/Sidebar/BookingShortcut';
import { ModuleTypes } from 'components/config/types/modules';
import useAuthentication from 'context/security_authentication/hook';
import React from 'react';
import tw, { styled } from 'twin.macro';
import { PrimaryAction } from '../../ToolbarActions/ActionButtons';

type Props = Readonly<{
  actions?: any[];
  skipPermission?: boolean;
  module?: string;
  selectedItems?: any[];
  onCreateNew?: () => void;
  onDisable?: () => void;
  onMultipleDelete?: () => void;
  onExcelExport?: () => void;
  onCSVExport?: () => void;
  onDuplicate?: () => void;
}>;

const StyledFormGroup = styled.div`
  ${tw`relative`}
  padding-left: 8px;
`;

const StyledAction = styled.div`
  ${tw`flex items-center`}
`;

function GeneralAction(props: Props) {
  // ===================== init ========================= //
  const {
    actions,
    module,
    selectedItems,
    onCreateNew,
    onDuplicate,
    onDisable,
    onMultipleDelete,
    onExcelExport,
    onCSVExport,
  } = props;

  const { currentAccount, isAllow } = useAuthentication();

  const exportMenuItems: MenuProps['items'] = [
    {
      key: '1',
      label: 'Export to Excel',
      icon: <FontAwesomeIcon icon={faFileExcel} />,
      onClick: () => onExcelExport(),
    },
    {
      key: '2',
      label: 'Export to CSV',
      icon: <FontAwesomeIcon icon={faFileCsv} />,
      onClick: () => onCSVExport(),
    },
  ];

  const renderAction = () => {
    const isAdmin = currentAccount?.userTypeId === 19;

    return (
      <StyledAction className="grid">
        {actions?.map(item => {
          if (item?.skipPermission || isAllow(item?.id, module) || isAdmin) {
            return (
              <div key={item?.id}>
                {
                  {
                    create: (
                      <>
                        {module === ModuleTypes.Booking.value ? (
                          <BookingShortcut as="toolbar" />
                        ) : (
                          <PrimaryAction
                            icon={faPlusCircle}
                            onClick={onCreateNew}
                            tooltip={item.tooltip}
                          />
                        )}
                      </>
                    ),

                    duplicate: (
                      <>
                        {selectedItems?.length === 1 && (
                          <PrimaryAction
                            icon={faCopy}
                            tooltip={item.tooltip}
                            onClick={onDuplicate}
                          />
                        )}
                      </>
                    ),
                    import: (
                      <PrimaryAction
                        icon={faFileImport}
                        tooltip={item.tooltip}
                      />
                    ),
                    export: (
                      <Dropdown
                        className="flex bg-transparent items-center"
                        menu={{ items: exportMenuItems }}
                      >
                        <PrimaryAction icon={faFileExport} />
                      </Dropdown>
                    ),
                    disable: (
                      <PrimaryAction
                        icon={faStop}
                        onClick={onDisable}
                        tooltip={item.tooltip}
                      />
                    ),
                    delete: (
                      <Popconfirm
                        className="flex bg-transparent items-center"
                        placement="bottom"
                        title={'Confirm delete item(s)?'}
                        onConfirm={onMultipleDelete}
                        okText="Yes"
                        cancelText="No"
                      >
                        <PrimaryAction icon={faTrash} tooltip={item.tooltip} />
                      </Popconfirm>
                    ),
                    cancel: (
                      <Popconfirm
                        className="flex bg-transparent items-center"
                        placement="bottom"
                        title={'Confirm cancel item(s)?'}
                        onConfirm={onMultipleDelete}
                        okText="Yes"
                        cancelText="No"
                      >
                        <PrimaryAction icon={faBan} tooltip={item.tooltip} />
                      </Popconfirm>
                    ),
                  }[item?.id]
                }
              </div>
            );
          }
          return null;
        })}
      </StyledAction>
    );
  };

  // = ==================== main render =========================//

  return (
    <StyledFormGroup className="general-action-list">
      {renderAction()}
    </StyledFormGroup>
  );
}

export default GeneralAction;
