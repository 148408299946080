import { styled } from 'twin.macro';

const ThemedButton = styled.button(({ disabled }) => [
  `
  opacity: ${disabled ? 0.3 : 1};
  background-color: white;
  font-weight: 400;
  box-shadow: none;
`,
]);

export default ThemedButton;
