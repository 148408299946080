import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import * as React from 'react';
import ThemedButton from './ThemedButton';

type ButtonProps = React.ComponentProps<typeof ThemedButton> & {
  icon: IconDefinition;
  tooltip?: string;
};

const GenericAction = ({ icon, tooltip = '', ...rest }: ButtonProps) => {
  const ButtomComponent = (
    <ThemedButton
      type="button"
      className="hover:bg-gray-100 text-gray-700  px-[6px] py-[6px] rounded-md focus:ring-0 focus:outline-none text-xs flex items-center mr-1"
      {...rest}
    >
      <FontAwesomeIcon
        icon={icon}
        style={{
          fontSize: '16px',
          color: 'inherit',
        }}
      />
    </ThemedButton>
  );

  if (tooltip) {
    return (
      <Tooltip
        className="flex bg-transparent items-center"
        placement="topLeft"
        title={tooltip}
        color="black"
      >
        <ThemedButton
          type="button"
          className="hover:bg-gray-100 text-gray-700  px-[6px] py-[6px] rounded-md focus:ring-0 focus:outline-none text-xs flex items-center mr-1"
          {...rest}
        >
          <FontAwesomeIcon
            icon={icon}
            style={{
              fontSize: '16px',
              color: 'inherit',
            }}
          />
        </ThemedButton>
      </Tooltip>
    );
  }
  return ButtomComponent;
};

const SecondaryAction = ({
  icon,
  tooltip,
  label,
  disabled,
  onClick,
  loading,
}: {
  icon: IconDefinition;
  tooltip?: string;
  label: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}) => {
  return (
    <span className={`flex ${disabled ? 'hidden' : 'block'}`}>
      <Tooltip
        className="flex bg-transparent items-center"
        placement="topLeft"
        title={tooltip}
        color="black"
      >
        <ThemedButton
          type="button"
          disabled={disabled || loading}
          onClick={onClick}
          className="text-gray-700 px-2 py-2 rounded-md focus:ring-0 m-0 focus:outline-none text-xs flex items-center"
        >
          <FontAwesomeIcon
            icon={icon}
            style={{
              fontSize: '16px',
              color: 'inherit',
              marginRight: '8px',
            }}
          />
          {label}
        </ThemedButton>
      </Tooltip>
    </span>
  );
};

const PrimaryAction = ({ icon, tooltip, ...rest }: ButtonProps) => (
  <Tooltip
    className="flex bg-transparent items-center"
    placement="topLeft"
    title={tooltip}
    color="black"
  >
    <span className="flex items-center justify-center px-2 py-2 focus:outline-none rounded-md bg-white border-gray-200 border-solid border border-opacity-0 hover:shadow-md hover:border hover:border-solid hover:border-opacity-100 text-gray-500 hover:text-gray-800 cursor-pointer">
      <button
        type="button"
        className="flex items-center bg-transparent"
        {...rest}
      >
        <FontAwesomeIcon
          icon={icon}
          style={{
            height: '20px',
            width: '20px',
            color: 'inherit',
          }}
        />
      </button>
    </span>
  </Tooltip>
);

export { GenericAction, PrimaryAction, SecondaryAction };
