import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, MenuProps } from 'antd';
import { PrimaryAction } from 'components/ToolbarActions/ActionButtons';
import Booking from 'containers/Dispatchs/Booking/DetailsScreen';
import useBookingPlacesStore from 'containers/Dispatchs/Booking/DetailsScreen/components/Places/store/places';
import { TDispatchBooking } from 'containers/Dispatchs/Booking/DetailsScreen/types/booking';
import useApp from 'context/app/hooks';
import * as React from 'react';
import { ModalMode } from 'types/modal';

type Props = {
  as?: 'toolbar' | 'quickLink';
  isHidden?: boolean;
};

const BookingShortcut = React.forwardRef(
  ({ as, isHidden = false }: Props, ref) => {
    const { openDialog } = useApp();
    const [initBookingPlaces] = useBookingPlacesStore(state => [
      state.initBookingPlaces,
    ]);

    const items: MenuProps['items'] = [
      {
        key: '1',
        label: 'Create General Booking',
        onClick: () => _createBooking('Freight'),
      },
      {
        key: '2',
        label: 'Create Container Booking',
        onClick: () => _createBooking('Container'),
      },
    ];

    // eslint-disable-next-line no-underscore-dangle
    function _createBooking(bookingType: TDispatchBooking) {
      initBookingPlaces();
      openDialog({
        content: <Booking bookingType={bookingType} mode={ModalMode.CREATE} />,
        dialogProps: {
          height: 90,
        },
      });
    }

    React.useImperativeHandle(ref, () => ({
      createBooking: type => {
        _createBooking(type);
      },
    }));

    if (as === 'toolbar') {
      return (
        <Dropdown className="flex bg-transparent items-center" menu={{ items }}>
          <PrimaryAction icon={faPlusCircle} />
        </Dropdown>
      );
    }

    return (
      <div
        className={`flex justify-center w-full px-8 mb-6 ${
          isHidden ? 'hidden' : 'visible'
        } `}
      >
        <Dropdown menu={{ items }}>
          <button
            type="button"
            style={{ background: '#EE5919' }}
            className="rounded-lg h-10 text-white flex items-center px-3 focus:outline-none w-full justify-between"
          >
            <span className="mr-2 text-ellipsis">New Booking</span>
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{
                fontSize: '16px',
                color: 'inherit',
                fontWeight: 900,
              }}
            />
          </button>
        </Dropdown>
      </div>
    );
  },
);

export default BookingShortcut;
