import {
  faArrowsToDot,
  faBackward,
  faBan,
  faBoxesPacking,
  faCalendarDays,
  faCheckCircle,
  faCheckSquare,
  faCheckToSlot,
  faList,
  faRetweet,
  faShuffle,
  faTruckRampBox,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Popconfirm, Tooltip, message } from 'antd';
import useBookings from 'context/bookings/hooks';
import useInvoicing from 'context/financials_invoicing/hooks';
import useAuthentication from 'context/security_authentication/hook';
import { dispatchActionValidator } from 'helpers/dispatchActionValidator';
import React from 'react';
import { styled } from 'twin.macro';
import { SecondaryAction } from '../../ToolbarActions/ActionButtons';

type Props = Readonly<{
  jobs?: any[];
  selectedItems?: any[];
  module?: string;
  onUprater?: () => void;
  visible?: boolean;
}>;

const StyledActionItem = styled.div`
  .ant-btn-sm {
    border: 0;
    color: ${p => p.theme.primaryButtonTextColor};
    background-color: ${p => p.theme.primaryButtonColor};
    font-size: 12px;
    display: flex;
    align-items: center;
  }
`;

function DispatchActions(props: Props) {
  // = ==================== init =========================//
  const { jobs, module, selectedItems, onUprater, visible } = props;
  const {
    pendingAcceptBooking,
    pendingAllocateBooking,
    pendingUpliftBooking,
    pendingOffloadBooking,
    pendingUnallocateBooking,
    pendingReAllocateBooking,
    pendingCancelBooking,
    pendingConfirmBooking,
    showSubModal,
    acceptBooking,
    upliftBooking,
    offloadBooking,
    unAllocateBooking,
    setUpdatedItem,
    cancelBooking,
    confirmBooking,
  } = useBookings();

  const {
    verifyBooking,
    unverifyBooking,
    reRateBooking,
    invoiceBooking,
    setUpdatedInvoice,
    pendingVerifyBooking,
    pendingReRateBooking,
    pendingInvoiceBooking,
  } = useInvoicing();

  const { isAllow } = useAuthentication();

  const errorMessageContent = (errors, action) => (
    <div>
      <span>{`Cannot ${action} booking.`}</span>
      {errors?.length > 0 ? (
        <div>
          {errors.map(({ extensions }, index) => (
            <p key={`error_${index}`}>{extensions?.message}</p>
          ))}
        </div>
      ) : (
        <p>{`Unknow error, please try again or contact administrator.`}</p>
      )}
    </div>
  );

  // ===================== handler =========================//

  const handleAllocate = () => {
    const actionValidator = dispatchActionValidator('allocate', selectedItems);
    if (actionValidator?.message) {
      message.error(actionValidator.message);
      return;
    }
    showSubModal({
      name: 'selectVehicle',
      visible: true,
    });
  };

  const handleReAllocate = () => {
    const actionValidator = dispatchActionValidator(
      'reallocate',
      selectedItems,
    );
    if (actionValidator?.message) {
      message.error(actionValidator.message);
      return;
    }
    showSubModal({
      name: 'reSelectVehicle',
      visible: true,
    });
  };

  const handleSetDates = () => {
    showSubModal({
      name: 'setDates',
      visible: true,
    });
  };

  const handleBookingAction = async (
    actionType,
    apiCall,
    isInvoiceAction = false,
  ) => {
    if (!isInvoiceAction) {
      const actionValidator = dispatchActionValidator(
        actionType,
        selectedItems,
      );
      if (actionValidator?.message) {
        message.error(actionValidator.message);
        return;
      }
    }

    const bookingIds = selectedItems?.map(item => item.id) as [number];
    const result = await apiCall(bookingIds);

    // Determine whether to handle bookingViews or invoiceOutput based on the action type
    const bookingItems = isInvoiceAction
      ? result?.data?.[`${actionType}Bookings`]?.invoiceOutput
          ?.validBookingViews
      : result?.data?.[`${actionType}Bookings`]?.bookingViews;

    if (bookingItems?.length > 0) {
      message.success(
        `${actionType.replace(/^./, char =>
          char.toUpperCase(),
        )} bookings successfully!`,
      );

      if (isInvoiceAction) {
        setUpdatedInvoice(bookingItems); // Update invoice-related views
      } else {
        setUpdatedItem(bookingItems); // Update booking-related views
      }
    } else {
      message.error({
        content: errorMessageContent(result?.errors, actionType),
        className: 'custom-message-class',
        style: { color: 'red' },
        duration: 3,
      });
    }
  };

  // ===================== API Dispatch Action Handlers  =========================//

  const handleAccept = () => handleBookingAction('accept', acceptBooking);
  const handleUplift = () => handleBookingAction('uplift', upliftBooking);
  const handleOffload = () => handleBookingAction('offload', offloadBooking);
  const handleUnAllocate = () =>
    handleBookingAction('unallocate', unAllocateBooking);
  const handleCancel = () => handleBookingAction('cancel', cancelBooking);
  const handleConfirm = () => handleBookingAction('confirm', confirmBooking);

  // ===================== API Invoice Action Handlers  =========================//
  const handleVerifyBooking = () =>
    handleBookingAction('verify', verifyBooking, true);
  const handleUnverifyBooking = () =>
    handleBookingAction('unverify', unverifyBooking, true);
  const handleReRateBooking = () =>
    handleBookingAction('reRate', reRateBooking, true);
  const handleInvoiceBooking = () =>
    handleBookingAction('invoice', invoiceBooking, true);

  return (
    <div className="flex whitespace-nowrap">
      {jobs?.map(item => {
        const disabled = selectedItems?.length <= 0;
        if (isAllow(item?.id, module, false)) {
          return (
            <StyledActionItem key={item.id}>
              <Tooltip placement="topLeft" title={item.tooltip} color="black">
                {visible
                  ? {
                      setdates: (
                        <SecondaryAction
                          onClick={handleSetDates}
                          disabled={disabled}
                          icon={faCalendarDays}
                          label={item.label}
                          tooltip="Set Dates"
                        />
                      ),
                      // action for confirm "WEB" and "EDI" bookings
                      confirm: (
                        <Popconfirm
                          className="flex bg-transparent items-center"
                          placement="leftBottom"
                          title="Confirm bookings: It changes the status to 'NEW'"
                          onConfirm={handleConfirm}
                          okText="Yes"
                          cancelText="No"
                        >
                          <SecondaryAction
                            disabled={
                              disabled ||
                              !!dispatchActionValidator(
                                'confirm',
                                selectedItems,
                              )?.message
                            }
                            icon={faCheckSquare}
                            label={item.label}
                            loading={pendingConfirmBooking}
                          />
                        </Popconfirm>
                      ),
                      allocate: (
                        <SecondaryAction
                          onClick={handleAllocate}
                          disabled={
                            disabled ||
                            !!dispatchActionValidator('allocate', selectedItems)
                              ?.message
                          }
                          loading={pendingAllocateBooking}
                          icon={faArrowsToDot}
                          label={item.label}
                          tooltip="Allocate booking"
                        />
                      ),
                      accept: (
                        <SecondaryAction
                          tooltip="Accept booking"
                          onClick={handleAccept}
                          disabled={
                            disabled ||
                            !!dispatchActionValidator('accept', selectedItems)
                              ?.message
                          }
                          icon={faCheckCircle}
                          label={item.label}
                          loading={pendingAcceptBooking}
                        />
                      ),
                      uplift: (
                        <SecondaryAction
                          tooltip="Uplift"
                          onClick={handleUplift}
                          disabled={
                            disabled ||
                            !!dispatchActionValidator('uplift', selectedItems)
                              ?.message
                          }
                          icon={faBoxesPacking}
                          label={item.label}
                          loading={pendingUpliftBooking}
                        />
                      ),
                      offload: (
                        <SecondaryAction
                          tooltip="Offload"
                          onClick={handleOffload}
                          disabled={
                            disabled ||
                            !!dispatchActionValidator('offload', selectedItems)
                              ?.message
                          }
                          icon={faTruckRampBox}
                          label={item.label}
                          loading={pendingOffloadBooking}
                        />
                      ),
                      unallocate: (
                        <SecondaryAction
                          tooltip="Unallocate"
                          onClick={handleUnAllocate}
                          disabled={
                            disabled ||
                            pendingUnallocateBooking ||
                            !!dispatchActionValidator(
                              'unallocate',
                              selectedItems,
                            )?.message
                          }
                          icon={faList}
                          label={item.label}
                        />
                      ),
                      reset: (
                        <SecondaryAction
                          tooltip="Reallocate"
                          onClick={handleReAllocate}
                          disabled={
                            disabled ||
                            pendingReAllocateBooking ||
                            !!dispatchActionValidator(
                              'reallocate',
                              selectedItems,
                            )?.message
                          }
                          icon={faShuffle}
                          label={item.label}
                        />
                      ),
                      percentageUprater: (
                        <Button
                          onClick={onUprater}
                          className="w-full"
                          style={{
                            width: '100%',
                            color: 'white',
                            opacity: disabled ? 0.7 : 1,
                          }}
                          size="small"
                          disabled={disabled}
                        >
                          {item.label}
                        </Button>
                      ),
                      // actions for invoicing
                      verify: (
                        <SecondaryAction
                          tooltip="Verify"
                          onClick={handleVerifyBooking}
                          disabled={disabled || pendingVerifyBooking}
                          icon={faCheckToSlot}
                          label={item.label}
                        />
                      ),
                      unverify: (
                        <SecondaryAction
                          tooltip="Unverify"
                          onClick={handleUnverifyBooking}
                          disabled={disabled || pendingInvoiceBooking}
                          icon={faBackward}
                          label={item.label}
                        />
                      ),
                      invoice: (
                        <SecondaryAction
                          tooltip="Invoice"
                          onClick={handleInvoiceBooking}
                          disabled={disabled || pendingInvoiceBooking}
                          icon={faShuffle}
                          label={item.label}
                        />
                      ),
                      rerate: (
                        <SecondaryAction
                          tooltip="ReRate"
                          onClick={handleReRateBooking}
                          disabled={disabled || pendingReRateBooking}
                          icon={faRetweet}
                          label={item.label}
                        />
                      ),
                    }[item.id]
                  : null}
                {item.id === 'cancel' && (
                  <Popconfirm
                    className="flex bg-transparent items-center"
                    placement="leftBottom"
                    title="Confirm cancel booking?"
                    onConfirm={handleCancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <SecondaryAction
                      tooltip="Cancel"
                      disabled={disabled || pendingCancelBooking}
                      icon={faBan}
                      label={item.label}
                    />
                  </Popconfirm>
                )}
              </Tooltip>
            </StyledActionItem>
          );
        }
        return null;
      })}
    </div>
  );
}

export default DispatchActions;
