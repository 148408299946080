export const containerCustomerFields = [
  'orderRef',
  'docket',
  'containerSize',
  'containerNumber',
  'weight',
  'status',
  'pickupSiteName',
  'activitySiteName',
  'deliverySiteName',
  'nextMilestone',
  'containerReleaseNo',
  'containerVesselName',
  'containerVoyageNo',
  'pickupActual',
  'activityActual',
  'deliveryActual',
  'nowAt',
  'goingTo',
  'containerSealNo',
  'bookingType',
];
