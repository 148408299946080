import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// FIXME: runtime error on focus
// if not visible in view

// https://github.com/jquense/yup
const schema = yup
  .object()
  .shape({
    addressFromId: yup.string().required({ message: 'Address From required' }),
    addressToId: yup.string().required({ message: 'Address To required' }),
    startDate: yup.date().required({ message: 'Start Date required' }),
    endDate: yup.date().required({ message: 'End Date required' }),
    serviceTypeId: yup.number().required({ message: 'Address To required' }),
    commodityId: yup.number().required({ message: 'Commodity required' }),
    unitOfMeasureId: yup
      .number()
      .required({ message: 'Unit Of Measure Type required' }),
    addRateLines: yup.array().min(1, { message: 'Rate Line required' }),
  })
  .required();
export { schema };

export default yupResolver(schema);
