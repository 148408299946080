import {
  ModifyUserInput,
  User,
} from 'api/graphql/generated/serviceTypesAndHooks';

export function getPayload(formData: any, user: User = null) {
  if (!user) {
    return {
      addAddressInput: {},
      addUserADB2CInput: {
        userName: formData.userName,
        password: formData.password,
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        mobilePhone: formData.mobile,
        phone: formData.phone,
        divisionIds: [...formData.divisionIds],
      },
      position: formData.position,
    };
  }

  return {
    id: user.id,
    contactId: user.contactId,
    userName: formData.userName,
    position: formData.position,
    divisionIds: [...formData.divisionIds],
    modifyContactInput: {
      id: user.contactId,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      phone: formData.phone,
      mobile: formData.mobile,
      modifyAddressInput: {
        id: user.contact.addressId,
      },
    },
  } as ModifyUserInput;
}
