import { GridOptions } from 'ag-grid-community';
import {
  CheckBoxCell,
  InputCell,
  NumericCell,
} from 'components/ReactGrid/editors';
import { DeleteRow } from 'components/ReactGrid/renderers';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';

export const gridOptions = {
  headerHeight: 36,

  newDataItem: {
    id: 1,
  },
  gridClassName: 'grid-container',
  gridOptions: {
    suppressContextMenu: true,
    enablePivot: true,
    components: {
      CustomCell,
      DeleteRow,
      InputCell,
      NumericCell,
      CheckBoxCell,
    },
    singleClickEdit: true,
    stopEditingWhenCellsLoseFocus: true,
    maintainColumnOrder: true,
    defaultColDef: {
      flex: 1,
      resizable: true,
      sortable: true,
      suppressMenu: true,
      cellRenderer: 'CustomCell',
    },
    columnDefs: [
      {
        headerName: 'Id',
        width: 200,
        flex: 1,
        field: 'id',
        colId: 'id',
        editable: false,
        hide: true,
      },
      {
        headerName: 'First name',
        field: 'firstName',
        colId: 'firstName',
        editable: true,
        width: 150,
      },
      {
        headerName: 'Last name',
        field: 'lastName',
        colId: 'lastName',
        editable: true,
        width: 150,
      },
      {
        headerName: 'Email',
        field: 'email',
        colId: 'email',
        editable: true,
        width: 150,
      },
      {
        headerName: 'Phone',
        field: 'phone',
        colId: 'phone',
        editable: true,
        width: 150,
      },
      {
        headerName: 'Mobile',
        field: 'mobile',
        colId: 'mobile',
        editable: true,
        width: 150,
      },
      {
        headerName: 'Default',
        field: 'isDefaultContact',
        colId: 'isDefaultContact',
        width: 250,
        cellRenderer: 'CheckBoxCell',
      },
      {
        headerName: 'Action',
        colId: 'delAction',
        cellRenderer: 'DeleteRow',
        cellClass: 'cellCenter',
        editable: false,
        filter: false,
        width: 100,
        pinned: 'right',
      },
    ],
  } as GridOptions,
};
