import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import tw, { styled } from 'twin.macro';

export interface Props {
  context?: any;
  data?: any;
  node?: any;
}

const StyledWrapper = styled.div`
  ${tw`w-full relative text-center items-center justify-center`}
  .anticon {
    vertical-align: baseline;
  }
`;

export default (props: Props) => {
  const handleShowEdit = () => {
    const { data } = props;
    props.context.handleViewRecord(data);
  };

  return (
    <StyledWrapper>
      {!props.node.rowPinned ? (
        <FontAwesomeIcon
          icon={faEye}
          onClick={handleShowEdit}
          style={{ color: 'orange', cursor: 'pointer' }}
          size="sm"
        />
      ) : (
        <span />
      )}
    </StyledWrapper>
  );
};
