import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GroupState {
  workspaceData: any;
  flagRows: any[];
  itemDetails: any;
  newItem: any;
  updatedItem: any;
}
export const initialState: GroupState = {
  workspaceData: null,
  flagRows: null,

  itemDetails: null,
  newItem: {},
  updatedItem: {},
};

const storeSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setWorkspaceData: (state, action: PayloadAction<any>) => {
      const { workspaceData } = action.payload;
      state.workspaceData = workspaceData;
    },
    setGroupDetails: (state, action: PayloadAction<any>) => {
      const { itemDetails } = action.payload;
      state.itemDetails = itemDetails;
    },
    setNewGroup: (state, action: PayloadAction<any>) => {
      const { workspaceData, newItem, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.newItem = newItem;
      state.flagRows = flagRows;
    },
    setUpdatedGroup: (state, action: PayloadAction<any>) => {
      const { workspaceData, updatedItem, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.updatedItem = updatedItem;
      state.flagRows = flagRows;
    },
  },
});

export const selectWorkspaceData = state => state.groups.workspaceData;
export const selectFlagRows = state => state.groups.flagRows;
export const selectItemDetails = state => state.groups.itemDetails;

export const { actions, reducer, name: sliceKey } = storeSlice;
