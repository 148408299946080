import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IWorkspace,
  WorkspaceQuery,
  WorkspacesState,
} from 'components/Layout/types';

export const initialState: WorkspacesState = {
  workspace: null as IWorkspace,
  query: {} as WorkspaceQuery,
};

const storeSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    setWorkspace: (state, action: PayloadAction<any>) => {
      const { workspace, query } = action.payload;
      state.workspace = workspace;
      state.query = query;
    },
  },
});

export const { actions, reducer, name: sliceKey } = storeSlice;
