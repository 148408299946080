import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// FIXME: runtime error on focus
// if not visible in view

// https://github.com/jquense/yup
const schema = yup.object().shape({
  placeName: yup
    .string()
    .required({ message: 'Place Detail Name is required' }),
});
export { schema };

export default yupResolver(schema);
