import { Select, SelectProps } from 'antd';
import { useGetCustomersLazyQuery } from 'api/graphql/generated/serviceTypesAndHooks';
import WithFormHooksController from 'components/common/WithFormHooksController';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';

type Props = Readonly<{
  control: any;
  name?: string;
  componentProps?: Partial<SelectProps>;
  onChange?: (value: any) => void;
  selectedCustomerIds?: number[];
}>;

export default function FormControlledCustomerSelect(props: Props) {
  const {
    control,
    name,
    componentProps,
    onChange,
    selectedCustomerIds,
  } = props;
  const [options, setOptions] = useState(null);
  const [getCustomers, { loading }] = useGetCustomersLazyQuery({
    onCompleted: (response: any) => {
      const {
        allOrganizationOrganizationMappings: { nodes },
      } = response;

      if (nodes?.length) {
        const options = nodes.map((data: any) => {
          return {
            label: `${data?.displayName}`,
            value: data.id,
          };
        });

        if (selectedCustomerIds) {
          removeOptionFunction(options, selectedCustomerIds);
        }
        setOptions(options);
      }
    },
  });

  const removeOptionFunction = (
    options: any,
    selectedCustomerIds: number[],
  ) => {
    selectedCustomerIds.forEach(selectedCustomerId => {
      const existedOptionIndex = options.findIndex(
        (option: any) => option.value === selectedCustomerId,
      );

      if (existedOptionIndex !== -1) {
        options.splice(existedOptionIndex, 1);
      }
    });
  };

  const _debouncedSearch = (value: string = '') => {
    getCustomers({
      variables: {
        filters: { displayName: { contains: value }, isDeleted: { neq: true } },
      },
    });
    return true;
  };

  useEffect(() => {
    getCustomers({
      variables: {
        filters: { isDeleted: { neq: true } },
        cursor: null,
      },
    });
  }, []);

  function _onChange(value: any) {
    onChange?.(value);
  }

  return (
    <WithFormHooksController
      control={control}
      name={name}
      component={
        <Select
          {...componentProps}
          optionFilterProp="label"
          loading={loading}
          placeholder="Select customers"
          onChange={_onChange}
          popupClassName="certain-category-search-dropdown"
          options={options}
          filterOption={(input, option) =>
            option?.label
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onSearch={debounce(_debouncedSearch, 400)}
        />
      }
    />
  );
}
