import { Embed, models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import React, { useEffect, useState } from 'react';
import { PowerBIProps } from './types';

export function PowerBI(props: Readonly<PowerBIProps>) {
  const { powerBIData } = props;
  const [powerBIConfiguration, setPowerBIConfiguration] = useState<
    models.IReportEmbedConfiguration
  >({
    type: 'report',
    id: '',
    embedUrl: '',
    accessToken: '',
    tokenType: models.TokenType.Embed,
    settings: {},
  });

  useEffect(() => {
    if (powerBIData) {
      setPowerBIConfiguration({
        ...powerBIConfiguration,
        id: powerBIData?.reportId,
        embedUrl: powerBIData?.embedReport?.embedUrl,
        accessToken: powerBIData?.embedToken?.token,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: true,
            },
          },
          background: models.BackgroundType.Transparent,
        },
      });
    }
  }, [powerBIData]);

  const powerBIEventHandlersMap = new Map([
    ['loaded', function handleLoad() {}],
    ['rendered', function handleRender() {}],
    ['error', function handleError() {}],
  ]);

  return (
    <PowerBIEmbed
      embedConfig={powerBIConfiguration}
      eventHandlers={powerBIEventHandlersMap}
      cssClassName={
        'w-full h-full bg-white rounded-md p-2 shadow-md powerbi-container'
      }
      getEmbeddedComponent={(_embedObject: Embed) => {}}
    />
  );
}
