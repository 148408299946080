import { message } from 'antd';
import { IDialogTrigger } from 'components/Dialog/types';
import {
  FormHooksProvider,
  TFormHooksProviderProps,
} from 'context/FormHooksContext';
import useAuthentication from 'context/security_authentication/hook';
import useGroup from 'context/security_groups/hooks';
import React from 'react';
import useGroupsStore from '../../store';
import MainForm from './MainForm';
import schemaResolver from './schema-resolver';

interface IProps {
  group?: any;
  afterValidation?: (group?: any) => void;
}

export default ({
  group,
  afterValidation,
  ...rest
}: IDialogTrigger & IProps) => {
  const formHeaderTitle = group ? `Edit Group ${group.id}` : 'New Group';
  const formHeaderSub = group ? group.name : null;
  const { currentAccount } = useAuthentication();
  const { createGroup, modifyGroup, setNewItem } = useGroup();

  const setIsSaving = useGroupsStore(state => state.setIsSaving);

  // eslint-disable-next-line no-underscore-dangle
  async function _onSubmit(formData: any) {
    setIsSaving(true);
    const _group = group;
    if (_group) {
      const payload = {
        id: _group.id,
        ...formData,
      };
      const result = await modifyGroup(payload);
      if (result?.data) {
        message.success('Update group Successfully');
        afterValidation?.({
          ...result?.data?.modifyGroup,
        });
      } else {
        message.error('Cannot update group');
      }
      setIsSaving(false);
      return;
    }

    const payload = {
      organizationOwnerId:
        currentAccount?.organizationOrganizationMapping?.childOrganizationId,
      description: formData.description,
      name: formData.name,
    };

    const res = await createGroup(payload);

    if (res?.data.addGroup) {
      setNewItem(res.data?.addGroup);
      message.success(`Group ${formData.name} created successfully`);
      afterValidation();
    } else {
      message.error(`Failed to create ${formData.name} Group`, 3);
    }

    setIsSaving(false);
  }

  function _getFormOptions(): TFormHooksProviderProps {
    let defaultValues = {};
    let options = {};

    if (group) {
      defaultValues = {
        name: group.name,
        description: group.description,
      };

      options = {
        opts: { defaultValues },
      };
    }

    return options;
  }

  return (
    <FormHooksProvider resolver={schemaResolver} {..._getFormOptions()}>
      <MainForm
        group={group}
        onSubmit={_onSubmit}
        title={formHeaderTitle}
        subTitle={formHeaderSub}
        {...rest}
      />
    </FormHooksProvider>
  );
};
