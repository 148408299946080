import { GridApi, SelectionChangedEvent } from 'ag-grid-community';
import { Button } from 'antd';
import {
  AssetFilterInput,
  useGetAssetLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import Modal from 'components/Modal/index';
import AGGrid from 'components/ReactGrid';
import { selectVehicleGridConfig } from 'containers/Dispatchs/Booking/MainScreen/components/SelectVehicleGrid/selectVehicleGridConfig';
import useAuthentication from 'context/security_authentication/hook';
import React, { useEffect, useState } from 'react';
import DivisionSelect from './DivisionSelect';

type Props = Readonly<{
  onNextPlace?: () => void;
  onSelectVehicle?: (value: any[]) => void;
  visible: boolean;
  onClose: () => void;
  title: string;
  pendingAllocateBooking: boolean;
}>;

function SelectVehicleModal(props: Props) {
  const {
    visible,
    onNextPlace,
    onSelectVehicle,
    onClose,
    title,
    pendingAllocateBooking,
  } = props;
  const { currentAccount } = useAuthentication();

  const [gridApi, setGridApi] = useState<GridApi>(null);
  const [lastRow, setLastRow] = useState(null);
  const [assets, setAssets] = useState(null);
  const [cursor, setCursor] = useState(null);
  const userDivisionIds = currentAccount?.userDivisionMappings?.map(
    item => item.divisionId,
  );
  const [selectedDivisions, setSelectedDivisions] = useState<number[]>(
    userDivisionIds,
  );

  const onGridReady = params => {
    const { api } = params;
    setGridApi(api);
  };

  const handleCloseModal = () => {
    onClose();
  };

  const [getVehicleQuery] = useGetAssetLazyQuery({
    onCompleted: response => {
      let data;
      if (response?.assets?.nodes) {
        const nodes = response.assets.nodes.map(dataItem => {
          if (dataItem?.id) {
            return dataItem;
          }
          return undefined;
        });
        let newList = nodes;
        if (
          assets?.nodes?.length > 0 &&
          response.assets.pageInfo.hasPreviousPage
        ) {
          newList = [...(assets?.nodes ?? []), ...nodes];
        }
        data = {
          ...response?.assets,
          nodes: newList,
        };
        setCursor(response?.assets?.pageInfo?.endCursor);
        setLastRow(newList.length - 1);

        setAssets(data);
        gridApi?.ensureIndexVisible(lastRow, null);
      }
    },
  });

  const getVehicleList = cursor => {
    getVehicleQuery({
      variables: {
        filters: {
          isDeleted: { neq: true },
          divisionId: {
            in: userDivisionIds,
          },
        } as AssetFilterInput,
        cursor,
      },
    });
  };

  const handleSelectionChange = (event: SelectionChangedEvent) => {
    const { api } = event;
    const records = api.getSelectedRows();
    onSelectVehicle(records[0]?.id);
  };

  const handleGridPagination = () => {
    try {
      if (
        assets?.pageInfo &&
        assets?.pageInfo?.hasNextPage &&
        assets?.pageInfo?.endCursor
      ) {
        getVehicleList(cursor);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBodyScrollEnd = () => {
    const gridLastRow = gridApi?.getLastDisplayedRow() + 1;

    if (gridLastRow === assets?.nodes?.length && assets?.nodes?.length !== 0) {
      handleGridPagination();
    }
  };

  const handleSelectedDivisionsChange = (value: any[]) => {
    setSelectedDivisions(value);
    const divisionIds = value.filter(element => typeof element === 'number');
    const variables = {
      filters: {
        isDeleted: { neq: true },
        divisionId: { in: divisionIds },
      } as AssetFilterInput,
      cursor: null,
    };
    getVehicleQuery({
      variables,
    });
  };

  useEffect(() => {
    setSelectedDivisions(userDivisionIds);
    getVehicleList(cursor);
    gridApi?.deselectAll();
  }, [visible]);

  const renderGrid = () => {
    return (
      <div style={{ height: '50vh' }}>
        <AGGrid
          {...selectVehicleGridConfig.gridOptions}
          rowData={assets?.nodes}
          onSelectionChanged={handleSelectionChange}
          onGridReady={onGridReady}
          gridApi={gridApi}
          onBodyScrollEnd={handleBodyScrollEnd}
        />
        <p>Total Vehicles: {assets?.totalCount}</p>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          danger
          className="rounded-lg shadow-md"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          className="rounded-lg shadow-md"
          onClick={onNextPlace}
          disabled={pendingAllocateBooking}
        >
          Next Place
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title={title}
      width={800}
      visible={visible}
      onClose={handleCloseModal}
      footer={renderFooter()}
    >
      <div className="flex mb-2">
        <span className="flex mr-1 items-center">Select Division</span>
        <DivisionSelect
          selectedOptions={selectedDivisions}
          onChange={handleSelectedDivisionsChange}
          placeholder="Select division(s)"
        />
      </div>
      {renderGrid()}
    </Modal>
  );
}

export default SelectVehicleModal;
