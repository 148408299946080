import React from 'react';

function getStatusPrimaryColor({
  property,
  label,
}: {
  property: 'bg' | 'text';
  label: string;
}) {
  const colors = {
    bg: {
      all: 'bg-status-all',
      hold: 'bg-status-hold',
      pup: 'bg-status-pup',
      pak: 'bg-status-pak',
      pod: 'bg-status-pod',
      upk: 'bg-status-upk',
      acc: 'bg-status-acc',
      ofl: 'bg-status-ofl',
      rdy: 'bg-status-rdy',
      upl: 'bg-status-upl',
      del: 'bg-status-del',
      ofd: 'bg-status-ofd',
      spl: 'bg-status-spl',
      rfp: 'bg-status-rfp',
      new: 'bg-status-new',
      edi: 'bg-status-edi',
      qte: 'bg-status-qte',
      pln: 'bg-status-pln',
      canc: 'bg-status-canc',
      web: 'bg-status-web',
      default: 'bg-status-default',
      vfed: 'bg-status-vfed',
      invc: 'bg-status-invc',
      dispt: 'bg-status-dispt',
    },
    text: {
      all: 'text-status-all',
      hold: 'text-status-hold',
      pup: 'text-status-pup',
      pak: 'text-status-pak',
      pod: 'text-status-pod',
      upk: 'text-status-upk',
      acc: 'text-status-acc',
      ofl: 'text-status-ofl',
      rdy: 'text-status-rdy',
      upl: 'text-status-upl',
      del: 'text-status-del',
      ofd: 'text-status-ofd',
      spl: 'text-status-spl',
      rfp: 'text-status-rfp',
      new: 'text-status-new',
      edi: 'text-status-edi',
      qte: 'text-status-qte',
      pln: 'text-status-pln',
      canc: 'text-status-canc',
      web: 'text-status-web',
      default: 'text-status-default',
      vfed: 'text-status-vfed',
      invc: 'text-status-invc',
      dispt: 'text-status-dispt',
    },
  };

  return colors[property][label] || colors[property]['default'];
}

export default function StatusCell({
  label,
}: Readonly<{ label: string; clickable?: boolean }>) {
  if (label) {
    return (
      <div className={`flex items-center my-1 relative`}>
        <div
          className={`flex w-16 items-center px-1 rounded-md bg-opacity-[.15] ${getStatusPrimaryColor(
            {
              property: 'bg',
              label: label?.toLowerCase(),
            },
          )}`}
        >
          <span
            className={`flex rounded-full mr-2 w-[8px] h-[8px] ${getStatusPrimaryColor(
              {
                property: 'bg',
                label: label?.toLowerCase(),
              },
            )}`}
          />
          <span
            className={`font-medium ${getStatusPrimaryColor({
              property: 'text',
              label: label?.toLowerCase(),
            })}   `}
          >
            {label}
          </span>
        </div>
      </div>
    );
  }
  return null;
}
