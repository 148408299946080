import { Select } from 'antd';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { styled } from 'twin.macro';

const { Option } = Select;

const DropdownFloatingFilter = styled.div`
  .ant-select {
    padding: 3px 0;
    height: 30px;
    width: 100%;

    .ant-select-selector {
      height: 25px;
    }
    .ant-select-selection-search-input {
      height: 25px;
    }
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: 23px;
      font-weight: 400 !important;
    }
  }
`;

export default forwardRef((props: any, ref: any) => {
  const selectRef = useRef(null);
  const [booleanDropdownVal, setBooleanDropdownVal] = useState(null);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          selectRef.current.value = '';
          setBooleanDropdownVal(null);
        } else {
          selectRef.current.value = `${parentModel.filter}`;
          setBooleanDropdownVal(parentModel.filter);
        }
      },
    };
  });

  const onBooleanDropdownChange = (value: any) => {
    if (value === '' || value === null) {
      // Remove the filter
      props.parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged(null);
      });
      return;
    }
    setBooleanDropdownVal(value);
    props.parentFilterInstance((instance: any) => {
      instance.onFloatingFilterChanged('equals', value);
    });
  };

  return (
    <DropdownFloatingFilter className="custom-dropdown-floating-filter">
      <Select
        ref={selectRef}
        value={booleanDropdownVal}
        onChange={onBooleanDropdownChange}
        placeholder="Select ..."
      >
        <Option value={'1'}>Yes</Option>
        <Option value={'0'}>No</Option>
      </Select>
    </DropdownFloatingFilter>
  );
});
