import {
  useGetBookingByIdLazyQuery,
  useGetBookingsLazyQuery,
  useInvoiceBookingMutation,
  useModifyBookingMutation,
  useRerateBookingMutation,
  useUnverifyBookingMutation,
  useVerifyBookingMutation,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { ERROR_MESSAGE } from 'helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  actions,
  reducer,
  selectFlagRows,
  selectItemDetails,
  selectSelectedItems,
  selectSubModals,
  selectWorkspaceData,
  sliceKey,
} from './slice';

export default function useProduct() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();

  const invoiceWorkpaceData = useSelector(selectWorkspaceData);
  const bookingDetails = useSelector(selectItemDetails);
  const selectedItems = useSelector(selectSelectedItems);
  const subModals = useSelector(selectSubModals);
  const flagRows = useSelector(selectFlagRows);

  const [getBookingListQuery] = useGetBookingsLazyQuery({
    onCompleted: response => {
      let data;
      if (response?.dispatchBookingSummary?.nodes) {
        const nodes = response?.dispatchBookingSummary?.nodes;
        let newList = nodes;
        if (
          invoiceWorkpaceData?.nodes?.length > 0 &&
          response.dispatchBookingSummary.pageInfo.hasPreviousPage
        ) {
          newList = [...(invoiceWorkpaceData?.nodes ?? []), ...nodes];
        }
        data = {
          ...response?.dispatchBookingSummary,
          nodes: newList,
        };

        dispatch(
          actions.setInvoicingList({
            workspaceData: { ...data, loading: false, error: null },
          }),
        );
      }
    },
    onError: () => {
      dispatch(
        actions.setInvoicingList({
          workspaceData: {
            nodes: [],
            loading: false,
            error: ERROR_MESSAGE.LOADING_DATA_ERROR,
          },
        }),
      );
    },
  });

  const getBookingList = ({ divisionIds, filters, cursor, order }) => {
    return getBookingListQuery({
      variables: {
        divisionIds,
        filters,
        cursor,
        order,
      },
    });
  };

  const refreshWorkspaceData = () => {
    dispatch(
      actions.setInvoicingList({
        workspaceData: null,
      }),
    );
  };

  const [getBookingByIdQuery] = useGetBookingByIdLazyQuery({
    onCompleted: response => {
      if (response?.bookingById) {
        const data = response?.bookingById;
        dispatch(
          actions.setItemDetails({
            itemDetails: data,
          }),
        );
      }
    },
  });

  const getBookingById = async bookingId => {
    getBookingByIdQuery({
      variables: { bookingId },
    });
  };

  const resetItemDetails = () => {
    dispatch(
      actions.setItemDetails({
        itemDetails: null,
      }),
    );
  };

  const [modifyBookingMutation] = useModifyBookingMutation({
    onCompleted: response => {
      if (
        response?.modifyBooking?.modifyBookingPayloadOutputModel
          ?.validBookingView
      ) {
        setUpdatedInvoice([
          response.modifyBooking.modifyBookingPayloadOutputModel
            .validBookingView,
        ]);
      }
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const modifyBooking = async input => {
    return modifyBookingMutation({
      variables: { input },
    });
  };

  const setSelectedItems = rows => {
    dispatch(
      actions.setSelectedItems({
        selectedItems: rows,
      }),
    );
  };

  const [
    verifyBookingMutation,
    { loading: pendingVerifyBooking },
  ] = useVerifyBookingMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const verifyBooking = (bookingIds: [number]) => {
    return verifyBookingMutation({
      variables: { bookingIds },
    });
  };

  const [
    unverifyBookingMutation,
    { loading: pendingUnverifyBooking },
  ] = useUnverifyBookingMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const unverifyBooking = (bookingIds: [number]) => {
    return unverifyBookingMutation({
      variables: { bookingIds },
    });
  };

  const [
    reRateBookingMutation,
    { loading: pendingReRateBooking },
  ] = useRerateBookingMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const reRateBooking = (bookingIds: [number]) => {
    return reRateBookingMutation({
      variables: { bookingIds },
    });
  };

  const [
    invoiceBookingMutation,
    { loading: pendingInvoiceBooking },
  ] = useInvoiceBookingMutation({
    onCompleted: response => {
      return response;
    },
    onError: error => {
      return error;
    },
  });

  const invoiceBooking = (bookingIds: [number]) => {
    return invoiceBookingMutation({
      variables: { bookingIds },
    });
  };

  const setUpdatedInvoice = updatedItems => {
    const newList = invoiceWorkpaceData?.nodes?.map(item => {
      const uitem = updatedItems.find(x => x.id === item.id);
      if (uitem) {
        return uitem;
      }
      return item;
    });
    const data = {
      ...invoiceWorkpaceData,
      nodes: newList,
    };

    dispatch(
      actions.setUpdatedInvoice({
        updatedItems,
        workspaceData: data,
        flagRows: updatedItems,
      }),
    );
  };

  const showSubModal = content => {
    dispatch(actions.setSubModal(content));
  };

  return {
    refreshWorkspaceData,
    resetItemDetails,
    getBookingList,
    modifyBooking,
    verifyBooking,
    unverifyBooking,
    reRateBooking,
    setUpdatedInvoice,
    invoiceBooking,
    setSelectedItems,
    getBookingById,
    showSubModal,
    bookingDetails,
    selectedItems,
    subModals,
    flagRows,
    invoiceWorkpaceData,
    pendingVerifyBooking,
    pendingUnverifyBooking,
    pendingInvoiceBooking,
    pendingReRateBooking,
  };
}
