import {
  GroupFilterInput,
  GroupSortInput,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { useDialog } from 'components/Dialog/context/DialogProvider';
import DefaultLayout from 'components/Layout/DefaultLayout';
import useAuthentication from 'context/security_authentication/hook';
import useGroups from 'context/security_groups/hooks';
import { actions, reducer, sliceKey } from 'context/workspace/slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import GroupForm from '../components/Form';
import { groupScreenConfig } from './config';
import { groupGridOptions } from './gridOptions';

const dialogProps = {
  closeable: false,
  height: '560px',
};

export default () => {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const { openDialog, closeDialog } = useDialog();
  const dispatch = useDispatch();
  const { currentAccount } = useAuthentication();

  const {
    groupWorkspaceData,
    flagRows,
    getGroupList,
    refreshWorkspaceData,
    deleteGroup,
  } = useGroups();

  function _viewGroupRecord(data) {
    openDialog({
      content: <GroupForm group={data} />,
      dialogProps: {
        ...dialogProps,
        width: '750px',
      },
    });
  }

  function _onCreateGroup() {
    openDialog({
      content: <GroupForm afterValidation={closeDialog} />,
      dialogProps: {
        ...dialogProps,
        height: '200px',
        width: '500px',
      },
    });
  }

  const handleDeleteGroup = async ids => {
    const result = await deleteGroup(ids);
    let message = 'Delete user group successfully';

    if (result?.data.deleteGroups.length === 0) {
      message = 'Cannot delete user group';
    }
    return {
      message,
      data: result?.data.deleteGroups,
    };
  };

  useEffect(() => {
    dispatch(
      actions.setWorkspace({
        workspace: { ...groupScreenConfig, gridOptions: groupGridOptions },
        query: {
          cursor: null,
          filters: {
            ownerId: {
              eq:
                currentAccount?.organizationOrganizationMapping
                  ?.childOrganizationId,
            },
            isDeleted: { neq: true },
          } as GroupFilterInput,
          order: { id: 'DESC' } as GroupSortInput,
        },
      }),
    );
  }, []);

  return (
    <DefaultLayout
      gridOptions={groupGridOptions}
      data={groupWorkspaceData}
      flagRows={flagRows}
      onGetDataList={getGroupList}
      onRefreshDataList={refreshWorkspaceData}
      onViewRecord={_viewGroupRecord}
      onCreateNew={_onCreateGroup}
      onDelete={handleDeleteGroup}
    />
  );
};
