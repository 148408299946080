import { ICellRendererParams } from 'ag-grid-community';
import { InputNumber } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

export default forwardRef((props: ICellRendererParams, ref) => {
  const refInput = useRef(null);
  const [value, setValue] = useState(props.value);

  const handleOnChange = value => {
    setValue(value);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
    };
  });

  useEffect(() => {
    if (!props.node.rowPinned) {
      const eInput = refInput.current;
      eInput.focus();
    }
  }, []);

  return (
    <div className="flex flex-row justify-end w-full">
      {!props.node.rowPinned ? (
        <InputNumber
          ref={refInput}
          min={0}
          bordered={false}
          formatter={value =>
            value === 0 ? '0' : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={value =>
            value === '0' ? 0 : value.replace(/\$\s?|(,*)/g, '')
          }
          defaultValue={props.value}
          onChange={handleOnChange}
        />
      ) : (
        <span>{props.value}</span>
      )}
    </div>
  );
});
