import {
  faClock,
  faExclamationCircle,
  faMessage,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICellRendererParams } from 'ag-grid-community';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

const StyledWrapper = styled.div`
  ${tw`w-full relative pl-1`}

  .anticon {
    vertical-align: baseline;
  }
  .flag-hover {
    opacity: 0;
    &:hover {
      opacity: 0.4;
    }
  }
`;

export interface Props extends ICellRendererParams {
  // node: any;
}

function BookingFlag(props: Props) {
  const { data, context } = props;
  const [isHovering, setIsHovering] = useState(false);

  const handleAttention = flags => {
    context.handleAttention?.(props, flags);
  };

  const renderFlags = () => {
    const bookingFlagList = [];

    if (data?.bookingFlag) {
      bookingFlagList.push('ATTENTIONT');
    } else {
      bookingFlagList.push('ATTENTIONF');
    }

    const handleMouseEnter = () => {
      setIsHovering(true);
    };
    const handleMouseLeave = () => {
      setIsHovering(false);
    };

    return (
      <div className="flex">
        {bookingFlagList?.map(flag => (
          <div key={flag} className="mr-1">
            {
              {
                ATTENTIONT: (
                  <Tooltip
                    placement="rightBottom"
                    title="Booking with attention (Click to remove attention)"
                  >
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      style={{ fontSize: 17, color: 'red', cursor: 'pointer' }}
                      onClick={() => handleAttention(0)}
                    />
                  </Tooltip>
                ),
                ATTENTIONF: (
                  <Tooltip
                    placement="rightBottom"
                    title="Booking with attention (Click to add attention)"
                  >
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      style={{
                        fontSize: 17,
                        color: isHovering ? 'red' : 'grey',
                        opacity: isHovering ? 0.7 : 0.05,
                        cursor: 'pointer',
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => handleAttention(1)}
                    />
                  </Tooltip>
                ),
                EXPRESS: (
                  <Tooltip
                    placement="rightBottom"
                    title="Click to flag booking attention"
                    color="orange"
                  >
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      style={{ fontSize: 17, color: 'blue' }}
                      className="flag-hover"
                      onClick={() => handleAttention(8)}
                    />
                  </Tooltip>
                ),
                CALL: (
                  <Tooltip placement="rightBottom" title="Phone call requested">
                    <FontAwesomeIcon
                      icon={faPhone}
                      style={{ fontSize: 17, color: 'orange' }}
                    />
                  </Tooltip>
                ),
                URGENT: (
                  <Tooltip placement="rightBottom" title="Booking is urgent">
                    <FontAwesomeIcon
                      icon={faClock}
                      style={{ fontSize: 17, color: 'orange' }}
                    />
                  </Tooltip>
                ),
                SPECIAL: (
                  <Tooltip
                    placement="rightBottom"
                    title="Booking has special instructions"
                  >
                    <FontAwesomeIcon
                      icon={faMessage}
                      style={{ fontSize: 17, color: 'blue' }}
                    />
                  </Tooltip>
                ),
              }[flag]
            }
          </div>
        ))}
      </div>
    );
  };
  return <StyledWrapper>{renderFlags()}</StyledWrapper>;
}

export default BookingFlag;

// comment contains call => phone
// instruction contains urgent || serviceTypeId= 6 => clock
// if comments and instruction not null => asterisk
// flag = 8 => (!)attention
