export enum containerTypes {
  GP = 'GP',
  HC = 'HC',
  RE = 'RE',
  RX = 'RX',
  B = 'B',
  I = 'I',
  T = 'T',
  S = 'S',
  FR = 'FR',
  P = 'P',
  V = 'V',
  OT = 'OT',
}

export enum doorSideTypes {
  FORWARD = 'FORWARD',
  REAR = 'REAR',
  EITHER = 'EITHER',
  TBA = 'TBA',
}

export enum jobTypes {
  IMP = 'IMP',
  EXP = 'EXP',
  MOV = 'MOV',
}

export enum containerPorts {
  NZAKL = 'NZAKL',
  NZTRG = 'NZTRG',
  NZLYT = 'NZLYT',
  NZTIU = 'NZTIU',
}

export enum fullEmptyTypes {
  FULL = 'FULL',
  EMPTY = 'EMPTY',
}
