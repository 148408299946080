import { AddAddressInput } from 'api/graphql/generated/serviceTypesAndHooks';
import { create } from 'zustand';

export type TContact = {
  id?: number | string;
  [key: string]: any;
};

interface IState {
  selectedPlace:
    | (Partial<AddAddressInput> & { isSaving: boolean } & any)
    | null;
}

interface IActions {
  setSelectedPlace: (address: Partial<AddAddressInput>) => void;
  setIsSaving: (status: boolean) => void;
  reset: () => void;
}

const usePlacesStore = create<IState & IActions>(set => ({
  selectedPlace: null,
  setSelectedPlace: (address: any) => set({ selectedPlace: address }),
  setIsSaving: (status: boolean) =>
    set(state => ({
      selectedPlace: {
        ...state.selectedPlace,
        isSaving: status,
      },
    })),
  reset: () =>
    set({
      selectedPlace: null,
    }),
}));

export default usePlacesStore;
