import { useGetGenericCodeListLazyQuery } from 'api/graphql/generated/serviceTypesAndHooks';
import { groupBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import { actions, reducer, selectCodeLists, sliceKey } from './slice';

export default function useGenericCodeLists() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });
  const dispatch = useDispatch();
  const genericCodeListData = useSelector(selectCodeLists);

  const [
    getGenericCodeListsQuery,
    { loading: pendingGenericCodeLists },
  ] = useGetGenericCodeListLazyQuery({
    onCompleted: response => {
      const data = response?.genericCodeLists?.nodes;
      if (data) {
        const grouped = groupBy(data, x => x.parent?.name);
        dispatch(
          actions.setGenericCodeLists({
            codeLists: { ...grouped, loading: false },
          }),
        );
      }
    },
  });

  const getGenericCodeLists = ({ filters, order }) => {
    getGenericCodeListsQuery({
      variables: {
        filters,
        order,
      },
    });
  };

  return {
    getGenericCodeLists,
    pendingGenericCodeLists,
    genericCodeListData,
  };
}
