import { message } from 'antd';
import { WorkspaceQuery } from 'components/Layout/types';
import { TGridColumnType } from 'components/ReactGrid/types';
import { defaultBookingDateFilter } from 'components/config/date';
import useAuthentication from 'context/security_authentication/hook';
import { selectQuery, selectWorkspaces } from 'context/workspace/selectors';
import { dateQueryFormatter } from 'helpers/date';
import { bookingFilterEngines } from 'helpers/filterEngines';
import { sortEngines } from 'helpers/sortEngines';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GridPanel from './GridPanel';
import { StyledForm } from './styles';

type Props = Readonly<{
  gridOptions?: any;
  data?: any;
  flagRows?: any[];
  selectedItems?: any[];
  onGetDataList?: (query: any) => void;
  onRefreshDataList?: () => void;
  onCreateNew?: () => void;
  onDuplicate?: () => void;
  onViewRecord?: (record: any) => void;
  onDelete?: (ids: number[]) => Promise<{ data: any; message: string }>;
}>;

export default function DispatchLayout(props: Props) {
  const workspace = useSelector(selectWorkspaces);
  const [loading, setLoading] = useState(false);
  const { currentAccount } = useAuthentication();
  const workspaceQuery: WorkspaceQuery = useSelector(selectQuery);
  const [mainQuery, setMainQuery] = useState(null);
  const [aggridFilters, setAggridFilters] = useState(null);
  const [dateFilters, setDateFilters] = useState({
    and: [
      {
        bookedDate: {
          gte: defaultBookingDateFilter.from,
        },
      },
      {
        bookedDate: {
          lte: defaultBookingDateFilter.to,
        },
      },
    ],
  });
  const [divisionIds, setDivisionIds] = useState(
    currentAccount?.userDivisionMappings?.map(d => d.divisionId),
  );

  // =================== Handlers ===========================//

  const handleApplyFilter = query => {
    setLoading(true);
    let filterAnd = [];
    const { divisionIds, ...dateQuery } = query;
    const formattedQuery = dateQueryFormatter(dateQuery);
    const externalDateFilter = bookingFilterEngines(formattedQuery);
    if (aggridFilters?.and) {
      filterAnd = [
        ...(aggridFilters.and ?? []),
        ...(externalDateFilter?.and ?? []),
      ];
    } else {
      filterAnd = [...(externalDateFilter?.and ?? [])];
    }

    const newDivisionIds = divisionIds?.length > 0 ? divisionIds : null;
    setDivisionIds(newDivisionIds);
    setDateFilters(externalDateFilter);
    setMainQuery({
      ...mainQuery,
      divisionIds: newDivisionIds,
      filters: returnNullIfEmpty({
        ...aggridFilters,
        ...(filterAnd.length > 0 && {
          and: filterAnd,
        }),
      }),
    });
  };

  const handleClearFilter = () => {
    setLoading(true);
    const dateFilters = handleSetDefaultDateRange();
    const defaultDivisionIds = currentAccount?.userDivisionMappings?.map(
      d => d.divisionId,
    );
    setDivisionIds(defaultDivisionIds);
    setAggridFilters(null);
    setMainQuery({
      divisionIds: defaultDivisionIds?.length ? defaultDivisionIds : null,
      filters: returnNullIfEmpty({
        ...dateFilters,
      }),
    });
  };

  const handleSetDefaultDateRange = () => {
    const dateTypeSelector = workspace?.toolbar?.filters?.find(
      el => el.field === 'dateTypeSelector',
    );
    const dateRangePicker = workspace?.toolbar?.filters?.find(
      el => el.field === 'dateRangePicker',
    );
    const query = {
      [dateTypeSelector.field]: dateTypeSelector.defaultValue,
      [dateRangePicker.field]: dateRangePicker.defaultValue,
    };
    const formattedQuery = dateQueryFormatter(query);
    const internalFilters = bookingFilterEngines(formattedQuery);
    const dateFilters = {
      ...internalFilters,
      ...workspaceQuery?.filters,
    };

    setDateFilters(dateFilters);
    return dateFilters;
  };

  const handleInternalFilterChanged = (filterState, sortState) => {
    setLoading(true);
    let filterAnd = [];
    const refinedSort = sortEngines(sortState, workspace?.module?.value);
    let externalDateFilter = bookingFilterEngines(filterState);
    if (externalDateFilter?.and) {
      const dateFilter = dateFilters?.and;
      filterAnd = [...(externalDateFilter.and ?? []), ...(dateFilter ?? [])];
    } else {
      filterAnd = dateFilters?.and;
    }

    setAggridFilters(externalDateFilter);
    const newQuery = {
      ...mainQuery,
      divisionIds: divisionIds?.length ? divisionIds : null,
      filters: returnNullIfEmpty({
        ...externalDateFilter,
        ...(filterAnd?.length && {
          and: filterAnd,
        }),
      }),
      ...(refinedSort?.length && {
        order: refinedSort,
      }),
    };
    setMainQuery(newQuery);
  };

  const handleInternalSortChanged = (
    sortState: [{ colId: string; sort: string; sortIndex: number }],
  ) => {
    const refinedSort = sortEngines(sortState, workspace?.module?.value);
    if (refinedSort?.length) {
      setMainQuery(prevState => ({
        ...prevState,
        order: refinedSort,
      }));
    }
  };

  const handleGridPagination = () => {
    try {
      if (
        props.data?.pageInfo?.hasNextPage &&
        props.data?.pageInfo?.endCursor &&
        !loading
      ) {
        setLoading(true);
        const cursor = props.data.pageInfo.endCursor;
        props.onGetDataList({ ...mainQuery, cursor });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRowDoubleClick = event => {
    const record = event.data;
    const colType = event.column.colDef.type;
    const suppressRowDoubleClick =
      colType === TGridColumnType.ClickableColumn ||
      colType === TGridColumnType.EditableColumn;
    if (!suppressRowDoubleClick) {
      props.onViewRecord?.(record);
    }
  };

  const handleDeleteRecord = async (ids: number[]) => {
    const result = await props?.onDelete(ids);
    if (result?.data) {
      message.success(result.message);
      props?.onGetDataList(mainQuery || workspaceQuery);
    } else {
      message.error(result.message);
    }
  };

  const getInitialData = () => {
    const newInitWorkspaceQuery = {
      ...workspaceQuery,
      filters: workspaceQuery?.filters ? workspaceQuery.filters : null,
    };
    const initQuery = newInitWorkspaceQuery || {
      filters: null,
      cursor: null,
      order: null,
    };
    setMainQuery(initQuery);
  };

  const returnNullIfEmpty = newFilter => {
    return Object.keys(newFilter).length === 0 ? null : newFilter;
  };

  // =================== UseEffects =========================== //

  useEffect(() => {
    if (mainQuery) {
      setLoading(true);
      props?.onGetDataList(mainQuery);
    }
    return () => {
      if (props?.onRefreshDataList) {
        props?.onRefreshDataList();
      }
    };
  }, [mainQuery]);

  useEffect(() => {
    setLoading(props.data?.loading);
  }, [props.data]);

  // =================== Render components =========================== //

  if (workspace) {
    return (
      <StyledForm>
        <div className="h-full">
          <GridPanel
            gridOptions={props?.gridOptions}
            module={workspace?.module?.value}
            loading={loading}
            data={props.data}
            flagRows={props.flagRows}
            selectedItems={props.selectedItems}
            toolbar={workspace?.toolbar}
            screenViews={workspace?.screenViews}
            onApplyFilter={handleApplyFilter}
            onClearFilter={handleClearFilter}
            onGridPagination={handleGridPagination}
            onGridRowDoubleClick={handleRowDoubleClick}
            onViewRecord={props.onViewRecord}
            onInternalFilterChanged={handleInternalFilterChanged}
            onInternalSortChanged={handleInternalSortChanged}
            onCreateNew={props.onCreateNew}
            onDuplicate={props.onDuplicate}
            onDelete={handleDeleteRecord}
            onGetInitialData={getInitialData}
          />
        </div>
      </StyledForm>
    );
  }
  return null;
}
