export const generateId = (onlyNumber: boolean = false, length: number = 6) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const number = '0123456789';
  let characters = alphabet + number;
  if (onlyNumber) {
    characters = number;
  }
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
