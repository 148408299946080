import { Input } from 'antd';
import WithFormHooksController from 'components/common/WithFormHooksController';
import Field from 'components/Form/Field';
import { useFormHooks } from 'context/FormHooksContext';
import React from 'react';

export default function Index() {
  const { control } = useFormHooks();

  return (
    <div className="p-4">
      <div className="grid gap-3 grid-cols-12 h-auto">
        <Field label="Group Name" isRequired>
          <WithFormHooksController
            name="name"
            control={control}
            component={<Input className="px-2 rounded-md" />}
          />
        </Field>
        <Field label="Description">
          <WithFormHooksController
            control={control}
            name="description"
            component={<Input className="px-2 rounded-md" />}
          />
        </Field>
      </div>
    </div>
  );
}
