/* eslint-disable react/prop-types */
import sprite from 'assets/icon-sprites.svg';
import * as React from 'react';
import { css } from 'styled-components';
import tw, { styled } from 'twin.macro';

export enum IconRef {
  settings = 'icon-settings',
  icosLogo = 'icon-icos-new-logo',
  menuSubscriberSetting = 'icon-menu-subscriberSetting',
  menuCustomerSetting = 'icon-menu-customerSetting',
  menuDriverSetting = 'icon-menu-iconMenuSetting',
  menuSettings = 'icon-menu-settings',
  menuTransport = 'icon-menu-transport',
  menuDispatch = 'icon-menu-booking-dispatch',
  menuManifest = 'icon-menu-booking-manifest',
  menuEDI = 'icon-menu-booking-edi',
  menuRouteOptimisation = 'icon-menu-booking-route-optimisation',
  menuFinancials = 'icon-menu-financials',
  menuInvoice = 'icon-menu-financials-invoices',
  menuSurcharge = 'icon-menu-financials-surcharges',
  menuProducts = 'icon-menu-financials-products',
  menuRates = 'icon-menu-financials-rates',
  menuLocations = 'icon-menu-locations',
  menuPlaces = 'icon-menu-locations-places',
  menuRunRoutes = 'icon-menu-run-routes',
  menuAssets = 'icon-menu-assets',
  menuVehicles = 'icon-menu-assets-vehicles',
  menuVessels = 'icon-menu-assets-vessels',
  menuOrganization = 'icon-menu-companies',
  menuDashboard = 'icon-menu-dashboard',
  menuOrgCompanies = 'icon-menu-organizations-companies',
  menuDocuments = 'icon-menu-documents',
  menuReports = 'icon-menu-reports',
  menuBookingReports = 'icon-menu-reports-booking',
  menuFinanceReports = 'icon-menu-reports-finance',
  menuSecurity = 'icon-menu-security',
  menuUsers = 'icon-menu-users',
  menuUsersGroup = 'icon-menu-users-group',
  menuInvitations = 'icon-menu-invitations',
  menuPermissions = 'icon-menu-permissions',
  menuFiles = 'icon-menu-files',
  menuFileManager = 'icon-menu-filemanager',
  menuHelp = 'icon-menu-help',

  tabHistory = 'icon-tab-history',
  tabCommunication = 'icon-tab-communication',
  tabItem = 'icon-tab-item',
  tabMoves = 'icon-tab-moves',
  tabProducts = 'icon-tab-products',
  tabPlaces = 'icon-tab-places',
  tabLegs = 'icon-tab-legs',
  tabMap = 'icon-tab-map',
  tabPort = 'icon-tab-port',
  tabDocs = 'icon-tab-docs',

  settingsViolet = 'icon-settings-violet',
  livemap = 'icon-live-map',
  quickAdd = 'icon-quick-add',
  transport = 'icon-transport',
  transportOrange = 'icon-transport-orange',
  financials = 'icon-financials',
  financialsGreen = 'icon-financial-green',
  dashboard = 'icon-dashboard',
  dashboardBlue = 'icon-dashboard-blue',
  search = 'icon-search',
  toggle = 'icon-toggle-sidebar',
  myaccount = 'icon-my-account',
  logout = 'icon-logout',
  close = 'icon-close',
  calendar = 'icon-calendar',
  maximize = 'icon-maximize',
  moreMenu = 'icon-more-menu',
  onlineSupport = 'icon-online-support',
  supportTicket = 'icon-support-ticket',
  supportIcon = 'icon-blue-support-circle',
  knowledgeBase = 'icon-knowledgebase',
  circleCheck = 'icon-circle-check',
  circlePlus = 'icon-circle-plus',
  resize = 'icon-resize',
  edit = 'icon-edit',
  trash = 'icon-trash',
  save = 'icon-save',
  duplicate = 'icon-duplicate',
  plus = 'icon-plus',
  toggleViewVertical = 'icon-toggle-view-vertical',
  toggleViewHorizontal = 'icon-toggle-view-horizontal',
  alertCircle = 'icon-alert-circle',
  cross = 'icon-cross',
  check = 'icon-check',
  location = 'icon-location',
  locationPin = 'icon-location-pin',
  document = 'icon-document',
  costCharge = 'icon-cost-charge',
  comments = 'icon-comments',
  print = 'icon-print',
  manifest = 'icon-manifest',
  moves = 'icon-moves',
  history = 'icon-history',
  documentChecked = 'icon-document-checked',
  chat = 'icon-chat',
  organisations = 'icon-organisations',
  chevronDown = 'icon-chevron-down',
  chevronUp = 'icon-chevron-up',
  chevronRight = 'icon-chevron-right',
  chevronLeft = 'icon-chevron-left',
  undo = 'icon-undo',
  carretDown = 'icon-carret-down',
  cRadio100Active = 'c-radio-100-active',
  cRadio100Inactive = 'c-radio-100-inactive',
}

interface Props extends React.HTMLAttributes<SVGElement> {
  icon?: IconRef | string | null;
  size?: 'small' | 'medium' | 'large';
  color?: string;
  fill?: string;
  scale?: string;
  lat?: number;
  lng?: number;
  text?: string;
}

const SVGIcon = styled.svg<Props>`
  ${tw`w-6 h-6 z-10`}
  //margin-left: -8px;
  color: ${p => p.theme.textColor};
  fill: ${p => p.theme.textColor};

  ${({ scale }) => scale && `transform: scale(${scale});`}
  ${({ size }) =>
    size === 'small'
      ? css`
          width: 16px;
          height: 16px;
        `
      : ''}
`;

const Icon: React.FunctionComponent<Props> = props => (
  <SVGIcon
    {...props}
    className={`icon ${props.className}`}
    color={props.color}
    fill={props.fill}
    size={props.size}
  >
    <use className="svg-use" href={`${sprite}#${props.icon}`} />
  </SVGIcon>
);

export default Icon;
