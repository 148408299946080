import React, { useRef, useEffect } from 'react';
import TabPane from 'antd/es/tabs/TabPane';
import { message, Tabs } from 'antd';
import { useFormHooks } from 'context/FormHooksContext';
import { IDialogHeader } from 'components/Dialog/types';
import { getErrorMessage } from 'helpers/form-errors';
import Dialog from 'components/Dialog';
import WithThemeProvider from 'components/providers/WithThemeProvider';
import styled from 'styled-components';

import { UserGroupMapping } from 'api/graphql/generated/serviceTypesAndHooks';
import TabBarExtraHeader from 'components/TabBar/TabBarExtraHeader';
import FormUserActions from 'components/Form/FormUserActions';
import DetailsForm from './Details';
import UsersForm from './Users';
import PermissionForm from './Permissions';
import useGroupsStore from '../../store';

type Props = {
  group?: UserGroupMapping | null;
  onClose?: () => void;
  onSubmit: (data: any) => void;
} & IDialogHeader;

type TUserTabs = 'details' | 'users' | 'permission';

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    margin: 0.6rem 0 0.6rem 1.5rem !important;
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

const Form = ({ group, onClose, onSubmit, title, subTitle }: Props) => {
  const [isAbandoning, setIsAbandoning] = React.useState(false);
  const [tabSelected, setTabSelected] = React.useState<TUserTabs>('details');
  const isSaving = useGroupsStore(state => state.isSaving);

  const { handleSubmit, formState } = useFormHooks();
  const { errors, isDirty } = formState;

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (Object.keys(errors).length) {
      message.error(getErrorMessage(errors));
    }
  }, [errors]);

  // eslint-disable-next-line no-underscore-dangle
  async function _onSave() {
    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
    setIsAbandoning(false);
  }

  // eslint-disable-next-line no-underscore-dangle
  function _onCancel() {
    if (isAbandoning || !isDirty) {
      onClose();
      return;
    }

    setIsAbandoning(true);
  }

  function onTabSelect(tab: TUserTabs) {
    setTabSelected(tab);
  }

  return (
    <form
      ref={formRef}
      className="flex flex-col w-full h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col w-full h-full">
        <Dialog.Header
          onClose={onClose}
          title={getHeaderTitle(title, subTitle, onTabSelect, group)}
        />
        {
          {
            details: <DetailsForm />,
            users: <UsersForm group={group} />,
            permission: <PermissionForm />,
          }[tabSelected]
        }
      </div>
      <FormUserActions
        onCancel={_onCancel}
        onSave={_onSave}
        isAbandoning={isAbandoning}
        isLoading={isSaving}
      />
    </form>
  );
};

export default function Index({ group = null, ...rest }: Props) {
  return <Form group={group} {...rest} />;
}

const getHeaderTitle = (title, subTitle, onTabSelect, group) => (
  <WithThemeProvider
    token={{ colorPrimaryActive: '#EE5919', colorPrimary: '#ee5919' }}
  >
    <div className="flex h-full w-full">
      {getHeaderTitleTabs(title, subTitle, onTabSelect, group)}
    </div>
  </WithThemeProvider>
);

function getHeaderTitleTabs(title, subTitle, onTabSelect, group) {
  return group ? (
    <StyledTabs
      defaultActiveKey="1"
      className="w-full"
      onTabClick={onTabSelect}
      tabBarExtraContent={{
        left: <TabBarExtraHeader title={title} subTitle={subTitle} />,
      }}
    >
      <TabPane tab="Group Details" key="details" />
      <TabPane tab="Users" key="users" />
      <TabPane tab="Permission" key="permission" />
    </StyledTabs>
  ) : (
    <div className="flex items-center">
      <div className="relative border-solid border-r border-gray-200 pr-4">
        <h3 className="text-[18px] font-semibold text-primary">{title}</h3>
      </div>

      <div className="ml-4">Group Details</div>
    </div>
  );
}
