import { ICellRendererParams } from 'ag-grid-community';
import { Spin, Tabs } from 'antd';
import { useGetBookingByIdLazyQuery } from 'api/graphql/generated/serviceTypesAndHooks';
import { legGridConfig } from 'containers/Financial/Invoicing/components/legGridConfig';
import React, { Suspense, useEffect, useState } from 'react';
import { styled } from 'twin.macro';
import CostAndChargeGrid from './CostAndChargeGrid';
import { chargeGridOptions } from './CostAndChargeGrid/gridOptions';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  padding: 10px;
  margin-left: 40px;
  .ant-tabs-tab {
    background: #f9fafb !important;
    color: #8d8d8d;
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    &:hover {
      color: #001529;
    }
  }
  .ant-tabs-tab-active {
    background: #fff !important;
    .ant-tabs-tab-btn div {
      color: #8d8d8d;
      font-weight: bold;
    }
  }
`;

const StyledTabPane = styled(TabPane)`
  padding-left: 20px;
`;

const AgGrid = React.lazy(() => import('components/ReactGrid'));

export default function BookingDetailExpand(
  props: Readonly<ICellRendererParams>,
) {
  const { data } = props;
  const [bookingExpandItem, setBookingExpandItem] = useState<any>(null);

  const [getBookingExpandDetailsQuery] = useGetBookingByIdLazyQuery({
    onCompleted: response => {
      if (response?.bookingById) {
        setBookingExpandItem(response?.bookingById);
      }
    },
  });

  useEffect(() => {
    if (data?.id) {
      getBookingExpandDetailsQuery({
        variables: { bookingId: data?.id },
      });
    }
  }, [data?.id]);

  const renderLegs = () => {
    return (
      <StyledTabPane key="legs" tab={<div>Moves</div>}>
        <div id="leg-grid" className="min-h-[150px] mb-[10px]">
          <Suspense fallback={<div>Loading...</div>}>
            <AgGrid
              rowData={bookingExpandItem?.bookingLegs}
              gridOptions={legGridConfig}
              hideOverlay={true}
            />
          </Suspense>
        </div>
      </StyledTabPane>
    );
  };

  const renderSurcharges = () => {
    return (
      <StyledTabPane key="surcharges" tab={<div>Costs & Charges</div>}>
        <CostAndChargeGrid
          gridConfig={chargeGridOptions}
          surcharges={bookingExpandItem?.bookingSurcharges}
          cartageCharges={bookingExpandItem?.bookingLines}
          legs={bookingExpandItem?.bookingLegs}
        />
      </StyledTabPane>
    );
  };
  return (
    <StyledTabs type="card" tabBarGutter={0}>
      {bookingExpandItem ? (
        <>
          {renderSurcharges()}
          {renderLegs()}
        </>
      ) : (
        <Spin tip="Loading" size="large" />
      )}
    </StyledTabs>
  );
}
