export function sortByNumber(valueA, valueB, _nodeA, _nodeB, isInverted) {
  if (valueA === valueB) {
    return 0;
  }
  // for null
  if (valueA === undefined || valueA === null || valueA === 0) {
    return isInverted ? -1 : 1;
  }
  if (valueB === undefined || valueB === null || valueB === 0) {
    return isInverted ? 1 : -1;
  }
  return valueA.toString().localeCompare(valueB.toString());
}

export function sortByText(valueA, valueB, _nodeA, _nodeB, isInverted) {
  if (valueA === valueB) {
    return 0;
  }
  // for null
  if (valueA === undefined || valueA === null) {
    return isInverted ? -1 : 1;
  }
  if (valueB === undefined || valueB === null) {
    return isInverted ? 1 : -1;
  }
  return valueA.localeCompare(valueB);
}
