import { Input } from 'antd';
import Field from 'components/Form/Field';
import WithFormHooksController from 'components/common/WithFormHooksController';
import { useFormHooks } from 'context/FormHooksContext';
import React from 'react';

export default function DetailsForm() {
  const { control } = useFormHooks();
  return (
    <div className="grid gap-3 grid-cols-12 h-auto px-3 mt-3">
      <Field label="Name" isRequired span={12}>
        <WithFormHooksController
          control={control}
          name="name"
          component={<Input className="px-2 rounded-md" />}
        />
      </Field>
      <Field label="Description" span={12}>
        <WithFormHooksController
          control={control}
          name="description"
          component={<Input className="px-2 rounded-md" />}
        />
      </Field>
    </div>
  );
}
