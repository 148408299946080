import { message } from 'antd';
import Dialog from 'components/Dialog';
import { IDialogHeader } from 'components/Dialog/types';
import WithThemeProvider from 'components/providers/WithThemeProvider';
import { useFormHooks } from 'context/FormHooksContext';
import { getErrorMessage } from 'helpers/form-errors';
import React, { ReactNode, useEffect, useRef } from 'react';
import FormUserActions from 'components/Form/FormUserActions';
import DetailsForm from './components/DetailsForm';

type Props = {
  vehicle?: any;
  onClose?: () => void;
  onSubmit: (data: any) => void;
} & IDialogHeader;

type FormProps = {
  detailsForm: ReactNode;
};

const getHeaderTitle = ({ title }) => (
  <WithThemeProvider
    token={{ colorPrimaryActive: '#EE5919', colorPrimary: '#ee5919' }}
  >
    <div className="flex h-full w-full">
      <div className="relative">
        <h3 className="text-[18px] font-semibold">{title}</h3>
      </div>
    </div>
  </WithThemeProvider>
);

const Form = ({
  onClose,
  onSubmit,
  title,
  vehicle,
  detailsForm,
}: Props & FormProps) => {
  const [isAbandoning, setIsAbandoning] = React.useState(false);
  const {
    handleSubmit,
    formState: { errors, isDirty },
  } = useFormHooks();

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (Object.keys(errors).length) {
      message.error(getErrorMessage(errors));
    }
  }, [errors]);

  // eslint-disable-next-line no-underscore-dangle
  async function _onSave() {
    setIsAbandoning(false);
    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  }

  // eslint-disable-next-line no-underscore-dangle
  function _onCancel() {
    if (isAbandoning || !isDirty) {
      onClose();
      return;
    }

    setIsAbandoning(true);
  }

  return (
    <form
      ref={formRef}
      className="flex flex-col w-full h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col w-full h-full">
        <Dialog.Header onClose={onClose} title={getHeaderTitle({ title })} />
        {detailsForm}
      </div>
      <FormUserActions
        onCancel={_onCancel}
        onSave={_onSave}
        isAbandoning={isAbandoning}
        isLoading={vehicle?.isSaving}
      />
    </form>
  );
};

export default function Index({ vehicle = null, ...rest }: Props) {
  return (
    <Form
      {...rest}
      vehicle={vehicle}
      detailsForm={<DetailsForm key="details" />}
    />
  );
}
