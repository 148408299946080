import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OrganizationState {
  workspaceData: any;
  itemDetails: any;
  flagRows: any[];
  newItem: any;
  updatedItem: any;
  selectedItems: any[];
}
export const initialState: OrganizationState = {
  workspaceData: null,
  itemDetails: null,
  flagRows: null,
  newItem: {},
  updatedItem: {},
  selectedItems: null,
};

const storeSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setWorkspaceData: (state, action: PayloadAction<any>) => {
      const { workspaceData } = action.payload;
      state.workspaceData = workspaceData;
    },
    setItemDetails: (state, action: PayloadAction<any>) => {
      const { itemDetails } = action.payload;
      state.itemDetails = itemDetails;
    },
    setNewOrganization: (state, action: PayloadAction<any>) => {
      const { workspaceData, newItem, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.newItem = newItem;
      state.flagRows = flagRows;
    },
    setUpdatedOrganization: (state, action: PayloadAction<any>) => {
      const { workspaceData, updatedItem, flagRows } = action.payload;
      state.workspaceData = workspaceData;
      state.updatedItem = updatedItem;
      state.flagRows = flagRows;
    },
    setSelectedItems: (state, action: PayloadAction<any>) => {
      const { selectedItems } = action.payload;
      state.selectedItems = selectedItems;
    },
  },
});

export const selectWorkspaceData = state => state.organizations.workspaceData;
export const selectFlagRows = state => state.organizations.flagRows;
export const selectItemDetails = state => state.organizations.itemDetails;
export const selectSelectedItems = state => state.organizations.selectedItems;

export const { actions, reducer, name: sliceKey } = storeSlice;
