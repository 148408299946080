import {
  GridOptions,
  RowGroupOpenedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import DefaultLayout from 'components/Layout/DefaultLayout';
import Booking from 'containers/Dispatchs/Booking/DetailsScreen';
import useApp from 'context/app/hooks';
import { ModalMode } from 'context/app/types';
import useInvoices from 'context/financials_invoices/hooks';
import { actions, reducer, sliceKey } from 'context/workspace/slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import { invoiceScreenConfig } from './config';
import { invoiceGridOptions } from './gridOptions';

export default () => {
  //= ========================== init ===================//
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();
  const { openDialog } = useApp();

  const {
    invoiceWorkpaceData,
    selectedItems,
    bookingDetails,
    setSelectedItems,
    cancelInvoices,
    getInvoiceList,
    refreshWorkspaceData,
    resetItemDetails,
    flagRows,
  } = useInvoices();

  //= ========================== handler ===================//

  const handleCancelInvoices = async invoiceIds => {
    const result = await cancelInvoices(invoiceIds);
    let resultMessage = 'Cancel invoice(s) successfully';
    if (!result?.data?.cancelInvoices) {
      resultMessage = 'Cannot cancel invoice(s)';
    }
    return {
      message: resultMessage,
      data: result?.data?.cancelInvoices,
    };
  };

  const handleRowGroupOpened = async (event: RowGroupOpenedEvent) => {
    const { api } = event;
    if (api.isToolPanelShowing()) {
      // if the toolpanel of sidebar on grid, close the tool panel
      api.closeToolPanel();
    }
  };

  const filterRowNodes = (rowNode, selectedRows) => {
    return selectedRows.find(row => row.id === rowNode.data.id);
  };

  const gridOptions = {
    ...invoiceGridOptions,
    onSelectionChanged: (event: SelectionChangedEvent) => {
      const { api } = event;
      const records = [];
      const selectedRows = api.getSelectedRows();
      api.forEachNodeAfterFilter((rowNode, _index) => {
        if (filterRowNodes(rowNode, selectedRows)) {
          records.push(rowNode.data);
        }
      });
      setSelectedItems([...records]);
    },
    onRowGroupOpened: event => {
      handleRowGroupOpened(event);
    },
  } as GridOptions;

  //= ========================== useEffect ===================//

  useEffect(() => {
    dispatch(
      actions.setWorkspace({
        workspace: {
          ...invoiceScreenConfig,
          gridOptions,
        },
        query: {
          cursor: null,
          filters: null,
          order: { id: 'DESC' },
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (bookingDetails) {
      openDialog({
        content: (
          <Booking
            bookingType={bookingDetails?.bookingType?.name}
            mode={ModalMode.MODIFY}
            status={bookingDetails?.bookingLegEventStatus?.code}
            record={bookingDetails}
          />
        ),
        dialogProps: {
          height: 90,
        },
      });
    }
    return () => {
      resetItemDetails();
    };
  }, [bookingDetails]);

  return (
    <DefaultLayout
      gridOptions={gridOptions}
      data={invoiceWorkpaceData}
      flagRows={flagRows}
      selectedItems={selectedItems}
      onGetDataList={getInvoiceList}
      onRefreshDataList={refreshWorkspaceData}
      onDelete={handleCancelInvoices}
    />
  );
};
