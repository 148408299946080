import { Collapse } from 'antd';
import React from 'react';
import { StyledCollapse } from './style';

const { Panel } = Collapse;

/**
 *
 * Styled Collapsible content a.k.a. Accordion based on Antd Collapse component
 *
 * @export CollapsibleContent
 * @param {{
 *   isOpen?: boolean;
 *   label: React.ReactNode
 *   content: React.ReactNode;
 *   extra?: React.ReactNode;
 * }} {
 *   label,
 *   content,
 *   isOpen,
 *   extra
 * }
 * @returns JSX.Element
 */

type Props = Readonly<{
  isOpen?: boolean;
  label: React.ReactNode;
  content: React.ReactNode;
  extra?: React.ReactNode;
}>;

export default function CollapsibleContent({
  label,
  content,
  isOpen,
  extra,
}: Props) {
  return (
    <StyledCollapse defaultActiveKey={isOpen ? ['1'] : null}>
      <Panel key="1" header={label} extra={extra}>
        {content}
      </Panel>
    </StyledCollapse>
  );
}
