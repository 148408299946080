import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// FIXME: runtime error on focus
// if not visible in view

// https://github.com/jquense/yup
const schema = yup
  .object()
  .shape({
    name: yup.string().required({ message: 'Vehicle name required' }),
    divisionId: yup.number().required({ message: 'Division required' }),
  })
  .required();
export { schema };

export default yupResolver(schema);
