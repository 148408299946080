/* eslint-disable react/no-unescaped-entities */

import { ModuleTypes } from 'components/config/types/modules';
import useAuthentication from 'context/security_authentication/hook';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignInForm from './SignIn.Form';

export default function Home() {
  const {
    currentAccount,
    userAuthenticate,
    setAzureUser,
  } = useAuthentication();
  const navigate = useNavigate();

  const [isCheckingCredentials, setIsCheckingCredentials] = useState(true);

  useEffect(() => {
    checkCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAuthSuccess = useCallback(() => {
    if (currentAccount?.organizationTypeId === 1) {
      navigate(`/dashboard`);
      return;
    }

    navigate(`${ModuleTypes.Booking.url}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  async function checkCurrentUser() {
    const credentials = localStorage?.getItem('icosAuth') ?? null;

    if (credentials) {
      setIsCheckingCredentials(true);
      const authToken = JSON.parse(credentials);

      if (authToken?.accessToken) {
        const user = await userAuthenticate(authToken);

        if (user) {
          setAzureUser(user);
          setTimeout(() => {
            setIsCheckingCredentials(false);
            navigate('/dashboard');
          }, 1500);
        }
      }
    }

    setIsCheckingCredentials(false);
  }

  return (
    <div
      className="grid grid-cols-2 w-full box-border overflow-hidden"
      style={{ gridTemplateColumns: 'min-content 1fr' }}
    >
      <div className="w-[500px] grid place-items-center bg-white">
        <SignInForm
          onAuthSuccess={onAuthSuccess}
          isCheckingCredentails={isCheckingCredentials}
        />
        <div className="text-primary text-sm absolute bottom-[20px]">
          © 2023 ICOS Global All Rights Reserved
        </div>
      </div>
      <div className="bg-mainOrange flex items-center justify-center h-screen min-w-[400px] overflow-x-hidden">
        <div className="flex flex-col items-center text-center text-[1.2rem] lg:text-[1.7rem] -mt-24">
          <h1 className="text-white font-semibold">
            The Smart Solution for Freight and Logistics
          </h1>
          <div className="flex flex-col items-center">
            <div className="absolute ml-[100px]">
              <div className="flex flex-col items-center">
                <img
                  width={680}
                  src={`${process.env.PUBLIC_URL}/assets/splash_dispatch.1x-min.png`}
                  alt="dispatch"
                />
              </div>
            </div>
            <div className="relative top-[120px] ml-[-100px]">
              <img
                width={600}
                src={`${process.env.PUBLIC_URL}/assets/splash_dashboard.1x-min.png`}
                alt="dispatch"
              />
              <div className="flex justify-center ml-[100px]">
                <img
                  width={170}
                  src={`${process.env.PUBLIC_URL}/assets/splash_global-knows.1.5x.png`}
                  alt="global knows"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
