import { ModalProps } from 'antd';
import { create } from 'zustand';
/* Dialog Props
 *
 * @content: React.ReactElement | JSX.Element;
 * @dialogProps?: ModalProps & { height?: number, width?: number };
 *
 * @export
 * @interface IDialog
 * */
export interface IDialog {
  content: React.ReactElement | JSX.Element;
  // https://ant.design/components/modal#modalusemodal
  dialogProps?: ModalProps & {
    // extra custom visual props

    // in percent
    height?: number | string;
    width?: number | string;
  };
}

interface IState {
  dialog?: IDialog;
}

export interface IActions {
  closeDialog: () => void;
  openDialog: (params: IDialog) => void;
}

const useDialogStore = create<IState & IActions>(set => ({
  closeDialog: () => set({ dialog: null }),
  openDialog: params => set({ dialog: params }),
}));

export default useDialogStore;
