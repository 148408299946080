import { ICellRendererParams } from 'ag-grid-community';
import { Select } from 'antd';
import useGenericCodeLists from 'context/genericCodeLists/hooks';

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

interface ISelectCellParams extends ICellRendererParams {
  value: string;
}

export default forwardRef((props: ISelectCellParams, ref) => {
  const [value, setValue] = useState(props.value);
  const { genericCodeListData } = useGenericCodeLists();
  const [formattedOptions, setFormattedOptions] = useState(null);

  const handleOnChange = value => {
    setValue(value);
  };

  useEffect(() => {
    const options = genericCodeListData?.BookingServiceType?.map(item => {
      return {
        value: item?.id,
        label: item?.name,
      };
    });
    setFormattedOptions(options);
  }, [genericCodeListData]);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
      afterGuiAttached: () => {
        setValue(props.value);
      },
    };
  });

  return (
    <Select
      style={{ width: '100%' }}
      value={value}
      onChange={handleOnChange}
      bordered={false}
      options={formattedOptions}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    />
  );
});
