import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BookingRateState {
  queryOrgList: {};
  queryRateGroupList: {};
  queryFafGroupList: {};
  queryFafGroupNewList: {};
  queryavailableFafGroupNewOrgsList: {};
  orgList: [];
  rateGroupList: [];
  fafGroupList: [];
  fafGroupNewList: [];
  fafGroupRates: [];
  fafGroupCustomers: [];
  availableFafGroupNewOrgsList: [];
  rates: any;
  rateLines: any;
  selectedRate: {};
  selectedMenu: {};
  selectedFafGroupNew: {};
  editingAddressFrom: any;
  editingAddressTo: any;
}
export const initialState: BookingRateState = {
  queryOrgList: {},
  queryRateGroupList: {},
  queryFafGroupList: {},
  queryFafGroupNewList: {},
  queryavailableFafGroupNewOrgsList: {},
  orgList: [],
  rateGroupList: [],
  fafGroupList: [],
  fafGroupRates: [],
  fafGroupCustomers: [],
  fafGroupNewList: [],
  availableFafGroupNewOrgsList: [],
  rates: {},
  rateLines: {},
  selectedRate: {},
  selectedMenu: {},
  selectedFafGroupNew: null,
  editingAddressFrom: {},
  editingAddressTo: {},
};

const storeSlice = createSlice({
  name: 'bookingRates',
  initialState,
  reducers: {
    setQueryOrgList: (state, action: PayloadAction<any>) => {
      const { query } = action.payload;
      state.queryOrgList = query;
    },
    setQueryRateGroupList: (state, action: PayloadAction<any>) => {
      const { query } = action.payload;
      state.queryRateGroupList = query;
    },
    setQueryFafGroupList: (state, action: PayloadAction<any>) => {
      const { query } = action.payload;
      state.queryFafGroupList = query;
    },
    setOrgList: (state, action: PayloadAction<any>) => {
      const { orgList } = action.payload;
      state.orgList = orgList;
    },
    setRateGroupList: (state, action: PayloadAction<any>) => {
      const { rateGroupList } = action.payload;
      state.rateGroupList = rateGroupList;
    },
    setFafGroupList: (state, action: PayloadAction<any>) => {
      const { fafGroupList } = action.payload;
      state.fafGroupList = fafGroupList;
    },
    setRates: (state, action: PayloadAction<any>) => {
      const { nodes, loading, selectedRow } = action.payload;
      state.rates = { nodes, loading };
      state.selectedRate = selectedRow;
    },
    setRateLines: (state, action: PayloadAction<any>) => {
      const { nodes, loading } = action.payload;
      state.rateLines = { nodes, loading };
    },
    setSelectedRate: (state, action: PayloadAction<any>) => {
      const { selectedRow } = action.payload;
      state.selectedRate = selectedRow;
    },
    setSelectedMenu: (state, action: PayloadAction<any>) => {
      const { selectedMenu } = action.payload;
      state.selectedMenu = selectedMenu;
    },
    setSelectedFafGroupNew: (state, action: PayloadAction<any>) => {
      const { selectedFafGroupNew } = action.payload;
      state.selectedFafGroupNew = selectedFafGroupNew;
    },
    setQueryFafGroupNewList: (state, action: PayloadAction<any>) => {
      const { query } = action.payload;
      state.queryFafGroupNewList = query;
    },
    setFafGroupNewList: (state, action: PayloadAction<any>) => {
      const { fafGroupList } = action.payload;
      state.fafGroupNewList = fafGroupList;
    },
    setFafGroupRates: (state, action: PayloadAction<any>) => {
      const { fafGroupRates } = action.payload;
      state.fafGroupRates = fafGroupRates;
    },
    setFafGroupCustomers: (state, action: PayloadAction<any>) => {
      const { fafGroupCustomers } = action.payload;
      state.fafGroupCustomers = fafGroupCustomers;
    },
    setAvailableFafGroupNewOrgs: (state, action: PayloadAction<any>) => {
      const { availableFafGroupNewOrgsList } = action.payload;
      state.availableFafGroupNewOrgsList = availableFafGroupNewOrgsList;
    },
    setAddressFrom: (state, action: PayloadAction<any>) => {
      const { addressFrom } = action.payload;
      state.editingAddressFrom = addressFrom;
    },
    setAddressTo: (state, action: PayloadAction<any>) => {
      const { addressTo } = action.payload;
      state.editingAddressTo = addressTo;
    },
    setRefreshData: (state, action: PayloadAction<any>) => {
      const {
        rates,
        rateLines,
        selectedRate,
        editingAddressFrom,
        editingAddressTo,
      } = action.payload;
      state.rates = rates;
      state.rateLines = rateLines;
      state.selectedRate = selectedRate;
      state.editingAddressFrom = editingAddressFrom;
      state.editingAddressTo = editingAddressTo;
    },
    setRefreshEditingAddress: (state, action: PayloadAction<any>) => {
      const { editingAddressFrom, editingAddressTo } = action.payload;
      state.editingAddressFrom = editingAddressFrom;
      state.editingAddressTo = editingAddressTo;
    },
  },
});

export const selectBookingRates = state => state?.bookingRates;
export const selectRateGroups = state => state?.bookingRates?.rateGroupList;
export const selectFafGroups = state => state?.bookingRates?.fafGroupList;
export const selectCustomerList = state => state?.bookingRates?.orgList;
export const selectRates = state => state?.bookingRates?.rates;
export const selectRateLines = state => state?.bookingRates?.rateLines;
export const selectSelectedRate = state => state?.bookingRates?.selectedRate;
export const selectSelectedMenu = state => state?.bookingRates?.selectedMenu;
export const selectAddressFrom = state =>
  state?.bookingRates?.editingAddressFrom;
export const selectAddressTo = state => state?.bookingRates?.editingAddressTo;
export const selectAvailableFafGroupNewOrgsList = state =>
  state?.bookingRates?.availableFafGroupNewOrgsList;
export const selectFafGroupNewList = state =>
  state?.bookingRates?.fafGroupNewList;
export const selectFafGroupNew = state =>
  state?.bookingRates?.selectedFafGroupNew;
export const selectFafGroupRates = state => state?.bookingRates?.fafGroupRates;
export const selectFafGroupCustomers = state =>
  state?.bookingRates?.fafGroupCustomers;
export const { actions, reducer, name: sliceKey } = storeSlice;
