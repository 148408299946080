import { create } from 'zustand';

interface IState {
  selectedOrganizationId: number | undefined;
  isSaving: boolean;
}

interface IActions {
  setSelectedOrganizationId: (id: number) => void;
  setIsSaving: (status: boolean) => void;
  reset: () => void;
}

const useUsersStore = create<IState & IActions>(set => ({
  selectedOrganizationId: null,
  isSaving: false,
  setSelectedOrganizationId(id) {
    set({ selectedOrganizationId: id });
  },
  setIsSaving: (status: boolean) =>
    set({
      isSaving: status,
    }),
  reset: () =>
    set({
      selectedOrganizationId: undefined,
    }),
}));

export default useUsersStore;
