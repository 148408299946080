import { GridOptions, SelectionChangedEvent } from 'ag-grid-community';
import { UserFilterInput } from 'api/graphql/generated/serviceTypesAndHooks';
import { useDialog } from 'components/Dialog/context/DialogProvider';
import DefaultLayout from 'components/Layout/DefaultLayout';
import { userScreenConfig } from 'containers/UsersAndGroups/Users/MainScreen/config';
import useUsers from 'context/security_users/hooks';
import { actions, reducer, sliceKey } from 'context/workspace/slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import UserForm from '../components/Form';
import { userGridOptions } from './gridOptions';

const dialogProps = {
  closeable: false,
  height: '620px',
};

export default () => {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();
  const {
    userWorkpaceData,
    flagRows,
    selectedItems,
    getUserList,
    refreshWorkspaceData,
    deleteUsers,
    setSelectedItems,
  } = useUsers();

  const { openDialog, closeDialog } = useDialog();

  const gridOptions = {
    ...userGridOptions,
    onSelectionChanged: (event: SelectionChangedEvent) => {
      const { api } = event;
      const records = [];
      const selectedRows = api.getSelectedRows();
      api.forEachNodeAfterFilter((rowNode, _index) => {
        if (selectedRows.find(row => row.id === rowNode.data.id))
          records.push(rowNode.data);
      });
      setSelectedItems([...records]);
    },
  } as GridOptions;

  useEffect(() => {
    dispatch(
      actions.setWorkspace({
        workspace: { ...userScreenConfig, gridOptions: userGridOptions },
        query: {
          cursor: null,
          filters: {
            isDeleted: { neq: true },
          } as UserFilterInput,
          order: { id: 'DESC' },
        },
      }),
    );
  }, []);

  function _onModifyUser(data) {
    openDialog({
      content: <UserForm user={data} afterValidation={closeDialog} />,
      dialogProps: {
        ...dialogProps,
        width: '750px',
        height: '560px',
      },
    });
  }

  function _onCreateUser() {
    openDialog({
      content: <UserForm afterValidation={closeDialog} />,
      dialogProps: {
        ...dialogProps,
        width: '650px',
      },
    });
  }

  const handleDeleteUser = async (ids: number[]) => {
    const result = await deleteUsers(ids);
    let message = 'Delete user(s) successfully';

    if (!result?.data?.deleteUsers) {
      message = 'Cannot delete(s) user';
    }
    return {
      message,
      data: result?.data?.deleteUsers,
    };
  };

  return (
    <DefaultLayout
      gridOptions={gridOptions}
      data={userWorkpaceData}
      selectedItems={selectedItems}
      flagRows={flagRows}
      onGetDataList={getUserList}
      onRefreshDataList={refreshWorkspaceData}
      onViewRecord={_onModifyUser}
      onCreateNew={_onCreateUser}
      onDelete={handleDeleteUser}
    />
  );
};
