import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  TimePicker,
} from 'antd';
import DateFormats from 'components/config/date';
import Modal from 'components/Modal/index';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

type Props = Readonly<{
  visible: boolean;
  content?: any;
  title: string;
  onClose: () => void;
  onConfirm: (input: any) => void;
}>;

function Pod(props: Props) {
  const { visible, content, title, onClose, onConfirm } = props;
  const [value, setValue] = useState(false);
  const [deliverToName, setDeliverToName] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  const [timeValue, setTimeValue] = useState(null);
  const disabled = !value;
  // =================== Handlers ===========================//
  const handleChange = () => {
    setValue(!value);
  };
  const handleTimeChange = timeString => {
    setTimeValue(timeString);
  };
  const handleDateChange = dateString => {
    setDateValue(dateString);
  };
  const handleNameChange = e => {
    setDeliverToName(e?.target?.value);
  };
  const handleCloseModal = () => {
    onClose();
  };

  const handleAccept = async () => {
    const input = {
      bookingIds: [content?.id],
      deliverToName,
      deliverDateTime: dateValue
        .format(DateFormats.asPayloadDate)
        .toString()
        .concat(' ', timeValue.format('HH:mm').toString()),
    };
    onConfirm(input);
  };
  // each time showing modal, set date time back to now
  useEffect(() => {
    setDateValue(dayjs(Date.now()));
    setTimeValue(dayjs(Date.now()));
    setValue(false);
    setDeliverToName(null);
  }, [visible]);
  // =================== Render components ===========================//
  const renderForm = () => {
    return (
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item label="Booking Id">
              <Input readOnly type="text" value={content?.id} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Client">
              <Input readOnly type="text" value={content?.client} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Docket">
              <Input readOnly type="text" value={content?.docket} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Delivered To">
              <Input
                type="text"
                value={deliverToName?.toUpperCase()}
                onChange={e => handleNameChange(e)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Delivered Date Time">
              <Input.Group compact>
                <Form.Item>
                  <DatePicker
                    format={'DD-MM-YYYY'}
                    value={dateValue}
                    onChange={dateString => handleDateChange(dateString)}
                  />
                </Form.Item>
                <Form.Item>
                  <TimePicker
                    format="HH:mm"
                    value={timeValue}
                    onChange={timeString => handleTimeChange(timeString)}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="POD">
              <Checkbox checked={value} onChange={handleChange} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const renderFooter = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          danger
          className="rounded-lg shadow-md"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          className="rounded-lg shadow-md"
          onClick={handleAccept}
          disabled={disabled}
        >
          Accept
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title={title}
      width={800}
      visible={visible}
      onClose={handleCloseModal}
      footer={renderFooter()}
    >
      {renderForm()}
    </Modal>
  );
}

export default Pod;
