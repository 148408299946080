import { Layout, Menu } from 'antd';
import tw, { styled } from 'twin.macro';

const { SubMenu } = Menu;
const { Sider } = Layout;

const StyledSider = styled(Sider)`
  ${tw`shadow-lg`}
  z-index: 100;

  /* .ant-layout-sider-children {
    background-color: red;
  } */
  .ant-menu-inline-collapsed {
    width: 50px;
  }

  .sidebar-wrapper {
    position: relative;
  }
`;

const StyledMenu = styled(Menu)`
  .ant-menu-submenu-open > .ant-menu-submenu-title {
    color: white;
  }

  .ant-menu-title-content {
    font-weight: 400 !important;
  }

  .ant-menu-item-selected {
    background-color: transparent;
    font-weight: 400 !important;
  }
  .any-menu-item:active {
    background-color: transparent;
  }
`;

const StyledSubMenu = styled(SubMenu)`
  .ant-menu-submenu-title {
    /* font-size: 14px;
    font-weight: bold; */
    padding-left: 14px !important;
    line-height: 38px;
    height: 38px;
  }

  .anticon {
    vertical-align: baseline;
  }

  .ant-menu-item {
    line-height: 30px !important;
    height: 30px !important;
  }

  .ant-menu-item a {
    font-size: 13px !important;
  }
`;

const QuickLinkContainer = styled.div`
  position: relative;
  &.quick-link-container-collapsed {
    margin: 12px 0 !important;
    display: flex;
    justify-content: center;
    border: none;
    width: auto;
    align-items: center;
    flex: 1;

    & > .anticon {
      display: none;
    }
  }

  &.quick-link-container {
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 8px;
    margin-bottom: 0px;
    padding-bottom: 8px;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f4f4f4;
  }
`;

const QuickLinkButton = styled.div.attrs({
  className: 'quick-link-button',
})`
  .icon-quick-link {
    font-size: 1rem;
    line-height: 1rem;
    padding: 4px;
    background: #f4f4f4;
    height: 28px;
    width: 28px;

    svg {
      margin-top: 1px;
    }
  }
`;

const QuickLinkWrapper = styled.div`
  z-index: 100;
  background-color: #fff;
  width: max-content;
  min-width: 172px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  border: 1px solid #d9d9d9;
  border-top: none;
  position: absolute;
  padding: 20px 0;
  top: 0;
  margin-left: 30px;

  .quick-link-menu-item {
    cursor: pointer;
    line-height: 2.5rem;
    height: 2.5rem;
    position: relative;

    & > div {
      flex: 1;
      justify-content: space-between;
      display: flex;
      align-items: center;
      align-content: center;
      padding: 0 14px;

      & > .icon-quick-link-menu {
        font-size: 10px;
      }

      div {
        text-transform: capitalize;
        span {
          vertical-align: middle;

          svg {
            margin-right: 10px;
            margin-top: -2px;
          }
        }
      }
    }

    &:hover {
      & > div {
        background-color: #f4f4f4;
      }

      & > .quick-link-submenu {
        display: block;
      }
    }
  }

  .quick-link-submenu {
    z-index: 100;
    background-color: #fff;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    border: 1px solid #d9d9d9;
    border-top: none;
    position: absolute;
    top: 0;
    right: -170px;
    padding: 12px 0;
    width: inherit;
    border-radius: 4px;
    display: none;

    & > li {
      & > div {
        padding: 0 14px;
        margin-bottom: 4px;

        .anticon {
          margin-right: 4px;
        }
      }

      &:hover > div {
        background-color: #f4f4f4;
      }
    }
  }
`;

export {
  QuickLinkButton,
  QuickLinkContainer,
  QuickLinkWrapper,
  StyledMenu,
  StyledSider,
  StyledSubMenu,
};
