import { GridOptions } from 'ag-grid-community';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';
import DeleteRow from 'components/ReactGrid/renderers/DeleteRow';
import { ICustomCellRendererParams } from 'components/ReactGrid/renderers/types';

const suppressKbEvent = params => {
  const KEY_ENTER = 13;
  const KEY_LEFT = 37;
  const KEY_UP = 38;
  const KEY_RIGHT = 39;
  const KEY_DOWN = 40;
  const { event } = params;
  const key = event.which;
  const editingKeys = [KEY_LEFT, KEY_RIGHT, KEY_UP, KEY_DOWN, KEY_ENTER];
  return params.editing && editingKeys.indexOf(key) >= 0;
};

export const chargeGridOptions = {
  suppressContextMenu: true,
  stopEditingWhenCellsLoseFocus: true,
  headerHeight: 24,
  rowHeight: 24,
  components: {
    CustomCell,
    DeleteRow,
  },
  defaultColDef: {
    flex: 1,
    resizable: true,
    sortable: false,
    suppressMenu: true,
    enablePivot: true,
    suppressPropertyNamesCheck: true,
    cellStyle: { paddingLeft: '12px', fontSize: '12px' },
    cellRenderer: 'CustomCell',
  },
  columnDefs: [
    {
      field: 'id',
      colId: 'id',
      hide: true,
    },
    {
      field: 'surchargeId',
      colId: 'surchargeId',
      valueGetter(params) {
        return params.data.id;
      },
      hide: true,
    },
    {
      headerName: 'Name',
      width: 100,
      field: 'surcharge.name',
      colId: 'name',
      suppressKeyboardEvent: suppressKbEvent,
    },

    {
      headerName: 'Code',
      width: 60,
      field: 'surcharge.code',
      colId: 'code',
    },

    {
      headerName: 'Category',
      width: 60,
      field: 'surcharge.category',
    },

    {
      headerName: 'Leg',
      width: 60,
      field: 'leg',
      valueGetter(params) {
        return params?.data?.leg
          ? `${params?.data?.leg?.bookingPlaceFrom?.organizationLocation?.placeName} -> ${params?.data?.leg?.bookingPlaceTo?.organizationLocation?.placeName}`
          : null;
      },
      editable: false,
    },
    {
      headerName: 'Leg Event',
      width: 60,
      field: 'legDetails',
      valueGetter(params) {
        return params.data?.legDetails?.code;
      },
      editable: false,
    },
    {
      headerName: 'Note',
      width: 100,
      field: 'note',
    },

    {
      headerName: 'Quantity',
      width: 50,
      field: 'quantity',
      cellClass: 'cellRight',
      suppressKeyboardEvent: suppressKbEvent,
      cellRendererParams: {
        type: 'number',
      } as ICustomCellRendererParams,
    },
    {
      headerName: 'Cost',
      width: 100,
      field: 'cost',
      cellClass: 'cellRight',
      suppressKeyboardEvent: suppressKbEvent,
      cellRendererParams: {
        type: 'currency',
      } as ICustomCellRendererParams,
    },
    {
      headerName: 'Charge',
      width: 100,
      field: 'charge',
      cellClass: 'cellRight',
      suppressKeyboardEvent: suppressKbEvent,
      cellRendererParams: {
        type: 'currency',
      } as ICustomCellRendererParams,
    },
    {
      headerName: 'Amount',
      width: 100,
      field: 'amount',
      cellClass: 'cellRight',
      suppressKeyboardEvent: suppressKbEvent,
      cellRendererParams: {
        type: 'currency',
      } as ICustomCellRendererParams,
    },
  ],
} as GridOptions;
