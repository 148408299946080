export function getNamespaceColor(namespace: string = '') {
  switch (namespace) {
    case 'activity':
      return '#FF7A00';
    case 'pickup':
      return '#6FCD12';
    case 'delivery':
      return '#A23CDF';
    default:
      return '#A8AEB8';
  }
}
