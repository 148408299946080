import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types/RootState';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.rightSideMenu || initialState;

export const selectShowRightSideMenu = createSelector(
  [selectDomain],
  state => state.showRightSideMenu,
);

export const selectActiveSideMenu = createSelector(
  [selectDomain],
  state => state.activeSideMenu,
);
