import { create } from 'zustand';
import { RESOURCE_ERROR } from './types/resource';

export type TResourceErrorCode = RESOURCE_ERROR | null;

interface IState {
  error: {
    code: TResourceErrorCode;
  };
}

interface IActions {
  setError: (e: TResourceErrorCode) => void;
}

const useErrors = create<IState & IActions>(set => ({
  error: { code: null },
  setError: (e: TResourceErrorCode) => set(() => ({ error: { code: e } })),
}));

export default useErrors;
