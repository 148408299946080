import { Button } from 'antd';
import tw, { styled } from 'twin.macro';

const StyledForm = styled.div`
  ${tw`w-full h-full relative`}
  .filter-fields {
    ${tw`w-full flex overflow-y-auto`} &::-webkit-scrollbar-track {
      background-color: ${p => p.theme.styledFormBackgroundColor};
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: ${p => p.theme.webkitScrollbarBackgroundColor};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${p => p.theme.webkitScrollbarThumbBackgroundColor};
    }
  }

  .list-container {
    ${tw`relative`}
    div[class*='ant-spin'] {
      height: inherit;
    }
    .ag-row .ag-cell {
      display: flex;
      align-items: center;
    }
  }

  .footer-container {
    ${tw`relative mr-10 mb-1`}
    justify-content: flex-end;
    text-align: end;
  }
`;

const StyledSubmitButton = styled(Button)`
  background-color: ${({ disabled, theme }) =>
    disabled ? `grey !important` : theme.submitButtonColor};
  color: ${p => p.theme.submitButtonTextColor} !important;
  &:hover {
    background-color: ${({ theme }) => theme.submitButtonColor};
    color: ${p => p.theme.menuItemHoverTextColor};
  }
`;

const StyledCancelButton = styled(Button)`
  background-color: ${p => p.theme.cancelButtonColor} !important;
  color: ${p => p.theme.cancelButtonTextColor} !important;
`;

export { StyledCancelButton, StyledForm, StyledSubmitButton };
