import React from 'react';
import { ThemeProvider as OriginalThemeProvider } from 'styled-components';
import { useInjectReducer } from 'redux-injectors';
import { useSelector } from 'react-redux';
import { selectTheme, themeSliceKey, reducer } from './slice';

export const ThemeProvider = (props: { children: React.ReactChild }) => {
  useInjectReducer({ key: themeSliceKey, reducer });

  const theme = useSelector(selectTheme);
  return (
    <OriginalThemeProvider theme={theme}>
      {
        // eslint-disable-next-line react/destructuring-assignment
        React.Children.only(props.children)
      }
    </OriginalThemeProvider>
  );
};
