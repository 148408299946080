import React, { useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { StyledModal } from './styles';

type Props = React.PropsWithChildren<{
  loading?: boolean;
  title?: string;
  visible: boolean;
  width?: number;
  type?: string;
  onClose: () => void;
  onConfirm?: () => void;
  footer?: any;
}>;
type DraggableModalProps = React.PropsWithChildren<{
  disabled: boolean;
  bounds: any;
  draggleRef: React.RefObject<HTMLDivElement>;
  onStart: (event: DraggableEvent, uiData: DraggableData) => void;
}>;

function DraggableModal({
  children,
  disabled,
  bounds,
  draggleRef,
  onStart,
}: DraggableModalProps) {
  return (
    <Draggable
      disabled={disabled}
      bounds={bounds}
      onStart={(event, uiData) => onStart(event, uiData)}
    >
      <div ref={draggleRef}>{children}</div>
    </Draggable>
  );
}

function Modal(props: Props) {
  const {
    visible,
    title,
    width,
    children,
    type,
    footer,
    loading,
    onConfirm,
    onClose,
  } = props;
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = React.createRef<HTMLInputElement>();

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  return (
    <StyledModal
      wrapClassName={type}
      confirmLoading={loading}
      title={
        <div
          style={{
            fontSize: '15px',
            width: '100%',
            cursor: type === 'fullscreen-modal' ? 'pointer' : 'move',
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {title}
        </div>
      }
      modalRender={modal => {
        if (type !== 'fullscreen-modal') {
          return (
            <DraggableModal
              disabled={disabled}
              bounds={bounds}
              draggleRef={draggleRef}
              onStart={onStart}
            >
              {modal}
            </DraggableModal>
          );
        }
        return <div>{modal}</div>;
      }}
      width={width}
      open={visible}
      onCancel={onClose}
      onOk={onConfirm}
      footer={footer}
    >
      {children}
    </StyledModal>
  );
}

export default Modal;
