import { AddAssetInput } from 'api/graphql/generated/serviceTypesAndHooks';
import { create } from 'zustand';

interface IState {
  selectedVehicle:
    | (Partial<AddAssetInput> & { isSaving: boolean } & any)
    | null;
}

interface IActions {
  setSelectedVehicle: (vehicle: Partial<AddAssetInput>) => void;
  setIsSaving: (status: boolean) => void;
  reset: () => void;
}

const useVehiclesStore = create<IState & IActions>((set, _get) => ({
  selectedVehicle: null,
  contact: { new: [], updated: [], deleted: [] },
  setSelectedVehicle: (vehicle: any) => set({ selectedVehicle: vehicle }),
  setIsSaving: (status: boolean) =>
    set(state => ({
      selectedVehicle: {
        ...state.selectedVehicle,
        isSaving: status,
      },
    })),
  reset: () =>
    set({
      selectedVehicle: null,
    }),
}));

export default useVehiclesStore;
