import {
  CalculateRateInput,
  OrganizationLocationFilterInput,
  OrganizationOrganizationMappingFilterInput,
  useGetDangerousGoodLazyQuery,
  useGetOrgLocationsLazyQuery,
  useGetOrgOrgMappingsLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { OrganizationType } from 'components/config/types/common';
import useAuthentication from 'context/security_authentication/hook';
import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';

export interface ICalculateRateInput extends CalculateRateInput {
  suppressCalculate: boolean;
}

interface IBookingContextValue {
  bookingType: number;
  jobType: any;
  containerSize: number;
  portCode: any;
  freightPayers: any[];
  bookingLocations: any[];
  dangerousGoods: any[];
  calculateRateInput: ICalculateRateInput | null;
  containerItem: any[];
}

interface IBookingContextChange {
  changeBookingType: (type: number) => void;
  changeJobType: (type: any) => void;
  changePortCode: (code: any) => void;
  changeContainerSize: (sizeId: number) => void;
  changeCalculateRateInput: (data: ICalculateRateInput) => void;
  getBookingLocationList: () => void;
  getFreightPayerList: () => void;
  getDangerousGoodList: () => void;
  changeContainerItem: (data: any) => void;
}

const BookingContext = React.createContext({} as IBookingContextValue);
const BookingContextChange = React.createContext({} as IBookingContextChange);

export function useBookingContext() {
  const context = useContext(BookingContext);
  if (!context) {
    throw new Error('useBookingContext must be used within a BookingProvider');
  }
  return context;
}

export function useBookingContextChange() {
  const context = useContext(BookingContextChange);
  if (!context) {
    throw new Error(
      'useBookingContextChange must be used within a BookingProvider',
    );
  }
  return context;
}

export function BookingProvider(props: PropsWithChildren<any>) {
  const [jobType, setJobType] = useState<any>(null);
  const [portCode, setPortCode] = useState<any>(null);
  const [bookingType, setBookingType] = useState<number>(1);
  const [containerSize, setContainerSize] = useState(null);
  const [containerItem, setContainerItem] = useState<any>(null);
  const { currentAccount } = useAuthentication();

  const [
    calculateRateInput,
    setCalculateRateInput,
  ] = useState<ICalculateRateInput | null>(null);

  const [bookingLocations, setBookingLocations] = useState<any[]>([]);
  const [freightPayers, setFreightPayers] = useState<any[]>([]);
  const [dangerousGoods, setDangerousGoods] = useState<any[]>([]);

  const changeJobType = (type: any) => {
    setJobType(type);
  };

  const changePortCode = (code: any) => {
    setPortCode(code);
  };

  const changeBookingType = (type: number) => {
    setBookingType(type);
  };

  const changeContainerSize = (sizeId: number) => {
    setContainerSize(sizeId);
  };

  const changeCalculateRateInput = (data: ICalculateRateInput) => {
    const input = {
      ...calculateRateInput,
      ...data,
    };
    setCalculateRateInput(input);
  };

  const changeContainerItem = (data: any) => {
    setContainerItem(data);
  };

  const [getOrgOrgMappingQuery] = useGetOrgOrgMappingsLazyQuery({
    onCompleted: response => {
      if (response?.organizationOrganizationMappings?.nodes) {
        setFreightPayers(response?.organizationOrganizationMappings?.nodes);
      }
    },
  });

  const getOrgOrgMappings = (variables: {
    filters: OrganizationOrganizationMappingFilterInput;
    cursor: any;
    order: any;
  }) => {
    getOrgOrgMappingQuery({ variables });
  };

  const [getGetDangerousGoodQuery] = useGetDangerousGoodLazyQuery({
    onCompleted: response => {
      if (response?.dangerousGoods?.nodes) {
        setDangerousGoods(response?.dangerousGoods?.nodes);
      }
    },
  });

  const getDangerousGoodList = () => {
    getGetDangerousGoodQuery({
      variables: {
        filters: null,
        cursor: null,
        order: null,
      },
    });
  };

  const getFreightPayerList = () => {
    if (
      currentAccount?.organizationOrganizationMapping?.organizationTypeId ===
      OrganizationType.Subscriber
    ) {
      const variables = {
        filters: {
          parentOrganizationId: {
            eq:
              currentAccount?.organizationOrganizationMapping
                ?.parentOrganizationId,
          },
          organizationTypeId: { eq: OrganizationType.Customer },
          isDeleted: { neq: true },
        } as OrganizationOrganizationMappingFilterInput,
        cursor: null,
        order: { id: 'DESC' },
      };
      getOrgOrgMappings(variables);
    } else {
      setFreightPayers([
        {
          childOrganizationId:
            currentAccount?.organizationOrganizationMapping
              ?.childOrganizationId,
          ...currentAccount?.organizationOrganizationMapping?.childOrganization,
        },
      ]);
    }
  };

  const [getOrgLocationQuery] = useGetOrgLocationsLazyQuery({
    onCompleted: response => {
      if (response?.organizationLocations?.nodes) {
        setBookingLocations(response?.organizationLocations?.nodes);
      }
    },
  });

  const getBookingLocationList = () => {
    const variables = {
      filters: null as OrganizationLocationFilterInput,
      order: null,
    };
    getOrgLocationQuery({
      variables,
    });
  };

  const contextValue = useMemo(() => {
    return {
      bookingType,
      jobType,
      portCode,
      freightPayers,
      bookingLocations,
      dangerousGoods,
      calculateRateInput,
      containerItem,
      containerSize,
    };
  }, [
    bookingType,
    jobType,
    portCode,
    freightPayers,
    bookingLocations,
    dangerousGoods,
    calculateRateInput,
    containerItem,
    containerSize,
  ]);

  const contextChangeValue = useMemo(() => {
    return {
      changeBookingType,
      changeJobType,
      changePortCode,
      getBookingLocationList,
      getFreightPayerList,
      getDangerousGoodList,
      changeCalculateRateInput,
      changeContainerItem,
      changeContainerSize,
    };
  }, [
    changeBookingType,
    changeJobType,
    changePortCode,
    getBookingLocationList,
    getFreightPayerList,
    getDangerousGoodList,
    changeCalculateRateInput,
    changeContainerItem,
    changeContainerSize,
  ]);

  return (
    <BookingContext.Provider value={contextValue}>
      <BookingContextChange.Provider value={contextChangeValue}>
        {props.children}
      </BookingContextChange.Provider>
    </BookingContext.Provider>
  );
}
