import { Modal, ModalProps } from 'antd';
import { css, styled } from 'twin.macro';

interface StyledModalProps extends ModalProps {
  height?: number | string;
  width?: number | string;
}

export const StyledModal = styled(Modal)<StyledModalProps>(
  ({
    height = 90,
    width = 100,
  }: {
    height?: number | string;
    width?: number | string;
  }) => css`
    width: ${typeof width === 'number' ? `${width}%` : `${width}`} !important;
    height: ${
      typeof height === 'number' ? `${height}%` : `${height}`
    } !important;

    .ant-modal-content {
      padding: 0;
      height: 100%;
    }

    .ant-modal-content > .ant-modal-body {
      height: 100%;
    }'
  `,
);
