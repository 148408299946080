/* eslint no-nested-ternary: "error" */
import { PowerBI } from 'components/PowerBI';
import { dashboardConfig } from 'containers/Dashboard/config';
import usePowerBIReports from 'context/powerBIReport/hooks';
import useAuthentication from 'context/security_authentication/hook';
import { actions, reducer, sliceKey } from 'context/workspace/slice';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import tw from 'twin.macro';
import { useInjectReducer } from 'utils/redux-injectors';
import './styles.css';

export const NavTitle = tw.div`font-semibold flex flex-row items-center ml-4 px-4`;

export default () => {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });
  const { currentAccount } = useAuthentication();
  const [powerBIReportId, setPowerBIReportId] = useState('');
  const [powerBIData, setPowerBIData] = useState({} as any);
  const {
    reports,
    reportsByGroup,
    generatePowerBIReport,
    generateReportByOrganization,
    loadingPowerBIReport,
    loadingReportsByGroup,
  } = usePowerBIReports();
  const dispatch = useDispatch();

  const handleGetReportByGroup = (powerBiReport: any) => {
    if (powerBiReport?.pbiReportId && powerBiReport?.pbiGroupId) {
      generateReportByOrganization(
        powerBiReport.pbiReportId,
        powerBiReport.pbiGroupId,
      );
      setPowerBIReportId(powerBiReport.pbiReportId);
    }
  };

  useEffect(() => {
    setPowerBIReportId('');
  }, []);

  useEffect(() => {
    dispatch(
      actions.setWorkspace({
        loading: true,
        workspace: dashboardConfig,
        workspaceId: dashboardConfig?.id,
      }),
    );
    if (currentAccount?.organizationOrganizationMapping?.parentOrganizationId) {
      generatePowerBIReport(
        currentAccount?.organizationOrganizationMapping?.parentOrganizationId,
      );
    }
  }, [currentAccount]);

  useEffect(() => {
    if (!loadingPowerBIReport) {
      handleGetReportByGroup(reports?.[0]);
    } else {
      setPowerBIData({});
    }
  }, [loadingPowerBIReport]);

  const refinePowerBIData = data => {
    const embedReports = data?.embedReport || [];

    if (embedReports.length) {
      const refinedData = embedReports.map(embedReportItem => ({
        embedReport: embedReportItem,
        embedToken: data?.embedToken,
        reportId: powerBIReportId, // make sure powerBIReportId is defined
      }));

      setPowerBIData(refinedData);
    }
  };

  useEffect(() => {
    if (!loadingReportsByGroup) {
      refinePowerBIData(reportsByGroup);
    }
  }, [loadingReportsByGroup]);

  const renderPowerBI = () => {
    if (powerBIData?.length) {
      return powerBIData?.map((embedReportItem: any) => {
        return (
          <PowerBI
            key={embedReportItem.reportId}
            powerBIData={embedReportItem}
          />
        );
      });
    }
    return (
      <div className="w-full h-full bg-white flex rounded-md p-2 shadow-md powerbi-container items-center justify-center font-semibold text-center align-middle text-lg">
        NO DATA TO DISPLAY
      </div>
    );
  };

  return (
    <div className="m-2 bg-white flex rounded-md shadow-md items-center justify-center font-semibold text-center align-middle text-lg h-full">
      {renderPowerBI()}
    </div>
  );
};
