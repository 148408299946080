import { ConfigProvider } from 'antd';
import { AliasToken } from 'antd/es/theme/internal';
import React from 'react';

// https://ant.design/docs/react/customize-theme#aliastoken
// Feel free to expose more api for customizing theme
export default ({
  children,
  token,
}: {
  children: React.ReactNode;
  token: Partial<AliasToken>;
}) => (
  <ConfigProvider
    theme={{
      token,
    }}
  >
    {children}
  </ConfigProvider>
);
