import { faClose, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutoComplete, Input } from 'antd';
import {
  CountryCodeType,
  useGetMapAddressesLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import Spinner from 'components/Loaders/Spinner';
import WithFormHooksController from 'components/common/WithFormHooksController';
import { useFormHooks } from 'context/FormHooksContext';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { defaultTGERateAddress } from '../../helpers/payload-serializer';
import useRateStore from '../../store';

type Props = Readonly<{
  control: Control;
  name?: string;
  disabled?: boolean;
}>;

export default function FormControlledMapAddressAutoComplete({
  name,
  control,
  disabled,
}: Props) {
  const [options, setOptions] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const [
    setSelectedFromPlace,
    setSelectedToPlace,
    resetFromPlace,
    resetToPlace,
  ] = useRateStore(state => [
    state.setSelectedFromPlace,
    state.setSelectedToPlace,
    state.resetFromPlace,
    state.resetToPlace,
  ]);

  const { setValue } = useFormHooks();

  const [getMapAddesses, { loading }] = useGetMapAddressesLazyQuery({
    onCompleted: (response: any) => {
      const {
        mapAddress: { nodes },
      } = response;
      if (nodes?.length) {
        const redefineData = nodes.map((data: any) => {
          // data.text is the name of the region, so if the placeType is region and region is not defined, we set the region to the text
          if (data?.placeType === 'region' && !data?.region) {
            data.region = data.text;
          }
          return data;
        });
        const options = redefineData.map((data: any) => {
          return {
            value: `${data?.placeName}`,
            key: data.mapId,
          };
        });

        setAddresses(redefineData);
        setOptions(options);
      }
    },
  });

  const _debouncedUpdate = (value: string = '') => {
    if (!value) {
      setOptions([]);
      return false;
    }

    const variables = {
      input: value,
      countryCode: CountryCodeType.Nz,
      filters: null,
    };

    getMapAddesses({
      variables,
    });
    return true;
  };

  function _onSelect(_val, option) {
    const place = addresses?.find(a => a.mapId === option.key);
    if (place) {
      if (name === 'addressFromId') {
        setSelectedFromPlace(place);
      } else if (name === 'addressToId') {
        setSelectedToPlace(place);
      }
    }
  }

  function _onClear() {
    resetFromPlace();
    resetToPlace();
  }

  useEffect(() => {
    setValue(name, defaultTGERateAddress.placeName);
  }, []);

  return (
    <WithFormHooksController
      control={control}
      name={name}
      component={
        <AutoComplete
          allowClear
          disabled={disabled}
          clearIcon={<FontAwesomeIcon icon={faClose} />}
          options={options}
          onSearch={debounce(_debouncedUpdate, 400)}
          onSelect={_onSelect}
          onClear={_onClear}
          value={defaultTGERateAddress.placeName}
          filterOption={(input, option) =>
            option?.value
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          <Input
            className="px-2 rounded-md"
            placeholder="search location address"
            prefix={
              loading ? (
                <span>
                  <Spinner />
                </span>
              ) : (
                <FontAwesomeIcon
                  icon={faLocationDot}
                  style={{
                    fontSize: '12px',
                    color: 'gray',
                    marginRight: '4px',
                    opacity: 0.3,
                  }}
                />
              )
            }
          />
        </AutoComplete>
      }
    />
  );
}
