import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IDialogHeader, IDialogTrigger } from '../types';

export function Header({
  onClose,
  title,
  children,
}: IDialogTrigger & IDialogHeader & { children?: React.ReactNode }) {
  return (
    <div
      id="header"
      className="h-[64px] flex justify-between items-center border-b border-solid border-gray-300 p-2 px-4"
    >
      <div>{title}</div>
      <div className="flex items-center space-x-1 justify-end">
        {children && (
          <div className="flex">
            {children}
            <span className="flex mx-2 h-[20px] w-[1px] bg-gray-200" />
          </div>
        )}
        {onClose && (
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="cursor-pointer text-black-800 hover:text-gray-700 hover:bg-gray-100"
            onClick={onClose}
            onKeyDown={event => {
              if (event.key === 'Enter' || event.key === ' ') {
                onClose();
              }
            }}
            size="lg"
            tabIndex={0}
          />
        )}
      </div>
    </div>
  );
}
