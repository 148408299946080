import {
  FafGroupFilterInput,
  FafGroupSortInput,
  OrganizationOrganizationMappingFilterInput,
  Rate,
  RateGroupFilterInput,
  RateGroupSortInput,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { OrganizationType } from 'components/config/types/common';
import GroupSettingForm from 'containers/Financial/Rates/DetailsScreen/GroupSettingForm';
import RateForm from 'containers/Financial/Rates/DetailsScreen/RateForm';
import RateLineForm from 'containers/Financial/Rates/DetailsScreen/RateLineForm';
import MainScreen from 'containers/Financial/Rates/MainScreen';
import useRateConfig from 'containers/Financial/Rates/MainScreen/config';
import useApp from 'context/app/hooks';
import { actions as rateActions } from 'context/financials_rate/slice';
import useAuthentication from 'context/security_authentication/hook';
import { actions, reducer, sliceKey } from 'context/workspace/slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';

export default () => {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();
  const { config, detailConfig } = useRateConfig();
  const { currentAccount } = useAuthentication();
  const { openDialog, closeDialog } = useApp();

  const onRateUpdate = (response: Rate) => {
    closeDialog();
    window.$gridApi.getRowNode?.(response?.id)?.setData?.(response);
  };

  function _createNewRate() {
    openDialog({
      content: <RateForm afterValidation={onRateUpdate} />,
      dialogProps: {
        closable: false,
        height: '623px',
        width: '860px',
      },
    });
  }

  function _createNewRateLine() {
    openDialog({
      content: <RateLineForm afterValidation={onRateUpdate} />,
      dialogProps: {
        closable: false,
        height: '423px',
        width: '860px',
      },
    });
  }

  function _modifyGroupSetting() {
    openDialog({
      content: <GroupSettingForm />,
      dialogProps: {
        closable: false,
        height: '613px',
        width: '1180px',
      },
    });
  }

  dispatch(
    actions.setWorkspace({
      loading: true,
      workspace: config,
      workspaceId: config?.id,
    }),
  );

  useEffect(() => {
    dispatch(
      rateActions.setQueryOrgList({
        query: {
          cursor: null,
          filters: {
            parentOrganizationId: {
              eq:
                currentAccount?.organizationOrganizationMapping
                  ?.parentOrganizationId,
            },
            organizationTypeId: { eq: OrganizationType.Customer },
            isDeleted: { neq: true },
          } as OrganizationOrganizationMappingFilterInput,
          order: { id: 'DESC' },
        },
      }),
    );

    dispatch(
      rateActions.setQueryRateGroupList({
        query: {
          cursor: null,
          filters: {
            subscriberOrganizationId: {
              eq:
                currentAccount?.organizationOrganizationMapping
                  ?.parentOrganizationId,
            },
            isDeleted: { neq: true },
          } as RateGroupFilterInput,
          order: { name: 'ASC' } as RateGroupSortInput,
        },
      }),
    );

    dispatch(
      rateActions.setQueryFafGroupList({
        query: {
          // cursor: null,
          filters: {
            subscriberOrganizationId: {
              eq:
                currentAccount?.organizationOrganizationMapping
                  ?.parentOrganizationId,
            },
            isDeleted: { neq: true },
          } as FafGroupFilterInput,
          order: { name: 'ASC' } as FafGroupSortInput,
        },
      }),
    );
  }, []);

  return (
    <MainScreen
      useConfig={config}
      useDetailConfig={detailConfig}
      onCreateNewRate={_createNewRate}
      onCreateNewRateLine={_createNewRateLine}
      onModifyGroupSetting={_modifyGroupSetting}
    />
  );
};
