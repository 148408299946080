import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useDeleteUsersMutation,
  useGetUserByIdLazyQuery,
  useGetUsersLazyQuery,
  useModifyUserMutation,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { ERROR_MESSAGE } from 'helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  actions,
  reducer,
  selectFlagRows,
  selectItemDetails,
  selectSelectedItems,
  selectWorkspaceData,
  sliceKey,
} from './slice';

export default function useUser() {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();

  const userWorkpaceData = useSelector(selectWorkspaceData);
  const flagRows = useSelector(selectFlagRows);
  const userDetails = useSelector(selectItemDetails);
  const selectedItems = useSelector(selectSelectedItems);

  const [
    getUserListQuery,
    { loading: pendingGetUserList },
  ] = useGetUsersLazyQuery({
    onCompleted: response => {
      let data;
      if (response?.users?.nodes) {
        const nodes = response.users.nodes.map(dataItem => {
          if (dataItem?.id) {
            return dataItem;
          }
          return undefined;
        });

        let newList = nodes;
        if (
          userWorkpaceData?.nodes?.length > 0 &&
          response.users.pageInfo.hasPreviousPage
        ) {
          newList = [...(userWorkpaceData?.nodes ?? []), ...nodes];
        }
        data = {
          ...response?.users,
          nodes: newList,
        };
      }
      dispatch(
        actions.setWorkspaceData({
          workspaceData: { ...data, loading: false, error: null },
        }),
      );
    },
    onError: () => {
      dispatch(
        actions.setWorkspaceData({
          workspaceData: {
            nodes: [],
            loading: false,
            error: ERROR_MESSAGE.LOADING_DATA_ERROR,
          },
        }),
      );
    },
  });

  const getUserList = ({ filters, cursor, order }) => {
    getUserListQuery({
      variables: {
        filters,
        cursor,
        order,
      },
    });
  };

  const [getUserByIdQuery] = useGetUserByIdLazyQuery({
    onCompleted: response => {
      if (response?.userById) {
        setUserDetails(response?.userById);
      }
    },
  });

  const getUserById = userId => {
    getUserByIdQuery({
      variables: { userId },
    });
  };

  const setUserDetails = itemDetails => {
    dispatch(
      actions.setUserDetails({
        itemDetails,
      }),
    );
  };

  const resetItemDetails = () => {
    dispatch(
      actions.setUserDetails({
        itemDetails: null,
      }),
    );
  };

  const refreshWorkspaceData = () => {
    dispatch(
      actions.setWorkspaceData({
        workspaceData: null,
      }),
    );
  };

  const [
    createUserMutation,
    { loading: pendingCreateUser },
  ] = useCreateUserMutation();

  const createUser = input => {
    return createUserMutation({
      variables: { input },
    });
  };

  const [
    deleteUserMutation,
    { loading: pendingDeleteUser },
  ] = useDeleteUserMutation();

  const deleteUser = id => {
    return deleteUserMutation({
      variables: { id },
    });
  };

  const [
    deleteUsersMutation,
    { loading: pendingDeleteUsers },
  ] = useDeleteUsersMutation();

  const deleteUsers = ids => {
    return deleteUsersMutation({
      variables: { ids },
    });
  };

  const [
    updateUserMutation,
    { loading: pendingUpdateUser },
  ] = useModifyUserMutation();

  const updateUser = input => {
    return updateUserMutation({
      variables: { input },
    });
  };

  const setNewItem = newItem => {
    if (!newItem) {
      console.warn('Failed to set new Item of null value');
      return;
    }

    const newList = [newItem, ...(userWorkpaceData?.nodes ?? [])];
    const data = {
      ...userWorkpaceData,
      nodes: newList,
    };
    dispatch(
      actions.setNewUser({
        newItem,
        workspaceData: data,
        flagRows: [newItem],
      }),
    );
  };

  const setUpdatedItem = updatedItem => {
    const newList = userWorkpaceData?.nodes?.map(item => {
      if (item?.id === updatedItem?.id) {
        return { ...item, ...updatedItem };
      }
      return item;
    });
    const data = {
      ...userWorkpaceData,
      nodes: newList,
    };
    dispatch(
      actions.setUpdatedUser({
        updatedItem,
        workspaceData: data,
        flagRows: [updatedItem],
      }),
    );
  };

  const setSelectedItems = rows => {
    dispatch(
      actions.setSelectedItems({
        selectedItems: rows,
      }),
    );
  };

  return {
    refreshWorkspaceData,
    getUserList,
    getUserById,
    setUserDetails,
    createUser,
    setNewItem,
    setUpdatedItem,
    setSelectedItems,
    resetItemDetails,
    deleteUser,
    deleteUsers,
    updateUser,
    selectedItems,
    userDetails,
    flagRows,
    userWorkpaceData,
    pendingCreateUser,
    pendingGetUserList,
    pendingDeleteUsers,
    pendingDeleteUser,
    pendingUpdateUser,
  };
}
