import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

type WithFormHooksControllerProps<T> = Readonly<{
  name: Path<T>;
  component: React.ReactElement | JSX.Element;
  control: Control<T>;
}>;

export default function WithFormHooksController<T extends FieldValues>({
  name,
  component,
  control,
}: WithFormHooksControllerProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          {React.cloneElement(component, {
            // attach necessary props accordingly
            // https://react-hook-form.com/docs/usecontroller/controller
            ...field,
            ...component.props,
          })}
        </>
      )}
    />
  );
}
