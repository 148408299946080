import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ScreenViewState {
  defaultViews: any[];
  screenViewRegistry: {};
  screenViewSettings: {};
  currentView: {};
  activeTab: {};
  updatingTab: {};
  updatingColumnDefs: {};
  pendingScreenViewList: boolean;
}
export const initialState: ScreenViewState = {
  defaultViews: null,
  screenViewRegistry: null,
  screenViewSettings: null,
  currentView: null,
  activeTab: null,
  updatingTab: null,
  updatingColumnDefs: null,
  pendingScreenViewList: undefined,
};

const storeSlice = createSlice({
  name: 'screenViews',
  initialState,
  reducers: {
    setDefaultViews: (state, action: PayloadAction<any>) => {
      const { defaultViews } = action.payload;
      state.defaultViews = defaultViews;
    },
    setScreenViewRegistry: (state, action: PayloadAction<any>) => {
      const {
        screenViewRegistry,
        screenViewSettings,
        currentView,
        pendingScreenViewList,
      } = action.payload;
      state.screenViewRegistry = screenViewRegistry;
      state.screenViewSettings = screenViewSettings;
      state.currentView = currentView;
      state.pendingScreenViewList = pendingScreenViewList;
    },
    updateScreenViewRegistry: (state, action: PayloadAction<any>) => {
      const {
        screenViewRegistry,
        screenViewSettings,
        currentView,
        pendingScreenViewList,
        updatingTab,
      } = action.payload;
      state.screenViewRegistry = screenViewRegistry;
      state.screenViewSettings = screenViewSettings;
      state.currentView = currentView;
      state.pendingScreenViewList = pendingScreenViewList;
      state.updatingTab = updatingTab;
    },
    updateView: (state, action: PayloadAction<any>) => {
      const { screenViewSettings, currentView } = action.payload;
      state.screenViewSettings = screenViewSettings;
      state.currentView = currentView;
    },
    setActiveTab: (state, action: PayloadAction<any>) => {
      const { activeTab } = action.payload;
      state.activeTab = activeTab;
    },
    updateTab: (state, action: PayloadAction<any>) => {
      const { updatingTab } = action.payload;
      state.updatingTab = updatingTab;
    },
    setCurrentView: (state, action: PayloadAction<any>) => {
      const { currentView } = action.payload;
      state.currentView = currentView;
    },
    resetUpdatingTab: state => {
      state.updatingTab = null;
    },
  },
});

export const selectDefaultViews = state => state.screenViews.defaultViews;
export const selectScreenViewRegistry = state =>
  state.screenViews.screenViewRegistry;
export const selectPending = state => state.screenViews.pendingScreenViewList;
export const selectScreenViewSettings = state =>
  state.screenViews.screenViewSettings;
export const selectCurrentView = state => state.screenViews.currentView;
export const selectActiveTab = state => state.screenViews.activeTab;
export const selectUpdatingTab = state => state.screenViews.updatingTab;
export const selectLoading = state => state.screenViews.loading;
export const selectUpdatingColumnDefs = state =>
  state.screenViews.updatingColumnDefs;
export const { actions, reducer, name: sliceKey } = storeSlice;
